import { useNavigate } from "react-router-dom";

import icare from "../../assets/image/icare.png";

const PatientHeader = () => {

    const navigate = useNavigate();

    return (
        <div className="patient-home">
            <img src={icare} alt="icare-logo" onClick={() => navigate("/patient/patientHome")} />
        </div>
    );
}

export default PatientHeader;