import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { InputLabel, Select, MenuItem, FormControl, CircularProgress, Checkbox, TextField, Autocomplete } from "@mui/material";
import { connect } from 'react-redux';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import DatePicker from "react-datepicker";
import { performanceByBrand } from "../../redux/actions/adminActions";
import { CSVLink } from "react-csv";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

const mySwal = withReactContent(Swal);

const PerformanceByBrand = ({ role, token, accountNameList, performanceByBrand, performanceByBrandData }) => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [hcpType, setHcpType] = useState("");
    const [accountName, setAccountName] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    useEffect(() => {

        if (role && token) {
            if (!performanceByBrandData) {
                axios.post("/api/admin/performanceByBrand", { role, token, accountName: [] })
                    .then(res => {
                        let data = {};
                        data.data = res.data.dataObject;
                        data.startDate = "";
                        data.endDate = "";
                        data.hcpType = "";
                        data.accountName = [];
                        performanceByBrand(data);
                        setLoading(false);
                    })
                    .catch(err => {
                        setLoading(false);
                        if (err.response.data.error === "Token Expired") {
                            mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
                                .then(click => {
                                    if (click.isConfirmed) {
                                        navigate('/admin/login');
                                    }
                                })
                        } else {
                            mySwal.fire("Error", err.response.data.error, "error")
                        }
                    });
            } else {
                setStartDate(performanceByBrandData.startDate);
                setEndDate(performanceByBrandData.endDate);
                setHcpType(performanceByBrandData.hcpType);
                setAccountName(performanceByBrandData.accountName);
                setLoading(false);
            }
        } else {
            navigate('/admin/login');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDate = dates => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    const handleFilter = () => {
        setLoading(true);

        let sendThis = {
            role,
            token,
            hcpType,
            accountName
        }

        if (startDate && endDate) {
            sendThis.startDate = new Date(startDate).getFullYear() + "-" + (new Date(startDate).getMonth() + 1) + "-" + new Date(startDate).getDate()
            sendThis.endDate = new Date(endDate).getFullYear() + "-" + (new Date(endDate).getMonth() + 1) + "-" + new Date(endDate).getDate()
        }

        axios.post('/api/admin/performanceByBrand', sendThis)
            .then(res => {
                let data = {};
                data.data = res.data.dataObject;
                data.startDate = startDate;
                data.endDate = endDate;
                data.hcpType = hcpType;
                data.accountName = accountName;
                performanceByBrand(data);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                if (err.response.data.error === "Token Expired") {
                    mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
                        .then(click => {
                            if (click.isConfirmed) {
                                navigate('/admin/login');
                            }
                        })
                } else {
                    mySwal.fire("Error", err.response.data.error, "error")
                }
            });
    }

    const handleReset = () => {
        setStartDate("");
        setEndDate("");
        setHcpType("");
        setAccountName([]);
        setLoading(true);

        axios.post('/api/admin/performanceByBrand', { role, token, accountName: [] })
            .then(res => {
                let data = {};
                data.data = res.data.dataObject;
                data.startDate = "";
                data.endDate = "";
                data.hcpType = "";
                data.accountName = [];
                performanceByBrand(data);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                if (err.response.data.error === "Token Expired") {
                    mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
                        .then(click => {
                            if (click.isConfirmed) {
                                navigate('/admin/login');
                            }
                        })
                } else {
                    mySwal.fire("Error", err.response.data.error, "error")
                }
            });
    }

    const options = {
        responsive: true,
        plugins: {
            tooltip: {
                enabled: false
            },
            legend: {
                position: 'top'
            },
            datalabels: {
                anchor: 'end',
                align: 'end',
                color: "black",
                padding: -9,
                font: {
                    lineHeight: 1.5
                },
                display: function (context) {
                    return context.dataset.data[context.dataIndex].y
                },
                formatter: function (value) {
                    return value.y
                }
            }
        }
    };

    let datasets = [];
    let csv = [];

    const csvData = {
        headers: [
            { label: "Brand", key: "brand" },
            { label: "Submission Quantity", key: "quantity" },
        ],
        data: csv
    }

    if (performanceByBrandData) {
        datasets = [
            {
                label: "Lipitor",
                skipNull: true,
                data: [
                    { x: "Lipitor", y: performanceByBrandData.data.lipitor },
                    { x: "Norvasc", y: null },
                    { x: "Caduet", y: null },
                    { x: "Viagra", y: null },
                    { x: "Legalon", y: null },
                    { x: "Viartril-S Sac", y: null },
                    { x: "Viartril-S Cap", y: null },
                    { x: "Adaxil", y: null },
                    { x: "Celebrex", y: null },
                    { x: "Lyrica", y: null },
                ],
                backgroundColor: "#01718D",
                borderRadius: 7,
                borderSkipped: false
            },
            {
                label: "Norvasc",
                skipNull: true,
                data: [
                    { x: "Lipitor", y: null },
                    { x: "Norvasc", y: performanceByBrandData.data.norvasc },
                    { x: "Caduet", y: null },
                    { x: "Viagra", y: null },
                    { x: "Legalon", y: null },
                    { x: "Viartril-S Sac", y: null },
                    { x: "Viartril-S Cap", y: null },
                    { x: "Adaxil", y: null },
                    { x: "Celebrex", y: null },
                    { x: "Lyrica", y: null },
                ],
                backgroundColor: "#3A17D0",
                borderRadius: 7,
                borderSkipped: false
            },
            {
                label: "Caduet",
                skipNull: true,
                data: [
                    { x: "Lipitor", y: null },
                    { x: "Norvasc", y: null },
                    { x: "Caduet", y: performanceByBrandData.data.caduet },
                    { x: "Viagra", y: null },
                    { x: "Legalon", y: null },
                    { x: "Viartril-S Sac", y: null },
                    { x: "Viartril-S Cap", y: null },
                    { x: "Adaxil", y: null },
                    { x: "Celebrex", y: null },
                    { x: "Lyrica", y: null },
                ],
                backgroundColor: "#C0007E",
                borderRadius: 7,
                borderSkipped: false
            },
            {
                label: "Viagra",
                skipNull: true,
                data: [
                    { x: "Lipitor", y: null },
                    { x: "Norvasc", y: null },
                    { x: "Caduet", y: null },
                    { x: "Viagra", y: performanceByBrandData.data.viagra },
                    { x: "Legalon", y: null },
                    { x: "Viartril-S Sac", y: null },
                    { x: "Viartril-S Cap", y: null },
                    { x: "Adaxil", y: null },
                    { x: "Celebrex", y: null },
                    { x: "Lyrica", y: null },
                ],
                backgroundColor: "#0082F4",
                borderRadius: 7,
                borderSkipped: false
            },
            {
                label: "Legalon",
                skipNull: true,
                data: [
                    { x: "Lipitor", y: null },
                    { x: "Norvasc", y: null },
                    { x: "Caduet", y: null },
                    { x: "Viagra", y: null },
                    { x: "Legalon", y: performanceByBrandData.data.legalon },
                    { x: "Viartril-S Sac", y: null },
                    { x: "Viartril-S Cap", y: null },
                    { x: "Adaxil", y: null },
                    { x: "Celebrex", y: null },
                    { x: "Lyrica", y: null },
                ],
                backgroundColor: "#29AF4B",
                borderRadius: 7,
                borderSkipped: false
            },
            {
                label: "Viartril-S Sac",
                skipNull: true,
                data: [
                    { x: "Lipitor", y: null },
                    { x: "Norvasc", y: null },
                    { x: "Caduet", y: null },
                    { x: "Viagra", y: null },
                    { x: "Legalon", y: null },
                    { x: "Viartril-S Sac", y: performanceByBrandData.data.viartrilSSachets },
                    { x: "Viartril-S Cap", y: null },
                    { x: "Adaxil", y: null },
                    { x: "Celebrex", y: null },
                    { x: "Lyrica", y: null },
                ],
                backgroundColor: "#EE1B24",
                borderRadius: 7,
                borderSkipped: false
            },
            {
                label: "Viartril-S Cap",
                skipNull: true,
                data: [
                    { x: "Lipitor", y: null },
                    { x: "Norvasc", y: null },
                    { x: "Caduet", y: null },
                    { x: "Viagra", y: null },
                    { x: "Legalon", y: null },
                    { x: "Viartril-S Sac", y: null },
                    { x: "Viartril-S Cap", y: performanceByBrandData.data.viartrilSCapsules },
                    { x: "Adaxil", y: null },
                    { x: "Celebrex", y: null },
                    { x: "Lyrica", y: null },
                ],
                backgroundColor: "#EE1B24",
                borderRadius: 7,
                borderSkipped: false
            },
            {
                label: "Adaxil",
                skipNull: true,
                data: [
                    { x: "Lipitor", y: null },
                    { x: "Norvasc", y: null },
                    { x: "Caduet", y: null },
                    { x: "Viagra", y: null },
                    { x: "Legalon", y: null },
                    { x: "Viartril-S Sac", y: null },
                    { x: "Viartril-S Cap", y: null },
                    { x: "Adaxil", y: performanceByBrandData.data.adaxilSachets },
                    { x: "Celebrex", y: null },
                    { x: "Lyrica", y: null },
                ],
                backgroundColor: "#009EC9",
                borderRadius: 7,
                borderSkipped: false
            },
            {
                label: "Celebrex",
                skipNull: true,
                data: [
                    { x: "Lipitor", y: null },
                    { x: "Norvasc", y: null },
                    { x: "Caduet", y: null },
                    { x: "Viagra", y: null },
                    { x: "Legalon", y: null },
                    { x: "Viartril-S Sac", y: null },
                    { x: "Viartril-S Cap", y: null },
                    { x: "Adaxil", y: null },
                    { x: "Celebrex", y: performanceByBrandData.data.celebrexCapsules },
                    { x: "Lyrica", y: null },
                ],
                backgroundColor: "#AD2B4A",
                borderRadius: 7,
                borderSkipped: false
            },
            {
                label: "Lyrica",
                skipNull: true,
                data: [
                    { x: "Lipitor", y: null },
                    { x: "Norvasc", y: null },
                    { x: "Caduet", y: null },
                    { x: "Viagra", y: null },
                    { x: "Legalon", y: null },
                    { x: "Viartril-S Sac", y: null },
                    { x: "Viartril-S Cap", y: null },
                    { x: "Adaxil", y: null },
                    { x: "Celebrex", y: null },
                    { x: "Lyrica", y: performanceByBrandData.data.lyricaCapsules },
                ],
                backgroundColor: "#1F1C6B",
                borderRadius: 7,
                borderSkipped: false
            }
        ]

        const lipitorObject = {
            brand: "Lipitor",
            quantity: performanceByBrandData.data.lipitor
        }
        csv.push(lipitorObject);

        const norvascObject = {
            brand: "Norvasc",
            quantity: performanceByBrandData.data.norvasc
        }
        csv.push(norvascObject);

        const caduetObject = {
            brand: "Caduet",
            quantity: performanceByBrandData.data.caduet
        }
        csv.push(caduetObject);

        const viagraObject = {
            brand: "Viagra",
            quantity: performanceByBrandData.data.viagra
        }
        csv.push(viagraObject);

        const legalonObject = {
            brand: "Legalon",
            quantity: performanceByBrandData.data.legalon
        }
        csv.push(legalonObject);

        const viartrilSSachetsObject = {
            brand: "Viartril-S Sac",
            quantity: performanceByBrandData.data.viartrilSSachets
        }
        csv.push(viartrilSSachetsObject);

        const viartrilSCapsulesObject = {
            brand: "Viartril-S Cap",
            quantity: performanceByBrandData.data.viartrilSCapsules
        }
        csv.push(viartrilSCapsulesObject);

        const adaxilSachetsObject = {
            brand: "Adaxil",
            quantity: performanceByBrandData.data.adaxilSachets
        }
        csv.push(adaxilSachetsObject);

        const celebrexObject = {
            brand: "Celebrex",
            quantity: performanceByBrandData.data.celebrexCapsules
        }
        csv.push(celebrexObject);

        const lyricaObject = {
            brand: "Lyrica",
            quantity: performanceByBrandData.data.lyricaCapsules
        }
        csv.push(lyricaObject);
    }

    const data = {
        labels: ["Lipitor", "Norvasc", "Caduet", "Viagra", "Legalon", "Viartril-S Sac", "Viartril-S Cap", "Adaxil", "Celebrex", "Lyrica"],
        datasets: datasets
    }

    return (
        <div>
            <div>
                <p className="admin-dashboard-subtitle"><b>PERFORMANCE BY BRAND</b></p>
                <div>
                    <div className="d-flex justify-content-end">
                        {
                            role !== "hcp" ? (
                                <div>
                                    <FormControl sx={{ minWidth: 120 }}>
                                        <InputLabel id="hcp-type">HCP Type</InputLabel>
                                        <Select
                                            labelId="hcp-type"
                                            id="hcp-type"
                                            value={hcpType}
                                            label="HCP Type"
                                            onChange={e => setHcpType(e.target.value)}
                                        >
                                            <MenuItem value="Hospital">Hospital</MenuItem>
                                            <MenuItem value="Clinic">Clinic</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            ) : null
                        }
                        {
                            role !== "hcp" ? (
                                <div className="ps-2">
                                    <Autocomplete
                                        multiple
                                        id="accountName"
                                        options={accountNameList}
                                        getOptionLabel={(option) => option.hcpName}
                                        disableCloseOnSelect
                                        value={accountName}
                                        onChange={(e, value) => {
                                            setAccountName(value);
                                        }}
                                        renderOption={(props, accountNameList, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    checked={selected}
                                                />
                                                {accountNameList.hcpName}
                                            </li>
                                        )}
                                        style={{ width: 250, backgroundColor: "white" }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Account Name" />
                                        )}
                                    />
                                </div>
                            ) : null
                        }
                        <div className="ps-2">
                            <DatePicker placeholderText="Date" value={startDate ? `${startDate ? new Date(startDate).toLocaleDateString('en-GB') : ""} - ${endDate ? new Date(endDate).toLocaleDateString('en-GB') : ""}` : ""} onChange={handleDate} startDate={startDate} endDate={endDate} selectsRange shouldCloseOnSelect={false} className="form-control py-3" />
                        </div>
                    </div>
                    <div className="d-flex justify-content-end pt-2">
                        <div className="ps-2">
                            <button type="button" className="btn btn-primary shadow-none admin-button" onClick={() => handleFilter()}>Filter</button>
                        </div>
                        <div className="ps-2">
                            <button type="button" className="btn btn-primary shadow-none admin-button" onClick={() => handleReset()}>Reset</button>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                {
                    loading ? (
                        <div className="text-center">
                            <CircularProgress />
                        </div>
                    ) : (
                        <div>
                            <div className="admin-dashboard-border my-2">
                                <Bar options={options} data={data} />
                            </div>
                            <div className="d-flex justify-content-end mb-2">
                                <CSVLink className="btn btn-primary shadow-none" data={csvData.data} headers={csvData.headers} filename={`performance_by_brand_${new Date().getFullYear()}_${new Date().getMonth() + 1}_${new Date().getDate()}.csv`} target="_blank">
                                    Export Data
                                </CSVLink>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.admin.token,
        role: state.admin.role,
        performanceByBrandData: state.admin.performanceByBrand
    };
};

const mapDispatchToProps = dispatch => {
    return {
        performanceByBrand: data => dispatch(performanceByBrand(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceByBrand);