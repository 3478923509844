import { useNavigate } from "react-router-dom";

// images
import leftArrow from "../assets/image/left-arrow.png";
import myhealthmylife from "../assets/image/brandInfo/brandInfoMyHealthMyLife.png";
import legalon from "../assets/image/brandInfo/brandInfoLegalon.png";
import menHealth from "../assets/image/brandInfo/brandInfoMenHealth.png";
import viatrilS from "../assets/image/brandInfo/brandInfoViartrilS.png";

// component
import HcpNavigationBar from "./components/navigationBar";
import HcpHeader from "./components/header";

const HcpBrandInfo = () => {

    const navigate = useNavigate();

    return (
        <div>
            <HcpHeader />
            <div className="hcp-whole-body">
                <div className="page-title mt-3">
                    <img src={leftArrow} alt="back-icon" className="hcp-left-arrow" onClick={() => navigate('/hcp/home')} />
                    <h2>Brand Info</h2>
                </div>

                <div className="brand-info-page">
                    <div className="brand-info-item">
                        <div className="container py-3 px-4">
                            <a href="https://www.myhealthmylife.com.my/" target="_blank" rel="noreferrer">
                                <img src={myhealthmylife} alt="brandinfo1" className="img-fluid" style={{ borderRadius: "20px" }} />
                            </a>
                        </div>
                        <div className="container py-3 px-4">
                            <a href="https://www.menshealth.com.my/" target="_blank" rel="noreferrer">
                                <img src={menHealth} alt="brandinfo2" className="img-fluid" style={{ borderRadius: "20px" }} />
                            </a>
                        </div>
                    </div>
                    <div className="brand-info-item">
                        <div className="container py-3 px-4">
                            <a href="https://viartril-s-my.com" target="_blank" rel="noreferrer">
                                <img src={viatrilS} alt="brandinfo3" className="img-fluid" style={{ borderRadius: "20px" }} />
                            </a>
                        </div>
                        <div className="container py-3 px-4">
                            <a href="https://legalon.my" target="_blank" rel="noreferrer">
                                <img src={legalon} alt="brandinfo4" className="img-fluid" style={{ borderRadius: "20px" }} />
                            </a>
                        </div>
                    </div>
                </div>

                <HcpNavigationBar />

            </div>
        </div>
    );
}

export default HcpBrandInfo;
