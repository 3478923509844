import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { InputLabel, Select, MenuItem, FormControl, CircularProgress, Checkbox, TextField, Autocomplete, Tooltip, IconButton } from "@mui/material";
import { InfoOutlined } from '@mui/icons-material';
import { connect } from 'react-redux';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
import { MDBDataTableV5 } from 'mdbreact';
import { averagePurchase } from "../../redux/actions/adminActions";
import { medicationBrandList } from "../../dynamicController";

const mySwal = withReactContent(Swal);

const AveragePurchase = ({ role, token, accountNameList, averagePurchase, averageData }) => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [hcpType, setHcpType] = useState("");
    const [accountName, setAccountName] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [brand, setBrand] = useState([]);

    useEffect(() => {

        if (role && token) {
            if (!averageData) {
                axios.post("/api/admin/averagePurchase", { role, token, accountName: [], brand: [] })
                    .then(res => {
                        let data = {};
                        data.data = res.data.data;
                        data.startDate = "";
                        data.endDate = "";
                        data.hcpType = "";
                        data.accountName = [];
                        data.brand = [];
                        averagePurchase(data);
                        setLoading(false);
                    })
                    .catch(err => {
                        setLoading(false);
                        if (err.response.data.error === "Token Expired") {
                            mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
                                .then(click => {
                                    if (click.isConfirmed) {
                                        navigate('/admin/login');
                                    }
                                })
                        } else {
                            mySwal.fire("Error", err.response.data.error, "error")
                        }
                    });
            } else {
                setStartDate(averageData.startDate);
                setEndDate(averageData.endDate);
                setHcpType(averageData.hcpType);
                setAccountName(averageData.accountName);
                setBrand(averageData.brand);
                setLoading(false);
            }
        } else {
            navigate('/admin/login');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDate = dates => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    const handleFilter = () => {
        setLoading(true);

        let sendThis = {
            role,
            token,
            hcpType,
            accountName,
            brand
        }

        if (startDate && endDate) {
            sendThis.startDate = new Date(startDate).getFullYear() + "-" + (new Date(startDate).getMonth() + 1) + "-" + new Date(startDate).getDate()
            sendThis.endDate = new Date(endDate).getFullYear() + "-" + (new Date(endDate).getMonth() + 1) + "-" + new Date(endDate).getDate()
        }

        axios.post('/api/admin/averagePurchase', sendThis)
            .then(res => {
                let data = {};
                data.data = res.data.data;
                data.startDate = startDate;
                data.endDate = endDate;
                data.hcpType = hcpType;
                data.accountName = accountName;
                data.brand = brand;
                averagePurchase(data);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                if (err.response.data.error === "Token Expired") {
                    mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
                        .then(click => {
                            if (click.isConfirmed) {
                                navigate('/admin/login');
                            }
                        })
                } else {
                    mySwal.fire("Error", err.response.data.error, "error")
                }
            });
    }

    const handleReset = () => {
        setStartDate("");
        setEndDate("");
        setHcpType("");
        setBrand([]);
        setAccountName([]);
        setLoading(true);

        axios.post('/api/admin/averagePurchase', { role, token, accountName: [], brand: [] })
            .then(res => {
                let data = {};
                data.data = res.data.data;
                data.startDate = "";
                data.endDate = "";
                data.hcpType = "";
                data.accountName = [];
                data.brand = [];
                averagePurchase(data);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                if (err.response.data.error === "Token Expired") {
                    mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
                        .then(click => {
                            if (click.isConfirmed) {
                                navigate('/admin/login');
                            }
                        })
                } else {
                    mySwal.fire("Error", err.response.data.error, "error")
                }
            });
    }

    const numberWithCommas = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    let table = [];

    let tableData = {
        columns: [
            {
                field: "brand",
                label: "Brand",
                sort: "asc"
            },
            {
                field: "quantitySubmitted",
                label: "Quantity Submitted",
                sort: "asc"
            },
            {
                field: "quantityRedeemed",
                label: "Quantity Redeemed",
                sort: "asc"
            },
            {
                field: "uniquePatients",
                label: "Unique Patients",
                sort: "asc"
            },
            {
                field: "monthlyConsumption",
                label: "Monthly Consumption",
                sort: "asc"
            },
            {
                field: "durationOfTreatment",
                label: "DoT (mth)",
                sort: "asc"
            }
        ],
        rows: table ? table : []
    }

    let csv = [];

    const csvData = {
        headers: [
            { label: "Brand", key: "brand" },
            { label: "Quantity Submitted", key: "quantitySubmitted" },
            { label: "Quantity Redeemed", key: "quantityRedeemed" },
            { label: "Unique Patients", key: "uniquePatients" },
            { label: "Monthly Consumption", key: "monthlyConsumption" },
            { label: "DoT (mth)", key: "durationOfTreatment" },
        ],
        data: csv
    }

    if (averageData) {
        for (let i = 0; i < averageData.data.length; i++) {
            const tableObject = {
                brand: averageData.data[i].brand,
                quantitySubmitted: numberWithCommas(averageData.data[i].quantitySubmitted),
                quantityRedeemed: numberWithCommas(averageData.data[i].quantityRedeemed),
                uniquePatients: numberWithCommas(averageData.data[i].uniquePatients),
                monthlyConsumption: numberWithCommas(averageData.data[i].monthlyConsumption),
                durationOfTreatment: numberWithCommas(averageData.data[i].durationOfTreatment)
            }
            table.push(tableObject);

            const csvObject = {
                brand: averageData.data[i].brand,
                quantitySubmitted: averageData.data[i].quantitySubmitted,
                quantityRedeemed: averageData.data[i].quantityRedeemed,
                uniquePatients: averageData.data[i].uniquePatients,
                monthlyConsumption: averageData.data[i].monthlyConsumption,
                durationOfTreatment: averageData.data[i].durationOfTreatment
            }
            csv.push(csvObject);
        }
    }

    return (
        <div>
            <div className="d-flex mt-3">
                <p className="admin-dashboard-text"><b>DURATION OF TREATMENT</b></p>
                <div>
                    <Tooltip title="Number of qty (unit) submitted relative to number of patients on the same medication. This is a rough estimate of average duration of treatment for each product per patient">
                        <IconButton>
                            <InfoOutlined style={{ color: "#403B3B" }} fontSize="large" />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>

            {
                role === "dev" || role === "admin" ? (
                    <div className="d-flex justify-content-end pb-2">
                        <div>
                            <Autocomplete
                                multiple
                                id="brand"
                                options={medicationBrandList}
                                getOptionLabel={(option) => option.brandName}
                                disableCloseOnSelect
                                value={brand}
                                onChange={(e, value) => {
                                    setBrand(value);
                                }}
                                renderOption={(props, medicationBrandList, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            checked={selected}
                                        />
                                        {medicationBrandList.brandName}
                                    </li>
                                )}
                                style={{ width: 250, backgroundColor: "white" }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Brand" />
                                )}
                            />
                        </div>
                    </div>
                ) : null
            }

            <div className="d-flex justify-content-end">
                {
                    role === "hcp" || role === "sales" ? (
                        <div>
                            <Autocomplete
                                multiple
                                id="brand"
                                options={medicationBrandList}
                                getOptionLabel={(option) => option.brandName}
                                disableCloseOnSelect
                                value={brand}
                                onChange={(e, value) => {
                                    setBrand(value);
                                }}
                                renderOption={(props, medicationBrandList, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            checked={selected}
                                        />
                                        {medicationBrandList.brandName}
                                    </li>
                                )}
                                style={{ width: 250, backgroundColor: "white" }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Brand" />
                                )}
                            />
                        </div>
                    ) : null
                }
                {
                    role !== "hcp" ? (
                        <div className="ps-2">
                            <FormControl sx={{ minWidth: 120 }}>
                                <InputLabel id="hcp-type">HCP Type</InputLabel>
                                <Select
                                    labelId="hcp-type"
                                    id="hcp-type"
                                    value={hcpType}
                                    label="HCP Type"
                                    onChange={e => setHcpType(e.target.value)}
                                >
                                    <MenuItem value="Hospital">Hospital</MenuItem>
                                    <MenuItem value="Clinic">Clinic</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    ) : null
                }
                {
                    role !== "hcp" ? (
                        <div className="ps-2">
                            <Autocomplete
                                multiple
                                id="accountName"
                                options={accountNameList}
                                getOptionLabel={(option) => option.hcpName}
                                disableCloseOnSelect
                                value={accountName}
                                onChange={(e, value) => {
                                    setAccountName(value);
                                }}
                                renderOption={(props, accountNameList, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            checked={selected}
                                        />
                                        {accountNameList.hcpName}
                                    </li>
                                )}
                                style={{ width: 250, backgroundColor: "white" }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Account Name" />
                                )}
                            />
                        </div>
                    ) : null
                }
                <div className="ps-2">
                    <DatePicker placeholderText="Date" value={startDate ? `${startDate ? new Date(startDate).toLocaleDateString('en-GB') : ""} - ${endDate ? new Date(endDate).toLocaleDateString('en-GB') : ""}` : ""} onChange={handleDate} startDate={startDate} endDate={endDate} selectsRange shouldCloseOnSelect={false} className="form-control py-3" />
                </div>
            </div>
            <div className="d-flex justify-content-end pt-2">
                <div className="ps-2">
                    <button type="button" className="btn btn-primary shadow-none admin-button" onClick={() => handleFilter()}>Filter</button>
                </div>
                <div className="ps-2">
                    <button type="button" className="btn btn-primary shadow-none admin-button" onClick={() => handleReset()}>Reset</button>
                </div>
            </div>

            <div>
                {
                    loading ? (
                        <div className="text-center">
                            <CircularProgress />
                        </div>
                    ) : (
                        <div>
                            <div className="admin-dashboard-border my-2">
                                <MDBDataTableV5 data={tableData} hover responsive bordered />
                            </div>
                            <div className="d-flex justify-content-end mb-2">
                                <CSVLink className="btn btn-primary shadow-none" data={csvData.data} headers={csvData.headers} filename={`duration_of_treatment_${new Date().getFullYear()}_${new Date().getMonth() + 1}_${new Date().getDate()}.csv`} target="_blank">
                                    Export Data
                                </CSVLink>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )

}

const mapStateToProps = state => {
    return {
        token: state.admin.token,
        role: state.admin.role,
        averageData: state.admin.averagePurchase
    };
};

const mapDispatchToProps = dispatch => {
    return {
        averagePurchase: data => dispatch(averagePurchase(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AveragePurchase);