import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from "@mui/material";
import axios from 'axios';
import { adminLogin } from "../redux/actions/adminActions";
import { connect } from 'react-redux';

import logo from "../assets/image/hcpWelcomeLogo.png";

const AdminLogin = ({ adminLogin }) => {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const navigate = useNavigate();

    const handleSubmit = e => {
        e.preventDefault();

        setError("");
        setLoading(true);

        axios.post('/api/admin/login', { username, password })
            .then(response => {
                adminLogin(response.data);
                setLoading(false);
                navigate('/admin/dashboard');
            })
            .catch(err => {
                setError(err.response.data.error);
                setLoading(false);
            });
    }

    return (

        <div id="admin-login">
            <div id="admin-login-container">
                <div id="admin-image-container">
                    <div id="image-container">
                        <img src={logo} alt="icare" className="img-fluid" />
                        <h1>iCare Connect</h1>
                    </div>
                </div>

                <div id="admin-login-form">
                    <div>
                        <h4>Admin Login</h4>
                        <p><small>Please enter your username and password to login.</small></p>
                    </div>

                    <form onSubmit={handleSubmit}>
                        {error ? (
                            <div className="alert alert-warning" role="alert">
                                <strong>{error}</strong>
                            </div>
                        ) : null}
                        <div>
                            <input type="text" id="username" name="username" placeholder="Username / Email" className="form-control" onChange={e => setUsername(e.target.value)} value={username} required />
                            <input type="password" id="password" name="password" placeholder="Password" className="form-control mt-2" onChange={e => setPassword(e.target.value)} value={password} required />

                            <div className="mt-3">
                                {loading ? (
                                    <div className="text-center">
                                        <CircularProgress />
                                    </div>
                                ) : <input type="submit" className="btn btn-primary form-control" value="Sign In" />}
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    )

}

const mapDispatchToProps = dispatch => {
    return {
        adminLogin: data => dispatch(adminLogin(data)),
    };
};

export default connect(null, mapDispatchToProps)(AdminLogin);