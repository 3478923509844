import React, { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CountryPhone from "../patient/components/CountryPhone";
import axios from 'axios';

const HcpRequestOtp = () => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [isSubmit, setIsSubmit] = useState(false);
    const [number, setNumber] = useState("");
    const [countryArr, setCountryArr] = useState([]);

    const handleSubmit = e => {
        e.preventDefault();
        const country = {
            code: countryArr.countryCode,
            country: countryArr.name
        }
        setError("")
        setLoading(true);

        axios.post('/api/hcp/checkPatient', { number, country })
            .then(res => {
                localStorage.setItem("patient-number", number);
                axios.post('/api/patient/sendOtp', { number })
                    .then(res => {
                        setLoading(false);
                        navigate("/hcp/otp");
                    })
                    .catch(err => {
                        setError(err.response.data.error);
                        setLoading(false);
                    });
            })
            .catch(err => {
                setError(err.response.data.error);
                setLoading(false);
            });
    }
    useEffect(() => {
        if (error) {
          const timer = setTimeout(() => {
            setError('');
            setIsSubmit(false);
          }, 5000);
    
          return () => clearTimeout(timer);
        }
      }, [error]);

    return (
        <div className="hcp-signup-page">
            <div style={{ height: "5vh" }}></div>
            <div className="hcp-signup-container">

                <div className="form-container">
                    <div>
                        <h2 className="hcp-signup-title">Sign up</h2>
                        <p className="sign-up-subtitle"><i>* Mandatory field to be filled</i></p>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div>
                            <label className="hcp-signup-phone-label"><b>PHONE NUMBER *</b></label>
                            <CountryPhone value={number} onChange={(value, country) =>  {setNumber(value);setCountryArr(country)}} 
                            isValid={(value, country) => {
                                if (value.match(/6001/)) {
                                setError('Invalid value: '+value);
                                setIsSubmit(true);
                                } else {
                                console.log(true);
                                setIsSubmit(false);
                                }
                            }}/>
                        </div>

                        {
                            error ? (
                                <div className="pt-3">
                                    <p style={{ color: "red", textAlign: "center" }}>{error}</p>
                                </div>
                            ) : null
                        }

                        {
                            loading ? (
                                <div className="text-center">
                                    <CircularProgress />
                                </div>
                            ) : (
                                <div className="button-container">
                                    <button type="submit" className="btn form-control hcp-signup-button py-3" disabled={isSubmit}>
                                        <b>REQUEST OTP</b>
                                    </button>
                                </div>
                            )
                        }

                    </form>
                </div>
            </div>
        </div>
    );
};

export default HcpRequestOtp;
