import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from "@mui/material";
import { connect } from 'react-redux';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { uniquePatient } from "../../redux/actions/adminActions";
import { CSVLink } from "react-csv";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

const mySwal = withReactContent(Swal);

const UniquePatient = ({ role, token, totalUniquePatient, uniquePatient }) => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    useEffect(() => {

        if (role && token) {
            if (!totalUniquePatient) {
                axios.post("/api/admin/totalUniquePatient", { role, token })
                    .then(res => {
                        uniquePatient(res.data.data);
                        setLoading(false);
                    })
                    .catch(err => {
                        setLoading(false);
                        if (err.response.data.error === "Token Expired") {
                            mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
                                .then(click => {
                                    if (click.isConfirmed) {
                                        navigate('/admin/login');
                                    }
                                })
                        } else {
                            mySwal.fire("Error", err.response.data.error, "error")
                        }
                    });
            } else {
                setLoading(false);
            }
        } else {
            navigate('/admin/login');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            datalabels: {
                anchor: 'end',
                align: 'end',
                color: "black",
                padding: -9,
                font: {
                    lineHeight: 1.5
                },
                display: function (context) {
                    return context.dataset.data[context.dataIndex] > 0
                }
            }
        }
    };

    let datasets = [];
    let csv = [];
    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const csvData = {
        headers: [
            { label: "Month", key: "month" },
            { label: "Unique Patients", key: "uniquePatients" },
        ],
        data: csv
    }

    if (totalUniquePatient) {

        let datasetObject = {
            label: "Unique Patients",
            data: totalUniquePatient,
            backgroundColor: "#1F1C6B",
            borderRadius: 7,
            borderSkipped: false
        }
        datasets.push(datasetObject);

        for (let i = 0; i < 12; i++) {
            const csvObject = {
                month: labels[i],
                uniquePatients: totalUniquePatient[i]
            }
            csv.push(csvObject);
        }
    }

    const data = {
        labels: labels,
        datasets: datasets
    }

    return (
        <div>
            {
                loading ? (
                    <div className="text-center">
                        <CircularProgress />
                    </div>
                ) : (
                    <div>
                        <div className="admin-dashboard-border my-2">
                            <Bar options={options} data={data} />
                        </div>
                        <div className="d-flex justify-content-end mb-2">
                            <CSVLink className="btn btn-primary shadow-none" data={csvData.data} headers={csvData.headers} filename={`unique_patient_${new Date().getFullYear()}_${new Date().getMonth() + 1}_${new Date().getDate()}.csv`} target="_blank">
                                Export Data
                            </CSVLink>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.admin.token,
        role: state.admin.role,
        totalUniquePatient: state.admin.totalUniquePatient
    };
};

const mapDispatchToProps = dispatch => {
    return {
        uniquePatient: data => dispatch(uniquePatient(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UniquePatient);