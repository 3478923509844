import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { patientCheckToken } from "../redux/actions/patientActions";
import axios from 'axios';
import { connect } from 'react-redux';
import { CircularProgress } from "@mui/material";
import { useTranslation } from 'react-i18next';

// image
import leftArrow from "../assets/image/left-arrow.png";

// component
import PatientNavigationBar from "./components/navigationBar";
import PatientHeader from "./components/header";

const PatientInbox = ({ token, patient, patientCheckToken }) => {

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    setError("")
    setLoading(true);

    if (!patient) {
      if (token) {
        axios.post('/api/patient/checkToken', { token })
          .then(res => {
            patientCheckToken(res.data.data);
            axios.post('/api/patient/inbox', { patientId: res.data.data.patient.id })
              .then(res => {
                setData(res.data.data);
                setLoading(false);
              })
              .catch(err => {
                setError(err.response.data.error);
                setLoading(false);
              });
          })
          .catch(err => {
            setError(err.response.data.error);
            setLoading(false);
          });
      } else {
        navigate("/patient/patientWelcome")
      }
    } else {
      axios.post('/api/patient/inbox', { patientId: patient.patient.id })
        .then(res => {
          setData(res.data.data);
          setLoading(false);
        })
        .catch(err => {
          setError(err.response.data.error);
          setLoading(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderTime = (date) => {
    const thisDate = new Date(date);
    let hour24 = "";
    let hour = "";

    if (thisDate.getUTCHours() >= 16) {
      hour24 = thisDate.getUTCHours() + 8 - 24;
    } else {
      hour24 = thisDate.getUTCHours() + 8;
    }

    if (hour24 > 12) {
      hour = hour24 - 12;
      if (hour.toString().length === 1) {
        hour = `0${hour}`
      }
    } else {
      hour = hour24;
      if (hour.toString().length === 1) {
        hour = `0${hour}`
      }
    }
    const minute = thisDate.getUTCMinutes().toString().length === 1 ? `0${thisDate.getUTCMinutes()}` : thisDate.getUTCMinutes().toString();
    return `${hour}:${minute} ${hour24 >= 12 ? "pm" : "am"}`
  }

  return (
    <div>
      <PatientHeader />
      <div className="hcp-whole-body px-3">
        <div className="page-title mt-3">
          <img src={leftArrow} alt="back-icon" className="hcp-left-arrow" onClick={() => navigate('/patient/patientHome')} />
          <h2>{t("Inbox")}</h2>
        </div>
        {/* <p className="patient-unread-message-text">You have 2 unread message</p> */}
        <div className="page-body">
          {
            error ? (
              <div className="pt-3">
                <p style={{ color: "red", textAlign: "center" }}>{t(error)}</p>
              </div>
            ) : null
          }

          {
            loading ? (
              <div className="text-center">
                <CircularProgress />
              </div>
            ) : (
              data ? data.map((d, i) => {
                return (
                  <div className="patient-inbox-row" key={i}>
                    {/* <div className="circle">
                    <img src={icare} alt="inbox" className="patient-inbox-image" />
                  </div> */}
                    <div style={{ width: "100%", textAlign: "justify" }}>
                      <p><b>{d.message}</b></p>
                    </div>
                    <div className="ps-3">{new Date(d.createdAt).toLocaleDateString('en-GB')} {renderTime(d.createdAt)}</div>
                  </div>
                )
              }) : null
            )
          }
        </div>
      </div>
      <PatientNavigationBar />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    token: state.patient.token,
    patient: state.patient.patient
  };
};

const mapDispatchToProps = dispatch => {
  return {
    patientCheckToken: data => dispatch(patientCheckToken(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientInbox);
