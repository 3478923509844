import hcpWelcomeLogo from "../assets/image/hcpWelcomeLogo.png";
import { useNavigate } from "react-router-dom";

const HcpWelcome = () => {

  const navigate = useNavigate();

  return (
    <div>
      <div className="hcp-welcome-container">
        <img
          src={hcpWelcomeLogo}
          alt="welcomeLogo"
          className="hcp-welcome-logo"
        />
        <p className="hcp-welcome-title">
          <b>Welcome</b>
        </p>
        <p className="hcp-welcome-subtitle">
          <b>FOR HEALTHCARE PROFESSIONAL ONLY</b>
        </p>
      </div>

      <div className="hcp-welcome-background">
        <div className="button-container">
          <button
            className="btn form-control hcp-login-button py-3"
            onClick={() => navigate('/hcp/login')}
          >
            <b>LOGIN</b>
          </button>
        </div>
        <p style={{ color: "white", textAlign: "center" }} onClick={() => navigate('/hcp/hcpTermsAndCondition', { state: { showButton: false } })}><b>Terms &amp; Condition</b></p>
      </div>
    </div>
  );
};

export default HcpWelcome;
