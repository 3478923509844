import { HCP_LOGIN, UPDATE_HCP_LANGUAGE, UPDATE_HCP_REMEMBER_ME, UPDATE_PATIENT_DETAILS, REGISTER_POPUP, UPDATE_HCP_DETAILS, UPDATE_REDEMPTION, HCP_LOGOUT } from "../actions/type";
import i18n from "../../i18n";

const initState = {
    token: localStorage.getItem("hcp-token"),
    hcp: null,
    language: localStorage.getItem("patient-language") || 'en',
    rememberme: localStorage.getItem("hcp-remeber-me") === 'true' || false,
    patient: null,
    registerPopup: false,
    redemption: null
};

const hcpReducer = (state = initState, action) => {
    switch (action.type) {
        case HCP_LOGIN:
            localStorage.setItem("hcp-token", action.payload.token);
            return {
                ...state,
                token: action.payload.token
            };
        case HCP_LOGOUT:
            localStorage.removeItem("hcp-token");
            return {
                ...state,
                token: null,
                hcp: null
            };
        case UPDATE_HCP_LANGUAGE:
            localStorage.setItem("patient-language", action.payload);
            i18n.changeLanguage(action.payload);
            return {
                ...state,
                language: action.payload
            };
        case UPDATE_HCP_REMEMBER_ME:
            localStorage.setItem("hcp-remeber-me", action.payload);
            return {
                ...state,
                rememberme: action.payload
            };
        case UPDATE_PATIENT_DETAILS:
            return {
                ...state,
                patient: action.payload
            };
        case REGISTER_POPUP:
            return {
                ...state,
                registerPopup: action.payload
            };
        case UPDATE_HCP_DETAILS:
            return {
                ...state,
                hcp: action.payload
            };
        case UPDATE_REDEMPTION:
            return {
                ...state,
                redemption: action.payload
            };
        default:
            return state;
    }
};

export default hcpReducer;