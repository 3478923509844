import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { MDBDataTableV5 } from 'mdbreact';
import { CircularProgress, Drawer } from "@mui/material";
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { CSVLink } from "react-csv";
import { styled } from '@mui/material/styles';

// components
import Sidebar from "./components/sidebar";
import Header from "./components/header";

const mySwal = withReactContent(Swal);

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-20%`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
            maxWidth: "80%"
        }),
    }),
);

const HcpEnrollmentRecords = ({ role, token }) => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [openSidebar, setOpenSidebar] = useState(true);

    useEffect(() => {
        if (role && token) {
            if (role === "dev") {
                axios.post("/api/admin/hcpEnrollmentRecords", { role, token })
                    .then(res => {
                        setData(res.data.data);
                        setLoading(false);
                    })
                    .catch(err => {
                        setLoading(false);
                        if (err.response.data.error === "Token Expired") {
                            mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
                                .then(click => {
                                    if (click.isConfirmed) {
                                        navigate('/admin/login');
                                    }
                                })
                        } else {
                            mySwal.fire("Error", err.response.data.error, "error")
                        }
                    });
            } else {
                mySwal.fire({ title: "Error", text: "Unauthorized Access", icon: "error", allowOutsideClick: false })
                    .then(click => {
                        if (click.isConfirmed) {
                            navigate('/admin/login');
                        }
                    })
            }
        } else {
            navigate('/admin/login');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let tableData = {
        columns: [
            {
                field: "signDate",
                label: "Enrollment Letter Signing Date",
                sort: "asc"
            },
            {
                field: "hcpEnrollDate",
                label: "Program Start Date",
                sort: "asc"
            },
            {
                field: "cvmylanStart",
                label: "CV + Mylan Sales Transaction Start From",
                sort: "asc"
            },
            {
                field: "viagraStart",
                label: "Viagra Sales Transaction Start From",
                sort: "asc"
            },
            {
                field: "email",
                label: "Email Address",
                sort: "asc"
            },
            {
                field: "hcpContact",
                label: "Contact number",
                sort: "asc"
            },
            {
                field: "hcpType",
                label: "Hospital / Clinic",
                sort: "asc"
            },
            {
                field: "hcpName",
                label: "Customer Name (appear in site)",
                sort: "asc"
            },
            {
                field: "hcpPic",
                label: "HCP PIC",
                sort: "asc"
            },
            {
                field: "purchaseName",
                label: "ZP Purchase Account Name (Sales Data)",
                sort: "asc"
            },
            {
                field: "soldToCode",
                label: "Redeem / Sold-To Code",
                sort: "asc"
            },
            {
                field: "shipToCode",
                label: "Ship-To Code",
                sort: "asc"
            },
            {
                field: "zpCode",
                label: "ZP Customer Code (Sales Data)",
                sort: "asc"
            },
            {
                field: "antahCode",
                label: "Antah Customer Code (Sales Data)",
                sort: "asc"
            },
            {
                field: "cvseName",
                label: "CV + SE Name",
                sort: "asc"
            },
            {
                field: "cvseEmail",
                label: "CV + SE Email",
                sort: "asc"
            },
            {
                field: "cvabmName",
                label: "CV + ABM Name",
                sort: "asc"
            },
            {
                field: "cvabmEmail",
                label: "CV + ABM Email",
                sort: "asc"
            },
            {
                field: "painseName",
                label: "Pain + SE Name",
                sort: "asc"
            },
            {
                field: "painseEmail",
                label: "Pain + SE Email",
                sort: "asc"
            },
            {
                field: "painabmName",
                label: "Pain + ABM Name",
                sort: "asc"
            },
            {
                field: "painabmEmail",
                label: "Pain + ABM Email",
                sort: "asc"
            },
            {
                field: "cvseContact",
                label: "CV + SE Contact",
                sort: "asc"
            },
            {
                field: "createdAt",
                label: "Email Sent Date",
                sort: "asc"
            }
        ],
        rows: data ? data : []
    };

    const csvData = {
        headers: [
            { label: "Enrollment Letter Signing Date", key: "signDate" },
            { label: "Program Start Date", key: "hcpEnrollDate" },
            { label: "CV + Mylan Sales Transaction Start From", key: "cvmylanStart" },
            { label: "Viagra Sales Transaction Start From", key: "viagraStart" },
            { label: "Email Address", key: "email" },
            { label: "Contact number", key: "hcpContact" },
            { label: "Hospital / Clinic", key: "hcpType" },
            { label: "Customer Name (appear in site)", key: "hcpName" },
            { label: "HCP PIC", key: "hcpPic" },
            { label: "ZP Purchase Account Name (Sales Data)", key: "purchaseName" },
            { label: "Redeem / Sold-To Code", key: "soldToCode" },
            { label: "Ship-To Code", key: "shipToCode" },
            { label: "ZP Customer Code (Sales Data)", key: "zpCode" },
            { label: "Antah Customer Code (Sales Data)", key: "antahCode" },
            { label: "CV + SE Name", key: "cvseName" },
            { label: "CV + SE Email", key: "cvseEmail" },
            { label: "CV + ABM Name", key: "cvabmName" },
            { label: "CV + ABM Email", key: "cvabmEmail" },
            { label: "Pain + SE Name", key: "painseName" },
            { label: "Pain + SE Email", key: "painseEmail" },
            { label: "Pain + ABM Name", key: "painabmName" },
            { label: "Pain + ABM Email", key: "painabmEmail" },
            { label: "CV + SE Contact", key: "cvseContact" },
            { label: "Email Sent Date", key: "createdAt" },
        ],
        data
    }

    return (
        <div className="d-flex">

            <Drawer
                sx={{
                    minWidth: "20%",
                    '& .MuiDrawer-paper': {
                        minWidth: "20%",
                        boxSizing: 'border-box',
                        minHeight: "100vh",
                        backgroundColor: "#FE7072",
                    },
                }}
                anchor="left"
                open={openSidebar}
                variant="persistent">
                <Sidebar />
            </Drawer>

            <Main open={openSidebar}>
                <div className="mt-3 px-3">
                    <Header title="HCP ENROLLMENT RECORDS" openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />

                    <div className="report-header mt-3 d-flex py-3 px-3 justify-content-end">
                        <div className="ps-2">
                            <CSVLink className="btn btn-primary shadow-none admin-button" data={csvData.data} headers={csvData.headers} filename={`hcp_enrollment_records_${new Date().getFullYear()}_${new Date().getMonth() + 1}_${new Date().getDate()}.csv`} target="_blank">
                                Export
                            </CSVLink>
                        </div>
                    </div>

                    {
                        loading ? (
                            <div className="text-center">
                                <CircularProgress />
                            </div>
                        ) : (
                            <div>
                                <MDBDataTableV5 data={tableData} hover responsive bordered />
                            </div>
                        )
                    }
                </div>
            </Main>

        </div>
    );
}

const mapStateToProps = state => {
    return {
        token: state.admin.token,
        role: state.admin.role
    };
};

export default connect(mapStateToProps, null)(HcpEnrollmentRecords);
