import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Box, CircularProgress } from "@mui/material";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import { MDBBtn } from "mdb-react-ui-kit";
import axios from 'axios';
import { connect } from 'react-redux';
import { updatePatientDetails, updateRegisterPopup, updateHcpDetails, updateRedemption, hcpLogout } from "../redux/actions/hcpActions";
// import { isMobile } from 'react-device-detect';
import { Carousel } from 'react-bootstrap';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// component
import HcpNavigationBar from "./components/navigationBar";
import HcpHeader from "./components/header";
import CustomModal from "./components/modal";

// image
// import viatrislogo from "../assets/image/viatris-logo.png";
import subtract from "../assets/image/Subtract.png";
import write from "../assets/image/write.png";
// import desktopBanner1 from "../assets/image/desktopBanner1.png";
// import desktopBanner2 from "../assets/image/desktopBanner2.png";
// import desktopBanner3 from "../assets/image/desktopBanner3.png";
import logoutIcon from "../assets/image/logout-icon.png";
// import norvascBmBanner from "../assets/image/banner/norvasc-bm-banner.jpg";
// import norvascChBanner from "../assets/image/banner/norvasc-ch-banner.jpg";
import norvascEnBanner from "../assets/image/banner/norvasc-en-banner.jpg";
// import viagraBmBanner from "../assets/image/banner/viagra-bm-banner.jpg";
// import viagraChBanner from "../assets/image/banner/viagra-ch-banner.jpg";
import viagraEnBanner from "../assets/image/banner/viagra-en-banner.jpg";
import legalonEnBanner1 from "../assets/image/banner/legalon-en-banner1.jpg";
import legalonEnBanner2 from "../assets/image/banner/legalon-en-banner2.jpg";
import legalonEnBanner3 from "../assets/image/banner/legalon-en-banner3.jpg";
import Banner1 from "../assets/NewBanner/Banner1.jpg";
import Banner2 from "../assets/NewBanner/Banner2.jpg";
import Banner3 from "../assets/NewBanner/Banner3.jpg";
import Banner4 from "../assets/NewBanner/Banner4.jpg";
import Banner5 from "../assets/NewBanner/Banner5.jpg";

const HcpHome = ({ updatePatientDetails, registerPopup, updateRegisterPopup, updateHcpDetails, token, updateRedemption, hcpLogout, hcp }) => {

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [number, setNumber] = useState("");
  const [redemptionCode, setRedemptionCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [redeemLoading, setRedeemLoading] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalContent, setModalContent] = useState("");
  const [openError, setOpenError] = useState(false);

  const handleClose = () => setOpenError(false);

  useEffect(() => {
    setError("")
    setLoading(true);

    if (token) {
      axios.post('/api/hcp/checkToken', { token })
        .then(res => {
          updateHcpDetails(res.data.data);
          setLoading(false);
          if (res.data.data.hcp.firstTimeLogin) {
            navigate("/hcp/hcpTermsAndCondition");
          }
        })
        .catch(err => {
          setError(err.response.data.error);
          setLoading(false);
        });
    } else {
      navigate("/hcp/welcome")
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchPatient = () => {
    if (!number) {
      setOpenError(true);
      setModalType("error");
      setModalContent("Please enter patient's MRN, IC or phone number");
    } else {
      setError("")
      setLoading(true);

      axios.post('/api/hcp/patientDetails', { number })
        .then(res => {
          localStorage.setItem("patient-number", number);
          updatePatientDetails(res.data.data)
          setLoading(false);
          navigate('/hcp/hcpSubmission');
        })
        .catch(err => {
          if (err.response.data.error === "Patient not found") {
            setOpen(true);
            setLoading(false);
          } else {
            setError(err.response.data.error);
            setLoading(false);
          }
        });
    }
  }

  const redeem = () => {
    if (!redemptionCode) {
      setOpenError(true);
      setModalType("error");
      setModalContent("Please enter the redemption code");
    } else {
      setRedeemLoading(true);

      axios.post('/api/hcp/verifyRedemptionCode', { redemptionCode, hcpId: hcp.hcp.id })
        .then(res => {
          updateRedemption(res.data.redemption);
          setRedeemLoading(false);
          navigate('/hcp/hcpCheckout', { state: { redemptionCode, hcpId: hcp.hcp.id } });
        })
        .catch(err => {
          setOpenError(true);
          setModalType("error");
          setModalContent(err.response.data.error);
          setRedeemLoading(false);
        })
    }
  }

  const logout = () => {
    hcpLogout();
    navigate('/hcp/welcome')
  }

  return (
    <div>
      <HcpHeader />
      <div className="hcp-whole-body">
        <div className="hcp-home-welcome">
          <p style={{ color: "#1F1C6B" }}><b>Hello {hcp ? hcp.hcp.hcpName : ""}</b></p>
        </div>

        <div>
          <Carousel nextIcon={<ArrowForwardIosIcon />} prevIcon={<ArrowBackIosIcon />}>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={norvascEnBanner}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={viagraEnBanner}
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={legalonEnBanner1}
                alt="Third slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={legalonEnBanner2}
                alt="Forth slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={legalonEnBanner3}
                alt="Fifth slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={Banner1}
                alt="Sixth slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={Banner2}
                alt="Seventh slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={Banner3}
                alt="Eight slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={Banner4}
                alt="Nineth slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={Banner5}
                alt="Tenth slide"
              />
            </Carousel.Item>
          </Carousel>
        </div>
        {/* {
          isMobile ? (
            <div>
              <img src={viatrislogo} className="hcp-home-logo" alt="viatris-logo" />
            </div>
          ) : (
            <div className="d-flex" style={{ overflowX: "auto" }}>
              <img src={desktopBanner1} className="hcp-home-logo" alt="viatris-logo" />
              <img src={desktopBanner2} className="hcp-home-logo" alt="viatris-logo" />
              <img src={desktopBanner3} className="hcp-home-logo" alt="viatris-logo" />
            </div>
          )
        } */}

        <div className="hcp-home-padding-outside">
          <div className="hcp-home-submission">
            <div className="hcp-home-submission-text pt-3">
              <p>Submission</p>
            </div>
            <div className="hcp-home-patient-text">
              <p style={{ marginBottom: "0px" }}>PATIENT MRN/IC/PHONE NO.</p>
            </div>
            <div className="hcp-submission-input">
              <input className="form-control input-field-white" id="number" name="number" type="number" placeholder="Search" value={number} onChange={e => setNumber(e.target.value)} onWheel={(e) => e.target.blur()} />
            </div>

            {
              error ? (
                <div className="pt-3">
                  <p style={{ color: "red", textAlign: "center" }}>{error}</p>
                </div>
              ) : null
            }

            {
              loading ? (
                <div className="text-center">
                  <CircularProgress />
                </div>
              ) : (
                <div className="d-flex pt-3">
                  <button className="btn form-control hcp-search-button py-3 mx-4" onClick={() => searchPatient()}>
                    <b>SEARCH</b>
                  </button>
                  <button className="btn form-control hcp-search-button py-3 mx-4" onClick={() => navigate("/hcp/requestOtp")}>
                    <b>Register</b>
                  </button>
                </div>
              )
            }
          </div>

          <div className="hcp-home-redemption">
            <div className="hcp-home-submission-text pt-3">
              <p>Redemption</p>
            </div>
            <div className="hcp-home-patient-text">
              <p style={{ marginBottom: "0px" }}>REDEMPTION CODE</p>
            </div>
            <div className="hcp-submission-input">
              <input className="form-control input-field-white" id="code" name="code" type="text" placeholder="Enter code" value={redemptionCode} onChange={e => setRedemptionCode(e.target.value)} />
            </div>

            <div className="button-container pt-3">
              {
                redeemLoading ? (
                  <div className="text-center">
                    <CircularProgress />
                  </div>
                ) : (
                  <button className="btn form-control hcp-redeem-button py-3" onClick={() => redeem()}>
                    <b>REDEEM</b>
                  </button>
                )
              }
            </div>

          </div>
        </div>

        <div className="text-center">
          <button className="btn hcp-addmore-button" onClick={() => setLogoutModal(true)}>
            <img src={logoutIcon} alt="logout-icon" className="px-2" />
            <b className="pe-2">LOG OUT</b>
          </button>
        </div>
      </div>

      <HcpNavigationBar />

      <div className="hcp-modal-box">
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="red-modal">
            <div className="align-right">
              <MDBBtn
                className="btn-close btn-close-white"
                color="none"
                aria-label="Close"
                onClick={() => setOpen(false)}
              />
            </div>
            <div className="text-center">
              <img src={subtract} alt="warning-icon" />
            </div>

            <p className="hcp-alert">
              This number does not exist in our database. Do you wish to proceed with new registration?
            </p>
            <div className="button-container">
              <button className="btn form-control hcp-register-button py-3" onClick={() => navigate("/hcp/requestOtp")}>
                <b>REGISTER</b>
              </button>
            </div>
          </Box>
        </Modal>
        <Modal
          open={registerPopup}
          onClose={() => updateRegisterPopup(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="red-modal">
            <div className="align-right">
              <MDBBtn
                className="btn-close btn-close-white"
                color="none"
                aria-label="Close"
                onClick={() => updateRegisterPopup(false)}
              />
            </div>
            <div className="text-center">
              <img src={write} alt="success-icon" />
            </div>

            <p className="hcp-alert" style={{ fontSize: "20px" }}>Registration successful!</p>
            <div className="button-container">
              <button
                className="btn form-control hcp-register-button py-3"
                onClick={() => updateRegisterPopup(false)}
              >
                <b>OK</b>
              </button>
            </div>
          </Box>
        </Modal>
        <Modal
          open={logoutModal}
          onClose={() => setLogoutModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="red-modal">
            <div className="align-right">
              <MDBBtn
                className="btn-close btn-close-white"
                color="none"
                aria-label="Close"
                onClick={() => setLogoutModal(false)}
              />
            </div>
            <div className="text-center">
              <img src={subtract} alt="warning-icon" />
            </div>

            <p className="hcp-alert">
              Are you sure want to log out?
            </p>
            <div className="button-container">
              <button className="btn form-control hcp-register-button py-3" onClick={() => logout()}>
                <b>LOG OUT</b>
              </button>
            </div>
            <div className="button-container">
              <button className="btn form-control hcp-no-button py-3" onClick={() => setLogoutModal(false)}>
                <b>CANCEL</b>
              </button>
            </div>
          </Box>
        </Modal>
        <CustomModal open={openError} handleClose={handleClose} modalType={modalType} modalContent={modalContent} />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    registerPopup: state.hcp.registerPopup,
    token: state.hcp.token,
    hcp: state.hcp.hcp
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updatePatientDetails: data => dispatch(updatePatientDetails(data)),
    updateRegisterPopup: data => dispatch(updateRegisterPopup(data)),
    updateHcpDetails: data => dispatch(updateHcpDetails(data)),
    updateRedemption: data => dispatch(updateRedemption(data)),
    hcpLogout: () => dispatch(hcpLogout())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HcpHome);
