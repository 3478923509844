import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Box } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';

// images
import patientMobile3En from "../assets/image/tutorial/patient/mobile/patientTutorial3-en.png";
import patientDesktop3En from "../assets/image/tutorial/patient/desktop/patientTutorial3-en.png";
import patientDesktop3Bm from "../assets/image/tutorial/patient/desktop/patientTutorial3-bm.png";
import tutorialArrow from "../assets/image/tutorialArrow.png";

const PatientTutorial3 = ({ language }) => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    const [openTutorial, setOpenTutorial] = useState(true);

    return (
        <div>
            {
                isMobile ? (
                    <img src={patientMobile3En} alt="patientTutorial" style={{ height: "100vh", width: "100vw" }} />
                ) : (
                    <img src={language === "bm" ? patientDesktop3Bm : patientDesktop3En} alt="patientTutorial" style={{ height: "100vh", width: "100vw" }} />
                )
            }
            <Modal
                open={openTutorial}
                onClose={() => setOpenTutorial(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                hideBackdrop={true}
            >
                <Box className="tutorial-modal-4">
                    <p style={{ fontSize: "18px" }}>{t("View your submissions and redemptions record here")}</p>
                    <img src={tutorialArrow} alt="arrow" style={{ float: "right" }} onClick={() => navigate('/patient/patientTutorial4')} />
                </Box>
            </Modal>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        language: state.patient.language
    };
};

export default connect(mapStateToProps, null)(PatientTutorial3);
