import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import axios from 'axios';
import { connect } from 'react-redux';
import { updateHcpDetails } from "../redux/actions/hcpActions";
import { CircularProgress } from "@mui/material";

// images
import leftArrow from "../assets/image/left-arrow.png";

// component
import HcpNavigationBar from "./components/navigationBar";
import HcpHeader from "./components/header";

const HcpCreditBalance = ({ token, hcp, updateHcpDetails }) => {

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    setError("")
    setLoading(true);

    if (!hcp) {
      if (token) {
        axios.post('/api/hcp/checkToken', { token })
          .then(res => {
            updateHcpDetails(res.data.data);
            axios.post('/api/hcp/creditBalance', { hcpId: res.data.data.hcp.id })
              .then(res => {
                setData(res.data.data);
                setLoading(false);
              })
              .catch(err => {
                setError(err.response.data.error);
                setLoading(false);
              });
          })
          .catch(err => {
            setError(err.response.data.error);
            setLoading(false);
          });
      } else {
        navigate("/hcp/welcome")
      }
    } else {
      axios.post('/api/hcp/creditBalance', { hcpId: hcp.hcp.id })
        .then(res => {
          setData(res.data.data);
          setLoading(false);
        })
        .catch(err => {
          setError(err.response.data.error);
          setLoading(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <HcpHeader />
      <div className="hcp-credit-body">
        <div className="page-title mt-3">
          <img src={leftArrow} alt="back-icon" className="hcp-left-arrow" onClick={() => navigate('/hcp/home')} />
          <h2>Credit Balance</h2>
        </div>

        {
          error ? (
            <div className="pt-3">
              <p style={{ color: "red", textAlign: "center" }}>{error}</p>
            </div>
          ) : null
        }

        {
          loading ? (
            <div className="text-center">
              <CircularProgress />
            </div>
          ) : (
            <div className="hcp-submission-checkout-table">
              <table className="hcp-table-width-submission table table-striped">
                <thead>
                  <tr className="hcp-bottom-border-table">
                    <th>
                      <p>Product</p>
                    </th>
                    <th>
                      <p>Total inventory</p>
                    </th>
                    <th>
                      <p>Credit balance</p>
                    </th>
                    <th>
                      <p>Unit</p>
                    </th>
                    <th>
                      <p>Status</p>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data ? data.map((d, i) => {
                      return (
                        <tr key={i}>
                          <td><b>{d.product}</b></td>
                          <td><b>{d.totalInventory}</b></td>
                          <td><b>{d.creditBalance}</b></td>
                          <td><b>{d.unit}</b></td>
                          <td style={{ color: d.status === "High" ? "green" : d.status === "Medium" ? "orange" : "red" }}><b>{d.status}</b></td>
                        </tr>
                      )
                    }) : null
                  }
                </tbody>
              </table>
            </div>
          )
        }
      </div>
      <HcpNavigationBar />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    token: state.hcp.token,
    hcp: state.hcp.hcp
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateHcpDetails: data => dispatch(updateHcpDetails(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HcpCreditBalance);
