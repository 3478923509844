import { useNavigate } from "react-router-dom";

import icare from "../../assets/image/icare.png";

const HcpHeader = () => {

    const navigate = useNavigate();

    return (
        <div className="hcp-home">
            <img src={icare} alt="icare-logo" onClick={() => navigate("/hcp/home")} />
        </div>
    );
}

export default HcpHeader;