import React, { useState, useEffect } from "react";
import { Modal, Box, CircularProgress, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import { MDBBtn } from "mdb-react-ui-kit";
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router";
import { connect } from 'react-redux';
import { updatePatientDetails, updateHcpDetails } from "../redux/actions/hcpActions";
import axios from 'axios';
import { medicationImage } from "../dynamicController";

// component
import HcpNavigationBar from "./components/navigationBar";
import HcpHeader from "./components/header";
import CustomModal from "./components/modal";

// images
import subtract from "../assets/image/Subtract.png";
import leftArrow from "../assets/image/left-arrow.png";
import minimize from "../assets/image/minimize.png";
import plus from "../assets/image/plus.png";
import minus from "../assets/image/minus.png";
import plusQ from "../assets/image/plus_icon.png";
import write from "../assets/image/write.png";

const HcpSubmission = ({ patient, updatePatientDetails, updateHcpDetails, token, hcp }) => {

  let number = localStorage.getItem("patient-number");

  const navigate = useNavigate();

  const [select, setSelect] = useState("medication");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [lipitor10mgQuantity, setLipitor10mgQuantity] = useState(0);
  const [lipitor20mgQuantity, setLipitor20mgQuantity] = useState(0);
  const [lipitor40mgQuantity, setLipitor40mgQuantity] = useState(0);
  const [lipitor80mgQuantity, setLipitor80mgQuantity] = useState(0);
  const [norvasc5mgQuantity, setNorvasc5mgQuantity] = useState(0);
  const [norvasc10mgQuantity, setNorvasc10mgQuantity] = useState(0);
  const [caduet510mgQuantity, setCaduet510mgQuantity] = useState(0);
  const [caduet520mgQuantity, setCaduet520mgQuantity] = useState(0);
  const [caduet540mgQuantity, setCaduet540mgQuantity] = useState(0);
  const [caduet1010mgQuantity, setCaduet1010mgQuantity] = useState(0);
  const [caduet1020mgQuantity, setCaduet1020mgQuantity] = useState(0);
  const [caduet1040mgQuantity, setCaduet1040mgQuantity] = useState(0);
  const [viagraQuantity, setViagraQuantity] = useState(0);
  const [viagra50mgQuantity, setViagra50mgQuantity] = useState(0);
  const [legalonQuantity, setLegalonQuantity] = useState(0);
  const [viartrilsSachetsQuantity, setViartrilsSachetsQuantity] = useState(0);
  const [viartrilsCapsulesQuantity, setViartrilsCapsulesQuantity] = useState(0);
  const [adaxilSachetsQuantity, setAdaxilSachetsQuantity] = useState(0);
  //const [fulphilaQuantity, setFulphilaQuantity] = useState(0);
  const [celebrexCapsulesQuantity, setCelebrexCapsulesQuantity] = useState(0);
  const [lyricaCapsules50mgQuantity, setLyricaCapsules50mgQuantity] = useState(0);
  const [lyricaCapsules75mgQuantity, setLyricaCapsules75mgQuantity] = useState(0);
  const [inspraCapsules25mgQuantity, setInspraCapsules25mgQuantity] = useState(0);
  const [inspraCapsules50mgQuantity, setInspraCapsules50mgQuantity] = useState(0);
  const [outOfStock, setOutOfStock] = useState("");
  const [redeem, setRedeem] = useState("");
  const [submissionMedication, setSubmissionMedication] = useState([]);
  const [redemptionMedication, setRedemptionMedication] = useState([]);
  const [normal, setNormal] = useState([]);
  const [instant, setInstant] = useState([]);
  const [modalType, setModalType] = useState("");
  const [modalContent, setModalContent] = useState("");

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(true);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);

  const handleClose6 = () => {
    setOpen6(false);
  }

  useEffect(() => {
    setError("");

    if (!patient || !hcp) {
      if (!hcp) {
        if (token) {
          axios.post('/api/hcp/checkToken', { token })
            .then(resp => {
              updateHcpDetails(resp.data.data);
              if (!patient) {
                if (number) {
                  axios.post('/api/hcp/patientDetails', { number })
                    .then(res => {
                      updatePatientDetails(res.data.data)

                      let submission = [];
                      let redemption = [];

                      for (let i = 0; i < res.data.data.submission.length; i++) {
                        if (!submission.includes(`${res.data.data.submission[i].medication} ${res.data.data.submission[i].sku}`) && res.data.data.submission[i].hcpId === resp.data.data.hcp.id) {
                          submission.push(`${res.data.data.submission[i].medication} ${res.data.data.submission[i].sku}`);
                        }
                      }

                      let redeemedRedemption = res.data.data.redemption.filter(r => r.status === "Redeemed");
                      for (let i = 0; i < redeemedRedemption.length; i++) {
                        if (!redemption.includes(`${redeemedRedemption[i].medication} ${redeemedRedemption[i].sku}`) && res.data.data.redemption[i].hcpId === resp.data.data.hcp.id) {
                          redemption.push(`${redeemedRedemption[i].medication} ${redeemedRedemption[i].sku}`);
                        }
                      }

                      setSubmissionMedication(submission);
                      setRedemptionMedication(redemption);

                      if (res.data.data.patient.updatedBy === "patient" && res.data.data.patient.updatedPopup === true) {
                        setOpen4(true);
                      }

                    })
                    .catch(err => {
                      navigate('/hcp/home');
                    });
                } else {
                  navigate('/hcp/home');
                }
              }
            })
            .catch(err => {
              setError(err.response.data.error);
            });
        } else {
          navigate("/hcp/welcome")
        }
      } else if (!patient) {
        if (number) {
          axios.post('/api/hcp/patientDetails', { number })
            .then(res => {
              updatePatientDetails(res.data.data)

              let submission = [];
              let redemption = [];

              for (let i = 0; i < res.data.data.submission.length; i++) {
                if (!submission.includes(`${res.data.data.submission[i].medication} ${res.data.data.submission[i].sku}`) && res.data.data.submission[i].hcpId === hcp.hcp.id) {
                  submission.push(`${res.data.data.submission[i].medication} ${res.data.data.submission[i].sku}`);
                }
              }

              let redeemedRedemption = res.data.data.redemption.filter(r => r.status === "Redeemed");
              for (let i = 0; i < redeemedRedemption.length; i++) {
                if (!redemption.includes(`${redeemedRedemption[i].medication} ${redeemedRedemption[i].sku}`) && res.data.data.redemption[i].hcpId === hcp.hcp.id) {
                  redemption.push(`${redeemedRedemption[i].medication} ${redeemedRedemption[i].sku}`);
                }
              }

              setSubmissionMedication(submission);
              setRedemptionMedication(redemption);

              if (res.data.data.patient.updatedBy === "patient" && res.data.data.patient.updatedPopup === true) {
                setOpen4(true);
              }

            })
            .catch(err => {
              navigate('/hcp/home');
            });
        } else {
          navigate('/hcp/home');
        }
      }
    } else {
      let medication = [];
      let redemption = [];

      for (let i = 0; i < patient.submission.length; i++) {
        if (!medication.includes(`${patient.submission[i].medication} ${patient.submission[i].sku}`) && patient.submission[i].hcpId === hcp.hcp.id) {
          medication.push(`${patient.submission[i].medication} ${patient.submission[i].sku}`);
        }
      }

      let redeemedRedemption = patient.redemption.filter(r => r.status === "Redeemed");
      for (let i = 0; i < redeemedRedemption.length; i++) {
        if (!redemption.includes(`${redeemedRedemption[i].medication} ${redeemedRedemption[i].sku}`) && redeemedRedemption[i].hcpId === hcp.hcp.id) {
          redemption.push(`${redeemedRedemption[i].medication} ${redeemedRedemption[i].sku}`);
        }
      }

      setSubmissionMedication(medication);
      setRedemptionMedication(redemption);

      if (patient.patient.updatedBy === "patient" && patient.patient.updatedPopup === true) {
        setOpen4(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModal4 = () => {

    setError("");
    setLoading(true);

    axios.post('/api/hcp/updatePatientPopup', { number: patient.patient.number })
      .then(res => {
        setLoading(false);
        setOpen4(false);
      })
      .catch(err => {
        setLoading(false);
        setError(err.response.data.error);
      });

  }

  const minusMedication = (medicationName, medicationSku) => {
    if (medicationName === "Lipitor" && medicationSku === "10 mg") {
      if (lipitor10mgQuantity > 0) {
        setLipitor10mgQuantity(lipitor10mgQuantity - 30);
      }
    } else if (medicationName === "Lipitor" && medicationSku === "20 mg") {
      if (lipitor20mgQuantity > 0) {
        setLipitor20mgQuantity(lipitor20mgQuantity - 30);
      }
    } else if (medicationName === "Lipitor" && medicationSku === "40 mg") {
      if (lipitor40mgQuantity > 0) {
        setLipitor40mgQuantity(lipitor40mgQuantity - 30);
      }
    } else if (medicationName === "Lipitor" && medicationSku === "80 mg") {
      if (lipitor80mgQuantity > 0) {
        setLipitor80mgQuantity(lipitor80mgQuantity - 30);
      }
    } else if (medicationName === "Norvasc" && medicationSku === "5 mg") {
      if (norvasc5mgQuantity > 0) {
        setNorvasc5mgQuantity(norvasc5mgQuantity - 30);
      }
    } else if (medicationName === "Norvasc" && medicationSku === "10 mg") {
      if (norvasc10mgQuantity > 0) {
        setNorvasc10mgQuantity(norvasc10mgQuantity - 30);
      }
    } else if (medicationName === "Caduet" && medicationSku === "5/10mg") {
      if (caduet510mgQuantity > 0) {
        setCaduet510mgQuantity(caduet510mgQuantity - 30);
      }
    } else if (medicationName === "Caduet" && medicationSku === "5/20mg") {
      if (caduet520mgQuantity > 0) {
        setCaduet520mgQuantity(caduet520mgQuantity - 30);
      }
    } else if (medicationName === "Caduet" && medicationSku === "5/40mg") {
      if (caduet540mgQuantity > 0) {
        setCaduet540mgQuantity(caduet540mgQuantity - 30);
      }
    } else if (medicationName === "Caduet" && medicationSku === "10/10mg") {
      if (caduet1010mgQuantity > 0) {
        setCaduet1010mgQuantity(caduet1010mgQuantity - 30);
      }
    } else if (medicationName === "Caduet" && medicationSku === "10/20mg") {
      if (caduet1020mgQuantity > 0) {
        setCaduet1020mgQuantity(caduet1020mgQuantity - 30);
      }
    } else if (medicationName === "Caduet" && medicationSku === "10/40mg") {
      if (caduet1040mgQuantity > 0) {
        setCaduet1040mgQuantity(caduet1040mgQuantity - 30);
      }
    } else if (medicationName === "Viagra" && medicationSku === "100 mg") {
      if (viagraQuantity > 0) {
        setViagraQuantity(viagraQuantity - 1);
      }
    } else if (medicationName === "Viagra" && medicationSku === "50 mg") {
      if (viagra50mgQuantity > 0) {
        setViagra50mgQuantity(viagra50mgQuantity - 1);
      }
    } else if (medicationName === "Legalon" && medicationSku === "140 mg") {
      if (legalonQuantity > 0) {
        if ((legalonQuantity - 10) % 30 === 0 || (legalonQuantity - 10) % 100 === 0) {
          setLegalonQuantity(legalonQuantity - 10);
        } else if ((legalonQuantity - 20) % 30 === 0 || (legalonQuantity - 20) % 100 === 0) {
          setLegalonQuantity(legalonQuantity - 20);
        } else if ((legalonQuantity - 30) % 30 === 0 || (legalonQuantity - 30) % 100 === 0) {
          setLegalonQuantity(legalonQuantity - 30);
        }
      }
    } else if (medicationName === "Viartril-S Sachets" && medicationSku === "1500 mg") {
      if (viartrilsSachetsQuantity > 0) {
        setViartrilsSachetsQuantity(viartrilsSachetsQuantity - 30);
      }
    } else if (medicationName === "Viartril-S Capsules" && medicationSku === "500 mg") {
      if (viartrilsCapsulesQuantity > 0) {
        setViartrilsCapsulesQuantity(viartrilsCapsulesQuantity - 90);
      }
    } else if (medicationName === "Adaxil Sachets" && medicationSku === "750mg/600mg") {
      if (adaxilSachetsQuantity > 0) {
        setAdaxilSachetsQuantity(adaxilSachetsQuantity - 30);
      }
    } else if (medicationName === "Celebrex Capsules" && medicationSku === "200 mg") {
      if (celebrexCapsulesQuantity > 0) {
        setCelebrexCapsulesQuantity(celebrexCapsulesQuantity - 10);
      }
    } else if (medicationName === "Lyrica Capsules" && medicationSku === "50 mg") {
      if (lyricaCapsules50mgQuantity > 0) {
        setLyricaCapsules50mgQuantity(lyricaCapsules50mgQuantity - 14);
      }
    } else if (medicationName === "Lyrica Capsules" && medicationSku === "75 mg") {
      if (lyricaCapsules75mgQuantity > 0) {
        setLyricaCapsules75mgQuantity(lyricaCapsules75mgQuantity - 14);
      }
    } else if (medicationName === "Inspra Capsules" && medicationSku === "25 mg") {
      if (inspraCapsules25mgQuantity > 0) {
        setInspraCapsules25mgQuantity(inspraCapsules25mgQuantity - 30);
      }
    } else if (medicationName === "Inspra Capsules" && medicationSku === "50 mg") {
      if (inspraCapsules50mgQuantity > 0) {
        setInspraCapsules50mgQuantity(inspraCapsules50mgQuantity - 30);
      }
    }
    //  else if (medicationName === "Fulphila" && medicationSku === "6mg/0.6ml") {
    //   if (fulphilaQuantity > 0) {
    //     setFulphilaQuantity(fulphilaQuantity - 1);
    //   }
    // }
  }

  const plusMedication = (medicationName, medicationSku) => {
    let maxPurchaseQuantity = patient.product.filter(p => p.medication === medicationName && p.sku === medicationSku)[0].maxPurchaseQuantity;
    console.log(medicationName === "Inspra Capsules" && medicationSku === "50 mg")
    if (medicationName === "Lipitor" && medicationSku === "10 mg") {
      if (lipitor10mgQuantity < maxPurchaseQuantity) {
        setLipitor10mgQuantity(lipitor10mgQuantity + 30);
      }
    } else if (medicationName === "Lipitor" && medicationSku === "20 mg") {
      if (lipitor20mgQuantity < maxPurchaseQuantity) {
        setLipitor20mgQuantity(lipitor20mgQuantity + 30);
      }
    } else if (medicationName === "Lipitor" && medicationSku === "40 mg") {
      if (lipitor40mgQuantity < maxPurchaseQuantity) {
        setLipitor40mgQuantity(lipitor40mgQuantity + 30);
      }
    } else if (medicationName === "Lipitor" && medicationSku === "80 mg") {
      if (lipitor80mgQuantity < maxPurchaseQuantity) {
        setLipitor80mgQuantity(lipitor80mgQuantity + 30);
      }
    } else if (medicationName === "Norvasc" && medicationSku === "5 mg") {
      if (norvasc5mgQuantity < maxPurchaseQuantity) {
        setNorvasc5mgQuantity(norvasc5mgQuantity + 30);
      }
    } else if (medicationName === "Norvasc" && medicationSku === "10 mg") {
      if (norvasc10mgQuantity < maxPurchaseQuantity) {
        setNorvasc10mgQuantity(norvasc10mgQuantity + 30);
      }
    } else if (medicationName === "Caduet" && medicationSku === "5/10mg") {
      if (caduet510mgQuantity < maxPurchaseQuantity) {
        setCaduet510mgQuantity(caduet510mgQuantity + 30);
      }
    } else if (medicationName === "Caduet" && medicationSku === "5/20mg") {
      if (caduet520mgQuantity < maxPurchaseQuantity) {
        setCaduet520mgQuantity(caduet520mgQuantity + 30);
      }
    } else if (medicationName === "Caduet" && medicationSku === "5/40mg") {
      if (caduet540mgQuantity < maxPurchaseQuantity) {
        setCaduet540mgQuantity(caduet540mgQuantity + 30);
      }
    } else if (medicationName === "Caduet" && medicationSku === "10/10mg") {
      if (caduet1010mgQuantity < maxPurchaseQuantity) {
        setCaduet1010mgQuantity(caduet1010mgQuantity + 30);
      }
    } else if (medicationName === "Caduet" && medicationSku === "10/20mg") {
      if (caduet1020mgQuantity < maxPurchaseQuantity) {
        setCaduet1020mgQuantity(caduet1020mgQuantity + 30);
      }
    } else if (medicationName === "Caduet" && medicationSku === "10/40mg") {
      if (caduet1040mgQuantity < maxPurchaseQuantity) {
        setCaduet1040mgQuantity(caduet1040mgQuantity + 30);
      }
    } else if (medicationName === "Viagra" && medicationSku === "100 mg") {
      if (viagraQuantity < maxPurchaseQuantity) {
        setViagraQuantity(viagraQuantity + 1);
      }
    } else if (medicationName === "Viagra" && medicationSku === "50 mg") {
      if (viagra50mgQuantity < maxPurchaseQuantity) {
        setViagra50mgQuantity(viagra50mgQuantity + 1);
      }
    } else if (medicationName === "Legalon" && medicationSku === "140 mg") {
      if (legalonQuantity < maxPurchaseQuantity) {
        if ((legalonQuantity + 10) % 30 === 0 || (legalonQuantity + 10) % 100 === 0) {
          setLegalonQuantity(legalonQuantity + 10);
        } else if ((legalonQuantity + 20) % 30 === 0 || (legalonQuantity + 20) % 100 === 0) {
          setLegalonQuantity(legalonQuantity + 20);
        } else if ((legalonQuantity + 30) % 30 === 0 || (legalonQuantity + 30) % 100 === 0) {
          setLegalonQuantity(legalonQuantity + 30);
        }
      }
    } else if (medicationName === "Viartril-S Sachets" && medicationSku === "1500 mg") {
      if (viartrilsSachetsQuantity < maxPurchaseQuantity) {
        setViartrilsSachetsQuantity(viartrilsSachetsQuantity + 30);
      }
    } else if (medicationName === "Viartril-S Capsules" && medicationSku === "500 mg") {
      if (viartrilsCapsulesQuantity < maxPurchaseQuantity) {
        setViartrilsCapsulesQuantity(viartrilsCapsulesQuantity + 90);
      }
    } else if (medicationName === "Adaxil Sachets" && medicationSku === "750mg/600mg") {
      if (adaxilSachetsQuantity < maxPurchaseQuantity) {
        setAdaxilSachetsQuantity(adaxilSachetsQuantity + 30);
      }
    } else if (medicationName === "Celebrex Capsules" && medicationSku === "200 mg") {
      if (celebrexCapsulesQuantity < maxPurchaseQuantity) {
        setCelebrexCapsulesQuantity(celebrexCapsulesQuantity + 10);
      }
    } else if (medicationName === "Lyrica Capsules" && medicationSku === "50 mg") {
      if (lyricaCapsules50mgQuantity < maxPurchaseQuantity) {
        setLyricaCapsules50mgQuantity(lyricaCapsules50mgQuantity + 14);
      }
    } else if (medicationName === "Lyrica Capsules" && medicationSku === "75 mg") {
      if (lyricaCapsules75mgQuantity < maxPurchaseQuantity) {
        setLyricaCapsules75mgQuantity(lyricaCapsules75mgQuantity + 14);
      }
    } else if (medicationName === "Inspra Capsules" && medicationSku === "25 mg") {
      if (inspraCapsules25mgQuantity < maxPurchaseQuantity) {
        setInspraCapsules25mgQuantity(inspraCapsules25mgQuantity + 30);
      }
    } else if (medicationName === "Inspra Capsules" && medicationSku === "50 mg") {
      if (inspraCapsules50mgQuantity < maxPurchaseQuantity) {
        setInspraCapsules50mgQuantity(inspraCapsules50mgQuantity + 30);
      }
    }
    //  else if (medicationName === "Fulphila" && medicationSku === "6mg/0.6ml") {
    //   if (fulphilaQuantity < maxPurchaseQuantity) {
    //     setFulphilaQuantity(fulphilaQuantity + 1);
    //   }
    // }
  }

  const medicationQuantity = (medicationName, medicationSku) => {
    if (medicationName === "Lipitor" && medicationSku === "10 mg") {
      return lipitor10mgQuantity;
    } else if (medicationName === "Lipitor" && medicationSku === "20 mg") {
      return lipitor20mgQuantity;
    } else if (medicationName === "Lipitor" && medicationSku === "40 mg") {
      return lipitor40mgQuantity;
    } else if (medicationName === "Lipitor" && medicationSku === "80 mg") {
      return lipitor80mgQuantity;
    } else if (medicationName === "Norvasc" && medicationSku === "5 mg") {
      return norvasc5mgQuantity;
    } else if (medicationName === "Norvasc" && medicationSku === "10 mg") {
      return norvasc10mgQuantity;
    } else if (medicationName === "Caduet" && medicationSku === "5/10mg") {
      return caduet510mgQuantity;
    } else if (medicationName === "Caduet" && medicationSku === "5/20mg") {
      return caduet520mgQuantity;
    } else if (medicationName === "Caduet" && medicationSku === "5/40mg") {
      return caduet540mgQuantity;
    } else if (medicationName === "Caduet" && medicationSku === "10/10mg") {
      return caduet1010mgQuantity;
    } else if (medicationName === "Caduet" && medicationSku === "10/20mg") {
      return caduet1020mgQuantity;
    } else if (medicationName === "Caduet" && medicationSku === "10/40mg") {
      return caduet1040mgQuantity;
    } else if (medicationName === "Viagra" && medicationSku === "100 mg") {
      return viagraQuantity;
    } else if (medicationName === "Viagra" && medicationSku === "50 mg") {
      return viagra50mgQuantity;
    } else if (medicationName === "Legalon" && medicationSku === "140 mg") {
      return legalonQuantity;
    } else if (medicationName === "Viartril-S Sachets" && medicationSku === "1500 mg") {
      return viartrilsSachetsQuantity;
    } else if (medicationName === "Viartril-S Capsules" && medicationSku === "500 mg") {
      return viartrilsCapsulesQuantity;
    } else if (medicationName === "Adaxil Sachets" && medicationSku === "750mg/600mg") {
      return adaxilSachetsQuantity;
    } else if (medicationName === "Celebrex Capsules" && medicationSku === "200 mg") {
      return celebrexCapsulesQuantity;
    } else if (medicationName === "Lyrica Capsules" && medicationSku === "50 mg") {
      return lyricaCapsules50mgQuantity;
    } else if (medicationName === "Lyrica Capsules" && medicationSku === "75 mg") {
      return lyricaCapsules75mgQuantity;
    } else if (medicationName === "Inspra Capsules" && medicationSku === "25 mg") {
      return inspraCapsules25mgQuantity;
    } else if (medicationName === "Inspra Capsules" && medicationSku === "50 mg") {
      return inspraCapsules50mgQuantity;
    }
    //  else if (medicationName === "Fulphila" && medicationSku === "6mg/0.6ml") {
    //   return fulphilaQuantity;
    // }
  }

  const medicationStock = (medicationName, medicationSku) => {

    let filterStock = hcp.stock.filter(s => s.medication === medicationName && s.sku === medicationSku)[0];

    if (filterStock) {
      return filterStock.stock;
    } else {
      return 0;
    }
  }

  const CustomExpandIcon = () => {
    return (
      <Box
        sx={{
          '.Mui-expanded & > .collapsIconWrapper': { display: 'none' },
          '.expandIconWrapper': { display: 'none' },
          '.Mui-expanded & > .expandIconWrapper': { display: 'block' },
        }}
      >
        <div className="expandIconWrapper">
          <img src={minimize} alt="minus" />
        </div>
        <div className="collapsIconWrapper">
          <img src={plus} alt="plus" />
        </div>
      </Box>
    )
  }

  const submission = () => {
    setError("");
    setLoading(true);
    setInstant([]);
    setNormal([]);

    const normalSubmission = [];
    const instantSubmission = [];
    let submission = true;

    if (!lipitor10mgQuantity && !lipitor20mgQuantity && !lipitor40mgQuantity && !lipitor80mgQuantity &&
      !norvasc5mgQuantity && !norvasc10mgQuantity && !caduet510mgQuantity && !caduet520mgQuantity &&
      !caduet540mgQuantity && !caduet1010mgQuantity && !caduet1020mgQuantity && !caduet1040mgQuantity &&
      !viagraQuantity && !viagra50mgQuantity && !legalonQuantity && !viartrilsSachetsQuantity && !viartrilsCapsulesQuantity &&
      !adaxilSachetsQuantity && !celebrexCapsulesQuantity && !lyricaCapsules50mgQuantity && !lyricaCapsules75mgQuantity /*&& !fulphilaQuantity*/
      && !inspraCapsules50mgQuantity && !inspraCapsules25mgQuantity) {
      setError("Please fill in the quantity to complete your submission");
      setLoading(false);
    } else {

      if (lipitor10mgQuantity) {
        let product = patient.product.filter(p => p.medication === "Lipitor" && p.sku === "10 mg")[0];
        if (lipitor10mgQuantity > medicationStock("Lipitor", "10 mg")) {
          // out of stock
          setOutOfStock("Lipitor 10 mg");
          setOpen3(true);
          submission = false;
        }
        else if (patient.redemption.filter(r => r.status === "Pending" && r.medication === "Lipitor" && r.sku === "10 mg" && r.hcpId === hcp.hcp.id).length > 0) {
          // having pending redemption
          submission = false;
          setRedeem("Lipitor 10 mg");
          setOpen(true);
        }
        else if (lipitor10mgQuantity >= product.threshold && submittedAmount("Lipitor") === 0 && hcp.hcp.hcpType === "Hospital") {
          // instant redemption
          submission = false;
          // let numberOfRedemption = parseInt(lipitor10mgQuantity / product.threshold);
          // if (lipitor10mgQuantity + (product.redeemQuantity * numberOfRedemption) > medicationStock("Lipitor", "10 mg")) {
          //   setError("The selected medication is running out of stock. Please contact your Viatris sales representative for replenishment.")
          // } else {
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: lipitor10mgQuantity
          }
          instantSubmission.push(temp);
          setOpen5(true);
          //}
        } else {
          // normal submission
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: lipitor10mgQuantity
          }
          normalSubmission.push(temp);
        }
      }

      if (lipitor20mgQuantity) {
        let product = patient.product.filter(p => p.medication === "Lipitor" && p.sku === "20 mg")[0];
        if (lipitor20mgQuantity > medicationStock("Lipitor", "20 mg")) {
          // out of stock
          setOutOfStock("Lipitor 20 mg");
          setOpen3(true);
          submission = false;
        }
        else if (patient.redemption.filter(r => r.status === "Pending" && r.medication === "Lipitor" && r.sku === "20 mg" && r.hcpId === hcp.hcp.id).length > 0) {
          // having pending redemption
          submission = false;
          setRedeem("Lipitor 20 mg");
          setOpen(true);
        }
        else if (lipitor20mgQuantity >= product.threshold && submittedAmount("Lipitor") === 0 && hcp.hcp.hcpType === "Hospital") {
          // instant redemption
          submission = false;
          // let numberOfRedemption = parseInt(lipitor20mgQuantity / product.threshold);
          // if (lipitor20mgQuantity + (product.redeemQuantity * numberOfRedemption) > medicationStock("Lipitor", "20 mg")) {
          //   setError("The selected medication is running out of stock. Please contact your Viatris sales representative for replenishment.")
          // } else {
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: lipitor20mgQuantity
          }
          instantSubmission.push(temp);
          setOpen5(true);
          //}
        } else {
          // normal submission
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: lipitor20mgQuantity
          }
          normalSubmission.push(temp);
        }
      }

      if (lipitor40mgQuantity) {
        let product = patient.product.filter(p => p.medication === "Lipitor" && p.sku === "40 mg")[0];
        if (lipitor40mgQuantity > medicationStock("Lipitor", "40 mg")) {
          // out of stock
          setOutOfStock("Lipitor 40 mg");
          setOpen3(true);
          submission = false;
        }
        else if (patient.redemption.filter(r => r.status === "Pending" && r.medication === "Lipitor" && r.sku === "40 mg" && r.hcpId === hcp.hcp.id).length > 0) {
          // having pending redemption
          submission = false;
          setRedeem("Lipitor 40 mg");
          setOpen(true);
        }
        else if (lipitor40mgQuantity >= product.threshold && submittedAmount("Lipitor") === 0 && hcp.hcp.hcpType === "Hospital") {
          // instant redemption
          submission = false;
          // let numberOfRedemption = parseInt(lipitor40mgQuantity / product.threshold);
          // if (lipitor40mgQuantity + (product.redeemQuantity * numberOfRedemption) > medicationStock("Lipitor", "40 mg")) {
          //   setError("The selected medication is running out of stock. Please contact your Viatris sales representative for replenishment.")
          // } else {
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: lipitor40mgQuantity
          }
          instantSubmission.push(temp);
          setOpen5(true);
          //}
        } else {
          // normal submission
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: lipitor40mgQuantity
          }
          normalSubmission.push(temp);
        }
      }

      if (lipitor80mgQuantity) {
        let product = patient.product.filter(p => p.medication === "Lipitor" && p.sku === "80 mg")[0];
        if (lipitor80mgQuantity > medicationStock("Lipitor", "80 mg")) {
          // out of stock
          setOutOfStock("Lipitor 80 mg");
          setOpen3(true);
          submission = false;
        }
        else if (patient.redemption.filter(r => r.status === "Pending" && r.medication === "Lipitor" && r.sku === "80 mg" && r.hcpId === hcp.hcp.id).length > 0) {
          // having pending redemption
          submission = false;
          setRedeem("Lipitor 80 mg");
          setOpen(true);
        }
        else if (lipitor80mgQuantity >= product.threshold && submittedAmount("Lipitor") === 0 && hcp.hcp.hcpType === "Hospital") {
          // instant redemption
          submission = false;
          // let numberOfRedemption = parseInt(lipitor80mgQuantity / product.threshold);
          // if (lipitor80mgQuantity + (product.redeemQuantity * numberOfRedemption) > medicationStock("Lipitor", "80 mg")) {
          //   setError("The selected medication is running out of stock. Please contact your Viatris sales representative for replenishment.")
          // } else {
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: lipitor80mgQuantity
          }
          instantSubmission.push(temp);
          setOpen5(true);
          //}
        } else {
          // normal submission
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: lipitor80mgQuantity
          }
          normalSubmission.push(temp);
        }
      }

      if (norvasc5mgQuantity) {
        let product = patient.product.filter(p => p.medication === "Norvasc" && p.sku === "5 mg")[0];
        if (norvasc5mgQuantity > medicationStock("Norvasc", "5 mg")) {
          // out of stock
          setOutOfStock("Norvasc 5 mg");
          setOpen3(true);
          submission = false;
        }
        else if (patient.redemption.filter(r => r.status === "Pending" && r.medication === "Norvasc" && r.sku === "5 mg" && r.hcpId === hcp.hcp.id).length > 0) {
          // having pending redemption
          submission = false;
          setRedeem("Norvasc 5 mg");
          setOpen(true);
        }
        else if (norvasc5mgQuantity >= product.threshold && submittedAmount("Norvasc") === 0 && hcp.hcp.hcpType === "Hospital") {
          // instant redemption
          submission = false;
          // let numberOfRedemption = parseInt(norvasc5mgQuantity / product.threshold);
          // if (norvasc5mgQuantity + (product.redeemQuantity * numberOfRedemption) > medicationStock("Norvasc", "5 mg")) {
          //   setError("The selected medication is running out of stock. Please contact your Viatris sales representative for replenishment.")
          // } else {
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: norvasc5mgQuantity
          }
          instantSubmission.push(temp);
          setOpen5(true);
          //}
        } else {
          // normal submission
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: norvasc5mgQuantity
          }
          normalSubmission.push(temp);
        }
      }

      if (norvasc10mgQuantity) {
        let product = patient.product.filter(p => p.medication === "Norvasc" && p.sku === "10 mg")[0];
        if (norvasc10mgQuantity > medicationStock("Norvasc", "10 mg")) {
          // out of stock
          setOutOfStock("Norvasc 10 mg");
          setOpen3(true);
          submission = false;
        }
        else if (patient.redemption.filter(r => r.status === "Pending" && r.medication === "Norvasc" && r.sku === "10 mg" && r.hcpId === hcp.hcp.id).length > 0) {
          // having pending redemption
          submission = false;
          setRedeem("Norvasc 10 mg");
          setOpen(true);
        }
        else if (norvasc10mgQuantity >= product.threshold && submittedAmount("Norvasc") === 0 && hcp.hcp.hcpType === "Hospital") {
          // instant redemption
          submission = false;
          // let numberOfRedemption = parseInt(norvasc10mgQuantity / product.threshold);
          // if (norvasc10mgQuantity + (product.redeemQuantity * numberOfRedemption) > medicationStock("Norvasc", "10 mg")) {
          //   setError("The selected medication is running out of stock. Please contact your Viatris sales representative for replenishment.")
          // } else {
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: norvasc10mgQuantity
          }
          instantSubmission.push(temp);
          setOpen5(true);
          //}
        } else {
          // normal submission
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: norvasc10mgQuantity
          }
          normalSubmission.push(temp);
        }
      }

      if (caduet510mgQuantity) {
        let product = patient.product.filter(p => p.medication === "Caduet" && p.sku === "5/10mg")[0];
        if (caduet510mgQuantity > medicationStock("Caduet", "5/10mg")) {
          // out of stock
          setOutOfStock("Caduet 5/10mg");
          setOpen3(true);
          submission = false;
        }
        else if (patient.redemption.filter(r => r.status === "Pending" && r.medication === "Caduet" && r.sku === "5/10mg" && r.hcpId === hcp.hcp.id).length > 0) {
          // having pending redemption
          submission = false;
          setRedeem("Caduet 5/10mg");
          setOpen(true);
        }
        else if (caduet510mgQuantity >= product.threshold && submittedAmount("Caduet") === 0 && hcp.hcp.hcpType === "Hospital") {
          // instant redemption
          submission = false;
          // let numberOfRedemption = parseInt(caduet510mgQuantity / product.threshold);
          // if (caduet510mgQuantity + (product.redeemQuantity * numberOfRedemption) > medicationStock("Caduet", "5/10mg")) {
          //   setError("The selected medication is running out of stock. Please contact your Viatris sales representative for replenishment.")
          // } else {
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: caduet510mgQuantity
          }
          instantSubmission.push(temp);
          setOpen5(true);
          //}
        } else {
          // normal submission
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: caduet510mgQuantity
          }
          normalSubmission.push(temp);
        }
      }

      if (caduet520mgQuantity) {
        let product = patient.product.filter(p => p.medication === "Caduet" && p.sku === "5/20mg")[0];
        if (caduet520mgQuantity > medicationStock("Caduet", "5/20mg")) {
          // out of stock
          setOutOfStock("Caduet 5/20mg");
          setOpen3(true);
          submission = false;
        }
        else if (patient.redemption.filter(r => r.status === "Pending" && r.medication === "Caduet" && r.sku === "5/20mg" && r.hcpId === hcp.hcp.id).length > 0) {
          // having pending redemption
          submission = false;
          setRedeem("Caduet 5/20mg");
          setOpen(true);
        }
        else if (caduet520mgQuantity >= product.threshold && submittedAmount("Caduet") === 0 && hcp.hcp.hcpType === "Hospital") {
          // instant redemption
          submission = false;
          // let numberOfRedemption = parseInt(caduet520mgQuantity / product.threshold);
          // if (caduet520mgQuantity + (product.redeemQuantity * numberOfRedemption) > medicationStock("Caduet", "5/20mg")) {
          //   setError("The selected medication is running out of stock. Please contact your Viatris sales representative for replenishment.")
          // } else {
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: caduet520mgQuantity
          }
          instantSubmission.push(temp);
          setOpen5(true);
          //}
        } else {
          // normal submission
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: caduet520mgQuantity
          }
          normalSubmission.push(temp);
        }
      }

      if (caduet540mgQuantity) {
        let product = patient.product.filter(p => p.medication === "Caduet" && p.sku === "5/40mg")[0];
        if (caduet540mgQuantity > medicationStock("Caduet", "5/40mg")) {
          // out of stock
          setOutOfStock("Caduet 5/40mg");
          setOpen3(true);
          submission = false;
        }
        else if (patient.redemption.filter(r => r.status === "Pending" && r.medication === "Caduet" && r.sku === "5/40mg" && r.hcpId === hcp.hcp.id).length > 0) {
          // having pending redemption
          submission = false;
          setRedeem("Caduet 5/40mg");
          setOpen(true);
        }
        else if (caduet540mgQuantity >= product.threshold && submittedAmount("Caduet") === 0 && hcp.hcp.hcpType === "Hospital") {
          // instant redemption
          submission = false;
          // let numberOfRedemption = parseInt(caduet540mgQuantity / product.threshold);
          // if (caduet540mgQuantity + (product.redeemQuantity * numberOfRedemption) > medicationStock("Caduet", "5/40mg")) {
          //   setError("The selected medication is running out of stock. Please contact your Viatris sales representative for replenishment.")
          // } else {
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: caduet540mgQuantity
          }
          instantSubmission.push(temp);
          setOpen5(true);
          //}
        } else {
          // normal submission
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: caduet540mgQuantity
          }
          normalSubmission.push(temp);
        }
      }

      if (caduet1010mgQuantity) {
        let product = patient.product.filter(p => p.medication === "Caduet" && p.sku === "10/10mg")[0];
        if (caduet1010mgQuantity > medicationStock("Caduet", "10/10mg")) {
          // out of stock
          setOutOfStock("Caduet 10/10mg");
          setOpen3(true);
          submission = false;
        }
        else if (patient.redemption.filter(r => r.status === "Pending" && r.medication === "Caduet" && r.sku === "10/10mg" && r.hcpId === hcp.hcp.id).length > 0) {
          // having pending redemption
          submission = false;
          setRedeem("Caduet 10/10mg");
          setOpen(true);
        }
        else if (caduet1010mgQuantity >= product.threshold && submittedAmount("Caduet") === 0 && hcp.hcp.hcpType === "Hospital") {
          // instant redemption
          submission = false;
          // let numberOfRedemption = parseInt(caduet1010mgQuantity / product.threshold);
          // if (caduet1010mgQuantity + (product.redeemQuantity * numberOfRedemption) > medicationStock("Caduet", "10/10mg")) {
          //   setError("The selected medication is running out of stock. Please contact your Viatris sales representative for replenishment.")
          // } else {
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: caduet1010mgQuantity
          }
          instantSubmission.push(temp);
          setOpen5(true);
          //}
        } else {
          // normal submission
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: caduet1010mgQuantity
          }
          normalSubmission.push(temp);
        }
      }

      if (caduet1020mgQuantity) {
        let product = patient.product.filter(p => p.medication === "Caduet" && p.sku === "10/20mg")[0];
        if (caduet1020mgQuantity > medicationStock("Caduet", "10/20mg")) {
          // out of stock
          setOutOfStock("Caduet 10/20mg");
          setOpen3(true);
          submission = false;
        }
        else if (patient.redemption.filter(r => r.status === "Pending" && r.medication === "Caduet" && r.sku === "10/20mg" && r.hcpId === hcp.hcp.id).length > 0) {
          // having pending redemption
          submission = false;
          setRedeem("Caduet 10/20mg");
          setOpen(true);
        }
        else if (caduet1020mgQuantity >= product.threshold && submittedAmount("Caduet") === 0 && hcp.hcp.hcpType === "Hospital") {
          // instant redemption
          submission = false;
          // let numberOfRedemption = parseInt(caduet1020mgQuantity / product.threshold);
          // if (caduet1020mgQuantity + (product.redeemQuantity * numberOfRedemption) > medicationStock("Caduet", "10/20mg")) {
          //   setError("The selected medication is running out of stock. Please contact your Viatris sales representative for replenishment.")
          // } else {
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: caduet1020mgQuantity
          }
          instantSubmission.push(temp);
          setOpen5(true);
          //}
        } else {
          // normal submission
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: caduet1020mgQuantity
          }
          normalSubmission.push(temp);
        }
      }

      if (caduet1040mgQuantity) {
        let product = patient.product.filter(p => p.medication === "Caduet" && p.sku === "10/40mg")[0];
        if (caduet1040mgQuantity > medicationStock("Caduet", "10/40mg")) {
          // out of stock
          setOutOfStock("Caduet 10/40mg");
          setOpen3(true);
          submission = false;
        }
        else if (patient.redemption.filter(r => r.status === "Pending" && r.medication === "Caduet" && r.sku === "10/40mg" && r.hcpId === hcp.hcp.id).length > 0) {
          // having pending redemption
          submission = false;
          setRedeem("Caduet 10/40mg");
          setOpen(true);
        }
        else if (caduet1040mgQuantity >= product.threshold && submittedAmount("Caduet") === 0 && hcp.hcp.hcpType === "Hospital") {
          // instant redemption
          submission = false;
          // let numberOfRedemption = parseInt(caduet1040mgQuantity / product.threshold);
          // if (caduet1040mgQuantity + (product.redeemQuantity * numberOfRedemption) > medicationStock("Caduet", "10/40mg")) {
          //   setError("The selected medication is running out of stock. Please contact your Viatris sales representative for replenishment.")
          // } else {
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: caduet1040mgQuantity
          }
          instantSubmission.push(temp);
          setOpen5(true);
          //}
        } else {
          // normal submission
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: caduet1040mgQuantity
          }
          normalSubmission.push(temp);
        }
      }

      if (viagraQuantity) {
        let product = patient.product.filter(p => p.medication === "Viagra" && p.sku === "100 mg")[0];
        if (viagraQuantity > medicationStock("Viagra", "100 mg")) {
          // out of stock
          setOutOfStock("Viagra 100 mg");
          setOpen3(true);
          submission = false;
        }
        else if (patient.redemption.filter(r => r.status === "Pending" && r.medication === "Viagra" && r.sku === "100 mg" && r.hcpId === hcp.hcp.id).length > 0) {
          // having pending redemption
          submission = false;
          setRedeem("Viagra 100 mg");
          setOpen(true);
        }
        else if (viagraQuantity >= product.threshold && submittedAmount("Viagra") === 0 && hcp.hcp.hcpType === "Hospital") {
          // instant redemption
          submission = false;
          // let numberOfRedemption = parseInt(viagraQuantity / product.threshold);
          // if (viagraQuantity + (product.redeemQuantity * numberOfRedemption) > medicationStock("Viagra", "100 mg")) {
          //   setError("The selected medication is running out of stock. Please contact your Viatris sales representative for replenishment.")
          // } else {
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: viagraQuantity
          }
          instantSubmission.push(temp);
          setOpen5(true);
          //}
        } else {
          // normal submission
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: viagraQuantity
          }
          normalSubmission.push(temp);
        }
      }

      if (viagra50mgQuantity) {
        let product = patient.product.filter(p => p.medication === "Viagra" && p.sku === "50 mg")[0];
        if (viagra50mgQuantity > medicationStock("Viagra", "50 mg")) {
          // out of stock
          setOutOfStock("Viagra 50 mg");
          setOpen3(true);
          submission = false;
        }
        else if (patient.redemption.filter(r => r.status === "Pending" && r.medication === "Viagra" && r.sku === "50 mg" && r.hcpId === hcp.hcp.id).length > 0) {
          // having pending redemption
          submission = false;
          setRedeem("Viagra 50 mg");
          setOpen(true);
        }
        else if (viagra50mgQuantity >= product.threshold && submittedAmount("Viagra") === 0 && hcp.hcp.hcpType === "Hospital") {
          // instant redemption
          submission = false;
          // let numberOfRedemption = parseInt(viagra50mgQuantity / product.threshold);
          // if (viagra50mgQuantity + (product.redeemQuantity * numberOfRedemption) > medicationStock("Viagra", "50 mg")) {
          //   setError("The selected medication is running out of stock. Please contact your Viatris sales representative for replenishment.")
          // } else {
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: viagra50mgQuantity
          }
          instantSubmission.push(temp);
          setOpen5(true);
          //}
        } else {
          // normal submission
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: viagra50mgQuantity
          }
          normalSubmission.push(temp);
        }
      }

      if (legalonQuantity) {
        let product = patient.product.filter(p => p.medication === "Legalon" && p.sku === "140 mg")[0];
        if (legalonQuantity > medicationStock("Legalon", "140 mg")) {
          // out of stock
          setOutOfStock("Legalon 140 mg");
          setOpen3(true);
          submission = false;
        }
        else if (patient.redemption.filter(r => r.status === "Pending" && r.medication === "Legalon" && r.sku === "140 mg" && r.hcpId === hcp.hcp.id).length > 0) {
          // having pending redemption
          submission = false;
          setRedeem("Legalon 140 mg");
          setOpen(true);
        }
        else if (legalonQuantity >= product.threshold && submittedAmount("Legalon") === 0 && hcp.hcp.hcpType === "Hospital") {
          // instant redemption
          submission = false;
          // let numberOfRedemption = parseInt(legalonQuantity / product.threshold);
          // if (legalonQuantity + (product.redeemQuantity * numberOfRedemption) > medicationStock("Legalon", "140 mg")) {
          //   setError("The selected medication is running out of stock. Please contact your Viatris sales representative for replenishment.")
          // } else {
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: legalonQuantity
          }
          instantSubmission.push(temp);
          setOpen5(true);
          //}
        } else {
          // normal submission
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: legalonQuantity
          }
          normalSubmission.push(temp);
        }
      }

      if (viartrilsSachetsQuantity) {
        let product = patient.product.filter(p => p.medication === "Viartril-S Sachets" && p.sku === "1500 mg")[0];
        if (viartrilsSachetsQuantity > medicationStock("Viartril-S Sachets", "1500 mg")) {
          // out of stock
          setOutOfStock("Viartril-S Sachets 1500 mg");
          setOpen3(true);
          submission = false;
        }
        else if (patient.redemption.filter(r => r.status === "Pending" && r.medication === "Viartril-S Sachets" && r.sku === "1500 mg" && r.hcpId === hcp.hcp.id).length > 0) {
          // having pending redemption
          submission = false;
          setRedeem("Viartril-S Sachets 1500 mg");
          setOpen(true);
        }
        else if (viartrilsSachetsQuantity >= product.threshold && submittedAmount("Viartril-S Sachets") === 0 && hcp.hcp.hcpType === "Hospital") {
          // instant redemption
          submission = false;
          // let numberOfRedemption = parseInt(viartrilsSachetsQuantity / product.threshold);
          // if (viartrilsSachetsQuantity + (product.redeemQuantity * numberOfRedemption) > medicationStock("Viartril-S Sachets", "1500 mg")) {
          //   setError("The selected medication is running out of stock. Please contact your Viatris sales representative for replenishment.")
          // } else {
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: viartrilsSachetsQuantity
          }
          instantSubmission.push(temp);
          setOpen5(true);
          //}
        } else {
          // normal submission
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: viartrilsSachetsQuantity
          }
          normalSubmission.push(temp);
        }
      }

      if (viartrilsCapsulesQuantity) {
        let product = patient.product.filter(p => p.medication === "Viartril-S Capsules" && p.sku === "500 mg")[0];
        if (viartrilsCapsulesQuantity > medicationStock("Viartril-S Capsules", "500 mg")) {
          // out of stock
          setOutOfStock("Viartril-S Capsules 500 mg");
          setOpen3(true);
          submission = false;
        }
        else if (patient.redemption.filter(r => r.status === "Pending" && r.medication === "Viartril-S Capsules" && r.sku === "500 mg" && r.hcpId === hcp.hcp.id).length > 0) {
          // having pending redemption
          submission = false;
          setRedeem("Viartril-S Capsules 500 mg");
          setOpen(true);
        }
        else if (viartrilsCapsulesQuantity >= product.threshold && submittedAmount("Viartril-S Capsules") === 0 && hcp.hcp.hcpType === "Hospital") {
          // instant redemption
          submission = false;
          // let numberOfRedemption = parseInt(viartrilsCapsulesQuantity / product.threshold);
          // if (viartrilsCapsulesQuantity + (product.redeemQuantity * numberOfRedemption) > medicationStock("Viartril-S Capsules", "500 mg")) {
          //   setError("The selected medication is running out of stock. Please contact your Viatris sales representative for replenishment.")
          // } else {
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: viartrilsCapsulesQuantity
          }
          instantSubmission.push(temp);
          setOpen5(true);
          //}
        } else {
          // normal submission
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: viartrilsCapsulesQuantity
          }
          normalSubmission.push(temp);
        }
      }

      if (adaxilSachetsQuantity) {
        let product = patient.product.filter(p => p.medication === "Adaxil Sachets" && p.sku === "750mg/600mg")[0];
        if (adaxilSachetsQuantity > medicationStock("Adaxil Sachets", "750mg/600mg")) {
          // out of stock
          setOutOfStock("Adaxil Sachets 750mg/600mg");
          setOpen3(true);
          submission = false;
        }
        else if (patient.redemption.filter(r => r.status === "Pending" && r.medication === "Adaxil Sachets" && r.sku === "750mg/600mg" && r.hcpId === hcp.hcp.id).length > 0) {
          // having pending redemption
          submission = false;
          setRedeem("Adaxil Sachets 750mg/600mg");
          setOpen(true);
        }
        else if (adaxilSachetsQuantity >= product.threshold && submittedAmount("Adaxil Sachets") === 0 && hcp.hcp.hcpType === "Hospital") {
          // instant redemption
          submission = false;
          // let numberOfRedemption = parseInt(adaxilSachetsQuantity / product.threshold);
          // if (adaxilSachetsQuantity + (product.redeemQuantity * numberOfRedemption) > medicationStock("Adaxil Sachets", "750mg/600mg")) {
          //   setError("The selected medication is running out of stock. Please contact your Viatris sales representative for replenishment.")
          // } else {
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: adaxilSachetsQuantity
          }
          instantSubmission.push(temp);
          setOpen5(true);
          //}
        } else {
          // normal submission
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: adaxilSachetsQuantity
          }
          normalSubmission.push(temp);
        }
      }

      if (celebrexCapsulesQuantity) {
        let product = patient.product.filter(p => p.medication === "Celebrex Capsules" && p.sku === "200 mg")[0];
        if (celebrexCapsulesQuantity > medicationStock("Celebrex Capsules", "200 mg")) {
          // out of stock
          setOutOfStock("Celebrex Capsules 200 mg");
          setOpen3(true);
          submission = false;
        }
        else if (patient.redemption.filter(r => r.status === "Pending" && r.medication === "Celebrex Capsules" && r.sku === "200 mg" && r.hcpId === hcp.hcp.id).length > 0) {
          // having pending redemption
          submission = false;
          setRedeem("Celebrex Capsules 200 mg");
          setOpen(true);
        }
        else if (celebrexCapsulesQuantity >= product.threshold && submittedAmount("Celebrex Capsules") === 0 && hcp.hcp.hcpType === "Hospital") {
          // instant redemption
          submission = false;
          // let numberOfRedemption = parseInt(celebrexCapsulesQuantity / product.threshold);
          // if (celebrexCapsulesQuantity + (product.redeemQuantity * numberOfRedemption) > medicationStock("Celebrex Capsules", "200 mg")) {
          //   setError("The selected medication is running out of stock. Please contact your Viatris sales representative for replenishment.")
          // } else {
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: celebrexCapsulesQuantity
          }
          instantSubmission.push(temp);
          setOpen5(true);
          //}
        } else {
          // normal submission
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: celebrexCapsulesQuantity
          }
          normalSubmission.push(temp);
        }
      }

      if (lyricaCapsules50mgQuantity) {
        let product = patient.product.filter(p => p.medication === "Lyrica Capsules" && p.sku === "50 mg")[0];
        if (lyricaCapsules50mgQuantity > medicationStock("Lyrica Capsules", "50 mg")) {
          // out of stock
          setOutOfStock("Lyrica Capsules 50 mg");
          setOpen3(true);
          submission = false;
        }
        else if (patient.redemption.filter(r => r.status === "Pending" && r.medication === "Lyrica Capsules" && r.sku === "50 mg" && r.hcpId === hcp.hcp.id).length > 0) {
          // having pending redemption
          submission = false;
          setRedeem("Lyrica Capsules 50 mg");
          setOpen(true);
        }
        else if (lyricaCapsules50mgQuantity >= product.threshold && submittedAmount("Lyrica Capsules") === 0 && hcp.hcp.hcpType === "Hospital") {
          // instant redemption
          submission = false;
          // let numberOfRedemption = parseInt(lyricaCapsules50mgQuantity / product.threshold);
          // if (lyricaCapsules50mgQuantity + (product.redeemQuantity * numberOfRedemption) > medicationStock("Lyrica Capsules", "50 mg")) {
          //   setError("The selected medication is running out of stock. Please contact your Viatris sales representative for replenishment.")
          // } else {
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: lyricaCapsules50mgQuantity
          }
          instantSubmission.push(temp);
          setOpen5(true);
          //}
        } else {
          // normal submission
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: lyricaCapsules50mgQuantity
          }
          normalSubmission.push(temp);
        }
      }

      if (lyricaCapsules75mgQuantity) {
        let product = patient.product.filter(p => p.medication === "Lyrica Capsules" && p.sku === "75 mg")[0];
        if (lyricaCapsules75mgQuantity > medicationStock("Lyrica Capsules", "75 mg")) {
          // out of stock
          setOutOfStock("Lyrica Capsules 75 mg");
          setOpen3(true);
          submission = false;
        }
        else if (patient.redemption.filter(r => r.status === "Pending" && r.medication === "Lyrica Capsules" && r.sku === "75 mg" && r.hcpId === hcp.hcp.id).length > 0) {
          // having pending redemption
          submission = false;
          setRedeem("Lyrica Capsules 75 mg");
          setOpen(true);
        }
        else if (lyricaCapsules75mgQuantity >= product.threshold && submittedAmount("Lyrica Capsules") === 0 && hcp.hcp.hcpType === "Hospital") {
          // instant redemption
          submission = false;
          // let numberOfRedemption = parseInt(lyricaCapsules75mgQuantity / product.threshold);
          // if (lyricaCapsules75mgQuantity + (product.redeemQuantity * numberOfRedemption) > medicationStock("Lyrica Capsules", "75 mg")) {
          //   setError("The selected medication is running out of stock. Please contact your Viatris sales representative for replenishment.")
          // } else {
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: lyricaCapsules75mgQuantity
          }
          instantSubmission.push(temp);
          setOpen5(true);
          //}
        } else {
          // normal submission
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: lyricaCapsules75mgQuantity
          }
          normalSubmission.push(temp);
        }
      }
      if (inspraCapsules25mgQuantity) {
        let product = patient.product.filter(p => p.medication === "Inspra Capsules" && p.sku === "25 mg")[0];
        if (inspraCapsules25mgQuantity > medicationStock("Inspra Capsules", "25 mg")) {
          // out of stock
          setOutOfStock("Inspra Capsules 25 mg");
          setOpen3(true);
          submission = false;
        }
        else if (patient.redemption.filter(r => r.status === "Pending" && r.medication === "Inspra Capsules" && r.sku === "25 mg" && r.hcpId === hcp.hcp.id).length > 0) {
          // having pending redemption
          submission = false;
          setRedeem("Inspra Capsules 25 mg");
          setOpen(true);
        }
        else if (inspraCapsules25mgQuantity >= product.threshold && submittedAmount("Inspra Capsules") === 0 && hcp.hcp.hcpType === "Hospital") {
          // instant redemption
          submission = false;
          // let numberOfRedemption = parseInt(inspraCapsules25mgQuantity / product.threshold);
          // if (inspraCapsules25mgQuantity + (product.redeemQuantity * numberOfRedemption) > medicationStock("Inspra Capsules", "25 mg")) {
          //   setError("The selected medication is running out of stock. Please contact your Viatris sales representative for replenishment.")
          // } else {
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: inspraCapsules25mgQuantity
          }
          instantSubmission.push(temp);
          setOpen5(true);
          //}
        } else {
          // normal submission
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: inspraCapsules25mgQuantity
          }
          normalSubmission.push(temp);
        }
      }
      if (inspraCapsules50mgQuantity) {
        let product = patient.product.filter(p => p.medication === "Inspra Capsules" && p.sku === "50 mg")[0];
        if (inspraCapsules50mgQuantity > medicationStock("Inspra Capsules", "50 mg")) {
          // out of stock
          setOutOfStock("Inspra Capsules 50 mg");
          setOpen3(true);
          submission = false;
        }
        else if (patient.redemption.filter(r => r.status === "Pending" && r.medication === "Inspra Capsules" && r.sku === "50 mg" && r.hcpId === hcp.hcp.id).length > 0) {
          // having pending redemption
          submission = false;
          setRedeem("Inspra Capsules 50 mg");
          setOpen(true);
        }
        else if (inspraCapsules50mgQuantity >= product.threshold && submittedAmount("Inspra Capsules") === 0 && hcp.hcp.hcpType === "Hospital") {
          // instant redemption
          submission = false;
          // let numberOfRedemption = parseInt(inspraCapsules50mgQuantity / product.threshold);
          // if (inspraCapsules50mgQuantity + (product.redeemQuantity * numberOfRedemption) > medicationStock("Inspra Capsules", "50 mg")) {
          //   setError("The selected medication is running out of stock. Please contact your Viatris sales representative for replenishment.")
          // } else {
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: inspraCapsules50mgQuantity
          }
          instantSubmission.push(temp);
          setOpen5(true);
          //}
        } else {
          // normal submission
          const temp = {
            hcpId: hcp.hcp.id,
            patientId: patient.patient.id,
            productId: product.productId,
            status: "Approved",
            quantity: inspraCapsules50mgQuantity
          }
          normalSubmission.push(temp);
        }
      }
      // if (fulphilaQuantity) {
      //   let product = patient.product.filter(p => p.medication === "Fulphila" && p.sku === "6mg/0.6ml")[0];
      //   if (fulphilaQuantity > medicationStock("Fulphila", "6mg/0.6ml")) {
      //     // out of stock
      //     setOutOfStock("Fulphila 6mg/0.6ml");
      //     setOpen3(true);
      //     submission = false;
      //   }
      //   // else if (){
      //   //   // reach the max purchase
      //   //   setError("Your submission have reach the maximum quantity");
      //   // }
      //   else if (patient.redemption.filter(r => r.status === "Pending" && r.medication === "Fulphila" && r.sku === "6mg/0.6ml" && r.hcpId === hcp.hcp.id).length > 0) {
      //     // having pending redemption
      //     submission = false;
      //     setRedeem("Fulphila 6mg/0.6ml");
      //     setOpen(true);
      //   }
      //   else if (fulphilaQuantity >= product.threshold) {
      //     // instant redemption
      //     submission = false;
      //     let numberOfRedemption = parseInt(fulphilaQuantity / product.threshold);
      //     if (fulphilaQuantity + (product.redeemQuantity * numberOfRedemption) > medicationStock("Fulphila", "6mg/0.6ml")) {
      //       setError("The selected medication is running out of stock. Please contact your Viatris sales representative for replenishment.")
      //     } else {
      //       setOpen5(true);
      //     }
      //   } else {
      //     // normal submission
      //     const temp = {
      //       hcpId: hcp.hcp.id,
      //       patientId: patient.patient.id,
      //       medication: "Fulphila",
      //       sku: "6mg/0.6ml",
      //       status: "Approved",
      //       quantity: fulphilaQuantity
      //     }
      //     normalSubmission.push(temp);
      //   }
      // }

      if (normalSubmission.length > 0) {
        // if only perform normal submission
        if (submission) {
          setNormal(normalSubmission);
          setLoading(false);
          navigate('/hcp/hcpCheckoutInstantRedemption', { state: { normalSubmission } });
        } else {
          if (instantSubmission.length > 0) {
            setInstant(instantSubmission);
          }
          setNormal(normalSubmission);
          setLoading(false);
        }

      } else if (instantSubmission.length > 0) {
        setInstant(instantSubmission);
        setLoading(false);
      } else {
        setLoading(false);
      }

    }
  }

  // const createNormalSubmission = () => {
  //   setError("");
  //   setLoading(true);

  //   axios.post('/api/hcp/createSubmission', { normalSubmission: normal })
  //   .then(res => {

  //     axios.post('/api/hcp/patientDetails', { number })
  //       .then(res => {
  //         updatePatientDetails(res.data.data)

  //         let submission = [];
  //         let redemption = [];

  //         for (let i = 0; i < res.data.data.submission.length; i++) {
  //           if (!submission.includes(`${res.data.data.submission[i].medication} ${res.data.data.submission[i].sku}`)) {
  //             submission.push(`${res.data.data.submission[i].medication} ${res.data.data.submission[i].sku}`);
  //           }
  //         }

  //         let redeemedRedemption = res.data.data.redemption.filter(r => r.status === "Redeemed");
  //         for (let i = 0; i < redeemedRedemption.length; i++) {
  //           if (!redemption.includes(`${redeemedRedemption[i].medication} ${redeemedRedemption[i].sku}`)) {
  //             redemption.push(`${redeemedRedemption[i].medication} ${redeemedRedemption[i].sku}`);
  //           }
  //         }

  //         setSubmissionMedication(submission);
  //         setRedemptionMedication(redemption);

  //         axios.post('/api/hcp/checkToken', { token })
  //           .then(res => {
  //             updateHcpDetails(res.data.data);
  //             setLipitor10mgQuantity(0);
  //             setLipitor20mgQuantity(0);
  //             setLipitor40mgQuantity(0);
  //             setLipitor80mgQuantity(0);
  //             setNorvasc5mgQuantity(0);
  //             setNorvasc10mgQuantity(0);
  //             setCaduet510mgQuantity(0);
  //             setCaduet520mgQuantity(0);
  //             setCaduet540mgQuantity(0);
  //             setCaduet1010mgQuantity(0);
  //             setCaduet1020mgQuantity(0);
  //             setCaduet1040mgQuantity(0);
  //             setViagraQuantity(0);
  //             setLegalonQuantity(0);
  //             setViartrilsSachetsQuantity(0);
  //             setViartrilsCapsulesQuantity(0);
  //             setAdaxilSachetsQuantity(0);
  //             //setFulphilaQuantity(0);
  //             setOpen7(false);
  //             setOpen6(true);
  //             setLoading(false);
  //           })
  //           .catch(err => {
  //             setError(err.response.data.error);
  //             setLoading(false);
  //           });
  //       })
  //       .catch(err => {
  //         setError(err.response.data.error);
  //         setLoading(false);
  //       });
  //   })
  //   .catch(err => {
  //     setError(err.response.data.error);
  //     setLoading(false);
  //   });
  // }

  const createInstantRedemption = () => {

    if (!error && !open && !open3) {
      setLoading(true);
      axios.post('/api/hcp/createInstantRedemption', { instant })
        .then(res => {
          if (res.data.createdRedemption.length > 0) {
            if (normal.length > 0) {
              setLoading(false);
              navigate('/hcp/hcpInstantRedemption', { state: { instantRedemption: res.data.createdRedemption, normalSubmission: normal } });
            } else {
              setLoading(false);
              navigate('/hcp/hcpInstantRedemption', { state: { instantRedemption: res.data.createdRedemption } });
            }
          } else {
            if (normal.length > 0) {
              axios.post('/api/hcp/createSubmission', { normalSubmission: normal })
                .then(res => {
                  axios.post('/api/hcp/patientDetails', { number })
                    .then(res => {
                      updatePatientDetails(res.data.data)

                      let submission = [];
                      let redemption = [];

                      for (let i = 0; i < res.data.data.submission.length; i++) {
                        if (!submission.includes(`${res.data.data.submission[i].medication} ${res.data.data.submission[i].sku}`)) {
                          submission.push(`${res.data.data.submission[i].medication} ${res.data.data.submission[i].sku}`);
                        }
                      }

                      let redeemedRedemption = res.data.data.redemption.filter(r => r.status === "Redeemed");
                      for (let i = 0; i < redeemedRedemption.length; i++) {
                        if (!redemption.includes(`${redeemedRedemption[i].medication} ${redeemedRedemption[i].sku}`)) {
                          redemption.push(`${redeemedRedemption[i].medication} ${redeemedRedemption[i].sku}`);
                        }
                      }

                      setSubmissionMedication(submission);
                      setRedemptionMedication(redemption);

                      axios.post('/api/hcp/checkToken', { token })
                        .then(res => {
                          updateHcpDetails(res.data.data);
                          setLipitor10mgQuantity(0);
                          setLipitor20mgQuantity(0);
                          setLipitor40mgQuantity(0);
                          setLipitor80mgQuantity(0);
                          setNorvasc5mgQuantity(0);
                          setNorvasc10mgQuantity(0);
                          setCaduet510mgQuantity(0);
                          setCaduet520mgQuantity(0);
                          setCaduet540mgQuantity(0);
                          setCaduet1010mgQuantity(0);
                          setCaduet1020mgQuantity(0);
                          setCaduet1040mgQuantity(0);
                          setViagraQuantity(0);
                          setViagra50mgQuantity(0);
                          setLegalonQuantity(0);
                          setViartrilsSachetsQuantity(0);
                          setViartrilsCapsulesQuantity(0);
                          setAdaxilSachetsQuantity(0);
                          //setFulphilaQuantity(0);
                          setCelebrexCapsulesQuantity(0);
                          setLyricaCapsules50mgQuantity(0);
                          setLyricaCapsules75mgQuantity(0);
                          setInspraCapsules25mgQuantity(0);
                          setInspraCapsules50mgQuantity(0);
                          setNormal([]);
                          setInstant([]);
                          setOpen6(true);
                          setModalType("success");
                          setModalContent("This medication has been submitted successfully for redemption.");
                          setOpen5(false);
                          setLoading(false);
                        })
                        .catch(err => {
                          setError(err.response.data.error);
                          setLoading(false);
                        });
                    })
                    .catch(err => {
                      setError(err.response.data.error);
                      setLoading(false);
                    });
                })
                .catch(err => {
                  setError(err.response.data.error);
                  setLoading(false);
                });
            } else {
              axios.post('/api/hcp/patientDetails', { number })
                .then(res => {
                  updatePatientDetails(res.data.data)

                  let submission = [];
                  let redemption = [];

                  for (let i = 0; i < res.data.data.submission.length; i++) {
                    if (!submission.includes(`${res.data.data.submission[i].medication} ${res.data.data.submission[i].sku}`)) {
                      submission.push(`${res.data.data.submission[i].medication} ${res.data.data.submission[i].sku}`);
                    }
                  }

                  let redeemedRedemption = res.data.data.redemption.filter(r => r.status === "Redeemed");
                  for (let i = 0; i < redeemedRedemption.length; i++) {
                    if (!redemption.includes(`${redeemedRedemption[i].medication} ${redeemedRedemption[i].sku}`)) {
                      redemption.push(`${redeemedRedemption[i].medication} ${redeemedRedemption[i].sku}`);
                    }
                  }

                  setSubmissionMedication(submission);
                  setRedemptionMedication(redemption);

                  axios.post('/api/hcp/checkToken', { token })
                    .then(res => {
                      updateHcpDetails(res.data.data);
                      setLipitor10mgQuantity(0);
                      setLipitor20mgQuantity(0);
                      setLipitor40mgQuantity(0);
                      setLipitor80mgQuantity(0);
                      setNorvasc5mgQuantity(0);
                      setNorvasc10mgQuantity(0);
                      setCaduet510mgQuantity(0);
                      setCaduet520mgQuantity(0);
                      setCaduet540mgQuantity(0);
                      setCaduet1010mgQuantity(0);
                      setCaduet1020mgQuantity(0);
                      setCaduet1040mgQuantity(0);
                      setViagraQuantity(0);
                      setViagra50mgQuantity(0);
                      setLegalonQuantity(0);
                      setViartrilsSachetsQuantity(0);
                      setViartrilsCapsulesQuantity(0);
                      setAdaxilSachetsQuantity(0);
                      //setFulphilaQuantity(0);
                      setCelebrexCapsulesQuantity(0);
                      setLyricaCapsules50mgQuantity(0);
                      setLyricaCapsules75mgQuantity(0);
                      setInspraCapsules25mgQuantity(0);
                      setInspraCapsules50mgQuantity(0);
                      setNormal([]);
                      setInstant([]);
                      setOpen6(true);
                      setModalType("success");
                      setModalContent("This medication has been submitted successfully for redemption.");
                      setOpen5(false);
                      setLoading(false);
                    })
                    .catch(err => {
                      setError(err.response.data.error);
                      setLoading(false);
                    });
                })
                .catch(err => {
                  setError(err.response.data.error);
                  setLoading(false);
                });
            }
          }
        })
        .catch(err => {
          setError(err.response.data.error);
          setLoading(false);
        });
    } else if (open) {
      setError(`You are unable to submit more ${redeem}. Please complete redemption to continue.`)
    } else if (open3) {
      setError(`This medication (${outOfStock}) is currently out of stock.`)
    }
  }

  const submittedAmount = (medication) => {
    let filterHcpPoint = patient.point.filter(p => p.hcpId === hcp.hcp.id)[0];
    if (filterHcpPoint) {
      let filterMedicationPoint = filterHcpPoint.medication.filter(m => m.medication === medication)[0];
      if (filterMedicationPoint) {
        return filterMedicationPoint.point
      } else {
        return 0
      }
    } else {
      return 0
    }
  }

  const remainingAmount = (medication, sku) => {
    let threshold = patient.product.filter(product => product.medication === medication && product.sku === sku)[0].threshold;
    let remaining = threshold - submittedAmount(medication);
    if (remaining < 0) {
      return 0;
    } else {
      return remaining;
    }
  }

  const submissionStatus = (medication, sku) => {
    if (remainingAmount(medication, sku) === 0) {
      let pendingRedemption = patient.redemption.filter(r => r.status === "Pending" && r.medication === medication && r.sku === sku && r.hcpId === hcp.hcp.id);
      if (pendingRedemption.length > 0) {
        return "Pending redemption";
      } else {
        return "Reach maximum redemption";
      }
    } else {
      return "Insufficient for redemption";
    }
  }

  return (
    <div>
      <HcpHeader />
      <div className="hcp-whole-body">
        <div className="page-title mt-3">
          <img src={leftArrow} className="hcp-left-arrow" alt="back-icon" onClick={() => navigate('/hcp/home')} />
          <h2>{select === "redemption" ? "Redemption" : "Submission"}</h2>
        </div>
        <div className="page-body">
          <div className="hcp-body-padding">

            <Accordion className="hcp-patient-profile">
              <AccordionSummary expandIcon={<CustomExpandIcon />}>
                <div className="d-flex">
                  <h2>Patient Profile</h2>
                  <EditIcon className="ms-2 mt-1" onClick={() => navigate('/hcp/hcpUpdateProfile')} />
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="px-2">
                  <div className="d-flex justify-content-between">
                    <p>Mobile Number</p>
                    <p>{patient ? patient.patient.number : ""}</p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p>NRIC / MRN Number</p>
                    <p>{patient ? patient.patient.nric : ""}</p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p>Annual cycle end date</p>
                    <p>{patient ? (patient.patient.currentCycleYear ? (new Date(patient.patient.cycleyears[patient.patient.cycleyears.length - 1].endDate).getMonth() + 1) + "/" + (new Date(patient.patient.cycleyears[patient.patient.cycleyears.length - 1].endDate).getFullYear()) : "NA") : ""}</p>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>

            <div className="hcp-submission-history-button">
              <button
                id="submission"
                className="btn form-control hcp-submission-button py-2"
                style={{
                  backgroundColor: select === "submission" ? "#403B3B" : "#FFFFFF",
                  color: select === "submission" ? "#ffffff" : "#403B3B",
                }}
                onClick={() => select === "submission" ? setSelect("medication") : setSelect("submission")}
              >
                <b>SUBMISSIONS</b>
              </button>
              <button
                id="registered"
                className="btn form-control hcp-submission-button py-2"
                style={{
                  backgroundColor: select === "redemption" ? "#403B3B" : "#FFFFFF",
                  color: select === "redemption" ? "#ffffff" : "#403B3B",
                }}
                onClick={() => select === "redemption" ? setSelect("medication") : setSelect("redemption")}
              >
                <b>REDEMPTIONS</b>
              </button>
            </div>

            {
              select === "medication" ? (
                <div>
                  <div>
                    <h3>Registered Medication</h3>
                    {
                      patient ? (patient.medication.map((m, i) => {
                        return (
                          <div className="hcp-checkout-details" key={i}>
                            <div className="d-flex">
                              <div className="submission-img-container">
                                <img src={medicationImage(m.medication, m.sku)} className="submission-product-img" alt="lipitor" />
                              </div>

                              <div style={{ margin: "auto" }} className="align-self-center">
                                <div style={{ lineHeight: "20px" }}>
                                  <p><b>Product:</b> {m.medication} {m.sku}</p>
                                  <p><b>Credit:</b> {hcp ? medicationStock(m.medication, m.sku) : ""} tablets</p>
                                </div>

                                <div className="d-flex justify-content-between">
                                  <img
                                    src={minus}
                                    className="hcp-minus-icon"
                                    alt="minus-icon"
                                    onClick={() => minusMedication(m.medication, m.sku)}
                                  />
                                  <p className="hcp-quantity-text">{medicationQuantity(m.medication, m.sku)}</p>
                                  <img
                                    src={plusQ}
                                    className="hcp-plus-icon"
                                    alt="plus-icon"
                                    onClick={() => plusMedication(m.medication, m.sku)}
                                  />
                                </div>
                              </div>
                            </div>

                            <table className="hcp-table-width-submission">
                              <thead>
                                <tr className="hcp-small-font">
                                  <th>
                                    <p className="hcp-table-header-text">Submitted</p>
                                  </th>
                                  <th>
                                    <p className="hcp-table-header-text">Remaining</p>
                                  </th>
                                  <th>
                                    <p className="hcp-table-header-text">Status</p>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr className="hcp-small-font">
                                  <td>{submittedAmount(m.medication)}</td>
                                  <td>{remainingAmount(m.medication, m.sku)}</td>
                                  <td>{submissionStatus(m.medication, m.sku)}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        )
                      })) : (
                        <div className="text-center">
                          <CircularProgress />
                        </div>
                      )
                    }
                  </div>

                  <button className="btn hcp-addmore-button" onClick={() => navigate('/hcp/hcpUpdateProfile')}>
                    <b>+ ADD MORE / EDIT</b>
                  </button>

                  {
                    error ? (
                      <div className="pt-3">
                        <p style={{ color: "red", textAlign: "center" }}>{error}</p>
                      </div>
                    ) : null
                  }

                  {
                    loading ? (
                      <div className="text-center">
                        <CircularProgress />
                      </div>
                    ) : (
                      <div className="text-center">
                        <button className="btn form-control hcp-submit-button py-3" onClick={() => submission()}>
                          <b>Submit</b>
                        </button>
                      </div>
                    )
                  }

                </div>
              ) : select === "submission" ? (
                <div>
                  <h2>Submission History</h2>

                  {
                    patient && submissionMedication ? (submissionMedication.map((med, i) => {
                      return (
                        <Accordion className="hcp-patient-profile my-3" key={i}>
                          <AccordionSummary expandIcon={<CustomExpandIcon />}>
                            <b>{med}</b>
                          </AccordionSummary>

                          {
                            patient.submission.filter(s => `${s.medication} ${s.sku}` === med && s.hcpId === hcp.hcp.id).map((submission, i) => {
                              return (
                                <AccordionDetails key={i}>
                                  <div className="mx-3" key={i} style={{ lineHeight: "15px" }}>
                                    <div className="d-flex justify-content-between">
                                      <p><b>Submission date</b></p>
                                      <p>{new Date(submission.submissionDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <p><b>Quantity submitted</b></p>
                                      <p>{submission.quantity}</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <p><b>HCP code</b></p>
                                      <p>{submission.hcpCode}</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <p><b>HCP name</b></p>
                                      <p>{submission.hcpName}</p>
                                    </div>
                                  </div>
                                </AccordionDetails>
                              )
                            })
                          }
                        </Accordion>
                      )
                    })) : (
                      <div className="text-center">
                        <CircularProgress />
                      </div>
                    )
                  }
                </div>
              ) : (
                <div>
                  <h2>Pending Redemptions</h2>

                  {
                    patient ? (patient.redemption.filter(r => r.status === "Pending" && r.hcpId === hcp.hcp.id).map((r, i) => {
                      return (
                        <Accordion className="hcp-pending-redemption my-3" key={i}>
                          <AccordionSummary expandIcon={<CustomExpandIcon />}>
                            <b>{r.hcpName} ({new Date(r.createdAt).toLocaleDateString('en-GB')})</b>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="mx-3" style={{ lineHeight: "15px" }}>
                              <div className="d-flex justify-content-between">
                                <p><b>Medication</b></p>
                                <p>{r.medication} {r.sku}</p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p><b>Quantity</b></p>
                                <p>{r.redeemQuantity}</p>
                              </div>
                              <div className="d-flex justify-content-between">
                                <p><b>Redemption end date</b></p>
                                <p>{new Date(r.redemptionExpiredDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}</p>
                              </div>
                              {
                                !hcp.hcp.hideRedemptionCode ? (
                                  <div className="d-flex justify-content-between">
                                    <p><b>Redemption code</b></p>
                                    <p>{r.redemptionCode}</p>
                                  </div>
                                ) : null
                              }
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      )
                    })) : (
                      <div className="text-center">
                        <CircularProgress />
                      </div>
                    )
                  }

                  <h2>Redemption History</h2>

                  {
                    patient && redemptionMedication ? (redemptionMedication.map((redemption, i) => {
                      return (
                        <Accordion className="hcp-patient-profile my-3" key={i}>
                          <AccordionSummary expandIcon={<CustomExpandIcon />}>
                            <b>{redemption}</b>
                          </AccordionSummary>

                          {
                            patient.redemption.filter(r => `${r.medication} ${r.sku}` === redemption && r.status === "Redeemed" && r.hcpId === hcp.hcp.id).map((r, i) => {
                              return (
                                <AccordionDetails key={i}>
                                  <div className="mx-3" key={i} style={{ lineHeight: "15px" }}>
                                    <div className="d-flex justify-content-between">
                                      <p><b>Redemption date</b></p>
                                      <p>{new Date(r.redemptionDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <p><b>Quantity redeemed</b></p>
                                      <p>{r.redeemQuantity}</p>
                                    </div>
                                    {
                                      !hcp.hcp.hideRedemptionCode ? (
                                        <div className="d-flex justify-content-between">
                                          <p><b>Redemption Code</b></p>
                                          <p>{r.redemptionCode}</p>
                                        </div>
                                      ) : null
                                    }
                                    <div className="d-flex justify-content-between">
                                      <p><b>HCP code</b></p>
                                      <p>{r.hcpCode}</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <p><b>HCP name</b></p>
                                      <p>{r.hcpName}</p>
                                    </div>
                                  </div>
                                </AccordionDetails>
                              )
                            })
                          }
                        </Accordion>
                      )
                    })) : (
                      <div className="text-center">
                        <CircularProgress />
                      </div>
                    )
                  }
                </div>
              )
            }
          </div>
        </div>

        <HcpNavigationBar />

      </div >
      <div className="hcp-modal-box">
        <Modal
          open={open}
          onClose={() => {
            setOpen(false);
            setRedeem("");
            setError("");
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="red-modal">
            <div className="align-right">
              <MDBBtn
                className="btn-close btn-close-white"
                color="none"
                aria-label="Close"
                onClick={() => {
                  setOpen(false);
                  setRedeem("");
                  setError("");
                }}
              />
            </div>
            <div className="text-center">
              <img src={subtract} alt="warning-icon" />
            </div>

            <p className="hcp-alert">
              You are unable to submit more <u>{redeem}</u>. Please complete redemption to continue.
            </p>
            <div className="button-container">
              <button className="btn form-control hcp-register-button py-3" onClick={() => navigate('/hcp/home')}>
                <b>REDEEM NOW</b>
              </button>
              <button className="btn form-control hcp-register-button py-3" onClick={() => { setOpen(false); setRedeem(""); setError(""); setSelect("redemption") }}>
                <b>VIEW REDEMPTION CODE</b>
              </button>
            </div>
          </Box>
        </Modal>
      </div>
      <div className="hcp-modal-box">
        <Modal
          open={open2}
          onClose={() => setOpen2(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="blue-modal">
            <div className="align-right">
              <MDBBtn
                className="btn-close"
                color="none"
                aria-label="Close"
                onClick={() => setOpen2(false)}
              />
            </div>
            <h3 className="hcp-alert2">Submission summary</h3>
            <table className="hcp-table-width">
              <thead>
                <tr className="hcp-header hcp-small-font">
                  <td>
                    <p className="hcp-label">Product</p>
                  </td>
                  <td>
                    <p className="hcp-label">Submitted</p>
                  </td>
                  <td>
                    <p className="hcp-label">Remaining</p>
                  </td>
                  <td>
                    <p className="hcp-label">Status</p>
                  </td>
                </tr>
              </thead>
              <tbody>
                {
                  patient ? patient.medication.map((m, i) => {
                    return (
                      <tr className="hcp-small-font hcp-table" key={i}>
                        <td>{m.medication}</td>
                        <td>{submittedAmount(m.medication)}</td>
                        <td>{remainingAmount(m.medication, m.sku)}</td>
                        <td>{submissionStatus(m.medication, m.sku)}</td>
                      </tr>
                    )
                  }) : null
                }
              </tbody>
            </table>
          </Box>
        </Modal>
        <Modal
          open={open3}
          onClose={() => {
            setOutOfStock("");
            setOpen3(false);
            setError("");
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="red-modal">
            <div className="align-right">
              <MDBBtn
                className="btn-close btn-close-white"
                color="none"
                aria-label="Close"
                onClick={() => {
                  setOutOfStock("");
                  setOpen3(false);
                  setError("");
                }}
              />
            </div>
            <div className="text-center">
              <img src={subtract} alt="warning-icon" />
            </div>

            <p className="hcp-alert">
              This medication ({outOfStock}) is currently out of stock.
            </p>
            <div className="button-container">
              <button
                onClick={() => {
                  setOutOfStock("");
                  setOpen3(false);
                  setError("");
                }}
                className="btn form-control hcp-register-button py-3"
              >
                <b>GO BACK</b>
              </button>
            </div>
          </Box>
        </Modal>
      </div>
      <div className="hcp-modal-box">
        <Modal
          open={open4}
          onClose={closeModal4}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="red-modal">
            <div className="align-right">
              <MDBBtn
                className="btn-close btn-close-white"
                color="none"
                aria-label="Close"
                onClick={closeModal4}
              />
            </div>
            <div className="text-center">
              <img src={write} alt="success-icon" />
            </div>

            <p className="hcp-alert">The patient has changed his/her medication.</p>
            <div className="button-container">
              {
                loading ? (
                  <div className="text-center">
                    <CircularProgress />
                  </div>
                ) : (
                  <button className="btn form-control hcp-register-button py-3" onClick={closeModal4}>
                    <b>OK</b>
                  </button>
                )
              }
            </div>
          </Box>
        </Modal>
      </div>
      <div className="hcp-modal-box">
        <Modal
          open={open5}
          onClose={() => setOpen5(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="red-modal instant-redemption-modal">
            <div className="align-right">
              <MDBBtn
                className="btn-close btn-close-white"
                color="none"
                aria-label="Close"
                onClick={() => setOpen5(false)}
              />
            </div>
            <div className="text-center">
              <img src={subtract} alt="warning-icon" />
            </div>
            <div className="hcp-info-instant-redemption-header-text">
              <h3>This medication is available for instant redemption.</h3>
            </div>

            <p className="hcp-instant-redemption-text">
              This function is only for patient who requires instant collection of complimentary product(s).
            </p>
            <p className="hcp-instant-redemption-text">
              Instant redemption of product(s) is completed before a quality check is performed by the Program Manager.
            </p>
            <p className="hcp-instant-redemption-text">
              To avoid rejection after redemption is made, please confirm that:
            </p>
            <ul className="hcp-instant-redemption-text">
              <li>Your inventory level is adequate for the purpose of this redemption.</li>
              <li>Patient is eligible for this redemption.</li>
            </ul>
            <p className="hcp-instant-redemption-text">
              Viatris shall not be responsible for any potential rejection or loss resulting from any violation/ error by the user of the program.
            </p>
            <p className="hcp-instant-redemption-text">
              Please click “Agree” below if you wish to proceed.
            </p>

            {
              error ? (
                <div className="pt-3">
                  <p style={{ color: "red", textAlign: "center" }}>{error}</p>
                </div>
              ) : null
            }

            {
              loading ? (
                <div className="text-center">
                  <CircularProgress />
                </div>
              ) : (
                <div className="button-container">
                  <button className="btn form-control hcp-register-button py-3" onClick={() => createInstantRedemption()}>
                    <b>AGREE</b>
                  </button>
                </div>
              )
            }

            <div className="button-container">
              <button className="btn form-control hcp-not-button py-3" onClick={() => setOpen5(false)}>
                <b>NOT NOW</b>
              </button>
            </div>
          </Box>
        </Modal>
        <CustomModal open={open6} handleClose={handleClose6} modalType={modalType} modalContent={modalContent} />
      </div>
    </div >
  );
};

const mapStateToProps = state => {
  return {
    patient: state.hcp.patient,
    hcp: state.hcp.hcp,
    token: state.hcp.token
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updatePatientDetails: data => dispatch(updatePatientDetails(data)),
    updateHcpDetails: data => dispatch(updateHcpDetails(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HcpSubmission);
