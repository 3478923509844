import React, { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import { patientLogin, updatePatientLanguage } from "../redux/actions/patientActions";
import axios from 'axios';
import { useTranslation } from 'react-i18next';

const PatientEnterOtpSignup = ({ patientLogin, language, updatePatientLanguage }) => {

  let number = localStorage.getItem("patient-number");

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [otp, setOtp] = useState("");

  useEffect(() => {
    if (!number) {
      navigate('/patient/patientWelcome');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = e => {
    e.preventDefault();

    setError("")
    setLoading(true);

    axios.post('/api/patient/verifyOtp', { number, otp })
      .then(res => {
        localStorage.setItem("patient-number", number);
        patientLogin(res.data);
        setLoading(false);
        navigate('/patient/patientSignUp');
      })
      .catch(err => {
        setError(err.response.data.error);
        setLoading(false);
      });
  }

  const resend = () => {
    setError("")
    setLoading(true);

    axios.post('/api/patient/resendOtp', { number })
      .then(res => {
        setLoading(false);
      })
      .catch(err => {
        setError(err.response.data.error);
        setLoading(false);
      });
  }

  return (
    <div className="patient-otp-page">
      <div style={{ height: "5vh" }}></div>
      <div className="patient-otp-container">
        <div className="d-flex justify-content-end">
          <div className="hcp-lang">
            <p className={language === "en" ? "hcp-lang-border px-2" : "px-2"} onClick={() => updatePatientLanguage("en")}>ENG</p>
            <p className={language === "bm" ? "hcp-lang-border px-2" : "px-2"} onClick={() => updatePatientLanguage("bm")}>BM</p>
            <p className={language === "ch" ? "hcp-lang-border px-2" : "px-2"} onClick={() => updatePatientLanguage("ch")}>MAN</p>
          </div>
        </div>

        <div className="form-container">
          <div>
            <h2 className="hcp-login-title">{t("OTP Verification")}</h2>
          </div>

          <div>
            <p className="hcp-pw-notifier">
              {t("An SMS has been sent to your phone number")} {number} {t("with your OTP. Please key in the OTP to finish the registration process.")}
            </p>
          </div>

          <form onSubmit={handleSubmit}>
            <div>
              <label className="hcp-email-label"><b>OTP</b></label>
              <input className="form-control input-field-white" type="number" id="otp" name="otp" value={otp} onChange={e => setOtp(e.target.value)} onWheel={(e) => e.target.blur()} required />
            </div>

            {
              error ? (
                <div className="pt-3">
                  <p style={{ color: "red", textAlign: "center" }}>{t(error)}</p>
                </div>
              ) : null
            }

            {
              loading ? (
                <div className="text-center">
                  <CircularProgress />
                </div>
              ) : (
                <div className="button-container">
                  <button type="submit" className="btn form-control patient-otp-button py-3">
                    <b>{t("SIGN UP")}</b>
                  </button>
                </div>
              )
            }
          </form>

          <div className="patient-didnt-received" style={{ color: "white" }}>
            <div className="patient-line" style={{ borderTop: "2px solid white" }}></div>
            <p className="patient-didnt-receive-text">
              {t("Didn't receive the SMS?")}
            </p>
            <div className="patient-line" style={{ borderTop: "2px solid white" }}></div>
          </div>
          {
            loading ? (
              <div className="text-center mt-5">
                <CircularProgress />
              </div>
            ) : (
              <div className="button-container">
                <button className="btn form-control patient-resendotp-button py-3" onClick={() => resend()}>
                  <b>{t("RESENT")}</b>
                </button>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    language: state.patient.language
  };
};


const mapDispatchToProps = dispatch => {
  return {
    patientLogin: data => dispatch(patientLogin(data)),
    updatePatientLanguage: data => dispatch(updatePatientLanguage(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientEnterOtpSignup);
