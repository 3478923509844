import React, { useState, useEffect } from "react";
import { FormControl, InputLabel, Select, MenuItem, CircularProgress, Autocomplete, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import { updatePatientDetails } from "../redux/actions/hcpActions";
import { medicationBrandList } from "../dynamicController";
import axios from 'axios';

const HcpUpdateProfile = ({ patient, updatePatientDetails }) => {

  let patientNumber = localStorage.getItem("patient-number");

  const navigate = useNavigate();

  const [index, setIndex] = useState(-1);
  const [inputList, setInputList] = useState([]);
  const [medicationList, setMedicationList] = useState([]);
  const [productList, setProductList] = useState([]);

  const [pageLoading, setPageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [number, setNumber] = useState("");
  const [nric, setNric] = useState("");
  const [smsLanguage, setSmsLanguage] = useState("");

  useEffect(() => {
    setPageLoading(true);

    axios.post('/api/hcp/getProducts')
      .then(res => {
        setProductList(res.data.data);
        if (!patient) {
          if (patientNumber) {
            axios.post('/api/hcp/patientDetails', { number: patientNumber })
              .then(res => {
                updatePatientDetails(res.data.data)
                setNumber(res.data.data.patient.number);
                setNric(res.data.data.patient.nric);
                setSmsLanguage(res.data.data.patient.language);
                for (let i = 0; i < res.data.data.medication.length; i++) {
                  let list = inputList;
                  let listObject = {
                    medication: res.data.data.medication[i].medication,
                    sku: res.data.data.medication[i].sku
                  }
                  list.push(listObject);
                  setInputList(list);
                }
                setPageLoading(false);
              })
              .catch(err => {
                navigate('/hcp/home');
              });
          } else {
            navigate('/hcp/home');
          }
        } else {
          setNumber(patient.patient.number);
          setNric(patient.patient.nric);
          setSmsLanguage(patient.patient.language);
          for (let i = 0; i < patient.medication.length; i++) {
            let list = inputList;
            let listObject = {
              medication: patient.medication[i].medication,
              sku: patient.medication[i].sku
            }
            list.push(listObject);
            setInputList(list);
          }
          setPageLoading(false);
        }
      })
      .catch(err => {
        setError(err.response.data.error);
        setPageLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    if (name === "medication") {
      list[index]["sku"] = "";
    }
    setInputList(list);
  };

  // handle click event of the Remove button
  // const handleRemoveClick = (index) => {
  //   const list = [...inputList];
  //   list.splice(index, 1);
  //   setInputList(list);
  // };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { medication: "", sku: "" }]);
  };

  const handleSubmit = e => {
    e.preventDefault();

    let register = true;
    let tempMedication = [];
    for (let i = 0; i < inputList.length; i++) {
      if (!tempMedication.includes(inputList[i].medication)) {
        tempMedication.push(inputList[i].medication)
      } else {
        setError("Medication name cannot be same");
        register = false;
      }
    }

    if (register) {
      setError("")
      setLoading(true);

      let medication = [];

      for (let i = 0; i < inputList.length; i++) {
        let selectedProduct = productList.filter(p => p.medication === inputList[i].medication && p.sku === inputList[i].sku)[0];
        let med = {
          productId: selectedProduct.productId,
          status: "Active"
        }
        medication.push(med);
      }

      for (let i = 0; i < patient.medication.length; i++) {
        let filterMedication = medication.filter(m => m.productId === patient.medication[i].productId);
        if (filterMedication.length <= 0) {
          let med = {
            productId: patient.medication[i].productId,
            status: "Inactive"
          }
          medication.push(med);
        }
      }

      axios.post('/api/hcp/updatePatient', { number, nric, language: smsLanguage, medication, updatedBy: "doctor" })
        .then(res => {
          axios.post('/api/hcp/patientDetails', { number: patientNumber })
            .then(res => {
              updatePatientDetails(res.data.data)
              setLoading(false);
              navigate(-1);
            })
            .catch(err => {
              setError(err.response.data.error);
              setLoading(false);
            });
        })
        .catch(err => {
          setError(err.response.data.error);
          setLoading(false);
        });
    }
  }

  return (
    <div className="hcp-signup-page">
      <div style={{ height: "5vh" }}></div>
      <div className="hcp-signup-container">

        <div className="form-container">
          <div>
            <h2 className="hcp-signup-title">Update Medication</h2>
            <p className="sign-up-subtitle"><i>* Mandatory field to be filled</i></p>
          </div>

          {
            pageLoading ? (
              <div className="text-center">
                <CircularProgress />
              </div>
            ) : (
              <form onSubmit={handleSubmit}>
                {/* <div>
                  <FormControl variant="standard">
                    <InputLabel shrink htmlFor="bootstrap-input">
                      <p className="hcp-signup-phone-label">PHONE NUMBER *</p>
                    </InputLabel>
                    <BootstrapInput id="number" name="number" type="number" placeholder="e.g. 0199999999" value={number} onChange={e => setNumber(e.target.value)} required readOnly />
                  </FormControl>
                </div>
                <div>
                  <FormControl variant="standard">
                    <InputLabel shrink htmlFor="bootstrap-input">
                      <p className="hcp-signup-phone-label">NRIC / MRN *</p>
                    </InputLabel>
                    <BootstrapInput id="nric" name="number" type="number" value={nric} onChange={e => setNric(e.target.value)} required readOnly />
                  </FormControl>
                </div> */}
                {/* <div>
                  <FormControl variant="standard">
                    <InputLabel shrink htmlFor="bootstrap-input">
                      <p className="hcp-signup-phone-label">SMS PREFERRED LANGUAGE</p>
                    </InputLabel>
                    <Select
                      name="smsLanguage"
                      id="smsLanguage"
                      className="hcp-signup-select"
                      style={{ height: "3rem" }}
                      value={smsLanguage}
                      onChange={e => setSmsLanguage(e.target.value)}
                    >
                      <MenuItem value="en">English</MenuItem>
                      <MenuItem value="bm">Malay</MenuItem>
                      <MenuItem value="ch">Chinese</MenuItem>
                    </Select>
                  </FormControl>
                </div> */}
                <div>
                  <label className="hcp-signup-phone-label"><b>MEDICATION *</b></label>
                  {inputList.map((x, i) => {
                    return (
                      <div key={i}>
                        <div className="d-flex">
                          {
                            inputList[i].medication ? (
                              <FormControl variant="standard" className={inputList[i].medication ? "col-7" : "col-12"}>
                                <InputLabel shrink htmlFor="bootstrap-input">
                                  <p className="hcp-signup-phone-label pt-3">MEDICATION {i + 1}</p>
                                </InputLabel>
                                <Autocomplete
                                  name="medication"
                                  id="medication"
                                  className="hcp-signup-autocomplete"
                                  options={medicationList}
                                  value={inputList[i].medication}
                                  onChange={(e, value) => {
                                    const list = [...inputList];
                                    list[i]["medication"] = value;
                                    list[i]["sku"] = "";
                                    setInputList(list);
                                    setMedicationList([]);
                                  }}
                                  onInputChange={(e, value) => {
                                    if (value) {
                                      setMedicationList(medicationBrandList.map(medication => medication.value));
                                    } else {
                                      setMedicationList([]);
                                    }
                                  }}
                                  onOpen={(e) => setMedicationList([])}
                                  renderInput={(params) => <TextField {...params} placeholder="Type medication name here" required />}
                                  required
                                  readOnly
                                />
                              </FormControl>
                            ) : (
                              <FormControl variant="standard" className={inputList[i].medication ? "col-7" : "col-12"}>
                                <InputLabel shrink htmlFor="bootstrap-input">
                                  <p className="hcp-signup-phone-label pt-3">MEDICATION {i + 1}</p>
                                </InputLabel>
                                <Autocomplete
                                  name="medication"
                                  id="medication"
                                  className="hcp-signup-autocomplete"
                                  options={medicationList}
                                  value={inputList[i].medication}
                                  onChange={(e, value) => {
                                    const list = [...inputList];
                                    list[i]["medication"] = value;
                                    list[i]["sku"] = "";
                                    setInputList(list);
                                    setMedicationList([]);
                                  }}
                                  onInputChange={(e, value) => {
                                    if (value) {
                                      setMedicationList(medicationBrandList.map(medication => medication.value));
                                    } else {
                                      setMedicationList([]);
                                    }
                                  }}
                                  onOpen={(e) => setMedicationList([])}
                                  renderInput={(params) => <TextField {...params} placeholder="Type medication name here" required />}
                                  required
                                />
                              </FormControl>
                            )
                          }

                          {inputList[i].medication ? (
                            <FormControl className="col-5">
                              <InputLabel
                                className="hcp-signup-select-label"
                                id="demo-simple-select-label"
                                style={{ display: inputList[i].sku ? "none" : index >= i ? "none" : "", marginTop: "40px" }}
                              >
                                Select mg
                              </InputLabel>
                              <Select
                                name="sku"
                                className="hcp-signup-select ms-2"
                                labelId="demo-simple-select-label"
                                id="sku"
                                value={inputList[i].sku}
                                onChange={e => handleInputChange(e, i)}
                                onFocus={() => setIndex(i)}
                                required
                              >
                                {
                                  productList.filter(product => product.medication === inputList[i].medication).map((product) => {
                                    return (<MenuItem key={product.productId} value={product.sku}>{product.sku}</MenuItem>)
                                  })
                                }
                              </Select>
                              {/* {
                                inputList[i].medication === "Lipitor" ? (
                                  <Select
                                    name="sku"
                                    className="hcp-signup-select ms-2"
                                    labelId="demo-simple-select-label"
                                    id="sku"
                                    value={inputList[i].sku}
                                    onChange={e => handleInputChange(e, i)}
                                    onFocus={() => setIndex(i)}
                                    required
                                  >
                                    <MenuItem value="10 mg">10 mg</MenuItem>
                                    <MenuItem value="20 mg">20 mg</MenuItem>
                                    <MenuItem value="40 mg">40 mg</MenuItem>
                                    <MenuItem value="80 mg">80 mg</MenuItem>
                                  </Select>
                                ) : inputList[i].medication === "Norvasc" ? (
                                  <Select
                                    name="sku"
                                    className="hcp-signup-select ms-2"
                                    labelId="demo-simple-select-label"
                                    id="sku"
                                    value={inputList[i].sku}
                                    onChange={e => handleInputChange(e, i)}
                                    onFocus={() => setIndex(i)}
                                    required
                                  >
                                    <MenuItem value="5 mg">5 mg</MenuItem>
                                    <MenuItem value="10 mg">10 mg</MenuItem>
                                  </Select>
                                ) : inputList[i].medication === "Caduet" ? (
                                  <Select
                                    name="sku"
                                    className="hcp-signup-select ms-2"
                                    labelId="demo-simple-select-label"
                                    id="sku"
                                    value={inputList[i].sku}
                                    onChange={e => handleInputChange(e, i)}
                                    onFocus={() => setIndex(i)}
                                    required
                                  >
                                    <MenuItem value="5/10mg">5/10mg</MenuItem>
                                    <MenuItem value="5/20mg">5/20mg</MenuItem>
                                    <MenuItem value="5/40mg">5/40mg</MenuItem>
                                    <MenuItem value="10/10mg">10/10mg</MenuItem>
                                    <MenuItem value="10/20mg">10/20mg</MenuItem>
                                    <MenuItem value="10/40mg">10/40mg</MenuItem>
                                  </Select>
                                ) : inputList[i].medication === "Viagra" ? (
                                  <Select
                                    name="sku"
                                    className="hcp-signup-select ms-2"
                                    labelId="demo-simple-select-label"
                                    id="sku"
                                    value={inputList[i].sku}
                                    onChange={e => handleInputChange(e, i)}
                                    onFocus={() => setIndex(i)}
                                    required
                                  >
                                    <MenuItem value="100 mg">100 mg</MenuItem>
                                  </Select>
                                ) : inputList[i].medication === "Legalon" ? (
                                  <Select
                                    name="sku"
                                    className="hcp-signup-select ms-2"
                                    labelId="demo-simple-select-label"
                                    id="sku"
                                    value={inputList[i].sku}
                                    onChange={e => handleInputChange(e, i)}
                                    onFocus={() => setIndex(i)}
                                    required
                                  >
                                    <MenuItem value="140 mg">140 mg</MenuItem>
                                  </Select>
                                ) : inputList[i].medication === "Viartril-S Sachets" ? (
                                  <Select
                                    name="sku"
                                    className="hcp-signup-select ms-2"
                                    labelId="demo-simple-select-label"
                                    id="sku"
                                    value={inputList[i].sku}
                                    onChange={e => handleInputChange(e, i)}
                                    onFocus={() => setIndex(i)}
                                    required
                                  >
                                    <MenuItem value="1500 mg">1500 mg</MenuItem>
                                  </Select>
                                ) : inputList[i].medication === "Viartril-S Capsules" ? (
                                  <Select
                                    name="sku"
                                    className="hcp-signup-select ms-2"
                                    labelId="demo-simple-select-label"
                                    id="sku"
                                    value={inputList[i].sku}
                                    onChange={e => handleInputChange(e, i)}
                                    onFocus={() => setIndex(i)}
                                    required
                                  >
                                    <MenuItem value="500 mg">500 mg</MenuItem>
                                  </Select>
                                ) : inputList[i].medication === "Adaxil Sachets" ? (
                                  <Select
                                    name="sku"
                                    className="hcp-signup-select ms-2"
                                    labelId="demo-simple-select-label"
                                    id="sku"
                                    value={inputList[i].sku}
                                    onChange={e => handleInputChange(e, i)}
                                    onFocus={() => setIndex(i)}
                                    required
                                  >
                                    <MenuItem value="750mg/600mg">750mg/600mg</MenuItem>
                                  </Select>
                                ) :
                                  // inputList[i].medication === "Fulphila" ? (
                                  //   <Select
                                  //     name="sku"
                                  //     className="hcp-signup-select ms-2"
                                  //     labelId="demo-simple-select-label"
                                  //     id="sku"
                                  //     value={inputList[i].sku}
                                  //     onChange={e => handleInputChange(e, i)}
                                  //     onFocus={() => setIndex(i)}
                                  //     required
                                  //   >
                                  //     <MenuItem value="6mg/0.6ml">6mg/0.6ml</MenuItem>
                                  //   </Select>
                                  // ) : 
                                  (
                                    <Select
                                      name="sku"
                                      className="hcp-signup-select ms-2"
                                      labelId="demo-simple-select-label"
                                      id="sku"
                                      value={inputList[i].sku}
                                      onChange={e => handleInputChange(e, i)}
                                      onFocus={() => setIndex(i)}
                                      required
                                    >
                                    </Select>
                                  )
                              } */}
                            </FormControl>
                          ) : (
                            null
                          )}
                        </div>

                        {/* <div className="btn-box">
                        {inputList.length !== 1 && (
                          <button
                            className="btn hcp-addmore-button mt-3"
                            onClick={() => handleRemoveClick(i)}
                          >
                            <b>- Remove</b>
                          </button>
                        )}
                      </div> */}

                        <div>
                          {inputList.length - 1 === i && inputList[i].medication && inputList[i].sku && (
                            <button
                              className="btn hcp-addmore-button mt-3"
                              onClick={handleAddClick}
                            >
                              <b>+ ADD MORE</b>
                            </button>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>

                {
                  error ? (
                    <div className="pt-3">
                      <p style={{ color: "red", textAlign: "center" }}>{error}</p>
                    </div>
                  ) : null
                }

                {
                  loading ? (
                    <div className="text-center">
                      <CircularProgress />
                    </div>
                  ) : (
                    <div className="button-container">
                      <button type="submit" className="btn form-control hcp-signup-button py-3">
                        <b>UPDATE</b>
                      </button>
                    </div>
                  )
                }

              </form>
            )
          }
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    patient: state.hcp.patient
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updatePatientDetails: data => dispatch(updatePatientDetails(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HcpUpdateProfile);
