import { useNavigate } from "react-router-dom";
import { Box, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";

// image
import leftArrow from "../assets/image/left-arrow.png";
import plus from "../assets/image/plus.png";
import minimize from "../assets/image/minimize.png";
import phonecall from "../assets/image/phone_call.png";
import businessRule from "../assets/image/businessRule.png";
import stock from "../assets/image/stock.png";
import emailIcon from "../assets/image/email-icon.png";
import whatsappIcon from "../assets/image/whatsapp-icon.png";

// component
import HcpNavigationBar from "./components/navigationBar";
import HcpHeader from "./components/header";

const HcpHelp = () => {

    const navigate = useNavigate();

    const CustomExpandIcon = () => {
        return (
            <Box
                sx={{
                    '.Mui-expanded & > .collapsIconWrapper': { display: 'none' },
                    '.expandIconWrapper': { display: 'none' },
                    '.Mui-expanded & > .expandIconWrapper': { display: 'block' },
                }}
            >
                <div className="expandIconWrapper pe-3">
                    <img src={minimize} alt="minus" />
                </div>
                <div className="collapsIconWrapper ps-3">
                    <img src={plus} alt="plus" />
                </div>
            </Box>
        )
    }

    return (
        <div>
            <HcpHeader />
            <div className="hcp-whole-body">
                <div className="page-title mt-3">
                    <img src={leftArrow} alt="back-icon" className="hcp-left-arrow" onClick={() => navigate('/hcp/home')} />
                    <h2>Help</h2>
                </div>
                <div className="page-body">
                    {/* <h2 className="ms-5 mt-3">View Tutorial</h2>

                    <Accordion className="hcp-patient-profile mx-3 my-3">
                        <AccordionSummary expandIcon={<CustomExpandIcon />}>
                            <b>Tutorial</b>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                Varius ultricies id nec faucibus amet, risus scelerisque
                                leo. Imperdiet purus semper lacus purus.
                            </div>
                        </AccordionDetails>
                    </Accordion> */}

                    <h2 className="ms-5 mt-3">FAQs</h2>

                    <h3 className="ms-5 mt-3">General</h3>

                    <div style={{ textAlign: "justify" }}>
                        <Accordion className="hcp-patient-profile mx-3 my-3">
                            <AccordionSummary expandIcon={<CustomExpandIcon />}>
                                <b>What is iCARE Connect+ Program and what does it offer?</b>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    <p>iCARE Connect+ Program is a digital patient assistance program that enables the patients to take control of their health through health information, medication rebate and support for treatment continuity.</p>
                                </div>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion className="hcp-patient-profile mx-3 my-3">
                            <AccordionSummary expandIcon={<CustomExpandIcon />}>
                                <b>What are the participating medication brands of this program?</b>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    <p>The participating medication brands includes of: Lipitor, Norvasc, Caduet, Viagra, Legalon, Viartril-S, and Adaxil.</p>
                                </div>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion className="hcp-patient-profile mx-3 my-3">
                            <AccordionSummary expandIcon={<CustomExpandIcon />}>
                                <b>How do I check the inventory level?</b>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    <p>Click the Credit Balance icon which located at the bottom section of the webpage for the inventory status. HCP can check credit balance before making a submission to prevent from getting error message due to insufficient balance. Credit balance is updated on real-time and will be deducted after a submission is made.</p>
                                </div>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion className="hcp-patient-profile mx-3 my-3">
                            <AccordionSummary expandIcon={<CustomExpandIcon />}>
                                <b>How can I view the submission and redemption histories for the patient?</b>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    <p>You can view both submission and redemption histories by clicking the Submissions or Redemptions buttons in either the submission or the redemption page. You may also view the entire patient transactions made in your account under Transaction History.</p>
                                </div>
                            </AccordionDetails>
                        </Accordion>

                        <h3 className="ms-5 mt-3">Registration</h3>

                        <Accordion className="hcp-patient-profile mx-3 my-3">
                            <AccordionSummary expandIcon={<CustomExpandIcon />}>
                                <b>How do I register my patients for the program?</b>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    <p>HCP can register on behalf of patient as iCARE Connect+ user.</p>
                                    <p>Step 1: Key in the patient’s mobile number in the Submission field and click Register.</p>
                                    <p>Step 2: You will be led to Sign Up page for OTP code requisition.</p>
                                    <p>Step 3: Key in OTP code which sent to the patient’s mobile through SMS.</p>
                                    <p>Step 4: Fill up patient’s information in the required fields including their medication.</p>
                                    <p>Step 5: Acknowledge the T&amp;C and privacy policy on behalf of patient upon registration.</p>
                                </div>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion className="hcp-patient-profile mx-3 my-3">
                            <AccordionSummary expandIcon={<CustomExpandIcon />}>
                                <b>How do I add or edit patient’s medication?</b>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    <p>Step 1: Select the Add More/Edit button under the submission page.</p>
                                    <p>Step 2: Click Add More button for additional medications and enter medication name.</p>
                                    <p>Step 3: Click Update once the changes have been completed. Patient will receive a notification on the change of medication.</p>
                                </div>
                            </AccordionDetails>
                        </Accordion>

                        <h3 className="ms-5 mt-3">Submission</h3>

                        <Accordion className="hcp-patient-profile mx-3 my-3">
                            <AccordionSummary expandIcon={<CustomExpandIcon />}>
                                <b>How do I submit the medication for my patient?</b>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    <p>Step 1: Key in the patient mobile/MRN/IC number in the Submission field and click Search.</p>
                                    <p>Step 2: Enter the quantity purchased by the patient and then click Submit.</p>
                                    <p>Step 3: Checkout the submission upon confirmation.</p>
                                </div>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion className="hcp-patient-profile mx-3 my-3">
                            <AccordionSummary expandIcon={<CustomExpandIcon />}>
                                <b>When is the initiation of annual cycle for the patient?</b>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    <p>Patient annual cycle will be counted from first submission ever made, regardless purchase from hospital/ clinic and SKU (single or combination brand). Any incomplete submissions and eligible redemptions will be carried forward to the next annual cycle.</p>
                                </div>
                            </AccordionDetails>
                        </Accordion>

                        <h3 className="ms-5 mt-3">Redemption</h3>

                        <Accordion className="hcp-patient-profile mx-3 my-3">
                            <AccordionSummary expandIcon={<CustomExpandIcon />}>
                                <b>How does the redemption scheme work?</b>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    <p>Step 1: Patient or HCP registers on behalf of patient as iCARE Connect+ user.</p>
                                    <p>Step 2: HCP selects SKU and quantity upon purchase of iCARE Connect+ participating medication.</p>
                                    <p>Step 3: When the patient is eligible for redemption, the patient provides the redemption code to the HCP to redeem their complimentary product.</p>
                                </div>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion className="hcp-patient-profile mx-3 my-3">
                            <AccordionSummary expandIcon={<CustomExpandIcon />}>
                                <b>How does patient get the medication redemption? </b>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    <p>Step 1: An SMS with redemption code will be sent to patient’s mobile once eligible for redemption.</p>
                                    <p>Step 2: Insert redemption code under Redemption field and click Redeem.</p>
                                    <p>Step 3: Click Yes to proceed after checking the redemption details are correct.</p>
                                    <p>If there is any Pending Redemption yet to be closed by HCP, the system will prompt HCP to complete before proceeding with any submission.</p>
                                </div>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion className="hcp-patient-profile mx-3 my-3">
                            <AccordionSummary expandIcon={<CustomExpandIcon />}>
                                <b>What are the redemption mechanics and the limit of purchase?</b>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    <p>The redemption and limitation on purchase differ by respective medications.</p>
                                    <img src={businessRule} alt="businessRule" className="img-fluid" />
                                </div>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion className="hcp-patient-profile mx-3 my-3">
                            <AccordionSummary expandIcon={<CustomExpandIcon />}>
                                <b>When does the patient need to collect the medication?</b>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    <p>The verification code will be valid for 90 days. The patient will need to collect their complimentary medication in 90 days, after which the redemption will be forfeited.</p>
                                </div>
                            </AccordionDetails>
                        </Accordion>

                        <h3 className="ms-5 mt-3">Business Rule</h3>

                        <Accordion className="hcp-patient-profile mx-3 my-3">
                            <AccordionSummary expandIcon={<CustomExpandIcon />}>
                                <b>What are the redemption mechanics and the limit of purchase?</b>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    <p>The redemption and limitation on purchase differ by respective medications.</p>
                                    <img src={businessRule} alt="businessRule" className="img-fluid" />
                                </div>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion className="hcp-patient-profile mx-3 my-3">
                            <AccordionSummary expandIcon={<CustomExpandIcon />}>
                                <b>When is the initiation of sales transaction validity?</b>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div>
                                    <p>The initiation of sales transaction validity differs by respective medications.</p>
                                    <img src={stock} alt="stock" className="img-fluid" />
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    </div>

                    <div className="ms-5 mt-3">
                        <h3>Hotline</h3>
                    </div>

                    <div className="patient-hotline-padding">
                        <div className="patient-hotline-bg-color">
                            <div className="patient-hotline">
                                <div>
                                    <img src={phonecall} alt="phone-icon" className="patient-phone-img" />
                                </div>
                                <div className="patient-contact-customer-service-text">
                                    <b>Contact a member of our Customer Service team!</b>
                                </div>
                            </div>

                            <div className="button-container">
                                <a className="btn form-control hcp-signup-button py-3 my-2" href="https://wa.me/601154235382">
                                    <img src={whatsappIcon} alt="whatsapp" className="me-2" />
                                    <b>WHATSAPP</b>
                                </a>
                            </div>

                            <div className="button-container">
                                <button className="btn form-control hcp-signup-button py-3 my-2" onClick={() => window.location = 'mailto:helpdesk@icareconnectplus.com.my'}>
                                    <img src={emailIcon} alt="email" className="me-2" />
                                    <b>EMAIL</b>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <HcpNavigationBar />

            </div>
        </div>
    );
}

export default HcpHelp;
