import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import { patientCheckToken } from "../redux/actions/patientActions";
import axios from 'axios';
import { useTranslation } from 'react-i18next';

// image
import leftArrow from "../assets/image/left-arrow.png";
import minimize from "../assets/image/minimize.png";
import plus from "../assets/image/plus.png";

// component
import PatientNavigationBar from "./components/navigationBar";
import PatientHeader from "./components/header";

const PatientSubmission = ({ patient, token, patientCheckToken, homepageNav }) => {

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [select, setSelect] = useState(homepageNav);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [submissionMedication, setSubmissionMedication] = useState([]);
  const [redemptionMedication, setRedemptionMedication] = useState([]);

  useEffect(() => {
    setError("")
    setLoading(true);

    if (token) {
      axios.post('/api/patient/checkToken', { token })
        .then(res => {
          patientCheckToken(res.data.data);

          let submission = [];
          let redemption = [];

          for (let i = 0; i < res.data.data.submission.length; i++) {
            if (!submission.includes(`${res.data.data.submission[i].medication} ${res.data.data.submission[i].sku}`)) {
              submission.push(`${res.data.data.submission[i].medication} ${res.data.data.submission[i].sku}`);
            }
          }

          let redeemedRedemption = res.data.data.redemption.filter(r => r.status === "Redeemed");
          for (let i = 0; i < redeemedRedemption.length; i++) {
            if (!redemption.includes(`${redeemedRedemption[i].medication} ${redeemedRedemption[i].sku}`)) {
              redemption.push(`${redeemedRedemption[i].medication} ${redeemedRedemption[i].sku}`);
            }
          }

          setSubmissionMedication(submission);
          setRedemptionMedication(redemption);

          setLoading(false);
        })
        .catch(err => {
          setError(err.response.data.error);
          setLoading(false);
        });
    } else {
      navigate("/patient/patientWelcome");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CustomExpandIcon = () => {
    return (
      <Box
        sx={{
          '.Mui-expanded & > .collapsIconWrapper': { display: 'none' },
          '.expandIconWrapper': { display: 'none' },
          '.Mui-expanded & > .expandIconWrapper': { display: 'block' },
        }}
      >
        <div className="expandIconWrapper">
          <img src={minimize} alt="minus" />
        </div>
        <div className="collapsIconWrapper">
          <img src={plus} alt="plus" />
        </div>
      </Box>
    )
  }

  return (
    <div>
      <PatientHeader />
      <div className="hcp-whole-body">
        <div className="page-title mt-3">
          <img src={leftArrow} alt="back-icon" className="hcp-left-arrow" onClick={() => navigate(-1)} />
          <h2>{select === "submission" ? t("Submission") : t("Redemption")}</h2>
        </div>

        <div className="page-body">
          {
            error ? (
              <div className="pt-3">
                <p style={{ color: "red", textAlign: "center" }}>{t(error)}</p>
              </div>
            ) : null
          }

          {
            loading ? (
              <div className="text-center">
                <CircularProgress />
              </div>
            ) : (
              <div className="hcp-body-padding">

                <Accordion className="hcp-patient-profile">
                  <AccordionSummary expandIcon={<CustomExpandIcon />}>
                    <h2>{t("Patient Profile")}</h2>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="px-2">
                      <div className="d-flex justify-content-between">
                        <p>{t("Mobile Number")}</p>
                        <p>{patient ? patient.patient.number : ""}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p>{t("NRIC / MRN Number")}</p>
                        <p>{patient ? patient.patient.nric : ""}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p>{t("Annual cycle end date")}</p>
                        <p>{patient ? (patient.patient.currentCycleYear ? (new Date(patient.patient.cycleyears[patient.patient.cycleyears.length - 1].endDate).getMonth() + 1) + "/" + (new Date(patient.patient.cycleyears[patient.patient.cycleyears.length - 1].endDate).getFullYear()) : "NA") : ""}</p>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <div className="hcp-submission-history-button">
                  <button
                    id="submission"
                    className="btn form-control hcp-submission-button"
                    style={{
                      backgroundColor: select === "submission" ? "#403B3B" : "#FFFFFF",
                      color: select === "submission" ? "#ffffff" : "#403B3B",
                    }}
                    onClick={() => select === "submission" ? setSelect("pending") : setSelect("submission")}
                  >
                    <b>{t("SUBMISSION HISTORY")}</b>
                  </button>
                  <button
                    id="registered"
                    className="btn form-control hcp-submission-button"
                    style={{
                      backgroundColor: select === "redemption" ? "#403B3B" : "#FFFFFF",
                      color: select === "redemption" ? "#ffffff" : "#403B3B",
                    }}
                    onClick={() => select === "redemption" ? setSelect("pending") : setSelect("redemption")}
                  >
                    <b>{t("REDEMPTION HISTORY")}</b>
                  </button>
                </div>

                {
                  select === "pending" ? (
                    <div>
                      <h3>{t("Pending Redemptions")}</h3>

                      {
                        patient ? (patient.redemption.filter(r => r.status === "Pending").map((r, i) => {
                          return (
                            <Accordion className="hcp-pending-redemption my-3" key={i}>
                              <AccordionSummary expandIcon={<CustomExpandIcon />}>
                                <b>{r.hcpName} ({new Date(r.createdAt).toLocaleDateString('en-GB')})</b>
                              </AccordionSummary>
                              <AccordionDetails>
                                <div className="mx-3" style={{ lineHeight: "15px" }}>
                                  <div className="d-flex justify-content-between">
                                    <p><b>{t("Medication")}</b></p>
                                    <p>{r.medication} {r.sku}</p>
                                  </div>
                                  <div className="d-flex justify-content-between">
                                    <p><b>{t("Quantity")}</b></p>
                                    <p>{r.redeemQuantity}</p>
                                  </div>
                                  <div className="d-flex justify-content-between">
                                    <p><b>{t("Redemption end date")}</b></p>
                                    <p>{new Date(r.redemptionExpiredDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}</p>
                                  </div>
                                  <div className="d-flex justify-content-between">
                                    <p><b>{t("Redemption code")}</b></p>
                                    <p>{r.redemptionCode}</p>
                                  </div>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          )
                        })) : (
                          <div className="text-center">
                            <CircularProgress />
                          </div>
                        )
                      }

                    </div>
                  ) : select === "submission" ? (
                    <div>
                      <h2>{t("Submission History")}</h2>
                      {
                        patient && submissionMedication ? (submissionMedication.map((med, i) => {
                          return (
                            <Accordion className="hcp-patient-profile my-3" key={i}>
                              <AccordionSummary expandIcon={<CustomExpandIcon />}>
                                <b>{t(med)}</b>
                              </AccordionSummary>

                              {
                                patient.submission.filter(s => `${s.medication} ${s.sku}` === med).map((submission, i) => {
                                  return (
                                    <AccordionDetails key={i}>
                                      <div className="mx-3" key={i} style={{ lineHeight: "15px" }}>
                                        <div className="d-flex justify-content-between">
                                          <p><b>{t("Submission date")}</b></p>
                                          <p>{new Date(submission.submissionDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p><b>{t("Quantity submitted")}</b></p>
                                          <p>{submission.quantity}</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p><b>{t("HCP code")}</b></p>
                                          <p>{submission.hcpCode}</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p><b>{t("HCP name")}</b></p>
                                          <p>{submission.hcpName}</p>
                                        </div>
                                      </div>
                                    </AccordionDetails>
                                  )
                                })
                              }
                            </Accordion>
                          )
                        })) : (
                          <div className="text-center">
                            <CircularProgress />
                          </div>
                        )
                      }
                    </div>
                  ) : (
                    <div>
                      <h2>{t("Redemption History")}</h2>
                      {
                        patient && redemptionMedication ? (redemptionMedication.map((redemption, i) => {
                          return (
                            <Accordion className="hcp-patient-profile my-3" key={i}>
                              <AccordionSummary expandIcon={<CustomExpandIcon />}>
                                <b>{redemption}</b>
                              </AccordionSummary>

                              {
                                patient.redemption.filter(r => `${r.medication} ${r.sku}` === redemption && r.status === "Redeemed").map((r, i) => {
                                  return (
                                    <AccordionDetails key={i}>
                                      <div className="mx-3" key={i} style={{ lineHeight: "15px" }}>
                                        <div className="d-flex justify-content-between">
                                          <p><b>{t("Redemption date")}</b></p>
                                          <p>{new Date(r.redemptionDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p><b>{t("Quantity redeemed")}</b></p>
                                          <p>{r.redeemQuantity}</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p><b>{t("Redemption Code")}</b></p>
                                          <p>{r.redemptionCode}</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p><b>{t("HCP code")}</b></p>
                                          <p>{r.hcpCode}</p>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <p><b>{t("HCP name")}</b></p>
                                          <p>{r.hcpName}</p>
                                        </div>
                                      </div>
                                    </AccordionDetails>
                                  )
                                })
                              }
                            </Accordion>
                          )
                        })) : (
                          <div className="text-center">
                            <CircularProgress />
                          </div>
                        )
                      }
                    </div>
                  )
                }
              </div>
            )
          }
        </div>
        <PatientNavigationBar />
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    patient: state.patient.patient,
    token: state.patient.token,
    homepageNav: state.patient.homepageNav
  };
};

const mapDispatchToProps = dispatch => {
  return {
    patientCheckToken: data => dispatch(patientCheckToken(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientSubmission);
