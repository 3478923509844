import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import { Modal, Box, InputBase, FormControl, CircularProgress } from "@mui/material";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import { MDBBtn } from "mdb-react-ui-kit";
import { alpha, styled } from "@mui/material/styles";
import axios from 'axios';
import { connect } from 'react-redux';
import { updateRedemption } from "../redux/actions/hcpActions";
import { medicationImage } from "../dynamicController";

// component
import HcpNavigationBar from "./components/navigationBar";
import HcpHeader from "./components/header";

// images
import leftArrow from "../assets/image/left-arrow.png";
import subtract from "../assets/image/Subtract.png";
import write from "../assets/image/write.png";
import question from "../assets/image/question_mark.png";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: "10px",
    position: "relative",
    border: "2px solid #000000",
    backgroundColor: "#00000000",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    marginBottom: "1rem",
    color: "#000000",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha("#000", 0.25)} 0 0 0 0.1rem`,
      borderColor: "#ffffff",
    },
  },
}));

const HcpCheckout = ({ redemption, updateRedemption }) => {

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [redemptionCode, setRedemptionCode] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showRedemptionCode, setShowRedemptionCode] = useState(false);
  const [verified, setVerified] = useState(false);
  const [code, setCode] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.redemptionCode) {
      setCode(location.state.redemptionCode);
    }

    if (!redemption) {
      if (location.state.redemptionCode && location.state.hcpId) {
        setError("");
        setLoading(true);
        axios.post('/api/hcp/verifyRedemptionCode', { redemptionCode: location.state.redemptionCode, hcpId: location.state.hcpId })
          .then(res => {
            updateRedemption(res.data.redemption);
            setLoading(false);
          })
          .catch(err => {
            setError(err.response.data.error);
            setLoading(false);
          })
      } else {
        navigate('/hcp/home');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkout = () => {
    if (redemption.status === "Re-Allocate") {
      setOpen5(true);
    } else {
      if (showRedemptionCode) {
        if (!redemptionCode) {
          setError("Please enter your redemption code and verify");
        } else {
          if (!verified) {
            setError("Please verify your redemption code");
          } else {
            setOpen(true);
          }
        }
      } else {
        if (new Date() > new Date(redemption.redemptionExpiredDate)) {
          setOpen3(true);
        } else {
          setOpen(true);
        }
      }
    }
  }

  const redeem = () => {
    setError("");
    setLoading(true);
    axios.post('/api/hcp/redemption', { redemptionCode: code })
      .then(res => {
        setOpen(false);
        setOpen2(true);
        setLoading(false);
      })
      .catch(err => {
        setError(err.response.data.error);
        setLoading(false);
      })
  }

  const expiredRedeem = () => {
    setError("");
    setLoading(true);
    axios.post('/api/hcp/expiredRedemption', { redemptionCode: code, number: redemption.number })
      .then(res => {
        setOpen3(false);
        setOpen4(true);
        setCode(res.data.code);
        setShowRedemptionCode(true);
        setLoading(false);
      })
      .catch(err => {
        setError(err.response.data.error);
        setLoading(false);
      })
  }

  const resend = () => {
    setError("");
    setLoading(true);
    axios.post('/api/hcp/resendRedemptionCode', { redemptionCode: code, number: redemption.number })
      .then(res => {
        setLoading(false);
      })
      .catch(err => {
        setError(err.response.data.error);
        setLoading(false);
      })
  }

  const verify = () => {
    setError("");
    setLoading(true);
    axios.post('/api/hcp/verifyRedemptionCode', { redemptionCode, hcpId: location.state.hcpId })
      .then(res => {
        setVerified(true);
        setLoading(false);
      })
      .catch(err => {
        setError(err.response.data.error);
        setLoading(false);
      })
  }

  return (
    <div>
      <HcpHeader />
      <div className="hcp-whole-body">
        <div className="page-title mt-3">
          <img src={leftArrow} className="hcp-left-arrow" alt="back-icon" onClick={() => navigate(-1)} />
          <h2>Checkout</h2>
        </div>
        <div className="page-body">
          <div className="px-3">
            <div className="hcp-checkout-details">
              {
                redemption ? <img src={medicationImage(redemption.medication, redemption.sku)} className="img-fluid mb-2 redemption-img" alt="product" /> : null
              }

              <div style={{ lineHeight: "15px" }}>
                <div className="row">
                  <p className="col"><small><b>Product name</b></small></p>
                  <p className="col"><small>{redemption ? redemption.medication + " " + redemption.sku : ""}</small></p>
                </div>
                <div className="row">
                  <p className="col"><small><b>Redeem quantity</b></small></p>
                  <p className="col"><small>{redemption ? redemption.redeemQuantity : ""} tablets</small></p>
                </div>
                <div className="row">
                  <p className="col"><small><b>Redemption end date</b></small></p>
                  <p className="col"><small>{redemption ? new Date(redemption.redemptionExpiredDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }) : ""}</small></p>
                </div>
                <div className="row">
                  <p className="col"><small><b>Mobile number</b></small></p>
                  <p className="col"><small>{redemption ? redemption.number : ""}</small></p>
                </div>
                <div className="row">
                  <p className="col"><small><b>NRIC / MRN</b></small></p>
                  <p className="col"><small>{redemption ? redemption.nric : ""}</small></p>
                </div>
              </div>
            </div>

            {
              showRedemptionCode ? (
                <div className="pb-3">
                  <h4>ENTER REDEMPTION CODE</h4>
                  <FormControl variant="standard" className="col-12">
                    <BootstrapInput id="redemptionCode" name="redemptionCode" type="text" value={redemptionCode} onChange={e => setRedemptionCode(e.target.value)} />
                    <div className="hcp-code-div">
                      <button className="btn form-control hcp-verify-button-instant py-2" style={{ minWidth: "100%" }} onClick={() => verify()}>
                        <b>{verified ? "Verified" : "Verify"}</b>
                      </button>
                    </div>
                  </FormControl>
                </div>
              ) : null
            }

            {
              error ? (
                <div className="pt-3">
                  <p style={{ color: "red", textAlign: "center" }}>{error}</p>
                </div>
              ) : null
            }

            <div className="button-container">
              <button className="btn form-control hcp-signup-button py-3" onClick={() => checkout()}>
                <b>Checkout</b>
              </button>
            </div>
          </div>
        </div>
      </div>

      <HcpNavigationBar />

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="red-modal">
          <div className="align-right">
            <MDBBtn
              className="btn-close btn-close-white"
              color="none"
              aria-label="Close"
              onClick={() => setOpen(false)}
            />
          </div>
          <div className="text-center">
            <img src={question} alt="question" />
          </div>

          <p className="hcp-alert">Are you sure you want to redeem these products?</p>
          <p style={{ color: "white", textAlign: "center" }}>* One normal redemption code for one product</p>
          <div className="button-container">
            {
              error ? (
                <div className="pt-3">
                  <p style={{ color: "red", textAlign: "center" }}>{error}</p>
                </div>
              ) : null
            }

            {
              loading ? (
                <div className="text-center">
                  <CircularProgress />
                </div>
              ) : (
                <button className="btn form-control hcp-register-button py-3" onClick={() => redeem()}>
                  <b>YES</b>
                </button>
              )
            }
          </div>
          <div className="button-container">
            <button className="btn form-control hcp-register-button py-3" onClick={() => setOpen(false)}>
              <b>NOT NOW</b>
            </button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={open2}
        onClose={() => navigate('/hcp/home')}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="red-modal">
          <div className="align-right">
            <MDBBtn
              className="btn-close btn-close-white"
              color="none"
              aria-label="Close"
              onClick={() => navigate('/hcp/home')}
            />
          </div>
          <div className="text-center">
            <img src={write} alt="success" />
          </div>

          <p className="hcp-alert">Congratulations! Your product has been successfully redeemed.</p>
          <div className="button-container">
            <button
              onClick={() => navigate('/hcp/home')}
              className="btn form-control hcp-register-button py-3"
            >
              <b>OKAY</b>
            </button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={open3}
        onClose={() => setOpen3(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="red-modal">
          <div className="align-right">
            <MDBBtn
              className="btn-close btn-close-white"
              color="none"
              aria-label="Close"
              onClick={() => setOpen3(false)}
            />
          </div>
          <div className="text-center">
            <img src={subtract} alt="subtract" />
          </div>

          <p className="hcp-alert">The following redemption is currently expired.</p>
          <div>
            <center>
              <table className="hcp-table-white-text">
                <thead>
                  <tr>
                    <td className="hcp-table-label"><b>Product</b></td>
                    <td>{redemption ? redemption.medication + " " + redemption.sku : ""}</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><b>Quantity</b></td>
                    <td>{redemption ? redemption.redeemQuantity : ""} tablets</td>
                  </tr>
                </tbody>
              </table>
            </center>
          </div>
          <p className="hcp-alert">Do you want to complete the redemption?</p>
          <div className="button-container">
            {
              error ? (
                <div className="pt-3">
                  <p style={{ color: "red", textAlign: "center" }}>{error}</p>
                </div>
              ) : null
            }

            {
              loading ? (
                <div className="text-center">
                  <CircularProgress />
                </div>
              ) : (
                <button className="btn form-control hcp-register-button py-3" onClick={() => expiredRedeem()}>
                  <b>YES</b>
                </button>
              )
            }
          </div>
          <div className="button-container">
            <button
              onClick={() => setOpen3(false)}
              className="btn form-control hcp-no-button py-3"
            >
              <b>NO</b>
            </button>
          </div>
        </Box>
      </Modal>
      <Modal
        open={open4}
        onClose={() => setOpen4(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="red-modal">
          <div className="align-right">
            <MDBBtn
              className="btn-close btn-close-white"
              color="none"
              aria-label="Close"
              onClick={() => setOpen4(false)}
            />
          </div>
          <div className="text-center">
            <img src={subtract} alt="subtract" />
          </div>

          <p className="hcp-alert">Expired redemption code. A new redemption code has been sent to patient's phone number.</p>
          <div className="button-container">
            <button
              onClick={() => setOpen4(false)}
              className="btn form-control hcp-register-button py-3"
            >
              <b>OK</b>
            </button>
          </div>
          <div className="button-container">
            {
              error ? (
                <div className="pt-3">
                  <p style={{ color: "red", textAlign: "center" }}>{error}</p>
                </div>
              ) : null
            }

            {
              loading ? (
                <div className="text-center">
                  <CircularProgress />
                </div>
              ) : (
                <button className="btn form-control hcp-no-button py-3" onClick={() => resend()}>
                  <b>RESENT</b>
                </button>
              )
            }
          </div>
        </Box>
      </Modal>
      <Modal
        open={open5}
        onClose={() => setOpen5(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="red-modal">
          <div className="align-right">
            <MDBBtn
              className="btn-close btn-close-white"
              color="none"
              aria-label="Close"
              onClick={() => setOpen5(false)}
            />
          </div>
          <div className="text-center">
            <img src={subtract} alt="subtract" />
          </div>

          <p className="hcp-alert">Expired redemption code. Your patient is not allowed to redeem the complimentary medication.</p>
          <div className="button-container">
            <button
              onClick={() => setOpen5(false)}
              className="btn form-control hcp-register-button py-3"
            >
              <b>OK</b>
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    redemption: state.hcp.redemption
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateRedemption: data => dispatch(updateRedemption(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HcpCheckout);
