import React, { useState, useEffect } from "react";
//import "react-modern-calendar-datepicker/lib/DatePicker.css";
//import DatePicker from "react-modern-calendar-datepicker";
//import DayPickerInput from "react-day-picker/DayPickerInput";
//import "react-day-picker/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router";
import { Accordion, AccordionSummary, AccordionDetails, Box, CircularProgress } from "@mui/material";
import { medicationImage } from "../dynamicController";
import axios from 'axios';
import { connect } from 'react-redux';
import { updateHcpDetails } from "../redux/actions/hcpActions";
import { medicationBrandList } from "../dynamicController";

// images
import leftArrow from "../assets/image/left-arrow.png";
import plus from "../assets/image/plus.png";
import minimize from "../assets/image/minimize.png";

// component
import HcpNavigationBar from "./components/navigationBar";
import HcpHeader from "./components/header";

const HcpTransactionHistory = ({ token, hcp, updateHcpDetails }) => {

  const navigate = useNavigate();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [brand, setBrand] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState([]);
  const [number, setNumber] = useState("");
  // const [selectedDay, setSelectedDay] = useState(null);
  // const [BG, setBG] = useState("#00000000");
  // const [BGR, setBGR] = useState("#00000000");

  useEffect(() => {
    setError("")
    setLoading(true);

    if (!hcp) {
      if (token) {
        axios.post('/api/hcp/checkToken', { token })
          .then(res => {
            updateHcpDetails(res.data.data);
            axios.post('/api/hcp/transactionHistory', { hcpId: res.data.data.hcp.id, brand, startDate, endDate })
              .then(res => {
                setData(res.data.data);
                setLoading(false);
              })
              .catch(err => {
                setError(err.response.data.error);
                setLoading(false);
              });
          })
          .catch(err => {
            setError(err.response.data.error);
            setLoading(false);
          });
      } else {
        navigate("/hcp/welcome")
      }
    } else {
      axios.post('/api/hcp/transactionHistory', { hcpId: hcp.hcp.id, brand, startDate, endDate })
        .then(res => {
          setData(res.data.data);
          setLoading(false);
        })
        .catch(err => {
          setError(err.response.data.error);
          setLoading(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (brand && startDate && endDate) {
      setError("")
      setLoading(true);

      axios.post('/api/hcp/transactionHistory', { hcpId: hcp.hcp.id, brand, startDate, endDate })
        .then(res => {
          setData(res.data.data);
          setLoading(false);
        })
        .catch(err => {
          setError(err.response.data.error);
          setLoading(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brand, startDate, endDate]);

  const CustomExpandIcon = () => {
    return (
      <Box
        sx={{
          '.Mui-expanded & > .collapsIconWrapper': { display: 'none' },
          '.expandIconWrapper': { display: 'none' },
          '.Mui-expanded & > .expandIconWrapper': { display: 'block' },
        }}
      >
        <div className="expandIconWrapper">
          <img src={minimize} alt="minus" />
        </div>
        <div className="collapsIconWrapper">
          <img src={plus} alt="plus" />
        </div>
      </Box>
    )
  }

  const handleDate = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }

  const renderHistoryData = (data, medication, sku) => {
    const filterData = data.filter(h => h.medication === medication && h.sku === sku);
    let date = [];
    let groupedData = [];
    for (let i = 0; i < filterData.length; i++) {
      let tempDate = new Date(filterData[i].date).toLocaleDateString('en-GB');
      if (!date.includes(tempDate)) {
        date.push(tempDate);
        let oneDayData = filterData.filter(d => new Date(d.date).toLocaleDateString('en-GB') === tempDate);
        let submission = oneDayData.map(d => d.submitted).reduce((a, b) => a + b, 0);
        let redemption = oneDayData.map(d => d.redeemed).reduce((a, b) => a + b, 0);
        let tempData = {
          date: tempDate,
          place: filterData[i].place,
          submitted: submission,
          redeemed: redemption
        }
        groupedData.push(tempData);
      }
    }
    return (
      groupedData.map((h, index) => {
        return (
          <tr className="hcp-small-font hcp-table" key={index}>
            <td>{h.date}</td>
            <td>{h.place}</td>
            <td>{h.submitted}</td>
            <td>{h.redeemed}</td>
          </tr>
        )
      })
    )
  }

  // const changeBgLeft = () => {
  //   setBG("#C2C2C2");
  //   setBGR("#00000000");
  // };

  // const changeBgRight = () => {
  //   setBGR("#C2C2C2");
  //   setBG("#00000000");
  // };

  // function CustomOverlay({ classNames, selectedDay, children, ...props }) {
  //   return (
  //     <div
  //       className={classNames.overlayWrapper}
  //       style={{ marginLeft: -175 }}
  //       {...props}
  //     >
  //       <div
  //         className={classNames.overlay}
  //         style={{ backgroundColor: "#EEF9FF" }}
  //       >
  //         <div className="hcp-calendar-top-bar">
  //           <div
  //             className="hcp-header-width"
  //             style={{ backgroundColor: BG }}
  //             onClick={changeBgLeft}
  //           >
  //             <p>From</p>
  //           </div>
  //           <div
  //             className="hcp-header-width"
  //             style={{ backgroundColor: BGR }}
  //             onClick={changeBgRight}
  //           >
  //             <p>To</p>
  //           </div>
  //         </div>
  //         {children}
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div>
      <HcpHeader />
      <div className="hcp-whole-body">
        <div className="page-title mt-3">
          <img src={leftArrow} className="hcp-left-arrow" alt="back-icon" onClick={() => navigate('/hcp/home')} />
          <h2>Transaction history</h2>
        </div>

        <div className="container pb-3">
          <input type="text" id="number" name="number" placeholder="MRN/IC/Patient Number" className="form-control select-input shadow-none" onChange={e => setNumber(e.target.value)} value={number} />
        </div>

        <div className="container pb-3 d-flex justify-content-end">
          <div>
            <select className="form-select select-input shadow-none" id="brand" name="brand" placeholder="Brand" value={brand} onChange={e => setBrand(e.target.value)}>
              <option value="" disabled>Brand</option>
              {medicationBrandList.map((medication, i) => {
                return (<option key={i} value={medication.value}>{medication.value}</option>)
              })}
            </select>
          </div>
          <div className="ps-2">
            <DatePicker placeholderText="Select date" value={startDate ? `${startDate ? new Date(startDate).toLocaleDateString('en-GB') : ""} - ${endDate ? new Date(endDate).toLocaleDateString('en-GB') : ""}` : ""} onChange={handleDate} startDate={startDate} endDate={endDate} selectsRange shouldCloseOnSelect={false} className="form-control select-input shadow-none" />
          </div>
        </div>

        {
          error ? (
            <div className="pt-3">
              <p style={{ color: "red", textAlign: "center" }}>{error}</p>
            </div>
          ) : null
        }

        {/* <div style={{ position: "relative" }}>
        <div className="hcp-float-right ">
          <DayPickerInput
            overlayComponent={CustomOverlay}
            value={selectedDay}
          />
        </div>
      </div> */}

        {
          loading ? (
            <div className="text-center">
              <CircularProgress />
            </div>
          ) : (
            <div>

              {
                data.filter(data => data.number.substring(0, number.length) === number || data.nric.substring(0, number.length) === number).map((d, i) => {
                  return (
                    <div key={i} className="mb-3">
                      <Accordion className="hcp-transaction-history">
                        <AccordionSummary expandIcon={<CustomExpandIcon />}>
                          <b>{d.number}</b>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="px-2">
                            {hcp.hcp.showIcMrn ? <p className="m-0">NRIC / MRN Number: {d.nric}</p> : null}
                            <p>Annual cycle end date: {new Date(d.cycleEndDate).toLocaleString('default', { month: 'long' }) + " " + new Date(d.cycleEndDate).getFullYear()}</p>

                            {
                              d.medication.map((m, j) => {
                                return (
                                  <div key={j}>
                                    <div className="d-flex">
                                      <div className="transaction-img-container"><img src={medicationImage(m.medication, m.sku)} alt="product" className="transaction-product-img" /></div>
                                      <div><p><b>{m.medication} {m.sku}</b></p></div>
                                    </div>

                                    <div>
                                      <table className="hcp-table-width">
                                        <thead>
                                          <tr className="hcp-header hcp-small-font">
                                            <td>
                                              <p className="hcp-label">Date</p>
                                            </td>
                                            <td>
                                              <p className="hcp-label">Place</p>
                                            </td>
                                            <td>
                                              <p className="hcp-label">Submitted</p>
                                            </td>
                                            <td>
                                              <p className="hcp-label">Redeemed</p>
                                            </td>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {
                                            renderHistoryData(d.history, m.medication, m.sku)
                                          }
                                        </tbody>
                                      </table>
                                    </div>

                                  </div>
                                )
                              })
                            }

                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  )
                })
              }

            </div>
          )
        }
      </div>

      <HcpNavigationBar />

    </div>
  );
};

const mapStateToProps = state => {
  return {
    token: state.hcp.token,
    hcp: state.hcp.hcp
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateHcpDetails: data => dispatch(updateHcpDetails(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HcpTransactionHistory);
