import write from "../../assets/image/write.png";
import subtract from "../../assets/image/Subtract.png";
import { Modal, Box } from "@mui/material";
import { MDBBtn } from "mdb-react-ui-kit";

const CustomModal = ({ open, handleClose, modalType, modalContent }) => {

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="red-modal">
                <div className="align-right">
                    <MDBBtn
                        className="btn-close btn-close-white"
                        color="none"
                        aria-label="Close"
                        onClick={handleClose}
                    />
                </div>
                <div className="text-center">
                    <img src={modalType === "success" ? write : subtract} alt="success-icon" />
                </div>

                <p className="hcp-alert">
                    {modalContent}
                </p>
                <div className="button-container">
                    <button
                        onClick={handleClose}
                        className="btn form-control hcp-register-button py-3"
                    >
                        <b>OKAY</b>
                    </button>
                </div>
            </Box>
        </Modal>
    );
}

export default CustomModal;