import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Menu, MenuItem, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';

import downArrow from "../../assets/image/down_arrow.png";
import { adminLogout } from "../../redux/actions/adminActions";

const mySwal = withReactContent(Swal);

const Header = ({ title, role, adminLogout, openSidebar, setOpenSidebar }) => {

    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const logout = () => {
        setAnchorEl(null);
        mySwal.fire({
            title: "Are you sure want to log out?",
            icon: "question",
            showCancelButton: true,
            cancelButtonText: "Cancel",
            confirmButtonText: "Log Out"
        })
            .then(result => {
                if (result.isConfirmed) {
                    adminLogout();
                    navigate('/admin/login');
                }
            })
    }

    return (
        <div className="d-flex justify-content-between">
            <div className="d-flex">
                <div>
                    <IconButton onClick={() => setOpenSidebar(!openSidebar)}>
                        <MenuIcon fontSize="large" />
                    </IconButton>
                </div>
                <p className="admin-dashboard-text ps-2"><b>{title}</b></p>
            </div>
            <div className="d-flex" onClick={(e) => setAnchorEl(e.currentTarget)}>
                <div id="circle"></div>
                <div className="align-self-center ps-2"><img src={downArrow} alt="down-arrow" /></div>
            </div>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                disableAutoFocusItem
            >
                {/* {role === "hcp" ? (<MenuItem onClick={() => { setAnchorEl(null); navigate('/admin/profile') }}>Profile</MenuItem>) : null} */}
                <MenuItem onClick={() => logout()}>Logout</MenuItem>
            </Menu>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        role: state.admin.role
    };
};

const mapDispatchToProps = dispatch => {
    return {
        adminLogout: () => dispatch(adminLogout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);