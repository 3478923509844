import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import { CircularProgress } from "@mui/material";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import { medicationImage } from "../dynamicController";
import { updatePatientDetails, updateHcpDetails } from "../redux/actions/hcpActions";
import { connect } from 'react-redux';
import axios from 'axios';

// images
import leftArrow from "../assets/image/left-arrow.png";

// component
import HcpNavigationBar from "./components/navigationBar";
import HcpHeader from "./components/header";
import CustomModal from "./components/modal";

const HcpCheckoutInstantRedemption = ({ patient, updatePatientDetails, updateHcpDetails, token, hcp }) => {

  const navigate = useNavigate();
  const location = useLocation();

  let number = localStorage.getItem("patient-number");

  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [normalSubmission, setNormalSubmission] = useState([]);
  const [instantRedemption, setInstantRedemption] = useState([]);
  const [code, setCode] = useState([]);
  const [modalType, setModalType] = useState("");
  const [modalContent, setModalContent] = useState("");

  useEffect(() => {
    if (location.state && location.state.normalSubmission) {
      setNormalSubmission(location.state.normalSubmission);
    }

    if (location.state && location.state.instantRedemption) {
      setInstantRedemption(location.state.instantRedemption);
    }

    if (location.state && location.state.redemptionCode) {
      setCode(location.state.redemptionCode);
    }

    if (!patient) {
      if (number) {
        axios.post('/api/hcp/patientDetails', { number })
          .then(res => {
            updatePatientDetails(res.data.data)
          })
          .catch(err => {
            navigate('/hcp/home');
          });
      } else {
        navigate('/hcp/home');
      }
    }

    if (!hcp) {
      if (token) {
        axios.post('/api/hcp/checkToken', { token })
          .then(res => {
            updateHcpDetails(res.data.data);
          })
          .catch(err => {
            setError(err.response.data.error);
          });
      } else {
        navigate("/hcp/welcome")
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setOpen(false);
    navigate('/hcp/hcpSubmission');
  };

  const checkOut = () => {
    setError("");
    setLoading(true);

    if (code) {
      for (let i = 0; i < code.length; i++) {
        axios.post('/api/hcp/redemption', { redemptionCode: code[i] })
          .then(res => {

          })
          .catch(err => {
            setError(err.response.data.error);
            setLoading(false);
          })
      }
    }

    if (normalSubmission.length > 0) {
      axios.post('/api/hcp/createSubmission', { normalSubmission })
        .then(res => {

          axios.post('/api/hcp/patientDetails', { number })
            .then(res => {
              updatePatientDetails(res.data.data)

              axios.post('/api/hcp/checkToken', { token })
                .then(res => {
                  updateHcpDetails(res.data.data);
                  setLoading(false);
                  setOpen(true);
                  setModalType("success");
                  setModalContent("This medication has been submitted successfully for redemption.");
                })
                .catch(err => {
                  setError(err.response.data.error);
                  setLoading(false);
                });
            })
            .catch(err => {
              setError(err.response.data.error);
              setLoading(false);
            });
        })
        .catch(err => {
          setOpen(true);
          setModalType("error");
          setModalContent(err.response.data.error);
          setLoading(false);
        });
    } else {
      axios.post('/api/hcp/checkToken', { token })
        .then(res => {
          updateHcpDetails(res.data.data);
          axios.post('/api/hcp/patientDetails', { number })
            .then(res => {
              updatePatientDetails(res.data.data)
              setLoading(false);
              setOpen(true);
              setModalType("success");
              setModalContent("Congratulations! Your product has been successfully redeemed.");
            })
            .catch(err => {
              setError(err.response.data.error);
              setLoading(false);
            });
        })
        .catch(err => {
          setError(err.response.data.error);
          setLoading(false);
        });
    }
  }

  return (
    <div>
      <HcpHeader />
      <div className="hcp-whole-body">
        <div className="page-title mt-3">
          <img src={leftArrow} className="hcp-left-arrow" alt="back-icon" onClick={() => navigate(-1)} />
          <h2>Checkout</h2>
        </div>

        <div className="page-body">
          <div className="px-3">

            {
              patient && normalSubmission ? normalSubmission.map((s, i) => {
                return (
                  <div className="hcp-checkout-details" key={i}>
                    <div className="d-flex">
                      <div className="transaction-img-container">
                        <img src={medicationImage(patient.product.filter(p => p.productId === s.productId)[0].medication, patient.product.filter(p => p.productId === s.productId)[0].sku)} className="transaction-product-img" alt="lipitor" />
                      </div>

                      <div style={{ margin: "auto" }}>
                        <p><b>Product:</b> {patient.product.filter(p => p.productId === s.productId)[0].medication} {patient.product.filter(p => p.productId === s.productId)[0].sku}</p>
                      </div>
                    </div>
                    <div>
                      <p><b>Submission Quantity:</b> {s.quantity}</p>
                    </div>
                  </div>
                )
              }) : null
            }

            {
              patient && instantRedemption ? instantRedemption.map((r, i) => {
                return (
                  <div className="hcp-checkout-details" key={i}>
                    <div className="d-flex">
                      <div className="transaction-img-container">
                        <img src={medicationImage(patient.product.filter(p => p.productId === r.productId)[0].medication, patient.product.filter(p => p.productId === r.productId)[0].sku)} className="transaction-product-img" alt="lipitor" />
                      </div>

                      <div style={{ margin: "auto" }}>
                        <p><b>Product:</b> {patient.product.filter(p => p.productId === r.productId)[0].medication} {patient.product.filter(p => p.productId === r.productId)[0].sku}</p>
                      </div>
                    </div>
                    <div className="pt-2" style={{ lineHeight: "15px" }}>
                      <p><b>Submission Quantity:</b> {r.threshold}</p>
                      <p><b>Instant Redemption Quantity:</b> {patient.product.filter(p => p.productId === r.productId)[0].redeemQuantity}</p>
                    </div>
                  </div>
                )
              }) : (
                null
              )
            }

          </div>

          {
            error ? (
              <div className="pt-3">
                <p style={{ color: "red", textAlign: "center" }}>{error}</p>
              </div>
            ) : null
          }

          {
            loading ? (
              <div className="text-center">
                <CircularProgress />
              </div>
            ) : (
              <div className="button-container">
                <button className="btn form-control hcp-signup-button py-3" onClick={() => checkOut()}>
                  <b>Checkout</b>
                </button>
              </div>
            )
          }
        </div>
      </div>

      <HcpNavigationBar />

      <CustomModal open={open} handleClose={handleClose} modalType={modalType} modalContent={modalContent} />
    </div>
  );
};

const mapStateToProps = state => {
  return {
    patient: state.hcp.patient,
    hcp: state.hcp.hcp,
    token: state.hcp.token
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updatePatientDetails: data => dispatch(updatePatientDetails(data)),
    updateHcpDetails: data => dispatch(updateHcpDetails(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HcpCheckoutInstantRedemption);
