import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import { updateHcpLanguage } from "../redux/actions/hcpActions";
import { updatePatientLanguage } from "../redux/actions/patientActions";
import { useTranslation } from 'react-i18next';

import logo from "../assets/image/landingPageLogo.png";

const LandingPage = ({ language, updateHcpLanguage, updatePatientLanguage }) => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div className="landing-page-container">
            <div className="d-flex justify-content-end pt-3 pe-3">
                <div className="hcp-lang">
                    <p className={language === "en" ? "hcp-lang-border px-2" : "px-2"} onClick={() => { updateHcpLanguage("en"); updatePatientLanguage("en") }}>ENG</p>
                    <p className={language === "bm" ? "hcp-lang-border px-2" : "px-2"} onClick={() => { updateHcpLanguage("bm"); updatePatientLanguage("bm") }}>BM</p>
                    <p className={language === "ch" ? "hcp-lang-border px-2" : "px-2"} onClick={() => { updateHcpLanguage("ch"); updatePatientLanguage("ch") }}>MAN</p>
                </div>
            </div>
            <div className="text-center pt-5">
                <img src={logo} alt="landing-logo" className="hcp-welcome-logo" />
                <p style={{ color: "white" }} className="pt-5"><b>{t("SELECT YOUR IDENTITY")}</b></p>
                <div className="button-container pt-3">
                    <button
                        className="btn form-control hcp-button py-3"
                        onClick={() => navigate('/hcp/welcome')}
                    >
                        <b>{t("I AM HCP")}</b>
                    </button>
                </div>
                <div className="button-container">
                    <button
                        className="btn form-control patient-button py-3"
                        onClick={() => navigate('/patient/patientWelcome')}
                    >
                        <b>{t("I AM PATIENT")}</b>
                    </button>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        language: state.hcp.language,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateHcpLanguage: data => dispatch(updateHcpLanguage(data)),
        updatePatientLanguage: data => dispatch(updatePatientLanguage(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);