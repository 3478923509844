import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { MDBDataTableV5 } from 'mdbreact';
import { CircularProgress, Modal, Box, Drawer } from "@mui/material";
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { styled } from '@mui/material/styles';

// components
import Sidebar from "./components/sidebar";
import Header from "./components/header";

const mySwal = withReactContent(Swal);

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-20%`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      maxWidth: "80%"
    }),
  }),
);

const InventoryUpdate = ({ role, token }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(true);

  let proceed = true;
  let errorIndex = -1;

  useEffect(() => {
    if (role && token) {
      if (role === "hcp" || role === "sales" || role === "admin") {
        mySwal.fire({ title: "Error", text: "Unauthorized Access", icon: "error", allowOutsideClick: false })
          .then(click => {
            if (click.isConfirmed) {
              navigate('/admin/login');
            }
          })
      }
    } else {
      navigate('/admin/login');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFile = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.type !== "text/csv") {
        mySwal.fire({ title: "Error", text: "Only CSV file is accepted", icon: "error" })
        e.target.value = null;
      } else {
        setLoading(true);

        const formData = new FormData();
        formData.append("file", file);
        formData.append("role", role);
        formData.append("token", token);

        axios.post("/api/admin/salesDataFileUpload", formData)
          .then(res => {
            setCsvData(res.data.data);
            setShowModal(true);
            setLoading(false);
          })
          .catch(err => {
            setLoading(false);
            if (err.response.data.error === "Token Expired") {
              mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
                .then(click => {
                  if (click.isConfirmed) {
                    navigate('/admin/login');
                  }
                })
            } else {
              mySwal.fire("Error", err.response.data.error, "error")
            }
          });
      }
    }
  }

  const handleClose = () => {
    setShowModal(false);
    setCsvData(null);
  }

  const updateInventory = () => {
    if (proceed) {
      setLoading(true);
      axios.post("/api/admin/salesDataUpdate", { role, token, jsonData: csvData })
        .then(res => {
          setCsvData(null);
          setShowModal(false);
          setLoading(false);
          mySwal.fire("Success", "Inventory Updated successfully!", "success")
        })
        .catch(err => {
          setCsvData(null);
          setShowModal(false);
          setLoading(false);
          if (err.response.data.error === "Token Expired") {
            mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
              .then(click => {
                if (click.isConfirmed) {
                  navigate('/admin/login');
                }
              })
          } else {
            mySwal.fire("Error", err.response.data.error, "error")
          }
        });

    } else {
      setShowModal(false);
      setCsvData(null);
      mySwal.fire("Error", `Please make sure all data is filled (Row ${errorIndex + 1})`, "error")
    }
  }

  let tableData = [];

  let data = {
    columns: [
      {
        field: "accountCode",
        label: "Account Code Combined"
      },
      {
        field: "brand",
        label: "Brand"
      },
      {
        field: "year",
        label: "Year"
      },
      {
        field: "month",
        label: "Month"
      },
      {
        field: "salesQuantityBox",
        label: "Sales Quantity (boxes)"
      },
      {
        field: "skuCode",
        label: "SKU Code"
      }
    ],
    rows: tableData ? tableData : []
  }

  if (csvData) {
    for (let i = 0; i < csvData.length; i++) {
      if (csvData[i].accountCode === "" || csvData[i].brand === "" || csvData[i].year === "" || csvData[i].month === "" || csvData[i].salesQuantityBox === "" || csvData[i].skuCode === "") {
        proceed = false;
        errorIndex = i;
      }
      const dataObject = {
        accountCode: (
          <p style={{ color: (csvData[i].accountCode === "" || csvData[i].brand === "" || csvData[i].year === "" || csvData[i].month === "" || csvData[i].salesQuantityBox === "" || csvData[i].skuCode === "") ? "red" : "black" }}>{csvData[i].accountCode}</p>
        ),
        brand: (
          <p style={{ color: (csvData[i].accountCode === "" || csvData[i].brand === "" || csvData[i].year === "" || csvData[i].month === "" || csvData[i].salesQuantityBox === "" || csvData[i].skuCode === "") ? "red" : "black" }}>{csvData[i].brand}</p>
        ),
        year: (
          <p style={{ color: (csvData[i].accountCode === "" || csvData[i].brand === "" || csvData[i].year === "" || csvData[i].month === "" || csvData[i].salesQuantityBox === "" || csvData[i].skuCode === "") ? "red" : "black" }}>{csvData[i].year}</p>
        ),
        month: (
          <p style={{ color: (csvData[i].accountCode === "" || csvData[i].brand === "" || csvData[i].year === "" || csvData[i].month === "" || csvData[i].salesQuantityBox === "" || csvData[i].skuCode === "") ? "red" : "black" }}>{csvData[i].month}</p>
        ),
        salesQuantityBox: (
          <p style={{ color: (csvData[i].accountCode === "" || csvData[i].brand === "" || csvData[i].year === "" || csvData[i].month === "" || csvData[i].salesQuantityBox === "" || csvData[i].skuCode === "") ? "red" : "black" }}>{csvData[i].salesQuantityBox}</p>
        ),
        skuCode: (
          <p style={{ color: (csvData[i].accountCode === "" || csvData[i].brand === "" || csvData[i].year === "" || csvData[i].month === "" || csvData[i].salesQuantityBox === "" || csvData[i].skuCode === "") ? "red" : "black" }}>{csvData[i].skuCode}</p>
        )
      }
      tableData.push(dataObject);
    }
  }

  return (
    <div className="d-flex">

      <Drawer
        sx={{
          minWidth: "20%",
          '& .MuiDrawer-paper': {
            minWidth: "20%",
            boxSizing: 'border-box',
            minHeight: "100vh",
            backgroundColor: "#FE7072",
          },
        }}
        anchor="left"
        open={openSidebar}
        variant="persistent">
        <Sidebar />
      </Drawer>

      <Main open={openSidebar}>
        <div className="mt-3 px-3">
          <Header title="INVENTORY UPDATE" openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />

          <div>
            <p>Header format in CSV file:</p>
            <table className="table table-bordered mt-3">
              <thead>
                <tr>
                  <th scope="col">Account Code Combined</th>
                  <th scope="col">Brand</th>
                  <th scope="col">Product Description</th>
                  <th scope="col">Year</th>
                  <th scope="col">Month</th>
                  <th scope="col">SE</th>
                  <th scope="col">ABM</th>
                  <th scope="col">Sales Quantity (boxes)</th>
                  <th scope="col">SKU Name</th>
                  <th scope="col">SKU Code</th>
                </tr>
              </thead>
            </table>
          </div>

          {
            loading ? (
              <div className="text-center">
                <CircularProgress />
              </div>
            ) : (
              <div className="form-group mt-5">
                <label htmlFor="file">
                  Upload File Here:{" "}
                  <small>
                    <strong>Only CSV file (comma delimited) is support now</strong>
                  </small>
                </label>
                <input type="file" id="file" name="file" className="form-control" accept=".csv" onChange={handleFile} required />
              </div>
            )
          }

        </div>
      </Main>

      <Modal
        open={showModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="inventory-modal">
          <p className="text-center">Confirm uploaded data</p>
          <MDBDataTableV5 data={data} hover responsive bordered small sortable={false} searching={false} />
          {
            loading ? (
              <div className="text-center">
                <CircularProgress />
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <button type="button" className="btn btn-danger" onClick={handleClose}>Cancel</button>
                <button type="button" className="btn btn-primary ms-4" onClick={updateInventory}>Update</button>
              </div>
            )
          }
        </Box>
      </Modal>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    token: state.admin.token,
    role: state.admin.role
  };
};

export default connect(mapStateToProps, null)(InventoryUpdate);
