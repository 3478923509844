export const UPDATE_HCP_LANGUAGE = "UPDATE_HCP_LANGUAGE";
export const UPDATE_HCP_REMEMBER_ME = "UPDATE_HCP_REMEMBER_ME";
export const HCP_LOGIN = "HCP_LOGIN";
export const UPDATE_PATIENT_DETAILS = "UPDATE_PATIENT_DETAILS";
export const REGISTER_POPUP = "REGISTER_POPUP";
export const UPDATE_HCP_DETAILS = "UPDATE_HCP_DETAILS";
export const UPDATE_REDEMPTION = "UPDATE_REDEMPTION";
export const HCP_LOGOUT = "HCP_LOGOUT";

export const UPDATE_PATIENT_LANGUAGE = "UPDATE_PATIENT_LANGUAGE";
export const UPDATE_PATIENT_REMEMBER_ME = "UPDATE_PATIENT_REMEMBER_ME";
export const PATIENT_LOGIN = "PATIENT_LOGIN";
export const PATIENT_REGISTER_POPUP = "PATIENT_REGISTER_POPUP";
export const PATIENT_CHECK_TOKEN = "PATIENT_CHECK_TOKEN";
export const HOMEPAGE_NAV = "HOMEPAGE_NAV";
export const PATIENT_LOGOUT = "PATIENT_LOGOUT";

export const ADMIN_LOGIN = "ADMIN_LOGIN";
export const ADMIN_LOGOUT = "ADMIN_LOGOUT";
export const SUMMARY_DASHBOARD = "SUMMARY_DASHBOARD";
export const PERFORMANCE_BY_BRAND = "PERFORMANCE_BY_BRAND";
export const PERFORMANCE_BY_SKU = "PERFORMANCE_BY_SKU";
export const TOTAL_SUBMISSIONS = "TOTAL_SUBMISSIONS";
export const UNIQUE_PATIENTS = "UNIQUE_PATIENTS";
export const AVERAGE_PURCHASE = "AVERAGE_PURCHASE";
export const TOP_TEN_ACCOUNTS = "TOP_TEN_ACCOUNTS";