import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import transaction from "../../assets/image/patient-transaction-icon.png";
import brandIcon from "../../assets/image/patient-brand-icon.png";
import inbox from "../../assets/image/patient-inbox.png";
import help from "../../assets/image/patient-help.png";

const PatientNavigationBar = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <div className="hcp-padding-bar">
            <div className="hcp-bottom-bar pt-2">
                <div className="hcp-img" onClick={() => navigate("/patient/transactionRules")}>
                    <img src={transaction} className="hcp-img" alt="transaction-icon" />
                    <p className="patient-transaction-text">{t("TRANSACTION HISTORY")}</p>
                </div>
                <div className="hcp-img" onClick={() => navigate("/patient/patientBrandInfo")}>
                    <img src={brandIcon} className="hcp-img" alt="brand-icon" />
                    <p className="patient-transaction-text">{t("BRAND INFO")}</p>
                </div>
                <div className="hcp-img patient-inbox" onClick={() => navigate("/patient/patientInbox")}>
                    <img src={inbox} className="hcp-img" alt="inbox-icon" />
                    <p className="patient-transaction-text">{t("INBOX")}</p>
                </div>
                <div className="hcp-img patient-help" onClick={() => navigate("/patient/patientHelp")}>
                    <img src={help} className="hcp-img" alt="help-icon" />
                    <p className="patient-transaction-text patient-text-help">{t("HELP")}</p>
                </div>
            </div>
        </div>
    );
}

export default PatientNavigationBar;