import { useNavigate } from "react-router-dom";
import { Box, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { useTranslation } from 'react-i18next';

// image
import leftArrow from "../assets/image/left-arrow.png";
import plus from "../assets/image/plus.png";
import minimize from "../assets/image/minimize.png";
import phonecall from "../assets/image/phone_call.png";
import emailIcon from "../assets/image/email-icon.png";
import whatsappIcon from "../assets/image/whatsapp-icon.png";

// component
import PatientNavigationBar from "./components/navigationBar";
import PatientHeader from "./components/header";

const PatientHelp = () => {

  const navigate = useNavigate();
  const { t } = useTranslation();

  const CustomExpandIcon = () => {
    return (
      <Box
        sx={{
          '.Mui-expanded & > .collapsIconWrapper': { display: 'none' },
          '.expandIconWrapper': { display: 'none' },
          '.Mui-expanded & > .expandIconWrapper': { display: 'block' },
        }}
      >
        <div className="expandIconWrapper pe-3">
          <img src={minimize} alt="minus" />
        </div>
        <div className="collapsIconWrapper ps-3">
          <img src={plus} alt="plus" />
        </div>
      </Box>
    )
  }

  return (
    <div>
      <PatientHeader />
      <div className="hcp-whole-body">
        <div className="page-title mt-3">
          <img src={leftArrow} alt="back-icon" className="hcp-left-arrow" onClick={() => navigate('/patient/patientHome')} />
          <h2>{t("Help")}</h2>
        </div>
        <div className="page-body">
          <h2 className="ms-5 mt-3">FAQs</h2>

          <h3 className="ms-5 mt-3">{t("General")}</h3>

          <div style={{ textAlign: "justify" }}>
            <Accordion className="hcp-patient-profile mx-3 my-3">
              <AccordionSummary expandIcon={<CustomExpandIcon />}>
                <b>{t("What is iCARE Connect+ Program and what does it offer?")}</b>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <p>{t("iCARE Connect+ Program is a digital patient assistance program that enables you to take control of your health through health information, medication rebate and support for treatment continuity.")}</p>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion className="hcp-patient-profile mx-3 my-3">
              <AccordionSummary expandIcon={<CustomExpandIcon />}>
                <b>{t("What happen if my medication has changed?")}</b>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <p>{t("You will receive a pop-up notification upon login if HCP updates the medication.")}</p>
                </div>
              </AccordionDetails>
            </Accordion>

            <h3 className="ms-5 mt-3">{t("Registration")}</h3>

            <Accordion className="hcp-patient-profile mx-3 my-3">
              <AccordionSummary expandIcon={<CustomExpandIcon />}>
                <b>{t("How do I register for the program?")}</b>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <p>{t("Step 1: Scan QR Code provided by your HCP or visit www.icareconnectplus.com.my to get onboard.")}</p>
                  <p>{t("Step 2: Click Sign Up and key in mobile number for OTP code requisition.")}</p>
                  <p>{t("Step 3: Key in OTP code which sent to your mobile through SMS.")}</p>
                  <p>{t("Step 4: Fill up your NRIC/ MRN and medication prescribed then click Sign Up.")}</p>
                  <p>{t("Step 5: Acknowledge the T&C and privacy policy upon registration.")}</p>
                  <p>{t("Please take some time to read the terms and conditions and privacy policy. We care for your privacy and want you to feel secure on this program. We are committed to respect and maintain your privacy throughout the program.")}</p>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion className="hcp-patient-profile mx-3 my-3">
              <AccordionSummary expandIcon={<CustomExpandIcon />}>
                <b>{t("Is there any other way I can register for this program with other’s help?")}</b>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <p>{t("Yes. Participating hospitals and clinics may help on your behalf for registration. You will need to provide your mobile number and the OTP code you received for verification during the registration process.")}</p>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion className="hcp-patient-profile mx-3 my-3">
              <AccordionSummary expandIcon={<CustomExpandIcon />}>
                <b>{t("Can I register in a different hospital/ clinic?")}</b>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <p>{t("You may register with maximum of 3 different hospitals/ clinics. However, you will need to complete the purchase from the same hospital/ clinic to be eligible for redemption.")}</p>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion className="hcp-patient-profile mx-3 my-3">
              <AccordionSummary expandIcon={<CustomExpandIcon />}>
                <b>{t("Can I register with multiple phone numbers?")}</b>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <p>{t("Only one mobile number is allowed to register for each unique NRIC/ MRN. Please inform your healthcare provider if your mobile number has changed.")}</p>
                </div>
              </AccordionDetails>
            </Accordion>

            <h3 className="ms-5 mt-3">{t("Submission")}</h3>

            <Accordion className="hcp-patient-profile mx-3 my-3">
              <AccordionSummary expandIcon={<CustomExpandIcon />}>
                <b>{t("Can I bring forward my submission this year if I have not achieved the redemption mechanics?")}</b>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <p>{t("Yes. You can bring forward any outstanding submission to the next annual cycle.")}</p>
                </div>
              </AccordionDetails>
            </Accordion>

            <h3 className="ms-5 mt-3">{t("Redemption")}</h3>

            <Accordion className="hcp-patient-profile mx-3 my-3">
              <AccordionSummary expandIcon={<CustomExpandIcon />}>
                <b>{t("How does the redemption scheme work?")}</b>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <p>{t("iCARE Connect+ Program offers medication rebate from participating hospitals and clinics. Talk to your healthcare provider for more information about the scheme.")}</p>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion className="hcp-patient-profile mx-3 my-3">
              <AccordionSummary expandIcon={<CustomExpandIcon />}>
                <b>{t("How do I go about the medication redemption?")}</b>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <p>{t("Step 1: Make purchase of the prescribed medication from participating hospital/ clinic.")}</p>
                  <p>{t("Step 2: HCP to submit the medication and key in the quantity purchased.")}</p>
                  <p>{t("Step 3: Verification code will be sent to you via SMS when you are eligible for a redemption.")}</p>
                  <p>{t("Step 4: Show verification code to HCP to redeem complimentary medication.")}</p>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion className="hcp-patient-profile mx-3 my-3">
              <AccordionSummary expandIcon={<CustomExpandIcon />}>
                <b>{t("When do I need to collect my medication?")}</b>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <p>{t("The verification code will be valid for 90 days. You will need to collect your complimentary medication in 90 days, after which the redemption will be forfeited.")}</p>
                </div>
              </AccordionDetails>
            </Accordion>

            <Accordion className="hcp-patient-profile mx-3 my-3">
              <AccordionSummary expandIcon={<CustomExpandIcon />}>
                <b>{t("What should I do if I received an SMS of completed redemption without doing any transaction?")}</b>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <p>{t("In the event if you received an SMS of completed redemption without your consent, please reach out to your healthcare professionals for more information.")}</p>
                </div>
              </AccordionDetails>
            </Accordion>

            <h3 className="ms-5 mt-3">{t("Business Rules")}</h3>

            <Accordion className="hcp-patient-profile mx-3 my-3">
              <AccordionSummary expandIcon={<CustomExpandIcon />}>
                <b>{t("Is there a limit on purchase?")}</b>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <p>{t("Yes. The limitation on purchase and redemption differs by the respective medication. Please refer to your healthcare provider for more details.")}</p>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>

          <div className="ms-5 mt-3">
            <h3>{t("Hotline")}</h3>
          </div>

          <div className="patient-hotline-padding">
            <div className="patient-hotline-bg-color">
              <div className="patient-hotline">
                <div>
                  <img src={phonecall} alt="phone-icon" className="patient-phone-img" />
                </div>
                <div className="patient-contact-customer-service-text">
                  <b>{t("Contact a member of our Customer Service team!")}</b>
                </div>
              </div>

              <div className="button-container">
                <a className="btn form-control hcp-signup-button py-3 my-2" href="https://wa.me/601154235382">
                  <img src={whatsappIcon} alt="whatsapp" className="me-2" />
                  <b>WHATSAPP</b>
                </a>
              </div>

              <div className="button-container">
                <button className="btn form-control hcp-signup-button py-3 my-2" onClick={() => window.location = 'mailto:helpdesk@icareconnectplus.com.my'}>
                  <img src={emailIcon} alt="email" className="me-2" />
                  <b>{t("EMAIL")}</b>
                </button>
              </div>
            </div>
          </div>
        </div>

        <PatientNavigationBar />

      </div>
    </div>
  );
}

export default PatientHelp;
