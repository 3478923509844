import { ADMIN_LOGIN, ADMIN_LOGOUT, SUMMARY_DASHBOARD, PERFORMANCE_BY_BRAND, PERFORMANCE_BY_SKU, TOTAL_SUBMISSIONS, UNIQUE_PATIENTS, AVERAGE_PURCHASE, TOP_TEN_ACCOUNTS } from "../actions/type";

const initState = {
	token: localStorage.getItem("icare-admin-token"),
	role: localStorage.getItem("icare-admin-role"),
	summaryDashboard: null,
	performanceByBrand: null,
	performanceBySku: null,
	totalSubmissionsLineGraph: null,
	totalUniquePatient: null,
	averagePurchase: null,
	topTenAccounts: null
};

const adminReducer = (state = initState, action) => {
	switch (action.type) {
		case ADMIN_LOGIN:
			localStorage.setItem("icare-admin-role", action.payload.role)
			localStorage.setItem("icare-admin-token", action.payload.token)
			return {
				...state,
				token: action.payload.token,
				role: action.payload.role
			};
		case ADMIN_LOGOUT:
			localStorage.removeItem("icare-admin-token");
			localStorage.removeItem("icare-admin-role");
			return {
				...state,
				token: null,
				role: null,
				summaryDashboard: null,
				performanceByBrand: null,
				performanceBySku: null,
				totalSubmissionsLineGraph: null,
				totalUniquePatient: null,
				averagePurchase: null,
				topTenAccounts: null
			};
		case SUMMARY_DASHBOARD:
			return {
				...state,
				summaryDashboard: action.payload
			};
		case PERFORMANCE_BY_BRAND:
			return {
				...state,
				performanceByBrand: action.payload
			};
		case PERFORMANCE_BY_SKU:
			return {
				...state,
				performanceBySku: action.payload
			};
		case TOTAL_SUBMISSIONS:
			return {
				...state,
				totalSubmissionsLineGraph: action.payload
			};
		case UNIQUE_PATIENTS:
			return {
				...state,
				totalUniquePatient: action.payload
			};
		case AVERAGE_PURCHASE:
			return {
				...state,
				averagePurchase: action.payload
			};
		case TOP_TEN_ACCOUNTS:
			return {
				...state,
				topTenAccounts: action.payload
			};
		default:
			return state;
	}
};

export default adminReducer;
