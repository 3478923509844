import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// images
import android1 from '../assets/image/tutorial/addToHomeScreen/android/tutorial-android_step1.png';
import android2 from '../assets/image/tutorial/addToHomeScreen/android/tutorial-android_step2.png';
import android3 from '../assets/image/tutorial/addToHomeScreen/android/tutorial-android_step3.png';
import ios1 from '../assets/image/tutorial/addToHomeScreen/ios/tutorial-IOS_step1.png';
import ios2 from '../assets/image/tutorial/addToHomeScreen/ios/tutorial-IOS_step2.png';
import ios3 from '../assets/image/tutorial/addToHomeScreen/ios/tutorial-IOS_step3.png';

const HcpAddHomeScreenTutorial = () => {

    const navigate = useNavigate();

    const [phoneType, setPhoneType] = useState("Android");

    return (
        <div className="hcp-login-page">
            <div style={{ height: "5vh" }}></div>
            <div>
                <div className="hcp-login-container">

                    <div>
                        <h2 className="hcp-login-title">Save the webpage before you begin your Journey.</h2>
                        <p className="pt-2" style={{ color: "white" }}>Choose your phone</p>
                        <div className="d-flex">
                            <div className="container" onClick={e => setPhoneType("Android")}>
                                <p className={phoneType === "Android" ? "hcp-selected" : "hcp-non-selected"}>Android</p>
                            </div>
                            <div className="container" onClick={e => setPhoneType("IOS")}>
                                <p className={phoneType === "IOS" ? "hcp-selected" : "hcp-non-selected"}>IOS</p>
                            </div>
                        </div>
                    </div>

                    <div className="pt-2">
                        {
                            phoneType === "Android" ? (
                                <div style={{ fontSize: "18px", color: "white" }}>
                                    <p><b>STEP 1</b></p>
                                    <p><b>Tap the menu icon (3 dots in upper right-hand corner).</b></p>
                                    <img src={android1} alt="android1" className="img-fluid" style={{ borderRadius: "15px" }} />
                                    <p className="pt-3"><b>STEP 2</b></p>
                                    <p><b>Tap "Add to Home Screen".</b></p>
                                    <img src={android2} alt="android2" className="img-fluid" style={{ borderRadius: "15px" }} />
                                    <p className="pt-3"><b>STEP 3</b></p>
                                    <p><b>Rename the website then tap "Add".</b></p>
                                    <img src={android3} alt="android3" className="img-fluid" style={{ borderRadius: "15px" }} />
                                    <p className="pt-3"><b>STEP 4</b></p>
                                    <p><b>Done.</b></p>
                                </div>
                            ) : (
                                <div style={{ fontSize: "18px", color: "white" }}>
                                    <p><b>STEP 1</b></p>
                                    <p><b>Tap the Share icon (Safari).</b></p>
                                    <img src={ios1} alt="ios1" className="img-fluid" style={{ borderRadius: "15px" }} />
                                    <p className="pt-3"><b>STEP 2</b></p>
                                    <p><b>Tap "Add to Home Screen".</b></p>
                                    <img src={ios2} alt="ios2" className="img-fluid" style={{ borderRadius: "15px" }} />
                                    <p className="pt-3"><b>STEP 3</b></p>
                                    <p><b>Rename the website then tap "Add".</b></p>
                                    <img src={ios3} alt="ios3" className="img-fluid" style={{ borderRadius: "15px" }} />
                                    <p className="pt-3"><b>STEP 4</b></p>
                                    <p><b>Done.</b></p>
                                </div>
                            )
                        }
                    </div>

                    <div className="button-container">
                        <button className="btn form-control hcp-login-button py-3" onClick={() => navigate('/hcp/hcpStartTutorial')}>
                            <b>OK</b>
                        </button>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default HcpAddHomeScreenTutorial;
