import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Box, CircularProgress } from "@mui/material";
import { MDBBtn } from "mdb-react-ui-kit";
import axios from 'axios';
import { connect } from 'react-redux';
import { updateHcpDetails } from "../redux/actions/hcpActions";
import { isMobile } from 'react-device-detect';

import hcpMobile0En from "../assets/image/tutorial/hcp/mobile/hcpTutorial0-en.png";
import hcpDesktop0En from "../assets/image/tutorial/hcp/desktop/hcpTutorial0-en.png";

const HcpStartTutorial = ({ token, updateHcpDetails, hcp }) => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        if (!hcp) {
            setError("")
            setLoading(true);

            if (token) {
                axios.post('/api/hcp/checkToken', { token })
                    .then(res => {
                        updateHcpDetails(res.data.data);
                        setLoading(false);
                    })
                    .catch(err => {
                        setError(err.response.data.error);
                        setLoading(false);
                    });
            } else {
                navigate("/hcp/welcome")
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const skipTutorial = () => {
        setError("")
        setLoading(true);

        axios.post('/api/hcp/updateFirstTimeLogin', { hcpId: hcp.hcp.id })
            .then(res => {
                setLoading(false);
                navigate('/hcp/home');
            })
            .catch(err => {
                setError(err.response.data.error);
                setLoading(false);
            });
    }

    const startTutorial = () => {
        setError("")
        setLoading(true);

        axios.post('/api/hcp/updateFirstTimeLogin', { hcpId: hcp.hcp.id })
            .then(res => {
                setLoading(false);
                navigate('/hcp/hcpTutorial1');
            })
            .catch(err => {
                setError(err.response.data.error);
                setLoading(false);
            });
    }

    return (
        <div>
            {
                isMobile ? (
                    <img src={hcpMobile0En} alt="hcpTutorial" style={{ height: "100vh", width: "100vw" }} />
                ) : (
                    <img src={hcpDesktop0En} alt="hcpTutorial" style={{ height: "100vh", width: "100vw" }} />
                )
            }
            <Modal
                open={true}
                onClose={() => skipTutorial()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                hideBackdrop={true}
            >
                <Box className="red-modal">
                    <div className="align-right">
                        <MDBBtn
                            className="btn-close btn-close-white"
                            color="none"
                            aria-label="Close"
                            onClick={() => skipTutorial()}
                        />
                    </div>
                    <p className="hcp-alert" style={{ fontSize: "18px" }}>Welcome to the tutorial!</p>
                    {
                        error ? (
                            <div className="pt-3">
                                <p style={{ color: "red", textAlign: "center" }}>{error}</p>
                            </div>
                        ) : null
                    }

                    {
                        loading ? (
                            <div className="text-center">
                                <CircularProgress />
                            </div>
                        ) : (
                            <div>
                                <div className="button-container">
                                    <button className="btn form-control hcp-register-button py-3" onClick={() => startTutorial()}>
                                        <b>START</b>
                                    </button>
                                </div>
                                <div className="button-container">
                                    <button className="btn form-control hcp-no-button py-3" onClick={() => skipTutorial()}>
                                        <b>SKIP</b>
                                    </button>
                                </div>
                            </div>
                        )
                    }
                </Box>
            </Modal>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        token: state.hcp.token,
        hcp: state.hcp.hcp
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateHcpDetails: data => dispatch(updateHcpDetails(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HcpStartTutorial);
