import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { connect } from 'react-redux';
import { CircularProgress, Tooltip, IconButton, Drawer } from "@mui/material";
import { InfoOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

// components
import Sidebar from "./components/sidebar";
import Header from "./components/header";
import SummaryDashboard from "./dashboard/summaryDashboard";
import PerformaceByBrand from "./dashboard/performaceByBrand";
import PerformanceBySku from "./dashboard/performanceBySku";
import SubmissionRedemptionLineGraph from "./dashboard/submissionRedemptionLineGraph";
import UniquePatient from "./dashboard/uniquePatient";
import AveragePurchase from "./dashboard/averagePurchase";
import TopTenAccounts from "./dashboard/topTenAccounts";

const mySwal = withReactContent(Swal);

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-20%`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      maxWidth: "80%"
    }),
  }),
);

const Dashboard = ({ role, token }) => {

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [accountNameList, setAccountNameList] = useState([]);
  const [seNameList, setSeNameList] = useState([]);
  const [openSidebar, setOpenSidebar] = useState(true);

  useEffect(() => {

    if (role && token) {
      if (role !== "hcp") {
        axios.post("/api/admin/getAccountName", { token })
          .then(res => {
            setAccountNameList(res.data.hcpData);
            setSeNameList(res.data.seData);
            setLoading(false);
          })
          .catch(err => {
            setLoading(false);
            if (err.response.data.error === "Token Expired") {
              mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
                .then(click => {
                  if (click.isConfirmed) {
                    navigate('/admin/login');
                  }
                })
            } else {
              mySwal.fire("Error", err.response.data.error, "error")
            }
          });
      } else {
        setLoading(false);
      }
    } else {
      navigate('/admin/login');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="d-flex">

      <Drawer
        sx={{
          minWidth: "20%",
          '& .MuiDrawer-paper': {
            minWidth: "20%",
            boxSizing: 'border-box',
            minHeight: "100vh",
            backgroundColor: "#FE7072",
          },
        }}
        anchor="left"
        open={openSidebar}
        variant="persistent">
        <Sidebar />
      </Drawer>

      <Main open={openSidebar}>
        <div className="mt-3 px-3">
          <Header title="AT A GLANCE" openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />

          {
            loading ? (
              <div className="text-center">
                <CircularProgress />
              </div>
            ) : (
              <div>
                <SummaryDashboard accountNameList={accountNameList} seNameList={seNameList} />

                <div className="d-flex mt-3">
                  <p className="admin-dashboard-text"><b>PERFORMANCE OVERVIEW</b></p>
                  <div>
                    <Tooltip title="Total qty (unit) submitted for each brand and SKU">
                      <IconButton>
                        <InfoOutlined style={{ color: "#403B3B" }} fontSize="large" />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>

                <div className="d-flex justify-content-around">
                  <div style={{ width: "48%" }}>
                    <PerformaceByBrand accountNameList={accountNameList} />
                  </div>
                  <div style={{ width: "48%" }}>
                    <PerformanceBySku />
                  </div>
                </div>

                <SubmissionRedemptionLineGraph />

                <div className="d-flex mt-3">
                  <p className="admin-dashboard-text"><b>TOTAL UNIQUE PATIENTS</b></p>
                  <div>
                    <Tooltip title="Number of unique patients with at least 1 transaction">
                      <IconButton>
                        <InfoOutlined style={{ color: "#403B3B" }} fontSize="large" />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>

                <UniquePatient />

                {
                  role === "hcp" || role === "sales" ? (
                    <AveragePurchase accountNameList={accountNameList} />
                  ) : (
                    <div className="d-flex">
                      <div className="pe-3">
                        <AveragePurchase accountNameList={accountNameList} />
                      </div>
                      <div style={{ flexGrow: 1 }}>
                        <TopTenAccounts accountNameList={accountNameList} />
                      </div>
                    </div>
                  )
                }

              </div>
            )
          }
        </div>
      </Main>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    token: state.admin.token,
    role: state.admin.role
  };
};

export default connect(mapStateToProps, null)(Dashboard);
