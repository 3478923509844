import React, { useState, useRef } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { useNavigate } from "react-router-dom";

const HcpPrivacyPolicy = () => {

  const navigate = useNavigate();
  const scrollRef = useRef();

  const [disableButton, setDisableButton] = useState(true);

  const onScroll = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        setDisableButton(false);
      }
    }
  }

  const handleSubmit = e => {
    e.preventDefault();

    navigate("/hcp/hcpAddToHomeScreenTutorial");
  }

  return (
    <div className="hcp-terms-and-condition-frame">
      <div className="text-center">
        <h3>Privacy Policy</h3>
      </div>
      <div className="hcp-terms-text" onScroll={() => onScroll()} ref={scrollRef}>
        
        <div className="text-center">
          <h6>CLICK SERVICES SDN BHD</h6>
          <h6>TERMS AND CONDITIONS (AGREEMENT) FOR HEALTHCARE PROFESSIONALS - iCARE CONNECT+</h6>
        </div>
        <div>
          <ol>
            <b><li>TERMS OF USE</li></b>
            <p>By downloading, browsing, accessing or using this iCare Connect+ mobile application (“<b>Mobile Application</b>”), you agree to be bound by these Terms and Conditions of Use. We reserve the right to amend these terms and conditions at any time. If you disagree with any of these Terms and Conditions of Use, you must immediately discontinue your access to the Mobile Application and your use of the services offered on the Mobile Application. Continued use of the Mobile Application will constitute acceptance of these Terms and Conditions of Use, as may be amended from time to time.</p>
            <b><li>DEFINITIONS</li></b>
            <p>In these Terms and Conditions of Use, the following capitalised terms shall have the following meanings, except where the context otherwise requires:</p>
            <p>"<b>Account</b>" means an account created by a User on the Mobile Application as part of Registration.</p>
            <p>"<b>Merchant</b>" refers to any entity whose products can be purchased and/or redeemed (as the case may be) via the Mobile Application.</p>
            <p>"<b>Privacy Policy</b>" means the privacy policy set out in Clause 14 of these Terms and Conditions of Use.</p>
            <p>"<b>Redeem</b>" means to redeem a Merchant’s products on these Terms and Conditions of Use and "<b>Redemption</b>" means the act of redeeming such products.</p>
            <p>"<b>Register</b>" means to create an Account on the Mobile Application and "<b>Registration</b>" means the act of creating such an Account.</p>
            <p>"<b>Services</b>" means all the services provided by Click Services Sdn Bhd (“<b>Click Services</b>”) via the Mobile Application to Users, and "<b>Service</b>" means any one of them.</p>
            <p>"<b>Users</b>" means users of the Mobile Application, including you and "<b>User</b>" means any one of them.</p>
            <b><li>GENERAL ISSUES ABOUT THE MOBILE APPLICATION AND THE SERVICES</li></b>
            <p>3.1 <i>Applicability of terms and conditions</i>: The use of any Services and/or the Mobile Application and the making of any Redemptions are subject to these Terms and Conditions of Use.</p>
            <p>3.2 <i>Location</i>: The Mobile Application, the Services and any Redemptions are intended solely for use by Users who access the Mobile Application in Malaysia. If you access the Mobile Application, use the Services or make any Redemptions from locations outside Malaysia, you do so on your own initiative and are responsible for the consequences and for compliance with all applicable laws.</p>
            <p>3.3 <i>Scope</i>: The Mobile Application, the Services and any Redemptions are for your non-commercial, personal use only and must not be used for business purposes.</p>
            <p>3.4 <i>Prevention on use</i>: We reserve the right to prevent you using the Mobile Application and the Service (or any part of them) and to prevent you from making any Redemptions.</p>
            <p>3.5 <i>Equipment and Networks</i>: The provision of the Services and the Mobile Application does not include the provision of a mobile telephone or handheld device or other necessary equipment to access the Mobile Application or the Services or make any Redemptions. To use the Mobile Application or Services or to make Redemptions, you will require Internet connectivity and appropriate telecommunication links. You acknowledge that the terms of agreement with your respective mobile network provider ("<b>Mobile Provider</b>") will continue to apply when using the Mobile Application. As a result, you may be charged by the Mobile Provider for access to network connection services for the duration of the connection while accessing the Mobile Application or any such third party charges as may arise. You accept responsibility for any such charges that arise.</p>
            <p>3.6 <i>License to Use Material</i>: By submitting any text or images (including photographs) (“<b>Material</b>”) via the Mobile Application, you represent that you are the owner of the Material, or have proper authorization from the owner of the Material to use, reproduce and distribute it. You hereby grant us a worldwide, royalty-free, non-exclusive license to use the Material to promote any products or services.</p>
            <b><li>REDEMPTIONS</li></b>
            <p>4.1 <i>Need for registration</i>: You must Register to make a Redemption from the Mobile Application.</p>
            <p>4.2 <i>Application of these Terms and Conditions of Use</i>: By making any Redemption, you acknowledge that the Redemption is subject to these Terms and Conditions of Use.</p>
            <p>4.3 <i>Redemption</i>: Any attempted Redemption not consistent with these Terms and Conditions of Use may be disallowed or rendered void at our or the relevant Merchant’s discretion. Redemption will be made only on basis of the prescription, which will be verified by the patient.</p>
            <p>4.4 <i>Responsibility for Redemptions of perishable products</i>: Merchant shall be responsible to ensure that any of its products for Redemption that are perishable has not expired.</p>
            <p>4.5 <i>Restrictions</i>: (a) Reproduction, sale, resale or trading of any products or Redeemed products is prohibited. (b) If any product is Redeemed for less than its face value, there is no entitlement to a credit or cash equal to the difference between the face value and the amount Redeemed. (c) Redemption of products is subject to availability of the relevant Merchants’ stocks.</p>
            <p>4.6 <i>Click Services is Not Liable</i>: For the avoidance of doubt, Click Services shall not be liable for any losses or damages suffered by you resulting from a failure by the relevant Merchant to fulfil any Redemptions in accordance with Clause 4.4 or for a failure by us to deliver any products to you due to the unavailability of such products pursuant to Clause 4.5(c).</p>
            <p>4.7 <i>Lost/stolen Samples</i>: Neither we nor Merchant shall be responsible for lost or stolen products that have been Redeemed.</p>
            <b><li>YOUR OBLIGATIONS</li></b>
            <p>5.1 <i>Merchant terms</i>: You agree to (and shall) abide by the terms and conditions of the relevant Merchant for which your Redemption relates to, as may be amended from time to time.</p>
            <p>5.2 <i>Accurate information</i>: You warrant that all information provided on Registration and contained as part of your Account is true, complete and accurate and that you will promptly inform us of any changes to such information by updating the information in your Account.</p>
            <p>5.3 <i>Content on the Mobile Application and Service</i>: It is your responsibility to ensure that any products or information available through the Mobile Application or the Services meet your specific requirements before making any Redemption.</p>
            <p>5.4 <i>Prohibitions in relation to usage of Services or Mobile Application</i>: Without limitation, you undertake not to use or permit anyone else to use the Services or Mobile Application:-</p>
            <p>5.4.1 to send or receive any material which is not civil or tasteful;</p>
            <p>5.4.2 to send or receive any material which is threatening, grossly offensive, of an indecent, obscene or menacing character, blasphemous or defamatory of any person, in contempt of court or in breach of confidence, copyright, rights of personality, publicity or privacy or any other third-party rights;</p>
            <p>5.4.3 to send or receive any material for which you have not obtained all necessary licences and/or approvals (from us or third parties); or which constitutes or encourages conduct that would be considered a criminal offence, give rise to civil liability, or otherwise be contrary to the law of or infringe the rights of any third party in any country in the world;</p>
            <p>5.4.4 to send or receive any material which is technically harmful (including computer viruses, logic bombs, Trojan horses, worms, harmful components, corrupted data or other malicious software or harmful data);</p>
            <p>5.4.5 to cause annoyance, inconvenience or needless anxiety;</p>
            <p>5.4.6 to intercept or attempt to intercept any communications transmitted by way of a telecommunications system;</p>
            <p>5.4.7 for a purpose other than which we have designed them or intended them to be used;</p>
            <p>5.4.8 for any fraudulent purpose;</p>
            <p>5.4.9 other than in conformance with accepted Internet practices and practices of any connected networks;</p>
            <p>5.4.10 in any way which is calculated to incite hatred against any ethnic, religious or any other minority or is otherwise calculated to adversely affect any individual, group or entity; or</p>
            <p>5.4.11 in such a way as to, or commit any act that would or does, impose an unreasonable or disproportionately large load on our infrastructure.</p>
            <p>5.5 <i>Prohibitions in relation to usage of Services, Mobile Application</i>: Without limitation, you further undertake not to or permit anyone else to:-</p>
            <p>5.5.1 resell any Redeemed products;</p>
            <p>5.5.2 furnish false data including false names, addresses and contact details and fraudulently use credit/debit card numbers;</p>
            <p>5.5.3 attempt to circumvent our security or network including to access data not intended for you, log into a server or account you are not expressly authorised to access, or probe the security of other networks (such as running a port scan);</p>
            <p>5.5.4 execute any form of network monitoring which will intercept data not intended for you;</p>
            <p>5.5.5 enter into fraudulent interactions or transactions with us or a Merchant (including interacting or transacting purportedly on behalf of a third party where you have no authority to bind that third party or you are pretending to be a third party);</p>
            <p>5.5.6 extract data from or hack into the Mobile Application;</p>
            <p>5.5.7 use the Services or Mobile Application in breach of these Terms and Conditions of Use;</p>
            <p>5.5.8 engage in any unlawful activity in connection with the use of the Mobile Application or the Services; or</p>
            <p>5.5.9 engage in any conduct which, in our exclusive reasonable opinion, restricts or inhibits any other customer from properly using or enjoying the Mobile Application or Services.</p>
            <b><li>RULES ABOUT USE OF THE SERVICE AND THE MOBILE APPLICATION</li></b>
            <p>6.1 We will use reasonable endeavours to correct any errors or omissions as soon as practicable after being notified of them. However, we do not guarantee that the Services or the Mobile Application will be free of faults, and we do not accept liability for any such faults, errors or omissions. In the event of any such error, fault or omission, you should report it by contacting us at +603 9213 0053.</p>
            <p>6.2 We do not warrant that your use of the Services or the Mobile Application will be uninterrupted and we do not warrant that any information (or messages) transmitted via the Services or the Mobile Application will be transmitted accurately, reliably, in a timely manner or at all. Notwithstanding that we will try to allow uninterrupted access to the Services and the Mobile Application, access to the Services and the Mobile Application may be suspended, restricted or terminated at any time.</p>
            <p>6.3 We do not give any warranty that the Services and the Mobile Application are free from viruses or anything else which may have a harmful effect on any technology.</p>
            <p>6.4 We reserve the right to change, modify, substitute, suspend or remove without notice any information or Services on the Mobile Application from time to time. Your access to the Mobile Application and/or the Services may also be occasionally restricted to allow for repairs, maintenance or the introduction of new facilities or services. We will attempt to restore such access as soon as we reasonably can. For the avoidance of doubt, we reserve the right to withdraw any information or Services from the Mobile Application at any time.</p>
            <p>6.5 We reserve the right to block access to and/or to edit or remove any material which in our reasonable opinion may give rise to a breach of these Terms and Conditions of Use.</p>
            <b><li>SUSPENSION AND TERMINATION</li></b>
            <p>7.1 If you use (or anyone other than you, with your permission uses) the Mobile Application, any Services in contravention of these Terms and Conditions of Use, we may suspend your use of the Services and/or Mobile Application.</p>
            <p>7.2 If we suspend the Services or Mobile Application, we may refuse to restore the Services or Mobile Application for your use until we receive an assurance from you, in a form we deem acceptable, that there will be no further breach of the provisions of these Terms and Conditions of Use.</p>
            <p>7.3 Without limitation to anything else in this Clause 8, we shall be entitled immediately or at any time (in whole or in part) to: (a) suspend the Services and/or Mobile Application; (b) suspend your use of the Services and/or Mobile Application; and/or (c) suspend the use of the Services and/or Mobile Application for persons we believe to be connected (in whatever manner) to you, if:</p>
            <p>7.3.1 you commit any breach of these Terms and Conditions of Use;</p>
            <p>7.3.2 we suspect, on reasonable grounds, that you have, might or will commit a breach of these Terms and Conditions of Use; or</p>
            <p>7.3.3 we suspect, on reasonable grounds, that you may have committed or be committing any fraud against us or any person.</p>
            <p>7.3.4 Our rights under this Clause 8 shall not prejudice any other right or remedy we may have in respect of any breach or any rights, obligations or liabilities accrued prior to termination.</p>
            <b><li>DISCLAIMER AND EXCLUSION OF LIABILITY</li></b>
            <p>8.1 The Mobile Application, the Services, the information on the Mobile Application and use of all related facilities are provided on an "as is, as available" basis without any warranties whether express or implied.</p>
            <p>8.2 To the fullest extent permitted by applicable law, we disclaim all representations and warranties relating to the Mobile Application and its contents, including in relation to any inaccuracies or omissions in the Mobile Application, warranties of merchantability, quality, fitness for a particular purpose, accuracy, availability, non-infringement or implied warranties from course of dealing or usage of trade.</p>
            <p>8.3 We do not warrant that the Mobile Application will always be accessible, uninterrupted, timely, secure, error free or free from computer virus or other invasive or damaging code or that the Mobile Application will not be affected by any acts of God or other force majeure events, including inability to obtain or shortage of necessary materials, equipment facilities, power or telecommunications, lack of telecommunications equipment or facilities and failure of information technology or telecommunications equipment or facilities.</p>
            <p>8.4 While we may use reasonable efforts to include accurate and up-to-date information on the Mobile Application, we make no warranties or representations as to its accuracy, timeliness or completeness.</p>
            <p>8.5 We shall not be liable for any acts or omissions of any third parties howsoever caused, and for any direct, indirect, incidental, consequential or punitive damages, howsoever caused, resulting from or in connection with the Mobile Application and the Services offered in the Mobile Application, your access to, use of or inability to use the Mobile Application or the services offered in the Mobile Application, reliance on or downloading from the Mobile Application and/or services, or any delays, inaccuracies in the information or in its transmission including but not limited to damages for loss of business or profits, use, data or other intangible, even if we have been advised of the possibility of such damages.</p>
            <p>8.6 We shall not be liable in contract, tort (including negligence or breach of statutory duty) or otherwise howsoever and whatever the cause thereof, for any indirect, consequential, collateral, or incidental loss or damage suffered or incurred by you in connection with the Mobile Application and these Terms and Conditions of Use. For the purposes of these Terms and Conditions of Use, indirect or consequential loss or damage includes, without limitation, loss of revenue, profits, anticipated savings or business, loss of data or goodwill, loss of use or value of any equipment including software, claims of third parties, and all associated and incidental costs and expenses.</p>
            <p>8.7 The above exclusions and limitations apply only to the extent permitted by law. None of your statutory rights as a consumer that cannot be excluded or limited are affected.</p>
            <p>8.8 Notwithstanding our efforts to ensure that our system is secure, you acknowledge that all electronic data transfers are potentially susceptible to interception by others. We cannot, and do not, warrant that data transfers pursuant to the Mobile Application, or electronic mail transmitted to and from us, will not be monitored or read by others.</p>
            <b><li>INDEMNITY</li></b>
            <p>You agree to indemnify and keep us indemnified against any claim, action, suit or proceeding brought or threatened to be brought against us which is caused by or arising out of (a) your use of the Services, (b) any other party’s use of the Services using your user ID, verification PIN and/or any identifier number allocated by Click Services, and/or (c) your breach of any of these Terms and Conditions of Use, and to pay us damages, costs and interest in connection with such claim, action, suit or proceeding.</p>
            <b><li>INTELLECTUAL PROPERTY RIGHTS</li></b>
            <p>10.1 All editorial content, information, photographs, illustrations, artwork and other graphic materials, and names, logos and trade marks on the Mobile Application are protected by copyright laws and/or other laws and/or international treaties, and belong to us and/or our suppliers, as the case may be. These works, logos, graphics, sounds or images may not be copied, reproduced, retransmitted, distributed, disseminated, sold, published, broadcasted or circulated whether in whole or in part, unless expressly permitted by us and/or our suppliers, as the case may be.</p>
            <p>10.2 Nothing contained on the Mobile Application should be construed as granting by implication, estoppel, or otherwise, any license or right to use any trademark displayed on the Mobile Application without our written permission. Misuse of any trademarks or any other content displayed on the Mobile Application is prohibited.</p>
            <p>10.3 We will not hesitate to take legal action against any unauthorised usage of our trade marks, name or symbols to preserve and protect its rights in the matter. All rights not expressly granted herein are reserved. Other product and company names mentioned herein may also be the trade marks of their respective owners.</p>
            <b><li>AMENDMENTS</li></b>
            <p>11.1 We may periodically make changes to the contents of the Mobile Application, including to the descriptions and services advertised, at any time and without notice. We assume no liability or responsibility for any errors or omissions in the content of the Mobile Application.</p>
            <p>11.2 We reserve the right to amend these Terms and Conditions of Use from time to time without notice. The revised Terms and Conditions of Use will be posted on the Mobile Application and shall take effect from the date of such posting. Your continued use of the Mobile Application after the issuance of the amended Terms and Conditions of Use will constitute your agreement to the amended Terms and Conditions of Use.</p>
            <b><li>APPLICABLE LAW AND JURISDICTION</li></b>
            <p>12.1 The Mobile Application can be accessed from all countries around the world where the local technology permits. As each of these places have differing laws, by accessing the Mobile Application both you and we agree that the laws of Malaysia, without regard to the conflicts of laws principles thereof, will apply to all matters relating to the use of the Mobile Application.</p>
            <p>12.2 You accept and agree that both you and we shall submit to the exclusive jurisdiction of the courts of Malaysia in respect of any dispute arising out of and/or in connection with these Terms and Conditions of Use.</p>
            <b><li>PRIVACY POLICY</li></b>
            <p>13.1 Access to the Mobile Application and use of the Services offered on the Mobile Application by Click Services and/or its group of companies is subject to this Privacy Policy. By accessing the Mobile Application and by continuing to use the Services offered, you are deemed to have accepted this Privacy Policy, and in particular, you are deemed to have consented to our collection, use and disclosure of your personal information in the manner prescribed in this Privacy Policy and for the purposes set out under these Terms and Conditions of Use. We reserve the right to amend this Privacy Policy from time to time. If you disagree with any part of this Privacy Policy, you must immediately discontinue your access to the Mobile Application and your use of the Services. “<b>Personal information</b>” is any information relating to you, which can be used to personally identify you, either directly or indirectly.</p>
            <p>13.2 We process personal information that you provide to us including when you fill in our Registration forms, or by corresponding with or contacting us.</p>
            <p>13.3 The personal information we collect may include (a) your name and Identification Card (IC) details; (b) your contact information such as mailing address, billing address, mobile number and email address; (c) your payment and transaction information; and (d) your preferences and requirements that relate to our products and services.</p>
            <p>13.4 As part of the normal operation of our Services, we collect, use and, in some cases, disclose information about you to the following third parties located within or outside Malaysia for the purposes described above, which includes our affiliates, customers, business partners, service providers, regulators and judicial authorities. Accordingly, we have developed this Privacy Policy in order for you to understand how we collect, use, communicate and disclose and make use of your personal information when you use the Services on the Mobile Application:-</p>
            <ol type="a">
              <li>Before or at the time of collecting personal information, we will identify the purposes for which information is being collected.</li>
              <li>We will collect and use of personal information solely with the objective of fulfilling those purposes specified by us and for other compatible purposes, unless we obtain the consent of the individual concerned or as required by law.</li>
              <li>We will only retain personal information as long as necessary for the fulfillment of those purposes.</li>
              <li>We will collect personal information by lawful and fair means and, where appropriate, with the knowledge or consent of the individual concerned.</li>
              <li>Personal information should be relevant to the purposes for which it is to be used, and, to the extent necessary for those purposes, should be accurate, complete, and up-to-date.</li>
              <li>We will protect personal information by reasonable security safeguards against loss or theft, as well as unauthorized access, disclosure, copying, use or modification.</li>
            </ol>
            <p>13.5 You are not obliged to provide us with your personal information; however, we may not be able to provide you with certain products/services in the absence of such personal information.</p>
            <p>13.6 You have the right to request access and/or to correct and limit the processing of your personal information. It is important that the personal information we hold about you is accurate and current. Please keep us informed if your personal information changes during our relationship with you. Any of the above requests, as well as inquiries or complaints with respect to your personal data, should be directed to our customer service executive at +601154235382 or via email at helpdesk@icareconnectplus.com.my</p>
          </ol>
        </div>
        
      </div>
      <form onSubmit={handleSubmit}>
        <div className="hcp-agree">
          <Checkbox color="default" required disabled={disableButton} />
          <p className="hcp-agree-text">I agree with the privacy policy.</p>
        </div>
        <div className="hcp-button-agree">
          <div className="button-container">
            <button type="submit" className="btn form-control hcp-signup-button py-3" style={{ marginBottom: "1rem" }} disabled={disableButton}>
              <b>Continue</b>
            </button>
            <p className="text-center">Please scroll down to the end to continue</p>
          </div>
        </div>
      </form>
    </div>
  );
}


export default HcpPrivacyPolicy;
