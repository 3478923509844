import React from "react";
import hcpWelcomeLogo from "../assets/image/hcpWelcomeLogo.png";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const PatientWelcome = () => {

  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div>
      <div className="patient-welcome-wholepage">
        <img src={hcpWelcomeLogo} alt="welcomeLogo" className="hcp-welcome-logo" />
        <p className="hcp-welcome-title"><b>{t("Welcome")}</b></p>
        <p className="patient-welcome-subtitle"><b>{t("FOR PATIENT ONLY")}</b></p>
      </div>

      <div className="patient-welcome-background">
        <div className="button-container">
          <button className="btn form-control patient-signup-button py-3" onClick={() => navigate("/patient/patientSignUpRequestOtp")}>
            <b>{t("SIGN UP")}</b>
          </button>
        </div>
        <div className="button-container">
          <button className="btn form-control patient-login-button py-3" onClick={() => navigate("/patient/patientLogin")}>
            <b>{t("LOGIN")}</b>
          </button>
        </div>
        <p className="text-center" onClick={() => navigate('/patient/patientTermsAndCondition', { state: { showButton: false } })}><b>{t("T&C and Personal Data Protection Act (PDPA)")}</b></p>
      </div>
    </div>
  );
}

export default PatientWelcome;
