import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import { updatePatientLanguage } from "../redux/actions/patientActions";
import { useTranslation } from 'react-i18next';

// images
import android1 from '../assets/image/tutorial/addToHomeScreen/android/tutorial-android_step1.png';
import android2 from '../assets/image/tutorial/addToHomeScreen/android/tutorial-android_step2.png';
import android3 from '../assets/image/tutorial/addToHomeScreen/android/tutorial-android_step3.png';
import ios1 from '../assets/image/tutorial/addToHomeScreen/ios/tutorial-IOS_step1.png';
import ios2 from '../assets/image/tutorial/addToHomeScreen/ios/tutorial-IOS_step2.png';
import ios3 from '../assets/image/tutorial/addToHomeScreen/ios/tutorial-IOS_step3.png';

const PatientAddHomeScreenTutorial = ({ language, updatePatientLanguage }) => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    const [phoneType, setPhoneType] = useState("Android");

    return (
        <div className="patient-login-page">
            <div style={{ height: "5vh" }}></div>
            <div className="patient-login-container">
                <div className="d-flex justify-content-between">
                    <div></div>
                    <div className="hcp-signup-lang">
                        <p className={language === "en" ? "hcp-signup-lang-border px-2" : "px-2"} onClick={() => updatePatientLanguage("en")}>ENG</p>
                        <p className={language === "bm" ? "hcp-signup-lang-border px-2" : "px-2"} onClick={() => updatePatientLanguage("bm")}>BM</p>
                        <p className={language === "ch" ? "hcp-signup-lang-border px-2" : "px-2"} onClick={() => updatePatientLanguage("ch")}>MAN</p>
                    </div>
                </div>

                <div>
                    <h2 className="hcp-signup-title">{t("Save the webpage before you begin your Journey.")}</h2>
                    <p className="pt-2">{t("Choose your phone")}</p>
                    <div className="d-flex">
                        <div className="container" onClick={e => setPhoneType("Android")}>
                            <p className={phoneType === "Android" ? "patient-selected" : "patient-non-selected"}>{t("Android")}</p>
                        </div>
                        <div className="container" onClick={e => setPhoneType("IOS")}>
                            <p className={phoneType === "IOS" ? "patient-selected" : "patient-non-selected"}>IOS</p>
                        </div>
                    </div>
                </div>

                <div className="pt-2">
                    {
                        phoneType === "Android" ? (
                            <div style={{ fontSize: "18px" }}>
                                <p><b>{t("STEP 1")}</b></p>
                                <p><b>{t("Tap the menu icon (3 dots in upper right-hand corner).")}</b></p>
                                <img src={android1} alt="android1" className="img-fluid" style={{ borderRadius: "15px" }} />
                                <p className="pt-3"><b>{t("STEP 2")}</b></p>
                                <p><b>{t('Tap "Add to Home Screen".')}</b></p>
                                <img src={android2} alt="android2" className="img-fluid" style={{ borderRadius: "15px" }} />
                                <p className="pt-3"><b>{t("STEP 3")}</b></p>
                                <p><b>{t('Rename the website then tap "Add".')}</b></p>
                                <img src={android3} alt="android3" className="img-fluid" style={{ borderRadius: "15px" }} />
                                <p className="pt-3"><b>{t("STEP 4")}</b></p>
                                <p><b>{t("Done.")}</b></p>
                            </div>
                        ) : (
                            <div style={{ fontSize: "18px" }}>
                                <p><b>{t("STEP 1")}</b></p>
                                <p><b>{t("Tap the Share icon (Safari).")}</b></p>
                                <img src={ios1} alt="ios1" className="img-fluid" style={{ borderRadius: "15px" }} />
                                <p className="pt-3"><b>{t("STEP 2")}</b></p>
                                <p><b>{t('Tap "Add to Home Screen".')}</b></p>
                                <img src={ios2} alt="ios2" className="img-fluid" style={{ borderRadius: "15px" }} />
                                <p className="pt-3"><b>{t("STEP 3")}</b></p>
                                <p><b>{t('Rename the website then tap "Add".')}</b></p>
                                <img src={ios3} alt="ios3" className="img-fluid" style={{ borderRadius: "15px" }} />
                                <p className="pt-3"><b>{t("STEP 4")}</b></p>
                                <p><b>{t("Done.")}</b></p>
                            </div>
                        )
                    }
                </div>

                <div className="button-container">
                    <button className="btn form-control patient-signup-button py-3" onClick={() => navigate('/patient/patientStartTutorial')}>
                        <b>{t("OK")}</b>
                    </button>
                </div>

            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        language: state.patient.language
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updatePatientLanguage: data => dispatch(updatePatientLanguage(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientAddHomeScreenTutorial);
