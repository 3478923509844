import { PATIENT_LOGIN, UPDATE_PATIENT_LANGUAGE, UPDATE_PATIENT_REMEMBER_ME, PATIENT_REGISTER_POPUP, PATIENT_CHECK_TOKEN, HOMEPAGE_NAV, PATIENT_LOGOUT } from "../actions/type";
import i18n from "../../i18n";

const initState = {
    token: localStorage.getItem("patient-token"),
    language: localStorage.getItem("patient-language") || 'en',
    rememberme: localStorage.getItem("patient-remeber-me") === 'true' || false,
    patient: null,
    registerPopup: false,
    homepageNav: "pending",
};

const patientReducer = (state = initState, action) => {
    switch (action.type) {
        case PATIENT_LOGIN:
            localStorage.setItem("patient-token", action.payload.token);
            return {
                ...state,
                token: action.payload.token
            };
        case PATIENT_LOGOUT:
            localStorage.removeItem("patient-token");
            return {
                ...state,
                token: null,
                patient: null
            };
        case UPDATE_PATIENT_LANGUAGE:
            localStorage.setItem("patient-language", action.payload);
            i18n.changeLanguage(action.payload);
            return {
                ...state,
                language: action.payload
            };
        case UPDATE_PATIENT_REMEMBER_ME:
            localStorage.setItem("patient-remeber-me", action.payload);
            return {
                ...state,
                rememberme: action.payload
            };
        case PATIENT_REGISTER_POPUP:
            return {
                ...state,
                registerPopup: action.payload
            };
        case PATIENT_CHECK_TOKEN:
            return {
                ...state,
                patient: action.payload
            };
        case HOMEPAGE_NAV:
            return {
                ...state,
                homepageNav: action.payload
            };
        default:
            return state;
    }
};

export default patientReducer;