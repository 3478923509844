import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import '../../assets/custom.css';

function CountryPhone(props) {
    return (
        <PhoneInput country={"my"} inputClass="phoneCustomCss" buttonClass="buttonCustomCss"
            name="number" value={props.value} onChange={props.onChange} enableSearch={true} countryCodeEditable={false} id="number" 
                    isValid={props.isValid}/>
    )
}
export default CountryPhone;