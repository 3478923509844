import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Box } from "@mui/material";
import { isMobile } from 'react-device-detect';

// images
import hcpMobile5En from "../assets/image/tutorial/hcp/mobile/hcpTutorial5-en.png";
import hcpDesktop5En from "../assets/image/tutorial/hcp/desktop/hcpTutorial5-en.png";
import tutorialArrow from "../assets/image/tutorialArrow.png";

const HcpTutorial5 = () => {

    const navigate = useNavigate();

    const [openTutorial, setOpenTutorial] = useState(true);

    return (
        <div>
            {
                isMobile ? (
                    <img src={hcpMobile5En} alt="hcpTutorial" style={{ height: "100vh", width: "100vw" }} />
                ) : (
                    <img src={hcpDesktop5En} alt="hcpTutorial" style={{ height: "100vh", width: "100vw" }} />
                )
            }
            <Modal
                open={openTutorial}
                onClose={() => setOpenTutorial(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                hideBackdrop={true}
            >
                <Box className="tutorial-modal-4">
                    <p style={{ fontSize: "18px" }}>Check product credit balance in your account</p>
                    <img src={tutorialArrow} alt="arrow" style={{ float: "right" }} onClick={() => navigate('/hcp/home')} />
                </Box>
            </Modal>
        </div>
    );
};

export default HcpTutorial5;
