import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { MDBDataTableV5 } from 'mdbreact';
import { CircularProgress, Drawer } from "@mui/material";
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { CSVLink } from "react-csv";
import { styled } from '@mui/material/styles';
import DatePicker from "react-datepicker";

// components
import Sidebar from "./components/sidebar";
import Header from "./components/header";

const mySwal = withReactContent(Swal);

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-20%`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
            maxWidth: "80%"
        }),
    }),
);

const HcpPerformances = ({ role, token }) => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [openSidebar, setOpenSidebar] = useState(true);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    useEffect(() => {
        if (role && token) {
            if (role === "dev") {
                axios.post("/api/admin/hcpPerformances", { role, token })
                    .then(res => {
                        setData(res.data.data);
                        setLoading(false);
                    })
                    .catch(err => {
                        setLoading(false);
                        if (err.response.data.error === "Token Expired") {
                            mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
                                .then(click => {
                                    if (click.isConfirmed) {
                                        navigate('/admin/login');
                                    }
                                })
                        } else {
                            mySwal.fire("Error", err.response.data.error, "error")
                        }
                    });
            } else {
                mySwal.fire({ title: "Error", text: "Unauthorized Access", icon: "error", allowOutsideClick: false })
                    .then(click => {
                        if (click.isConfirmed) {
                            navigate('/admin/login');
                        }
                    })
            }
        } else {
            navigate('/admin/login');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDate = dates => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    const handleFilter = () => {
        setLoading(true);

        let sendThis = {
            role,
            token
        };

        if (startDate && endDate) {
            sendThis.startDate = new Date(startDate).getFullYear() + "-" + (new Date(startDate).getMonth() + 1) + "-" + new Date(startDate).getDate()
            sendThis.endDate = new Date(endDate).getFullYear() + "-" + (new Date(endDate).getMonth() + 1) + "-" + new Date(endDate).getDate()
        }

        axios.post("/api/admin/hcpPerformances", sendThis)
            .then(res => {
                setData(res.data.data);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                if (err.response.data.error === "Token Expired") {
                    mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
                        .then(click => {
                            if (click.isConfirmed) {
                                navigate('/admin/login');
                            }
                        })
                } else {
                    mySwal.fire("Error", err.response.data.error, "error")
                }
            });
    }

    const handleReset = () => {
        setStartDate("");
        setEndDate("");
        setLoading(true);

        axios.post("/api/admin/hcpPerformances", { role, token })
            .then(res => {
                setData(res.data.data);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                if (err.response.data.error === "Token Expired") {
                    mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
                        .then(click => {
                            if (click.isConfirmed) {
                                navigate('/admin/login');
                            }
                        })
                } else {
                    mySwal.fire("Error", err.response.data.error, "error")
                }
            });
    }

    let tableData = {
        columns: [
            {
                field: "hcpEnrollDate",
                label: "Program Start Date",
                sort: "asc"
            },
            {
                field: "hcpType",
                label: "Hospital / Clinic",
                sort: "asc"
            },
            {
                field: "hcpName",
                label: "Customer Name (appear in site)",
                sort: "asc"
            },
            {
                field: "hcpPic",
                label: "HCP PIC",
                sort: "asc"
            },
            {
                field: "purchaseName",
                label: "ZP Purchase Account Name (Sales Data)",
                sort: "asc"
            },
            {
                field: "soldToCode",
                label: "Redeem / Sold-To Code",
                sort: "asc"
            },
            {
                field: "noOfRecruitment",
                label: "No of Recruitment",
                sort: "asc"
            },
            {
                field: "noOfSubmission",
                label: "No of Submission",
                sort: "asc"
            },
            {
                field: "totalBoxesSubmitted",
                label: "Total Boxes Submitted",
                sort: "asc"
            },
            {
                field: "totalPendingRedemption",
                label: "Total Pending Redemption",
                sort: "asc"
            },
            {
                field: "totalCompletedRedemption",
                label: "Total Completed Redemption",
                sort: "asc"
            },
            {
                field: "lastSubmissionDate",
                label: "Last Submission Date",
                sort: "asc"
            },
            {
                field: "lastRedemptionDate",
                label: "Last Redemption Date",
                sort: "asc"
            },
            {
                field: "firstTimeLogin",
                label: "Active/Inactive (First time login done or not)",
                sort: "asc"
            },
        ],
        rows: data ? data : []
    };

    const csvData = {
        headers: [
            { label: "Program Start Date", key: "hcpEnrollDate" },
            { label: "Hospital / Clinic", key: "hcpType" },
            { label: "Customer Name (appear in site)", key: "hcpName" },
            { label: "HCP PIC", key: "hcpPic" },
            { label: "ZP Purchase Account Name (Sales Data)", key: "purchaseName" },
            { label: "Redeem / Sold-To Code", key: "soldToCode" },
            { label: "No of Recruitment", key: "noOfRecruitment" },
            { label: "No of Submission", key: "noOfSubmission" },
            { label: "Total Boxes Submitted", key: "totalBoxesSubmitted" },
            { label: "Total Pending Redemption", key: "totalPendingRedemption" },
            { label: "Total Completed Redemption", key: "totalCompletedRedemption" },
            { label: "Last Submission Date", key: "lastSubmissionDate" },
            { label: "Last Redemption Date", key: "lastRedemptionDate" },
            { label: "Active/Inactive (First time login done or not)", key: "firstTimeLogin" }
        ],
        data
    }

    return (
        <div className="d-flex">

            <Drawer
                sx={{
                    minWidth: "20%",
                    '& .MuiDrawer-paper': {
                        minWidth: "20%",
                        boxSizing: 'border-box',
                        minHeight: "100vh",
                        backgroundColor: "#FE7072",
                    },
                }}
                anchor="left"
                open={openSidebar}
                variant="persistent">
                <Sidebar />
            </Drawer>

            <Main open={openSidebar}>
                <div className="mt-3 px-3">
                    <Header title="HCP PERFORMANCES" openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />

                    <div className="report-header mt-3 d-flex py-3 px-3 justify-content-end">
                        <div>
                            <DatePicker placeholderText="Date" value={startDate ? `${startDate ? new Date(startDate).toLocaleDateString('en-GB') : ""} - ${endDate ? new Date(endDate).toLocaleDateString('en-GB') : ""}` : ""} onChange={handleDate} startDate={startDate} endDate={endDate} selectsRange shouldCloseOnSelect={false} className="form-control py-3" />
                        </div>
                        <div className="ps-2">
                            <button type="button" className="btn btn-primary shadow-none admin-button" onClick={() => handleFilter()}>Filter</button>
                        </div>
                        <div className="ps-2">
                            <button type="button" className="btn btn-primary shadow-none admin-button" onClick={() => handleReset()}>Reset</button>
                        </div>
                        <div className="ps-2">
                            <CSVLink className="btn btn-primary shadow-none admin-button" data={csvData.data} headers={csvData.headers} filename={`hcp_performances_${new Date().getFullYear()}_${new Date().getMonth() + 1}_${new Date().getDate()}.csv`} target="_blank">
                                Export
                            </CSVLink>
                        </div>
                    </div>

                    {
                        loading ? (
                            <div className="text-center">
                                <CircularProgress />
                            </div>
                        ) : (
                            <div>
                                <MDBDataTableV5 data={tableData} hover responsive bordered />
                            </div>
                        )
                    }
                </div>
            </Main>

        </div>
    );
}

const mapStateToProps = state => {
    return {
        token: state.admin.token,
        role: state.admin.role
    };
};

export default connect(mapStateToProps, null)(HcpPerformances);
