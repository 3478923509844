import { combineReducers } from 'redux';
import hcpReducer from './hcpReducer';
import patientReducer from './patientReducer';
import adminReducer from './adminReducer';

const rootReducer = combineReducers({
    hcp: hcpReducer,
    patient: patientReducer,
    admin: adminReducer,
});

export default rootReducer;