import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { InputLabel, Select, MenuItem, FormControl, CircularProgress } from "@mui/material";
import { connect } from 'react-redux';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { performanceBySku } from "../../redux/actions/adminActions";
import { medicationBrandList } from "../../dynamicController";
import { CSVLink } from "react-csv";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

const mySwal = withReactContent(Swal);

const PerformanceBySku = ({ role, token, performanceBySku, performanceBySkuData }) => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [brand, setBrand] = useState("Lipitor");
    const [brandColor, setBrandColor] = useState("Lipitor");

    useEffect(() => {

        if (role && token) {
            if (!performanceBySkuData) {
                axios.post("/api/admin/performanceBySku", { role, token, brand: "Lipitor" })
                    .then(res => {
                        let data = {}
                        data.data = res.data.data;
                        data.brand = "Lipitor";
                        performanceBySku(data);
                        setLoading(false);
                    })
                    .catch(err => {
                        setLoading(false);
                        if (err.response.data.error === "Token Expired") {
                            mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
                                .then(click => {
                                    if (click.isConfirmed) {
                                        navigate('/admin/login');
                                    }
                                })
                        } else {
                            mySwal.fire("Error", err.response.data.error, "error")
                        }
                    });
            } else {
                setBrand(performanceBySkuData.brand);
                setBrandColor(performanceBySkuData.brand);
                setLoading(false);
            }
        } else {
            navigate('/admin/login');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFilter = () => {
        setLoading(true);

        axios.post('/api/admin/performanceBySku', { role, token, brand })
            .then(res => {
                let data = {}
                data.data = res.data.data;
                data.brand = brand;
                performanceBySku(data);
                setBrandColor(brand);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                if (err.response.data.error === "Token Expired") {
                    mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
                        .then(click => {
                            if (click.isConfirmed) {
                                navigate('/admin/login');
                            }
                        })
                } else {
                    mySwal.fire("Error", err.response.data.error, "error")
                }
            });
    }

    const graphColor = () => {
        if (brandColor === "Lipitor") {
            return "#01718D"
        } else if (brandColor === "Norvasc") {
            return "#3A17D0"
        } else if (brandColor === "Caduet") {
            return "#C0007E"
        } else if (brandColor === "Viagra") {
            return "#0082F4"
        } else if (brandColor === "Legalon") {
            return "#29AF4B"
        } else if (brandColor === "Viartril-S Sachets") {
            return "#EE1B24"
        } else if (brandColor === "Viartril-S Capsules") {
            return "#EE1B24"
        } else if (brandColor === "Celebrex Capsules") {
            return "#AD2B4A"
        } else if (brandColor === "Lyrica Capsules") {
            return "#1F1C6B"
        } else {
            return "#009EC9"
        }
    }

    // const handleReset = () => {
    //     setBrand("Lipitor");
    //     setLoading(true);

    //     axios.post('/api/admin/performanceBySku', { role, token, brand: "Lipitor" })
    //         .then(res => {
    //             let data = {}
    //             data.data = res.data.data;
    //             data.brand = "Lipitor";
    //             performanceBySku(data);
    //             setLoading(false);
    //         })
    //         .catch(err => {
    //             setLoading(false);
    //             if (err.response.data.error === "Token Expired") {
    //                 mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
    //                     .then(click => {
    //                         if (click.isConfirmed) {
    //                             navigate('/admin/login');
    //                         }
    //                     })
    //             } else {
    //                 mySwal.fire("Error", err.response.data.error, "error")
    //             }
    //         });
    // }

    const options = {
        responsive: true,
        plugins: {
            tooltip: {
                enabled: false
            },
            legend: {
                position: 'top',
            },
            datalabels: {
                anchor: 'end',
                align: 'end',
                color: "black",
                padding: -9,
                font: {
                    lineHeight: 1.5
                },
                display: function (context) {
                    return context.dataset.data[context.dataIndex].y
                },
                formatter: function (value) {
                    return value.y
                }
            }
        }
    };

    let datasets = [];
    let label = [];
    let csv = [];

    const csvData = {
        headers: [
            { label: "SKU", key: "sku" },
            { label: "Submission Quantity", key: "quantity" },
        ],
        data: csv
    }

    if (performanceBySkuData) {

        for (let i = 0; i < performanceBySkuData.data.length; i++) {
            label.push(performanceBySkuData.data[i].sku);

            let defaultData = {};
            for (let j = 0; j < performanceBySkuData.data.length; j++) {
                defaultData[performanceBySkuData.data[j].sku] = {
                    x: performanceBySkuData.data[j].sku,
                    y: null
                }
            }

            defaultData[performanceBySkuData.data[i].sku].y = performanceBySkuData.data[i].quantity;

            let datasetObject = {
                label: performanceBySkuData.data[i].sku,
                skipNull: true,
                data: Object.values(defaultData),
                backgroundColor: graphColor(),
                borderRadius: 7,
                borderSkipped: false
            }

            datasets.push(datasetObject);

            const csvObject = {
                sku: performanceBySkuData.data[i].sku,
                quantity: performanceBySkuData.data[i].quantity
            }
            csv.push(csvObject);
        }
    }

    const data = {
        labels: label,
        datasets: datasets
    }

    return (
        <div>
            <div>
                <p className="admin-dashboard-subtitle"><b>PERFORMANCE BY SKU</b></p>
                <div>
                    <div className="d-flex justify-content-end">
                        <div>
                            <FormControl sx={{ minWidth: 120 }}>
                                <InputLabel id="brand">Brand</InputLabel>
                                <Select
                                    labelId="brand"
                                    id="brand"
                                    value={brand}
                                    label="Brand"
                                    onChange={e => setBrand(e.target.value)}
                                >
                                    {
                                        medicationBrandList.map((brand, i) => {
                                            return (<MenuItem key={i} value={brand.value}>{brand.brandName}</MenuItem>)
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end pt-2">
                        <div className="ps-2">
                            <button type="button" className="btn btn-primary shadow-none admin-button" onClick={() => handleFilter()}>Filter</button>
                        </div>
                        {/* <div className="ps-2">
                            <button type="button" className="btn btn-primary shadow-none admin-button" onClick={() => handleReset()}>Reset</button>
                        </div> */}
                    </div>
                </div>
            </div>

            <div>
                {
                    loading ? (
                        <div className="text-center">
                            <CircularProgress />
                        </div>
                    ) : (
                        <div>
                            <div className="admin-dashboard-border my-2">
                                <Bar options={options} data={data} />
                            </div>
                            <div className="d-flex justify-content-end mb-2">
                                <CSVLink className="btn btn-primary shadow-none" data={csvData.data} headers={csvData.headers} filename={`performance_by_sku_${new Date().getFullYear()}_${new Date().getMonth() + 1}_${new Date().getDate()}.csv`} target="_blank">
                                    Export Data
                                </CSVLink>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.admin.token,
        role: state.admin.role,
        performanceBySkuData: state.admin.performanceBySku
    };
};

const mapDispatchToProps = dispatch => {
    return {
        performanceBySku: data => dispatch(performanceBySku(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceBySku);