import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Accordion, AccordionSummary, AccordionDetails, Box, CircularProgress } from "@mui/material";
import { medicationImage } from "../dynamicController";
import { patientCheckToken } from "../redux/actions/patientActions";
import axios from 'axios';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

// image
import leftArrow from "../assets/image/left-arrow.png";
import plus from "../assets/image/plus.png";
import minimize from "../assets/image/minimize.png";

// component
import PatientNavigationBar from "./components/navigationBar";
import PatientHeader from "./components/header";

const PatientTransactionRules = ({ token, patient, patientCheckToken }) => {

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    setError("")
    setLoading(true);

    if (!patient) {
      if (token) {
        axios.post('/api/patient/checkToken', { token })
          .then(res => {
            patientCheckToken(res.data.data);
            axios.post('/api/patient/transactionHistory', { patientId: res.data.data.patient.id, startDate, endDate })
              .then(res => {
                setData(res.data.data);
                setLoading(false);
              })
              .catch(err => {
                setError(err.response.data.error);
                setLoading(false);
              });
          })
          .catch(err => {
            setError(err.response.data.error);
            setLoading(false);
          });
      } else {
        navigate("/patient/patientWelcome")
      }
    } else {
      axios.post('/api/patient/transactionHistory', { patientId: patient.patient.id, startDate, endDate })
        .then(res => {
          setData(res.data.data);
          setLoading(false);
        })
        .catch(err => {
          setError(err.response.data.error);
          setLoading(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      setError("")
      setLoading(true);

      axios.post('/api/patient/transactionHistory', { patientId: patient.patient.id, startDate, endDate })
        .then(res => {
          setData(res.data.data);
          setLoading(false);
        })
        .catch(err => {
          setError(err.response.data.error);
          setLoading(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const CustomExpandIcon = () => {
    return (
      <Box
        sx={{
          '.Mui-expanded & > .collapsIconWrapper': { display: 'none' },
          '.expandIconWrapper': { display: 'none' },
          '.Mui-expanded & > .expandIconWrapper': { display: 'block' },
        }}
      >
        <div className="expandIconWrapper">
          <img src={minimize} alt="minus" />
        </div>
        <div className="collapsIconWrapper">
          <img src={plus} alt="plus" />
        </div>
      </Box>
    )
  }

  const handleDate = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }

  const renderHistoryData = (data) => {
    let date = [];
    let groupedData = [];
    for (let i = 0; i < data.length; i++) {
      let tempDate = new Date(data[i].date).toLocaleDateString('en-GB');
      let tempHcpId = data[i].hcpId;
      if (date.filter(d => d.date === tempDate && d.hcpId === tempHcpId).length <= 0) {
        let dateObject = {
          date: tempDate,
          hcpId: tempHcpId
        }
        date.push(dateObject);
        let oneEntry = data.filter(d => new Date(d.date).toLocaleDateString('en-GB') === tempDate && d.hcpId === tempHcpId);
        let tempData = {
          date: tempDate,
          place: data[i].place,
          submitted: oneEntry.map(d => d.submitted).reduce((a, b) => a + b, 0),
          redeemed: oneEntry.map(d => d.redeemed).reduce((a, b) => a + b, 0)
        }
        groupedData.push(tempData);
      }
    }
    return (
      groupedData.map((h, index) => {
        return (
          <tr key={index}>
            <td>{h.date}</td>
            <td>{h.place}</td>
            <td>{h.submitted}</td>
            <td>{h.redeemed}</td>
          </tr>
        )
      })
    )
  }

  return (
    <div>
      <PatientHeader />
      <div className="hcp-whole-body">
        <div className="page-title mt-3">
          <img src={leftArrow} alt="back-icon" className="hcp-left-arrow" onClick={() => navigate('/patient/patientHome')} />
          <h2>{t("Transaction history")}</h2>
        </div>

        <div className="container pb-3 d-flex justify-content-end">
          <div className="ps-2">
            <DatePicker placeholderText={t("Select date")} value={startDate ? `${startDate ? new Date(startDate).toLocaleDateString('en-GB') : ""} - ${endDate ? new Date(endDate).toLocaleDateString('en-GB') : ""}` : ""} onChange={handleDate} startDate={startDate} endDate={endDate} selectsRange shouldCloseOnSelect={false} className="form-control select-input shadow-none" />
          </div>
        </div>

        {
          error ? (
            <div className="pt-3">
              <p style={{ color: "red", textAlign: "center" }}>{t(error)}</p>
            </div>
          ) : null
        }

        {
          loading ? (
            <div className="text-center">
              <CircularProgress />
            </div>
          ) : (
            <div>
              {
                data ? data.map((d, i) => {
                  return (
                    <Accordion className="hcp-transaction-history mb-3" key={i}>
                      <AccordionSummary expandIcon={<CustomExpandIcon />}>
                        <b>{d.medication} {t(d.sku)}</b>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div>
                          <div className="transaction-img-container pb-2" style={{ margin: "auto" }}>
                            <img src={medicationImage(d.medication, d.sku)} alt="lipitor" className="transaction-product-img" />
                          </div>
                          <div className="patient-transaction-history-text">
                            {t("Transaction History")}
                          </div>
                          <div className="patient-transaction-rules-padding">
                            <table className="patient-transaction-history-table">
                              <thead>
                                <tr>
                                  <td>
                                    <p className="patient-transaction-rules-table-header">
                                      {t("Date")}
                                    </p>
                                  </td>
                                  <td>
                                    <p className="patient-transaction-rules-table-header">
                                      {t("Place")}
                                    </p>
                                  </td>
                                  <td>
                                    <p className="patient-transaction-rules-table-header">
                                      {t("Submitted")}
                                    </p>
                                  </td>
                                  <td>
                                    <p className="patient-transaction-rules-table-header">
                                      {t("Redemeed")}
                                    </p>
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  renderHistoryData(d.history)
                                }
                              </tbody>
                            </table>
                          </div>
                          <div className="patient-transaction-rules-padding">
                            <table className="patient-transaction-rules-table-total">
                              <thead>
                                <tr>
                                  <td></td>
                                  <th>
                                    <p className="patient-transaction-rules-table-header">
                                      {t("Submitted")}
                                    </p>
                                  </th>
                                  <th>
                                    <p className="patient-transaction-rules-table-header">
                                      {t("Redeemed")}
                                    </p>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  d.hcp.map((h, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{h.place}</td>
                                        <td>{h.submitted}</td>
                                        <td>{h.redeemed}</td>
                                      </tr>
                                    )
                                  })
                                }
                              </tbody>
                            </table>

                            <table
                              className="patient-transaction-rules-table-total"
                              style={{ marginTop: "0.5rem", backgroundColor: "#C2EBFF" }}
                            >
                              <tbody>
                                <tr>
                                  <td className="patient-total-width">{t("TOTAL")}</td>
                                  <td className="patient-submitted-width">{d.hcp.map(h => h.submitted).reduce((a, b) => a + b, 0)}</td>
                                  <td>{d.hcp.map(h => h.redeemed).reduce((a, b) => a + b, 0)}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  )
                }) : null
              }
            </div>
          )
        }

      </div>
      <PatientNavigationBar />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    token: state.patient.token,
    patient: state.patient.patient
  };
};

const mapDispatchToProps = dispatch => {
  return {
    patientCheckToken: data => dispatch(patientCheckToken(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientTransactionRules);
