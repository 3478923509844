import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { InputLabel, Select, MenuItem, FormControl, Tooltip, IconButton, CircularProgress, Checkbox, TextField, Autocomplete } from "@mui/material";
import { InfoOutlined } from '@mui/icons-material';
import { connect } from 'react-redux';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { summaryDashboard } from "../../redux/actions/adminActions";
import { medicationSkuList } from "../../dynamicController";
import DatePicker from "react-datepicker";

const mySwal = withReactContent(Swal);

const SummaryDashboard = ({ role, token, summaryDashboard, dashboard, accountNameList, seNameList }) => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [hcpType, setHcpType] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [productList, setProductList] = useState([]);
    const [accountName, setAccountName] = useState([]);
    const [seName, setSeName] = useState([]);

    useEffect(() => {

        if (role && token) {
            if (!dashboard) {
                setLoading(true);
                axios.post("/api/admin/dashboard", { role, token, productList: [], accountName: [], seName: [] })
                    .then(res => {
                        let data = {};
                        data.data = res.data.dataObject;
                        data.startDate = "";
                        data.endDate = "";
                        data.productList = [];
                        data.hcpType = "";
                        data.accountName = [];
                        data.seName = [];
                        summaryDashboard(data);
                        setLoading(false);
                    })
                    .catch(err => {
                        setLoading(false);
                        if (err.response.data.error === "Token Expired") {
                            mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
                                .then(click => {
                                    if (click.isConfirmed) {
                                        navigate('/admin/login');
                                    }
                                })
                        } else {
                            mySwal.fire("Error", err.response.data.error, "error")
                        }
                    });
            } else {
                setStartDate(dashboard.startDate);
                setEndDate(dashboard.endDate);
                setProductList(dashboard.productList);
                setHcpType(dashboard.hcpType);
                setAccountName(dashboard.accountName);
                setSeName(dashboard.seName);
            }
        } else {
            navigate('/admin/login');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const numberWithCommas = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const handleDate = dates => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    const handleFilter = () => {
        setLoading(true);

        let sendThis = {
            role,
            token,
            productList,
            hcpType,
            accountName,
            seName
        }

        if (startDate && endDate) {
            sendThis.startDate = new Date(startDate).getFullYear() + "-" + (new Date(startDate).getMonth() + 1) + "-" + new Date(startDate).getDate()
            sendThis.endDate = new Date(endDate).getFullYear() + "-" + (new Date(endDate).getMonth() + 1) + "-" + new Date(endDate).getDate()
        }

        axios.post('/api/admin/dashboard', sendThis)
            .then(res => {
                let data = {};
                data.data = res.data.dataObject;
                data.startDate = startDate;
                data.endDate = endDate;
                data.productList = productList;
                data.hcpType = hcpType;
                data.accountName = accountName;
                data.seName = seName;
                summaryDashboard(data);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                if (err.response.data.error === "Token Expired") {
                    mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
                        .then(click => {
                            if (click.isConfirmed) {
                                navigate('/admin/login');
                            }
                        })
                } else {
                    mySwal.fire("Error", err.response.data.error, "error")
                }
            });
    }

    const handleReset = () => {
        setStartDate("");
        setEndDate("");
        setProductList([]);
        setHcpType("");
        setAccountName([]);
        setSeName([]);
        setLoading(true);

        axios.post('/api/admin/dashboard', { role, token, productList: [], accountName: [], seName: [] })
            .then(res => {
                let data = {};
                data.data = res.data.dataObject;
                data.startDate = "";
                data.endDate = "";
                data.productList = [];
                data.hcpType = "";
                data.accountName = [];
                data.seName = [];
                summaryDashboard(data);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                if (err.response.data.error === "Token Expired") {
                    mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
                        .then(click => {
                            if (click.isConfirmed) {
                                navigate('/admin/login');
                            }
                        })
                } else {
                    mySwal.fire("Error", err.response.data.error, "error")
                }
            });
    }

    return (
        <div className="admin-dashboard-border">
            <div className="d-flex justify-content-end">
                {
                    role !== "hcp" ? (
                        <div>
                            <Autocomplete
                                multiple
                                id="seName"
                                options={seNameList}
                                disableCloseOnSelect
                                value={seName}
                                onChange={(e, value) => {
                                    setSeName(value);
                                }}
                                renderOption={(props, seNameList, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            checked={selected}
                                        />
                                        {seNameList}
                                    </li>
                                )}
                                style={{ width: 250, backgroundColor: "white" }}
                                renderInput={(params) => (
                                    <TextField {...params} label="SE Name" />
                                )}
                            />
                        </div>
                    ) : null
                }
                {
                    role !== "hcp" ? (
                        <div className="ps-2">
                            <FormControl sx={{ minWidth: 120 }}>
                                <InputLabel id="hcp-type">HCP Type</InputLabel>
                                <Select
                                    labelId="hcp-type"
                                    id="hcp-type"
                                    value={hcpType}
                                    label="HCP Type"
                                    onChange={e => setHcpType(e.target.value)}
                                >
                                    <MenuItem value="Hospital">Hospital</MenuItem>
                                    <MenuItem value="Clinic">Clinic</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    ) : null
                }
                {
                    role !== "hcp" ? (
                        <div className="ps-2">
                            <Autocomplete
                                multiple
                                id="accountName"
                                options={accountNameList}
                                getOptionLabel={(option) => option.hcpName}
                                disableCloseOnSelect
                                value={accountName}
                                onChange={(e, value) => {
                                    setAccountName(value);
                                }}
                                renderOption={(props, accountNameList, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            checked={selected}
                                        />
                                        {accountNameList.hcpName}
                                    </li>
                                )}
                                style={{ width: 250, backgroundColor: "white" }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Account Name" />
                                )}
                            />
                        </div>
                    ) : null
                }
                <div className="ps-2">
                    <DatePicker placeholderText="Date" value={startDate ? `${startDate ? new Date(startDate).toLocaleDateString('en-GB') : ""} - ${endDate ? new Date(endDate).toLocaleDateString('en-GB') : ""}` : ""} onChange={handleDate} startDate={startDate} endDate={endDate} selectsRange shouldCloseOnSelect={false} className="form-control py-3" />
                </div>
                <div className="ps-2">
                    <Autocomplete
                        multiple
                        id="sku"
                        options={medicationSkuList}
                        disableCloseOnSelect
                        value={productList}
                        onChange={(e, value) => {
                            setProductList(value);
                        }}
                        renderOption={(props, medicationSkuList, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    checked={selected}
                                />
                                {medicationSkuList}
                            </li>
                        )}
                        style={{ width: 250, backgroundColor: "white" }}
                        renderInput={(params) => (
                            <TextField {...params} label="SKU" />
                        )}
                    />
                </div>
            </div>
            <div className="d-flex justify-content-end pt-2">
                <div className="ps-2">
                    <button type="button" className="btn btn-primary shadow-none admin-button" onClick={() => handleFilter()}>Filter</button>
                </div>
                <div className="ps-2">
                    <button type="button" className="btn btn-primary shadow-none admin-button" onClick={() => handleReset()}>Reset</button>
                </div>
            </div>

            {
                loading ? (
                    <div className="text-center">
                        <CircularProgress />
                    </div>
                ) : (
                    <div>
                        <div className="d-flex flex-wrap justify-content-around">
                            <div className="admin-dashboard-grid mx-1 my-1">
                                <div className="d-flex justify-content-end me-2 mt-2">
                                    <Tooltip title="Number of hcp accounts enrolled">
                                        <IconButton>
                                            <InfoOutlined style={{ color: "white" }} fontSize="large" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <p className="ps-4" style={{ fontSize: "18px" }}>TOTAL ACCOUNTS</p>
                                <p className="d-flex justify-content-end me-5" style={{ fontSize: "35px" }}><b>{dashboard ? numberWithCommas(dashboard.data.totalAccounts) : ""}</b></p>
                            </div>
                            <div className="admin-dashboard-grid mx-1 my-1">
                                <div className="d-flex justify-content-end me-2 mt-2">
                                    <Tooltip title="Number of patient accounts enrolled">
                                        <IconButton>
                                            <InfoOutlined style={{ color: "white" }} fontSize="large" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <p className="ps-4" style={{ fontSize: "18px" }}>TOTAL REGISTERED PATIENTS</p>
                                <p className="d-flex justify-content-end me-5" style={{ fontSize: "35px" }}><b>{dashboard ? numberWithCommas(dashboard.data.totalRegisteredPatients) : ""}</b></p>
                            </div>
                            <div className="admin-dashboard-grid mx-1 my-1">
                                <div className="d-flex justify-content-end me-2 mt-2">
                                    <Tooltip title="Number of unique patients with at least 1 transaction">
                                        <IconButton>
                                            <InfoOutlined style={{ color: "white" }} fontSize="large" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <p className="ps-4" style={{ fontSize: "18px" }}>TOTAL UNIQUE PATIENTS</p>
                                <p className="d-flex justify-content-end me-5" style={{ fontSize: "35px" }}><b>{dashboard ? numberWithCommas(dashboard.data.totalUniquePatients) : ""}</b></p>
                            </div>
                            <div className="admin-dashboard-grid mx-1 my-1">
                                <div className="d-flex justify-content-end me-2 mt-2">
                                    <Tooltip title="Number of boxes submitted">
                                        <IconButton>
                                            <InfoOutlined style={{ color: "white" }} fontSize="large" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <p className="ps-4" style={{ fontSize: "18px" }}>TOTAL SUBMISSIONS</p>
                                <p className="d-flex justify-content-end me-5" style={{ fontSize: "35px" }}><b>{dashboard ? numberWithCommas(Math.round(dashboard.data.totalSubmissions)) : ""}</b></p>
                            </div>
                            <div className="admin-dashboard-grid mx-1 my-1">
                                <div className="d-flex justify-content-end me-2 mt-2">
                                    <Tooltip title="Number of redemption codes released">
                                        <IconButton>
                                            <InfoOutlined style={{ color: "white" }} fontSize="large" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <p className="ps-4" style={{ fontSize: "18px" }}>TOTAL REDEMPTIONS</p>
                                <p className="d-flex justify-content-end me-5" style={{ fontSize: "35px" }}><b>{dashboard ? numberWithCommas(dashboard.data.totalRedemptions) : ""}</b></p>
                            </div>
                            <div className="admin-dashboard-grid mx-1 my-1">
                                <div className="d-flex justify-content-end me-2 mt-2">
                                    <Tooltip title="Number of redemptions completed / collected">
                                        <IconButton>
                                            <InfoOutlined style={{ color: "white" }} fontSize="large" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <p className="ps-4" style={{ fontSize: "18px" }}>TOTAL COMPLETED REDEMPTIONS</p>
                                <p className="d-flex justify-content-end me-5" style={{ fontSize: "35px" }}><b>{dashboard ? numberWithCommas(dashboard.data.totalCompletedRedemptions) : ""}</b></p>
                            </div>
                            <div className="admin-dashboard-grid mx-1 my-1">
                                <div className="d-flex justify-content-end me-2 mt-2">
                                    <Tooltip title="Number of redemption codes issued to patient but pending closure by healthcare provider">
                                        <IconButton>
                                            <InfoOutlined style={{ color: "white" }} fontSize="large" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <p className="ps-4" style={{ fontSize: "18px" }}>TOTAL PENDING REDEMPTIONS</p>
                                <p className="d-flex justify-content-end me-5" style={{ fontSize: "35px" }}><b>{dashboard ? numberWithCommas(dashboard.data.totalPendingRedemptions) : ""}</b></p>
                            </div>
                        </div>
                    </div>
                )
            }

        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.admin.token,
        role: state.admin.role,
        dashboard: state.admin.summaryDashboard
    };
};

const mapDispatchToProps = dispatch => {
    return {
        summaryDashboard: data => dispatch(summaryDashboard(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryDashboard);