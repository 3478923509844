import { useNavigate } from "react-router-dom";

import historys from "../../assets/image/transactionHistory.png";
import balance from "../../assets/image/creditBalance.png";
import brand from "../../assets/image/brandInfo.png";
import help from "../../assets/image/help.png";

const HcpNavigationBar = () => {

    const navigate = useNavigate();

    return (
        <div className="hcp-padding-bar">
            <div className="hcp-bottom-bar pt-2">
                <div className="hcp-img" onClick={() => navigate("/hcp/hcpTransactionHistory")}>
                    <img src={historys} className="hcp-img" alt="transaction-history-icon" />
                    <p className="hcp-transaction-text">TRANSACTION HISTORY</p>
                </div>
                <div className="hcp-img" onClick={() => navigate("/hcp/hcpCreditBalance")}>
                    <img src={balance} className="hcp-img" alt="credit-balance-icon" />
                    <p className="hcp-transaction-text">CREDIT BALANCE</p>
                </div>
                <div className="hcp-img" onClick={() => navigate("/hcp/hcpBrandInfo")}>
                    <img src={brand} className="hcp-img" alt="brand-info-icon" />
                    <p className="hcp-transaction-text">BRAND INFO</p>
                </div>
                <div className="hcp-img" onClick={() => navigate("/hcp/hcpHelp")}>
                    <img src={help} className="hcp-img" alt="help-icon" />
                    <p className="hcp-transaction-text">HELP</p>
                </div>
            </div>
        </div>
    );
}

export default HcpNavigationBar;