import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { MDBDataTableV5 } from 'mdbreact';
import { CircularProgress, Checkbox, TextField, Autocomplete, Drawer } from "@mui/material";
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import { styled } from '@mui/material/styles';
import { medicationSkuList } from "../dynamicController";

// components
import Sidebar from "./components/sidebar";
import Header from "./components/header";

const mySwal = withReactContent(Swal);

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-20%`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      maxWidth: "80%"
    }),
  }),
);

const SalesData = ({ role, token }) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [productList, setProductList] = useState([]);
  const [openSidebar, setOpenSidebar] = useState(true);

  useEffect(() => {
    if (role && token) {
      axios.post("/api/admin/salesData", { role, token, productList: [] })
        .then(res => {
          setData(res.data.data);
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          if (err.response.data.error === "Token Expired") {
            mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
              .then(click => {
                if (click.isConfirmed) {
                  navigate('/admin/login');
                }
              })
          } else {
            mySwal.fire("Error", err.response.data.error, "error")
          }
        });
    } else {
      navigate('/admin/login');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDate = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }

  const handleFilter = () => {
    setLoading(true);

    let sendThis = {
      role,
      token,
      productList
    }

    if (startDate && endDate) {
      sendThis.startDate = new Date(startDate).getFullYear() + "-" + (new Date(startDate).getMonth() + 1) + "-" + new Date(startDate).getDate()
      sendThis.endDate = new Date(endDate).getFullYear() + "-" + (new Date(endDate).getMonth() + 1) + "-" + new Date(endDate).getDate()
    }

    axios.post('/api/admin/salesData', sendThis)
      .then(res => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        if (err.response.data.error === "Token Expired") {
          mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
            .then(click => {
              if (click.isConfirmed) {
                navigate('/admin/login');
              }
            })
        } else {
          mySwal.fire("Error", err.response.data.error, "error")
        }
      });
  }

  const handleReset = () => {
    setStartDate("");
    setEndDate("");
    setProductList([]);
    setLoading(true);

    axios.post('/api/admin/salesData', { role, token, productList: [] })
      .then(res => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        if (err.response.data.error === "Token Expired") {
          mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
            .then(click => {
              if (click.isConfirmed) {
                navigate('/admin/login');
              }
            })
        } else {
          mySwal.fire("Error", err.response.data.error, "error")
        }
      });
  }

  let tableData = {
    columns: [
      {
        field: "hcpType",
        label: "HCP Type",
        sort: "asc"
      },
      {
        field: "hcpName",
        label: "Customer Name",
        sort: "asc"
      },
      {
        field: "hcpCode",
        label: "Redeem / Sold to Code",
        sort: "asc"
      },
      {
        field: "accountName",
        label: "Account Name",
        sort: "asc"
      },
      {
        field: "accountCode",
        label: "Account Code",
        sort: "asc"
      },
      {
        field: "mpg",
        label: "MPG",
        sort: "asc"
      },
      {
        field: "sku",
        label: "SKU",
        sort: "asc"
      },
      {
        field: "totalSellIn",
        label: "Total Sell In",
        sort: "asc"
      },
      {
        field: "totalSellOut",
        label: "Total Sell Out",
        sort: "asc"
      },
      {
        field: "quantityPendingRedemption",
        label: "Quantity Pending Redemption",
        sort: "asc"
      },
      {
        field: "quantityRedeemed",
        label: "Quantity Redeemed",
        sort: "asc"
      },
      {
        field: "creditBalance",
        label: "Credit Balance",
        sort: "asc"
      }
    ],
    rows: data ? data : []
  };

  let csv = [];

  if (data) {
    for (let i = 0; i < data.length; i++) {
      const dataObject = {
        hcpType: data[i].hcpType,
        hcpName: data[i].hcpName,
        hcpCode: data[i].hcpCode,
        accountName: data[i].accountName,
        accountCode: data[i].accountCode,
        mpg: data[i].mpg,
        sku: data[i].sku,
        totalSellIn: data[i].totalSellIn,
        totalSellOut: data[i].totalSellOut,
        quantityPendingRedemption: data[i].quantityPendingRedemption,
        quantityRedeemed: data[i].quantityRedeemed,
        creditBalance: data[i].creditBalance
      }

      csv.push(dataObject);
    }
  }

  const csvData = {
    headers: [
      { label: "HCP Type", key: "hcpType" },
      { label: "Customer Name", key: "hcpName" },
      { label: "Redeem / Sold to Code", key: "hcpCode" },
      { label: "Account Name", key: "accountName" },
      { label: "Account Code", key: "accountCode" },
      { label: "MPG", key: "mpg" },
      { label: "SKU", key: "sku" },
      { label: "Total Sell In", key: "totalSellIn" },
      { label: "Total Sell Out", key: "totalSellOut" },
      { label: "Quanttiy Pending Redemption", key: "quantityPendingRedemption" },
      { label: "Quantity Redeemed", key: "quantityRedeemed" },
      { label: "Credit Balance", key: "creditBalance" }
    ],
    data: csv
  }

  return (
    <div className="d-flex">

      <Drawer
        sx={{
          minWidth: "20%",
          '& .MuiDrawer-paper': {
            minWidth: "20%",
            boxSizing: 'border-box',
            minHeight: "100vh",
            backgroundColor: "#FE7072",
          },
        }}
        anchor="left"
        open={openSidebar}
        variant="persistent">
        <Sidebar />
      </Drawer>

      <Main open={openSidebar}>
        <div className="mt-3 px-3">
          <Header title="SALES DATA" openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />

          <div className="report-header mt-3 d-flex py-3 px-3 justify-content-end">
            <div>
              <DatePicker placeholderText="Date" value={startDate ? `${startDate ? new Date(startDate).toLocaleDateString('en-GB') : ""} - ${endDate ? new Date(endDate).toLocaleDateString('en-GB') : ""}` : ""} onChange={handleDate} startDate={startDate} endDate={endDate} selectsRange shouldCloseOnSelect={false} className="form-control py-3" />
            </div>
            <div className="ps-2">
              <Autocomplete
                multiple
                id="sku"
                options={medicationSkuList}
                disableCloseOnSelect
                value={productList}
                onChange={(e, value) => {
                  setProductList(value);
                }}
                renderOption={(props, medicationSkuList, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      checked={selected}
                    />
                    {medicationSkuList}
                  </li>
                )}
                style={{ width: 250, backgroundColor: "white" }}
                renderInput={(params) => (
                  <TextField {...params} label="SKU" />
                )}
              />
            </div>
            <div className="ps-2">
              <button type="button" className="btn btn-primary shadow-none admin-button" onClick={() => handleFilter()}>Filter</button>
            </div>
            <div className="ps-2">
              <button type="button" className="btn btn-primary shadow-none admin-button" onClick={() => handleReset()}>Reset</button>
            </div>
            <div className="ps-2">
              <CSVLink className="btn btn-primary shadow-none admin-button" data={csvData.data} headers={csvData.headers} filename={`sales_data_report_${new Date().getFullYear()}_${new Date().getMonth() + 1}_${new Date().getDate()}.csv`} target="_blank">
                Export
              </CSVLink>
            </div>
          </div>

          {
            loading ? (
              <div className="text-center">
                <CircularProgress />
              </div>
            ) : (
              <div>
                <MDBDataTableV5 data={tableData} hover responsive bordered />
              </div>
            )
          }
        </div>
      </Main>

    </div>
  );
}

const mapStateToProps = state => {
  return {
    token: state.admin.token,
    role: state.admin.role
  };
};

export default connect(mapStateToProps, null)(SalesData);
