import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { CircularProgress, Tooltip, IconButton } from "@mui/material";
import { InfoOutlined } from '@mui/icons-material';
import { totalSubmissions } from "../../redux/actions/adminActions";
import { CSVLink } from "react-csv";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip as ChartTooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    ChartTooltip,
    Legend
);

const mySwal = withReactContent(Swal);

const SubmissionRedemptionLineGraph = ({ role, token, totalSubmissions, totalSubmissionsLineGraph }) => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState("Submissions");

    const title = () => {
        if (selected === "Submissions") {
            return "TOTAL SUBMISSIONS";
        } else if (selected === "Redemptions") {
            return "TOTAL REDEMPTIONS";
        } else if (selected === "Completed Redemptions") {
            return "TOTAL COMPLETED REDEMPTIONS";
        } else {
            return "TOTAL PENDING REDEMPTIONS";
        }
    }

    const tooltipTitle = () => {
        if (selected === "Submissions") {
            return "Number of boxes submitted for each brand";
        } else if (selected === "Redemptions") {
            return "Number of redemption codes sent to patient for each brand";
        } else if (selected === "Completed Redemptions") {
            return "Number of redemption codes closed by healthcare provider in the system";
        } else {
            return "Number of redemption codes issued to patient but pending closure by healthcare provider. Please be reminded to conduct closure in the system upon dispensing the complementary boxes; each code only valid for 90 days";
        }
    }

    const medicationName = (medication) => {
        if (medication === "Viartril-S Sachets") {
            return "Viartril-S Sac"
        } else if (medication === "Viartril-S Capsules") {
            return "Viartril-S Cap"
        } else {
            return medication
        }
    }

    const graphColor = (medication) => {
        if (medication === "Lipitor") {
            return "#01718D";
        } else if (medication === "Norvasc") {
            return "#3A17D0";
        } else if (medication === "Caduet") {
            return "#C0007E";
        } else if (medication === "Viagra") {
            return "#0082F4";
        } else if (medication === "Legalon") {
            return "#29AF4B";
        } else if (medication === "Viartril-S Sachets") {
            return "#EE1B24";
        } else if (medication === "Viartril-S Capsules") {
            return "#EE1B24";
        } else if (medication === "Adaxil Sachets") {
            return "#009EC9";
        } else if (medication === "Celebrex Capsules") {
            return "#AD2B4A";
        } else if (medication === "Lyrica Capsules") {
            return "#1F1C6B";
        } else {
            return "#000000";
        }
    }

    const csvFileName = () => {
        if (selected === "Submissions") {
            return `total_submissions_${new Date().getFullYear()}_${new Date().getMonth() + 1}_${new Date().getDate()}.csv`;
        } else if (selected === "Redemptions") {
            return `total_redemptions_${new Date().getFullYear()}_${new Date().getMonth() + 1}_${new Date().getDate()}.csv`;
        } else if (selected === "Completed Redemptions") {
            return `total_completed_redemptions_${new Date().getFullYear()}_${new Date().getMonth() + 1}_${new Date().getDate()}.csv`;
        } else {
            return `total_pending_redemptions_${new Date().getFullYear()}_${new Date().getMonth() + 1}_${new Date().getDate()}.csv`;
        }
    }

    const csvFilter = (medication) => {
        if (selected === "Submissions") {
            return totalSubmissionsLineGraph.filter(d => d.medication === medication)[0].totalSubmissions;
        } else if (selected === "Redemptions") {
            return totalSubmissionsLineGraph.filter(d => d.medication === medication)[0].totalRedemptions;
        } else if (selected === "Completed Redemptions") {
            return totalSubmissionsLineGraph.filter(d => d.medication === medication)[0].totalCompletedRedemptions;
        } else {
            return totalSubmissionsLineGraph.filter(d => d.medication === medication)[0].totalPendingRedemptions;
        }
    }

    useEffect(() => {

        if (role && token) {
            if (!totalSubmissionsLineGraph) {
                axios.post("/api/admin/submissionRedemptionLineGraph", { role, token })
                    .then(res => {
                        totalSubmissions(res.data.data);
                        setLoading(false);
                    })
                    .catch(err => {
                        setLoading(false);
                        if (err.response.data.error === "Token Expired") {
                            mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
                                .then(click => {
                                    if (click.isConfirmed) {
                                        navigate('/admin/login');
                                    }
                                })
                        } else {
                            mySwal.fire("Error", err.response.data.error, "error")
                        }
                    });
            } else {
                setLoading(false);
            }
        } else {
            navigate('/admin/login');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let datasets = [];
    let csv = [];
    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const csvData = {
        headers: [
            { label: "Month", key: "month" },
            { label: "Lipitor", key: "lipitor" },
            { label: "Norvasc", key: "norvasc" },
            { label: "Caduet", key: "caduet" },
            { label: "Viagra", key: "viagra" },
            { label: "Legalon", key: "legalon" },
            { label: "Viartril-S Sac", key: "viartrilSSachets" },
            { label: "Viartril-S Cap", key: "viartrilSCapsules" },
            { label: "Adaxil Sachets", key: "adaxilSachets" },
            { label: "Celebrex Capsules", key: "celebrexCapsules" },
            { label: "Lyrica Capsules", key: "lyricaCapsules" },
            { label: "Total", key: "total" }
        ],
        data: csv
    }

    if (totalSubmissionsLineGraph) {
        for (let i = 0; i < totalSubmissionsLineGraph.length; i++) {
            const datasetObject = {
                label: medicationName(totalSubmissionsLineGraph[i].medication),
                data: selected === "Submissions" ? totalSubmissionsLineGraph[i].totalSubmissions : selected === "Redemptions" ? totalSubmissionsLineGraph[i].totalRedemptions : selected === "Completed Redemptions" ? totalSubmissionsLineGraph[i].totalCompletedRedemptions : totalSubmissionsLineGraph[i].totalPendingRedemptions,
                borderColor: graphColor(totalSubmissionsLineGraph[i].medication),
                backgroundColor: graphColor(totalSubmissionsLineGraph[i].medication),
            }
            datasets.push(datasetObject)
        }

        for (let i = 0; i < 12; i++) {

            const csvObject = {
                month: labels[i],
                lipitor: csvFilter("Lipitor")[i],
                norvasc: csvFilter("Norvasc")[i],
                caduet: csvFilter("Caduet")[i],
                viagra: csvFilter("Viagra")[i],
                legalon: csvFilter("Legalon")[i],
                viartrilSSachets: csvFilter("Viartril-S Sachets")[i],
                viartrilSCapsules: csvFilter("Viartril-S Capsules")[i],
                adaxilSachets: csvFilter("Adaxil Sachets")[i],
                celebrexCapsules: csvFilter("Celebrex Capsules")[i],
                lyricaCapsules: csvFilter("Lyrica Capsules")[i],
                total: csvFilter("Total")[i]
            }
            csv.push(csvObject);
        }
    }

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'right',
            },
            datalabels: {
                display: false
            }
        },
    };

    const data = {
        labels: labels,
        datasets: datasets
    }

    return (
        <div>
            <div className="d-flex mt-3">
                <p className="admin-dashboard-text"><b>{title()}</b></p>
                <div>
                    <Tooltip title={tooltipTitle()}>
                        <IconButton>
                            <InfoOutlined style={{ color: "#403B3B" }} fontSize="large" />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>
            {
                loading ? (
                    <div className="text-center">
                        <CircularProgress />
                    </div>
                ) : (
                    <div>
                        <div className="d-flex justify-content-end mb-2">
                            <button type="button" className={selected === "Submissions" ? "btn btn-primary admin-button shadow-none me-2" : "btn btn-primary admin-cancel-btn shadow-none me-2"} onClick={() => setSelected("Submissions")}>Total Submissions</button>
                            <button type="button" className={selected === "Redemptions" ? "btn btn-primary admin-button shadow-none me-2" : "btn btn-primary admin-cancel-btn shadow-none me-2"} onClick={() => setSelected("Redemptions")}>Total Redemptions</button>
                            <button type="button" className={selected === "Completed Redemptions" ? "btn btn-primary admin-button shadow-none me-2" : "btn btn-primary admin-cancel-btn shadow-none me-2"} onClick={() => setSelected("Completed Redemptions")}>Total Completed Redemptions</button>
                            <button type="button" className={selected === "Pending Redemptions" ? "btn btn-primary admin-button shadow-none me-2" : "btn btn-primary admin-cancel-btn shadow-none me-2"} onClick={() => setSelected("Pending Redemptions")}>Total Pending Redemptions</button>
                        </div>
                        <div className="admin-dashboard-border my-2">
                            <Line options={options} data={data} />
                        </div>
                        <div className="d-flex justify-content-end mb-2">
                            <CSVLink className="btn btn-primary shadow-none" data={csvData.data} headers={csvData.headers} filename={csvFileName()} target="_blank">
                                Export Data
                            </CSVLink>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.admin.token,
        role: state.admin.role,
        totalSubmissionsLineGraph: state.admin.totalSubmissionsLineGraph,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        totalSubmissions: data => dispatch(totalSubmissions(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionRedemptionLineGraph);