import { UPDATE_PATIENT_LANGUAGE, UPDATE_PATIENT_REMEMBER_ME, PATIENT_LOGIN, PATIENT_REGISTER_POPUP, PATIENT_CHECK_TOKEN, HOMEPAGE_NAV, PATIENT_LOGOUT } from "../actions/type";

// update patient language
export const updatePatientLanguage = data => {
    return {
        type: UPDATE_PATIENT_LANGUAGE,
        payload: data
    };
};

// update patient rememberme
export const updatePatientRememberMe = data => {
    return {
        type: UPDATE_PATIENT_REMEMBER_ME,
        payload: data
    };
};

// patient login
export const patientLogin = data => {
    return {
        type: PATIENT_LOGIN,
        payload: data
    };
};

// patient logout
export const patientLogout = () => {
    return {
        type : PATIENT_LOGOUT
    };
};

// patient homepage register pop up
export const updateRegisterPopup = data => {
    return {
        type: PATIENT_REGISTER_POPUP,
        payload: data
    };
};

// patient homepage check token
export const patientCheckToken = data => {
    return {
        type: PATIENT_CHECK_TOKEN,
        payload: data
    };
};

// patient homepage navigation
export const homepageNavigation = data => {
    return {
        type: HOMEPAGE_NAV,
        payload: data
    };
};