import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Box, CircularProgress } from "@mui/material";
import { MDBBtn } from "mdb-react-ui-kit";
import axios from 'axios';
import { connect } from 'react-redux';
import { patientCheckToken } from "../redux/actions/patientActions";
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';

import patientMobile0En from "../assets/image/tutorial/patient/mobile/patientTutorial0-en.png";
import patientMobile0Bm from "../assets/image/tutorial/patient/mobile/patientTutorial0-bm.png";
import patientMobile0Ch from "../assets/image/tutorial/patient/mobile/patientTutorial0-ch.png";
import patientDesktop0En from "../assets/image/tutorial/patient/desktop/patientTutorial0-en.png";
import patientDesktop0Bm from "../assets/image/tutorial/patient/desktop/patientTutorial0-bm.png";

const PatientStartTutorial = ({ token, patientCheckToken, patient, language }) => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const { t } = useTranslation();

    useEffect(() => {
        if (!patient) {
            setError("")
            setLoading(true);

            if (token) {
                axios.post('/api/patient/checkToken', { token })
                    .then(res => {
                        patientCheckToken(res.data.data);
                        setLoading(false);
                    })
                    .catch(err => {
                        setError(err.response.data.error);
                        setLoading(false);
                    });
            } else {
                navigate("/patient/patientWelcome")
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const skipTutorial = () => {
        setError("")
        setLoading(true);

        axios.post('/api/patient/updateFirstTimeLogin', { patientId: patient.patient.id })
            .then(res => {
                setLoading(false);
                navigate('/patient/patientHome');
            })
            .catch(err => {
                setError(err.response.data.error);
                setLoading(false);
            });
    }

    const startTutorial = () => {
        setError("")
        setLoading(true);

        axios.post('/api/patient/updateFirstTimeLogin', { patientId: patient.patient.id })
            .then(res => {
                setLoading(false);
                navigate('/patient/patientTutorial1');
            })
            .catch(err => {
                setError(err.response.data.error);
                setLoading(false);
            });
    }

    return (
        <div>
            {
                isMobile ? (
                    <img src={language === "en" ? patientMobile0En : language === "bm" ? patientMobile0Bm : patientMobile0Ch} alt="patientTutorial" style={{ height: "100vh", width: "100vw" }} />
                ) : (
                    <img src={language === "bm" ? patientDesktop0Bm : patientDesktop0En} alt="patientTutorial" style={{ height: "100vh", width: "100vw" }} />
                )
            }
            <Modal
                open={true}
                onClose={() => skipTutorial()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="blue-modal">
                    <div className="align-right">
                        <MDBBtn
                            className="btn-close"
                            color="none"
                            aria-label="Close"
                            onClick={() => skipTutorial()}
                        />
                    </div>
                    <p className="hcp-alert" style={{ color: "black", fontSize: "20px" }}>{t("Welcome to the tutorial!")}</p>
                    {
                        error ? (
                            <div className="pt-3">
                                <p style={{ color: "red", textAlign: "center" }}>{t(error)}</p>
                            </div>
                        ) : null
                    }

                    {
                        loading ? (
                            <div className="text-center">
                                <CircularProgress />
                            </div>
                        ) : (
                            <div>
                                <div className="button-container">
                                    <button className="btn form-control patient-login-button py-3" onClick={() => startTutorial()}>
                                        <b>{t("START")}</b>
                                    </button>
                                </div>
                                <div className="button-container">
                                    <button className="btn form-control patient-login-button py-3" onClick={() => skipTutorial()}>
                                        <b>{t("SKIP")}</b>
                                    </button>
                                </div>
                            </div>
                        )
                    }
                </Box>
            </Modal>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        token: state.patient.token,
        patient: state.patient.patient,
        language: state.patient.language
    };
};

const mapDispatchToProps = dispatch => {
    return {
        patientCheckToken: data => dispatch(patientCheckToken(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientStartTutorial);
