import { ADMIN_LOGIN, ADMIN_LOGOUT, SUMMARY_DASHBOARD, PERFORMANCE_BY_BRAND, PERFORMANCE_BY_SKU, TOTAL_SUBMISSIONS, UNIQUE_PATIENTS, AVERAGE_PURCHASE, TOP_TEN_ACCOUNTS } from "../actions/type";

// admin login
export const adminLogin = data => {
    return {
        type: ADMIN_LOGIN,
        payload: data
    };
};

// admin logout
export const adminLogout = () => {
    return {
        type: ADMIN_LOGOUT
    };
};

// admin summary dashboard
export const summaryDashboard = data => {
    return {
        type: SUMMARY_DASHBOARD,
        payload: data
    };
};

// admin dashboard performance by brand
export const performanceByBrand = data => {
    return {
        type: PERFORMANCE_BY_BRAND,
        payload: data
    };
};

// admin dashboard performance by sku
export const performanceBySku = data => {
    return {
        type: PERFORMANCE_BY_SKU,
        payload: data
    };
};

// admin dashboard total submission line graph
export const totalSubmissions = data => {
    return {
        type: TOTAL_SUBMISSIONS,
        payload: data
    };
};

// admin dashboard total unique patient
export const uniquePatient = data => {
    return {
        type: UNIQUE_PATIENTS,
        payload: data
    };
};

// admin dashboard average purchase
export const averagePurchase = data => {
    return {
        type: AVERAGE_PURCHASE,
        payload: data
    };
};

// admin dashboard top 10 accounts
export const topTenAccounts = data => {
    return {
        type: TOP_TEN_ACCOUNTS,
        payload: data
    };
};