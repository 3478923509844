import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { MDBDataTableV5 } from 'mdbreact';
import { CircularProgress, Drawer } from "@mui/material";
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { styled } from '@mui/material/styles';
import DatePicker from "react-datepicker";

// components
import Sidebar from "./components/sidebar";
import Header from "./components/header";

const mySwal = withReactContent(Swal);

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-20%`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      maxWidth: "80%"
    }),
  }),
);

const Validation = ({ role, token }) => {

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [date, setDate] = useState(new Date().toISOString().substring(0, 10));
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [type, setType] = useState("Zuellig");
  const [validationStatus, setValidationStatus] = useState("Pending");
  const [selectedHcpIndex, setSelectedHcpIndex] = useState(0);
  const [openSidebar, setOpenSidebar] = useState(true);

  let checkboxList = [];
  let approveData = [];
  let hcpNameList = [];

  useEffect(() => {
    if (role && token) {
      if (role === "hcp" || role === "sales") {
        mySwal.fire({ title: "Error", text: "Unauthorized Access", icon: "error", allowOutsideClick: false })
          .then(click => {
            if (click.isConfirmed) {
              navigate('/admin/login');
            }
          })
      } else {
        setLoading(true);
        axios.post("/api/admin/getValidationList", { role, token, date, type, validationStatus, startDate, endDate })
          .then(res => {
            setData(res.data.data);
            setLoading(false);
          })
          .catch(err => {
            setLoading(false);
            if (err.response.data.error === "Token Expired") {
              mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
                .then(click => {
                  if (click.isConfirmed) {
                    navigate('/admin/login');
                  }
                })
            } else {
              mySwal.fire("Error", err.response.data.error, "error")
            }
          });
      }
    } else {
      navigate('/admin/login');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, type, validationStatus]);

  let pendingTableData = {
    columns: [
      {
        field: "requestDate",
        label: "Request Date",
        sort: "asc"
      },
      {
        field: "redemptionDate",
        label: "Redemption Date",
        sort: "asc"
      },
      {
        field: "redemptionType",
        label: "Redemption Type",
        sort: "asc"
      },
      {
        field: "accountName",
        label: "Account Name",
        sort: "asc"
      },
      {
        field: "accountCode",
        label: "Account Code",
        sort: "asc"
      },
      {
        field: "purchasecode",
        label: (type === "Zuellig" ? "ZP Code" : "Antah Code"),
        sort: "asc"
      },
      {
        field: "hcpPic",
        label: "HCP PIC",
        sort: "asc"
      },
      {
        field: "mpgRedeemed",
        label: "MPG Redeemed",
        sort: "asc"
      },
      {
        field: "skuRedeemed",
        label: "SKU Redeemed",
        sort: "asc"
      },
      {
        field: "skuCode",
        label: "SKU Code",
        sort: "asc"
      },
      {
        field: "quantityRedeemed",
        label: "Quantity Redeemed",
        sort: "asc"
      },
      {
        field: "patientLastFourDigit",
        label: "Patient Last 4-digit",
        sort: "asc"
      },
      {
        field: "seName",
        label: "SE Name",
        sort: "asc"
      },
      {
        field: "seContact",
        label: "SE Contact",
        sort: "asc"
      },
      {
        field: "remarks",
        label: "Remarks",
        sort: "asc"
      },
      {
        field: "serialNo",
        label: "Serial No.",
        sort: "asc"
      },
      {
        field: "or",
        label: "#OR",
        sort: "asc"
      }
    ],
    rows: data ? data : []
  };

  let approveTableData = {
    columns: [
      {
        field: "requestDate",
        label: "Request Date",
        sort: "asc"
      },
      {
        field: "redemptionDate",
        label: "Redemption Date",
        sort: "asc"
      },
      {
        field: "redemptionType",
        label: "Redemption Type",
        sort: "asc"
      },
      {
        field: "accountName",
        label: "Account Name",
        sort: "asc"
      },
      {
        field: "accountCode",
        label: "Account Code",
        sort: "asc"
      },
      {
        field: "purchasecode",
        label: (type === "Zuellig" ? "ZP Code" : "Antah Code"),
        sort: "asc"
      },
      {
        field: "hcpPic",
        label: "HCP PIC",
        sort: "asc"
      },
      {
        field: "hcpContact",
        label: "HCP Contact",
        sort: "asc"
      },
      {
        field: "hcpAddress",
        label: "HCP Address",
        sort: "asc"
      },
      {
        field: "skuRedeemed",
        label: "SKU Redeemed",
        sort: "asc"
      },
      {
        field: "skuCode",
        label: "SKU Code",
        sort: "asc"
      },
      {
        field: "quantityRedeemed",
        label: "Quantity Redeemed",
        sort: "asc"
      },
      {
        field: "patientLastFourDigit",
        label: "Patient Last 4-digit",
        sort: "asc"
      },
      {
        field: "remarks",
        label: "Remarks",
        sort: "asc"
      },
      {
        field: "action",
        label: "Action",
        sort: "asc"
      },
    ],
    rows: approveData ? approveData : []
  };

  if (validationStatus === "Approve") {
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        const dataObject = {
          requestDate: data[i].requestDate,
          redemptionDate: data[i].redemptionDate,
          redemptionType: data[i].redemptionType,
          accountName: data[i].accountName,
          accountCode: data[i].accountCode,
          purchasecode: data[i].purchasecode,
          hcpPic: data[i].hcpPic,
          hcpContact: data[i].hcpContact,
          hcpAddress: data[i].hcpAddress,
          skuRedeemed: data[i].skuRedeemed,
          skuCode: data[i].skuCode,
          quantityRedeemed: data[i].quantityRedeemed,
          patientLastFourDigit: data[i].patientLastFourDigit,
          remarks: data[i].remarks,
          action: (
            <div>
              <button type="button" className="btn btn-primary admin-cancel-btn shadow-none" onClick={() => cancel(data[i].redemptionId)}>Cancel</button>
            </div>
          )
        }
        approveData.push(dataObject);

        let filterHcp = hcpNameList.filter(hcp => hcp.hcpId === data[i].hcpId);
        if (filterHcp.length <= 0) {
          const hcpObject = {
            hcpId: data[i].hcpId,
            hcpName: data[i].accountName,
            hcpCode: data[i].accountCode,
            hcpPic: data[i].hcpPic,
            hcpContact: data[i].hcpContact,
            hcpAddress: data[i].hcpAddress,
            purchaseName: data[i].purchaseName,
            purchasecode: data[i].purchasecode,
            cvseName: data[i].cvseName,
            cvseContact: data[i].cvseContact,
            soldToCode: data[i].soldToCode,
            shipToCode: data[i].shipToCode,
            redemptions: [
              {
                skuRedeemed: data[i].redeemSku,
                skuCode: data[i].skuCode,
                quantityRedeemed: data[i].quantityRedeemed,
                patientLastFourDigit: data[i].patientLastFourDigit
              }
            ]
          }
          hcpNameList.push(hcpObject);
        } else {
          const redemptionObject = {
            skuRedeemed: data[i].redeemSku,
            skuCode: data[i].skuCode,
            quantityRedeemed: data[i].quantityRedeemed,
            patientLastFourDigit: data[i].patientLastFourDigit
          }
          filterHcp[0].redemptions.push(redemptionObject);
        }
      }
    }
  }

  const handleDate = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }

  const handleFilter = () => {
    setLoading(true);

    let sendThis = {
      role,
      token,
      date,
      type,
      validationStatus
    };

    if (startDate && endDate) {
      sendThis.startDate = new Date(startDate).getFullYear() + "-" + (new Date(startDate).getMonth() + 1) + "-" + new Date(startDate).getDate()
      sendThis.endDate = new Date(endDate).getFullYear() + "-" + (new Date(endDate).getMonth() + 1) + "-" + new Date(endDate).getDate()
    }

    axios.post("/api/admin/getValidationList", sendThis)
      .then(res => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        if (err.response.data.error === "Token Expired") {
          mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
            .then(click => {
              if (click.isConfirmed) {
                navigate('/admin/login');
              }
            })
        } else {
          mySwal.fire("Error", err.response.data.error, "error")
        }
      });
  }

  const handleReset = () => {
    setStartDate("");
    setEndDate("");

    setLoading(true);

    axios.post("/api/admin/getValidationList", { role, token, date, type, validationStatus, startDate: "", endDate: "" })
      .then(res => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        if (err.response.data.error === "Token Expired") {
          mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
            .then(click => {
              if (click.isConfirmed) {
                navigate('/admin/login');
              }
            })
        } else {
          mySwal.fire("Error", err.response.data.error, "error")
        }
      });
  }

  const selectRedemption = (e) => {
    if (e.checked) {
      checkboxList.push(e);
    } else {
      checkboxList = checkboxList.filter(c => c.redemptionId !== e.redemptionId);
    }
  };

  const selectAllRedemptions = (e) => {
    if (data.length > 0) {
      if (e[0].checked) {
        checkboxList = e;
      } else {
        checkboxList = [];
      }
    }
  };

  const approve = () => {
    if (checkboxList.length > 0) {
      setLoading(true);
      const sendThis = {
        role,
        token,
        redemptionIds: checkboxList.map(c => c.redemptionId)
      }
      axios.post("/api/admin/approveRedemption", sendThis)
        .then(res => {
          setLoading(false);
          setValidationStatus("Approve");
        })
        .catch(err => {
          setLoading(false);
          if (err.response.data.error === "Token Expired") {
            mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
              .then(click => {
                if (click.isConfirmed) {
                  navigate('/admin/login');
                }
              })
          } else {
            mySwal.fire("Error", err.response.data.error, "error")
          }
        });
    } else {
      mySwal.fire("Error", "Please select the redemption", "error")
    }
  }

  const cancel = (redemptionId) => {
    setLoading(true);
    axios.post("/api/admin/cancelRedemption", { role, token, redemptionId })
      .then(res => {
        setLoading(false);
        setValidationStatus("Pending");
      })
      .catch(err => {
        setLoading(false);
        if (err.response.data.error === "Token Expired") {
          mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
            .then(click => {
              if (click.isConfirmed) {
                navigate('/admin/login');
              }
            })
        } else {
          mySwal.fire("Error", err.response.data.error, "error")
        }
      });
  }

  const generate = () => {
    if (data.length > 0) {
      setLoading(true);
      const sendThis = {
        date,
        hcpIds: hcpNameList.map(hcp => hcp.hcpId),
        mytype: type
      }

      axios.post("/api/admin/excelgen", sendThis)
        .then(res => {
          setLoading(false);
          console.log(res.data.url);
          window.location = res.data.url;
        })
        .catch(err => {
          setLoading(false);
          if (err.response.data.error === "Token Expired") {
            mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
              .then(click => {
                if (click.isConfirmed) {
                  navigate('/admin/login');
                }
              })
          } else {
            mySwal.fire("Error", err.response.data.error, "error")
          }
        });
    } else {
      mySwal.fire("Error", "No Approved Redemption", "error")
    }
  }

  return (
    <div className="d-flex">

      <Drawer
        sx={{
          minWidth: "20%",
          '& .MuiDrawer-paper': {
            minWidth: "20%",
            boxSizing: 'border-box',
            minHeight: "100vh",
            backgroundColor: "#FE7072",
          },
        }}
        anchor="left"
        open={openSidebar}
        variant="persistent">
        <Sidebar />
      </Drawer>

      <Main open={openSidebar}>
        <div className="mt-3 px-3">
          <Header title={validationStatus === "Approve" && type === "Zuellig" ? "ZP REDEMPTION REPORT" : validationStatus === "Approve" && type === "Antah" ? "ANTAH REDEMPTION REPORT" : "VALIDATION"} openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />

          <div className="report-header mt-3 d-flex py-3 px-3 justify-content-end">
            {
              validationStatus === "Approve" ? (
                <div>
                  <input type="date" className="form-control" value={date} onChange={(e) => setDate(e.target.value)} />
                </div>
              ) : (
                <div className="d-flex">
                  <div>
                    <DatePicker placeholderText="Date" value={startDate ? `${startDate ? new Date(startDate).toLocaleDateString('en-GB') : ""} - ${endDate ? new Date(endDate).toLocaleDateString('en-GB') : ""}` : ""} onChange={handleDate} startDate={startDate} endDate={endDate} selectsRange shouldCloseOnSelect={false} className="form-control" />
                  </div>
                  <div className="ps-2">
                    <button type="button" className="btn btn-primary shadow-none admin-button" onClick={() => handleFilter()}>Filter</button>
                  </div>
                  <div className="ps-2">
                    <button type="button" className="btn btn-primary shadow-none admin-button" onClick={() => handleReset()}>Reset</button>
                  </div>
                </div>
              )
            }
          </div>

          <div>
            <div className="d-flex pt-2">
              <p className={type === "Zuellig" ? "validation-title-selected" : "validation-title"} onClick={() => setType("Zuellig")}>Zuellig</p>
              {/* <div className="validation-vertical-line"></div>
              <p className={type === "Antah" ? "validation-title-selected" : "validation-title"} onClick={() => setType("Antah")}>Antah</p> */}
            </div>

            <div className="d-flex pt-2">
              <p className={validationStatus === "Approve" ? "validation-title-selected" : "validation-title"} onClick={() => setValidationStatus("Approve")}>Approve</p>
              <div className="validation-vertical-line"></div>
              <p className={validationStatus === "Pending" ? "validation-title-selected" : "validation-title"} onClick={() => setValidationStatus("Pending")}>Pending</p>
            </div>

            <div className="pt-2">
              {
                loading ? (
                  <div className="text-center">
                    <CircularProgress />
                  </div>
                ) : (
                  <div>
                    {
                      validationStatus === "Pending" ? (
                        <div>
                          {
                            data.length > 0 ? (
                              <MDBDataTableV5
                                hover
                                responsive
                                data={pendingTableData}
                                checkbox
                                headCheckboxID='id6'
                                bodyCheckboxID='checkboxes6'
                                getValueCheckBox={(e) => {
                                  selectRedemption(e);
                                }}
                                getValueAllCheckBoxes={(e) => {
                                  selectAllRedemptions(e);
                                }}
                                multipleCheckboxes
                              />
                            ) : (
                              <MDBDataTableV5
                                hover
                                responsive
                                data={pendingTableData}
                              />
                            )
                          }
                          <div className="d-flex justify-content-end pb-2">
                            <button type="button" className="btn btn-primary" onClick={() => approve()}>Approve</button>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <MDBDataTableV5 data={approveTableData} hover responsive bordered />
                          {
                            type === "Zuellig" ? (
                              <div>
                                <p className="admin-dashboard-text"><b>ZUELLIG ORDER FORM</b></p>
                                <div className="d-flex">
                                  <div className="pe-2" style={{ margin: "auto" }}>
                                    {
                                      hcpNameList.length > 0 ? (
                                        hcpNameList.map((hcp, i) => {
                                          return (
                                            <div key={i} className="pb-2">
                                              <button type="button" className={selectedHcpIndex === i ? "btn btn-primary admin-button shadow-none form-control" : "btn btn-primary admin-cancel-btn shadow-none form-control"} onClick={() => setSelectedHcpIndex(i)}>{hcp.hcpName}</button>
                                            </div>
                                          )
                                        })
                                      ) : null
                                    }
                                  </div>
                                  <div style={{ margin: "auto" }}>
                                    <p style={{ fontSize: "28px" }}><u><b>ZPFC : PATIENT PROGRAM ORDER FORM</b></u> (for sales rep only)</p>
                                    <p style={{ fontSize: "18px", lineHeight: "8px" }}>email to : rpa-order@zuelligpharma.com</p>
                                    <table className="table table-bordered mt-5" style={{ borderColor: "black" }}>
                                      <thead>
                                        <tr>
                                          <th scope="col">Order Form Reference</th>
                                          <th scope="col">RPA-PP1Rep</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <th>ZP Client Code</th>
                                          <td>104590</td>
                                        </tr>
                                        <tr>
                                          <th>Sales Rep Name</th>
                                          <td>{hcpNameList.length > 0 ? hcpNameList[selectedHcpIndex].cvseName : ""}</td>
                                        </tr>
                                        <tr>
                                          <th>Sales Rep Contact</th>
                                          <td>{hcpNameList.length > 0 ? hcpNameList[selectedHcpIndex].cvseContact : ""}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    <table className="table table-bordered mt-5" style={{ borderColor: "black" }}>
                                      <thead>
                                        <tr>
                                          <th scope="col">ZP SKU Code</th>
                                          <th scope="col">Order (Qty)</th>
                                          <th scope="col">Sold-To Account</th>
                                          <th scope="col">Ship-To Account</th>
                                          <th scope="col">Cust PO / Ref No</th>
                                          <th scope="col">Delivery Instructions (for Transporter only)</th>
                                          <td></td>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td>{hcpNameList.length > 0 ? hcpNameList[selectedHcpIndex].soldToCode : ""}</td>
                                          <td>{hcpNameList.length > 0 ? hcpNameList[selectedHcpIndex].shipToCode : ""}</td>
                                          <td></td>
                                          <td>iCARE-{new Date().toISOString().substring(5, 7)}-{new Date().getFullYear()}; Attn to: {hcpNameList.length > 0 ? hcpNameList[selectedHcpIndex].hcpPic : ""}</td>
                                          <td>{hcpNameList.length > 0 ? hcpNameList[selectedHcpIndex].hcpName : ""}</td>
                                        </tr>
                                        {
                                          hcpNameList.length > 0 ? (
                                            hcpNameList[selectedHcpIndex].redemptions.map((redemption, i) => {
                                              return (
                                                <tr key={i}>
                                                  <td>{redemption.skuCode}</td>
                                                  <td>1</td>
                                                  <td></td>
                                                  <td></td>
                                                  <td></td>
                                                  <td>{redemption.patientLastFourDigit}</td>
                                                  <td>{redemption.skuRedeemed}</td>
                                                </tr>
                                              )
                                            })
                                          ) : null
                                        }
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-end pb-2">
                                  <button type="button" className="btn btn-primary" onClick={() => generate()}>Generate</button>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <p className="admin-dashboard-text"><b>ANTAH ORDER FORM</b></p>
                                <div className="d-flex">
                                  <div className="pe-2" style={{ margin: "auto" }}>
                                    {
                                      hcpNameList.length > 0 ? (
                                        hcpNameList.map((hcp, i) => {
                                          return (
                                            <div key={i} className="pb-2">
                                              <button type="button" className={selectedHcpIndex === i ? "btn btn-primary admin-button shadow-none form-control" : "btn btn-primary admin-cancel-btn shadow-none form-control"} onClick={() => setSelectedHcpIndex(i)}>{hcp.hcpName}</button>
                                            </div>
                                          )
                                        })
                                      ) : null
                                    }
                                  </div>
                                  <div style={{ margin: "auto" }}>
                                    <p style={{ fontSize: "28px" }}><b><i>ANTAH PHARMA SDN BHD <small>(118544-V)</small></i></b></p>
                                    <p style={{ fontSize: "24px", lineHeight: "8px" }}>STOCK ISSUE REQUISITION (SIR)</p>
                                    <div className="d-flex">
                                      <div className="mx-3" style={{ border: "1px solid black", padding: "2% 5%" }}>
                                        <p><b>Customer name &amp; address</b></p>
                                        <p>{hcpNameList.length > 0 ? hcpNameList[selectedHcpIndex].purchasecode : ""}</p>
                                        <p style={{ lineHeight: "10px" }}>{hcpNameList.length > 0 ? hcpNameList[selectedHcpIndex].purchaseName : ""}</p>
                                        <p>{hcpNameList.length > 0 ? hcpNameList[selectedHcpIndex].hcpAddress : ""}</p>
                                        <br />
                                        <p><b>Contact person: </b>{hcpNameList.length > 0 ? hcpNameList[selectedHcpIndex].hcpPic : ""}</p>
                                        <p><b>Contact number: </b>{hcpNameList.length > 0 ? hcpNameList[selectedHcpIndex].hcpContact : ""}</p>
                                      </div>
                                      <div>
                                        <div className="container" style={{ border: "1px solid black" }}>
                                          <p>Date: {new Date().toISOString().substring(0, 10)}</p>
                                        </div>
                                        <div className="container mt-2" style={{ border: "1px solid black" }}>
                                          <p>SIA No: </p>
                                        </div>
                                      </div>
                                    </div>
                                    <table className="table table-bordered mt-3" style={{ borderColor: "black" }}>
                                      <thead>
                                        <tr>
                                          <th scope="col">No.</th>
                                          <th scope="col">Item code</th>
                                          <th scope="col">Description &amp; Batch No/Expiry</th>
                                          <th scope="col">Qty</th>
                                          <th scope="col">Unit Cost / Selling Price</th>
                                          <th scope="col">Total Cost / Selling Price</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          hcpNameList.length > 0 ? (
                                            hcpNameList[selectedHcpIndex].redemptions.map((redemption, i) => {
                                              return (
                                                <tr key={i}>
                                                  <th scope="row">{i + 1}</th>
                                                  <td>{redemption.skuCode}</td>
                                                  <td>{redemption.skuRedeemed}</td>
                                                  <td>1</td>
                                                  <td></td>
                                                  <td></td>
                                                </tr>
                                              )
                                            })
                                          ) : null
                                        }
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-end pb-2">
                                  <button type="button" className="btn btn-primary" onClick={() => generate()}>Generate</button>
                                </div>
                              </div>
                            )
                          }
                        </div>
                      )
                    }
                  </div>
                )
              }
            </div>

          </div>
        </div>
      </Main>

    </div>
  );
}

const mapStateToProps = state => {
  return {
    token: state.admin.token,
    role: state.admin.role
  };
};

export default connect(mapStateToProps, null)(Validation);
