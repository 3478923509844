import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { CircularProgress, Checkbox, TextField, Autocomplete, Tooltip, IconButton } from "@mui/material";
import { InfoOutlined } from '@mui/icons-material';
import { connect } from 'react-redux';
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { CSVLink } from "react-csv";
import { MDBDataTableV5 } from 'mdbreact';
import { topTenAccounts } from "../../redux/actions/adminActions";
import { medicationSkuList } from "../../dynamicController";

const mySwal = withReactContent(Swal);

const TopTenAccounts = ({ role, token, accountNameList, topTenAccounts, topTen }) => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [accountName, setAccountName] = useState([]);
    const [productList, setProductList] = useState([]);

    useEffect(() => {

        if (role && token) {
            if (!topTen) {
                axios.post("/api/admin/topAccount", { role, token, productList: [], accountName: [] })
                    .then(res => {
                        let data = {};
                        data.data = res.data.data;
                        data.productList = [];
                        data.accountName = [];
                        topTenAccounts(data);
                        setLoading(false);
                    })
                    .catch(err => {
                        setLoading(false);
                        if (err.response.data.error === "Token Expired") {
                            mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
                                .then(click => {
                                    if (click.isConfirmed) {
                                        navigate('/admin/login');
                                    }
                                })
                        } else {
                            mySwal.fire("Error", err.response.data.error, "error")
                        }
                    });
            } else {
                setProductList(topTen.productList);
                setAccountName(topTen.accountName);
                setLoading(false);
            }
        } else {
            navigate('/admin/login');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFilter = () => {
        setLoading(true);

        axios.post('/api/admin/topAccount', { role, token, productList, accountName })
            .then(res => {
                let data = {};
                data.data = res.data.data;
                data.productList = productList;
                data.accountName = accountName;
                topTenAccounts(data);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                if (err.response.data.error === "Token Expired") {
                    mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
                        .then(click => {
                            if (click.isConfirmed) {
                                navigate('/admin/login');
                            }
                        })
                } else {
                    mySwal.fire("Error", err.response.data.error, "error")
                }
            });
    }

    const handleReset = () => {
        setProductList([]);
        setAccountName([]);
        setLoading(true);

        axios.post('/api/admin/topAccount', { role, token, accountName: [], productList: [] })
            .then(res => {
                let data = {};
                data.data = res.data.data;
                data.accountName = [];
                data.productList = [];
                topTenAccounts(data);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                if (err.response.data.error === "Token Expired") {
                    mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
                        .then(click => {
                            if (click.isConfirmed) {
                                navigate('/admin/login');
                            }
                        })
                } else {
                    mySwal.fire("Error", err.response.data.error, "error")
                }
            });
    }

    const numberWithCommas = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    let table = [];

    let tableData = {
        columns: [
            {
                field: "accountName",
                label: "Account Name",
            },
            {
                field: "quantitySubmitted",
                label: "Quantity Submitted",
            }
        ],
        rows: table ? table : []
    }

    let csv = [];

    const csvData = {
        headers: [
            { label: "Account Name", key: "accountName" },
            { label: "Quantity Submitted", key: "quantitySubmitted" }
        ],
        data: csv
    }

    if (topTen) {
        for (let i = 0; i < topTen.data.length; i++) {
            const tableObject = {
                accountName: topTen.data[i].accountName,
                quantitySubmitted: numberWithCommas(topTen.data[i].quantitySubmitted),
            }
            table.push(tableObject);

            const csvObject = {
                accountName: topTen.data[i].accountName,
                quantitySubmitted: topTen.data[i].quantitySubmitted,
            }
            csv.push(csvObject);
        }
    }

    return (
        <div>
            <div className="d-flex mt-3">
                <p className="admin-dashboard-text"><b>TOP 10 ACCOUNTS</b></p>
                <div>
                    <Tooltip title="Top 10 accounts by number of qty (unit) submitted by brand and SKU">
                        <IconButton>
                            <InfoOutlined style={{ color: "#403B3B" }} fontSize="large" />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>

            <div className="d-flex justify-content-end">
                <div>
                    <Autocomplete
                        multiple
                        id="accountName"
                        options={accountNameList}
                        getOptionLabel={(option) => option.hcpName}
                        disableCloseOnSelect
                        value={accountName}
                        onChange={(e, value) => {
                            setAccountName(value);
                        }}
                        renderOption={(props, accountNameList, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    checked={selected}
                                />
                                {accountNameList.hcpName}
                            </li>
                        )}
                        style={{ width: 250, backgroundColor: "white" }}
                        renderInput={(params) => (
                            <TextField {...params} label="Account Name" />
                        )}
                    />
                </div>
                <div className="ps-2">
                    <Autocomplete
                        multiple
                        id="sku"
                        options={medicationSkuList}
                        disableCloseOnSelect
                        value={productList}
                        onChange={(e, value) => {
                            setProductList(value);
                        }}
                        renderOption={(props, medicationSkuList, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    checked={selected}
                                />
                                {medicationSkuList}
                            </li>
                        )}
                        style={{ width: 250, backgroundColor: "white" }}
                        renderInput={(params) => (
                            <TextField {...params} label="SKU" />
                        )}
                    />
                </div>
            </div>
            <div className="d-flex justify-content-end pt-2">
                <div className="ps-2">
                    <button type="button" className="btn btn-primary shadow-none admin-button" onClick={() => handleFilter()}>Filter</button>
                </div>
                <div className="ps-2">
                    <button type="button" className="btn btn-primary shadow-none admin-button" onClick={() => handleReset()}>Reset</button>
                </div>
            </div>

            <div>
                {
                    loading ? (
                        <div className="text-center">
                            <CircularProgress />
                        </div>
                    ) : (
                        <div>
                            <div className="admin-dashboard-border my-2">
                                <MDBDataTableV5 data={tableData} hover responsive bordered sortable={false} />
                            </div>
                            <div className="d-flex justify-content-end mb-2">
                                <CSVLink className="btn btn-primary shadow-none" data={csvData.data} headers={csvData.headers} filename={`top_10_accounts_${new Date().getFullYear()}_${new Date().getMonth() + 1}_${new Date().getDate()}.csv`} target="_blank">
                                    Export Data
                                </CSVLink>
                            </div>
                        </div>
                    )
                }
            </div>

        </div>
    )
}

const mapStateToProps = state => {
    return {
        token: state.admin.token,
        role: state.admin.role,
        topTen: state.admin.topTenAccounts
    };
};

const mapDispatchToProps = dispatch => {
    return {
        topTenAccounts: data => dispatch(topTenAccounts(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopTenAccounts);