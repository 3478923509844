import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { MDBDataTableV5 } from 'mdbreact';
import { CircularProgress, Drawer } from "@mui/material";
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import EditIcon from '@mui/icons-material/Edit';
import { Modal } from 'react-bootstrap';
import { styled } from '@mui/material/styles';
import DatePicker from "react-datepicker";

// components
import Sidebar from "./components/sidebar";
import Header from "./components/header";

const mySwal = withReactContent(Swal);

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-20%`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
            maxWidth: "80%"
        }),
    }),
);

const RedemptionReport = ({ role, token }) => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [redemptionId, setRedemptionId] = useState("");
    const [fulfillmentAgency, setFulfillmentAgency] = useState("");
    const [redemptionDate, setRedemptionDate] = useState("");
    const [accountCode, setAccountCode] = useState("");
    const [skuRedeemed, setSkuRedeemed] = useState("");
    const [skuCode, setSkuCode] = useState("");
    const [serialNo, setSerialNo] = useState("");
    const [or, setOr] = useState("");
    const [editModal, setEditModal] = useState(false);
    const [openSidebar, setOpenSidebar] = useState(true);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    useEffect(() => {
        if (role && token) {
            if (role === "hcp" || role === "sales") {
                mySwal.fire({ title: "Error", text: "Unauthorized Access", icon: "error", allowOutsideClick: false })
                    .then(click => {
                        if (click.isConfirmed) {
                            navigate('/admin/login');
                        }
                    })
            } else {
                setLoading(true);
                axios.post("/api/admin/getRedemptionReport", { role, token })
                    .then(res => {
                        setData(res.data.data);
                        setLoading(false);
                    })
                    .catch(err => {
                        setLoading(false);
                        if (err.response.data.error === "Token Expired") {
                            mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
                                .then(click => {
                                    if (click.isConfirmed) {
                                        navigate('/admin/login');
                                    }
                                })
                        } else {
                            mySwal.fire("Error", err.response.data.error, "error")
                        }
                    });
            }
        } else {
            navigate('/admin/login');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showEditModal = (redemptionId, fulfillmentAgency, redemptionDate, accountCode, skuRedeemed, skuCode, serialNo, or) => {
        setRedemptionId(redemptionId);
        setFulfillmentAgency(fulfillmentAgency);
        setRedemptionDate(redemptionDate);
        setAccountCode(accountCode);
        setSkuRedeemed(skuRedeemed);
        setSkuCode(skuCode);
        setSerialNo(serialNo);
        if (or !== "-") {
            setOr(or);
        }
        setEditModal(true);
    }

    const hideEditModal = () => {
        setRedemptionId("");
        setFulfillmentAgency("");
        setRedemptionDate("");
        setAccountCode("");
        setSkuRedeemed("");
        setSkuCode("");
        setSerialNo("");
        setOr("");
        setEditModal(false);
    }

    const saveRedemption = () => {
        if (or) {
            setLoading(true);
            axios.post("/api/admin/editRedemption", { role, token, redemptionId, or })
                .then(res => {
                    axios.post("/api/admin/getRedemptionReport", { role, token })
                        .then(res => {
                            setData(res.data.data);
                            setRedemptionId("");
                            setFulfillmentAgency("");
                            setRedemptionDate("");
                            setAccountCode("");
                            setSkuRedeemed("");
                            setSkuCode("");
                            setSerialNo("");
                            setOr("");
                            setEditModal(false);
                            setLoading(false);
                        })
                        .catch(err => {
                            setLoading(false);
                            if (err.response.data.error === "Token Expired") {
                                mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
                                    .then(click => {
                                        if (click.isConfirmed) {
                                            navigate('/admin/login');
                                        }
                                    })
                            } else {
                                mySwal.fire("Error", err.response.data.error, "error")
                            }
                        });
                })
                .catch(err => {
                    setLoading(false);
                    if (err.response.data.error === "Token Expired") {
                        mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
                            .then(click => {
                                if (click.isConfirmed) {
                                    navigate('/admin/login');
                                }
                            })
                    } else {
                        mySwal.fire("Error", err.response.data.error, "error")
                    }
                });
        } else {
            mySwal.fire("Error", "Please fill in the #or", "error")
        }
    }

    const handleDate = dates => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    }

    const handleFilter = () => {
        setLoading(true);

        let sendThis = {
            role,
            token
        }

        if (startDate && endDate) {
            sendThis.startDate = new Date(startDate).getFullYear() + "-" + (new Date(startDate).getMonth() + 1) + "-" + new Date(startDate).getDate()
            sendThis.endDate = new Date(endDate).getFullYear() + "-" + (new Date(endDate).getMonth() + 1) + "-" + new Date(endDate).getDate()
        }

        axios.post("/api/admin/getRedemptionReport", sendThis)
            .then(res => {
                setData(res.data.data);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                if (err.response.data.error === "Token Expired") {
                    mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
                        .then(click => {
                            if (click.isConfirmed) {
                                navigate('/admin/login');
                            }
                        })
                } else {
                    mySwal.fire("Error", err.response.data.error, "error")
                }
            });
    }

    const handleReset = () => {
        setStartDate("");
        setEndDate("");

        setLoading(true);

        axios.post("/api/admin/getRedemptionReport", { role, token })
            .then(res => {
                setData(res.data.data);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
                if (err.response.data.error === "Token Expired") {
                    mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
                        .then(click => {
                            if (click.isConfirmed) {
                                navigate('/admin/login');
                            }
                        })
                } else {
                    mySwal.fire("Error", err.response.data.error, "error")
                }
            });
    }

    let redemptionData = [];

    let tableData = {
        columns: [
            {
                field: "redemptionDate",
                label: "Redemption Date",
                sort: "asc"
            },
            {
                field: "redemptionType",
                label: "Redemption Type",
                sort: "asc"
            },
            {
                field: "accountName",
                label: "Account Name",
                sort: "asc"
            },
            {
                field: "accountCode",
                label: "Account Code",
                sort: "asc"
            },
            {
                field: "hcpPic",
                label: "HCP PIC",
                sort: "asc"
            },
            {
                field: "mpgRedeemed",
                label: "MPG Redeemed",
                sort: "asc"
            },
            {
                field: "skuRedeemed",
                label: "SKU Redeemed",
                sort: "asc"
            },
            {
                field: "skuCode",
                label: "SKU Code",
                sort: "asc"
            },
            {
                field: "quantityRedeemed",
                label: "Quantity Redeemed",
                sort: "asc"
            },
            {
                field: "patientLastFourDigit",
                label: "Patient Last 4-digit",
                sort: "asc"
            },
            {
                field: "seName",
                label: "SE Name",
                sort: "asc"
            },
            {
                field: "seContact",
                label: "SE Contact",
                sort: "asc"
            },
            {
                field: "remarks",
                label: "Remarks",
                sort: "asc"
            },
            {
                field: "serialNo",
                label: "Serial No",
                sort: "asc"
            },
            {
                field: "or",
                label: "#OR",
                sort: "asc"
            },
            {
                field: "validationStatus",
                label: "Validation Status",
                sort: "asc"
            },
            {
                field: "approvedDate",
                label: "Approved Date",
                sort: "asc"
            },
            {
                field: "action",
                label: "Action",
                sort: "asc"
            },
        ],
        rows: redemptionData ? redemptionData : []
    };

    if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
            const dataObject = {
                redemptionDate: data[i].redemptionDate,
                redemptionType: data[i].redemptionType,
                accountName: data[i].accountName,
                accountCode: data[i].accountCode,
                hcpPic: data[i].hcpPic,
                mpgRedeemed: data[i].mpgRedeemed,
                skuRedeemed: data[i].skuRedeemed,
                skuCode: data[i].skuCode,
                quantityRedeemed: data[i].quantityRedeemed,
                patientLastFourDigit: data[i].patientLastFourDigit,
                seName: data[i].seName,
                seContact: data[i].seContact,
                remarks: data[i].remarks,
                serialNo: data[i].serialNo,
                or: data[i].or,
                validationStatus: data[i].validationStatus,
                approvedDate: data[i].approvedDate,
                action: (
                    <div className="text-center" onClick={() => showEditModal(data[i].redemptionId, data[i].fulfillmentAgency, data[i].redemptionDate, data[i].accountCode, data[i].skuRedeemed, data[i].skuCode, data[i].serialNo, data[i].or)}>
                        <EditIcon />
                    </div>
                )
            }
            redemptionData.push(dataObject);
        }
    }

    return (
        <div className="d-flex">

            <Drawer
                sx={{
                    minWidth: "20%",
                    '& .MuiDrawer-paper': {
                        minWidth: "20%",
                        boxSizing: 'border-box',
                        minHeight: "100vh",
                        backgroundColor: "#FE7072",
                    },
                }}
                anchor="left"
                open={openSidebar}
                variant="persistent">
                <Sidebar />
            </Drawer>

            <Main open={openSidebar}>
                <div className="mt-3 px-3">
                    <Header title="REDEMPTION REPORT" openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />

                    <div>
                        <div className="report-header mt-3 d-flex py-3 px-3 justify-content-end">
                            <div className="d-flex">
                                <div>
                                    <DatePicker placeholderText="Approved Date" value={startDate ? `${startDate ? new Date(startDate).toLocaleDateString('en-GB') : ""} - ${endDate ? new Date(endDate).toLocaleDateString('en-GB') : ""}` : ""} onChange={handleDate} startDate={startDate} endDate={endDate} selectsRange shouldCloseOnSelect={false} className="form-control" />
                                </div>
                                <div className="ps-2">
                                    <button type="button" className="btn btn-primary shadow-none admin-button" onClick={() => handleFilter()}>Filter</button>
                                </div>
                                <div className="ps-2">
                                    <button type="button" className="btn btn-primary shadow-none admin-button" onClick={() => handleReset()}>Reset</button>
                                </div>
                            </div>
                        </div>

                        <div>
                            {
                                loading ? (
                                    <div className="text-center">
                                        <CircularProgress />
                                    </div>
                                ) : (
                                    <div>
                                        <MDBDataTableV5 data={tableData} hover responsive bordered />
                                    </div>
                                )
                            }
                        </div>

                    </div>
                </div>
            </Main>

            <Modal show={editModal} onHide={hideEditModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Validation #OR</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container">
                        <div>
                            <label htmlFor="fulfillmentAgency">Fulfillment Agency</label>
                            <input
                                name="fulfillmentAgency"
                                type="text"
                                value={fulfillmentAgency}
                                className="form-control"
                                readOnly
                            />
                        </div>
                        <div className="pt-3">
                            <label htmlFor="redemptionDate">Redemption Date</label>
                            <input
                                name="redemptionDate"
                                type="text"
                                value={redemptionDate}
                                className="form-control"
                                readOnly
                            />
                        </div>
                        <div className="pt-3">
                            <label htmlFor="accountCode">Account Code</label>
                            <input
                                name="accountCode"
                                type="text"
                                value={accountCode}
                                className="form-control"
                                readOnly
                            />
                        </div>
                        <div className="pt-3">
                            <label htmlFor="skuRedeemed">SKU Redeemed</label>
                            <input
                                name="skuRedeemed"
                                type="text"
                                value={skuRedeemed}
                                className="form-control"
                                readOnly
                            />
                        </div>
                        <div className="pt-3">
                            <label htmlFor="skuCode">SKU Code</label>
                            <input
                                name="skuCode"
                                type="text"
                                value={skuCode}
                                className="form-control"
                                readOnly
                            />
                        </div>
                        <div className="pt-3">
                            <label htmlFor="serialNo">Serial Number</label>
                            <input
                                name="serialNo"
                                type="text"
                                value={serialNo}
                                className="form-control"
                                readOnly
                            />
                        </div>
                        <div className="pt-3">
                            <label htmlFor="or">#OR</label>
                            <input
                                name="or"
                                type="text"
                                value={or}
                                onChange={(e) => setOr(e.target.value)}
                                className="form-control"
                                placeholder="Insert OR Number here"
                            />
                        </div>
                    </div>
                    {
                        loading ? (
                            <div className="text-center">
                                <CircularProgress />
                            </div>
                        ) : (
                            <div className="text-center mt-4">
                                <button type="button" className="btn btn-primary shadow-none admin-button" onClick={saveRedemption}>CONFIRM</button>
                            </div>
                        )
                    }
                </Modal.Body>
            </Modal>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        token: state.admin.token,
        role: state.admin.role
    };
};

export default connect(mapStateToProps, null)(RedemptionReport);
