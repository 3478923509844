import { UPDATE_HCP_LANGUAGE, HCP_LOGIN, UPDATE_HCP_REMEMBER_ME, UPDATE_PATIENT_DETAILS, REGISTER_POPUP, UPDATE_HCP_DETAILS, UPDATE_REDEMPTION, HCP_LOGOUT } from "../actions/type";

// hcp login
export const hcpLogin = data => {
    return {
        type: HCP_LOGIN,
        payload: data
    };
};

// hcp logout
export const hcpLogout = () => {
    return {
        type : HCP_LOGOUT
    };
};

// update hcp language
export const updateHcpLanguage = data => {
    return {
        type: UPDATE_HCP_LANGUAGE,
        payload: data
    };
};

// update hcp rememberme
export const updateHcpRememberMe = data => {
    return {
        type: UPDATE_HCP_REMEMBER_ME,
        payload: data
    };
};

// hcp search patient details
export const updatePatientDetails = data => {
    return {
        type: UPDATE_PATIENT_DETAILS,
        payload: data
    };
};

// hcp homepage register pop up
export const updateRegisterPopup = data => {
    return {
        type: REGISTER_POPUP,
        payload: data
    };
};

// hcp homepage check token
export const updateHcpDetails = data => {
    return {
        type: UPDATE_HCP_DETAILS,
        payload: data
    };
};

// hcp redeem redemption
export const updateRedemption = data => {
    return {
        type: UPDATE_REDEMPTION,
        payload: data
    };
};