import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const translationEn = {
    "Terms & Conditions": "Terms & Conditions",
    "TERMS AND CONDITIONS (AGREEMENT) FOR PATIENTS iCARE CONNECT+": "TERMS AND CONDITIONS (AGREEMENT) FOR PATIENTS iCARE CONNECT+",
    "We wish to support you by providing a treatment assistance program for healthcare products. The Program (as defined below) endeavors to promote accessibility and treatment adherence of healthcare products through the provision of product redemption to you. The Product redemption provided through the Program is only offered by Partners that have signed up for the Program. Redemption will be made only on basis of the prescription, which will be verified from your end.": "We wish to support you by providing a treatment assistance program for healthcare products. The Program (as defined below) endeavors to promote accessibility and treatment adherence of healthcare products through the provision of product redemption to you. The Product redemption provided through the Program is only offered by Partners that have signed up for the Program. Redemption will be made only on basis of the prescription, which will be verified from your end.",
    'By registering and using the App, you agree to the terms and conditions set forth below by the Company. If you do not agree to the outlined terms and conditions (the "Terms and Conditions"), you are not allowed to participate in the Program or to use the App. The Terms and Conditions may change from time to time, and the Company will notify you of these changes. Your continued use of the App once the Terms and Conditions have been amended constitutes your acceptance of the amended Terms and Conditions.': 'By registering and using the App, you agree to the terms and conditions set forth below by the Company. If you do not agree to the outlined terms and conditions (the "Terms and Conditions"), you are not allowed to participate in the Program or to use the App. The Terms and Conditions may change from time to time, and the Company will notify you of these changes. Your continued use of the App once the Terms and Conditions have been amended constitutes your acceptance of the amended Terms and Conditions.',
    "Definition": "Definition",
    '“App” shall refer to iCARE CONNECT+ digital mobile platform, that serves to provide treatment support services. The App allows the users to do submission to the Company to verify their Redemption eligibility. The App operates on SMS, smartphone, web and/or other technology-based platforms.': '“App” shall refer to iCARE CONNECT+ digital mobile platform, that serves to provide treatment support services. The App allows the users to do submission to the Company to verify their Redemption eligibility. The App operates on SMS, smartphone, web and/or other technology-based platforms.',
    '“Company” shall refer to Click Services Sdn Bhd.': '“Company” shall refer to Click Services Sdn Bhd.',
    '“Healthcare Professionals” shall refer to healthcare professionals in Malaysia, which includes, but not limited to, medical practitioners, pharmacists, nurses, medical assistants and other allied healthcare professionals and any other person involved in the giving of medical, health, pharmaceutical and any other healthcare services under the jurisdiction of the Ministry of Health of Malaysia that are duly-registered and/or duly-licensed at all times.': '“Healthcare Professionals” shall refer to healthcare professionals in Malaysia, which includes, but not limited to, medical practitioners, pharmacists, nurses, medical assistants and other allied healthcare professionals and any other person involved in the giving of medical, health, pharmaceutical and any other healthcare services under the jurisdiction of the Ministry of Health of Malaysia that are duly-registered and/or duly-licensed at all times.',
    '“Partners” shall refer to the participating hospitals and/or clinics for this Program.': '“Partners” shall refer to the participating hospitals and/or clinics for this Program.',
    '“Program” shall refer to iCARE CONNECT+ Program for private hospitals and clinics in Malaysia.': '“Program” shall refer to iCARE CONNECT+ Program for private hospitals and clinics in Malaysia.',
    '“Product” or “Products”, whichever applicable, shall refer to the healthcare products that are prescribed under the Program.': '“Product” or “Products”, whichever applicable, shall refer to the healthcare products that are prescribed under the Program.',
    '“Redemption” shall refer to the entitlement of the patient to redeem the Products, provided that requirements set out under these Terms and Conditions are satisfied.': '“Redemption” shall refer to the entitlement of the patient to redeem the Products, provided that requirements set out under these Terms and Conditions are satisfied.',
    '“You” shall refer to the patient who is in need of the Products, and shall include customers who are purchasing the Products on behalf of the patient from the Partners under the Program.': '“You” shall refer to the patient who is in need of the Products, and shall include customers who are purchasing the Products on behalf of the patient from the Partners under the Program.',
    "How this Program works": "How this Program works",
    "The Program is only applicable for Products that are purchased from Partners.": "The Program is only applicable for Products that are purchased from Partners.",
    'programWorks': 'The Program begins on <b>7<sup>th</sup> Jun 2022</b> and will continue until terminated by Viatris (“Term”). Where Viatris decides to terminate the Program, 30 days prior notice will be provided by the Company to the patient.',
    "In order to help your doctors to manage your health conditions, benefits under the Program are only provided in respect of the Products, and ONLY IF the Products have been adequately prescribed by a Healthcare Professional qualified to do so.": "In order to help your doctors to manage your health conditions, benefits under the Program are only provided in respect of the Products, and ONLY IF the Products have been adequately prescribed by a Healthcare Professional qualified to do so.",
    "For every purchase of Product prescribed by a Healthcare Professional, a submission summary pop-up and notification will be sent to you. You will be required to check and confirm the submission details, which details will be then recorded and updated in the app. Failing of confirmation, which you shall not be entitled to the benefits under this Program.": "For every purchase of Product prescribed by a Healthcare Professional, a submission summary pop-up and notification will be sent to you. You will be required to check and confirm the submission details, which details will be then recorded and updated in the app. Failing of confirmation, which you shall not be entitled to the benefits under this Program.",
    "In order for you to redeem the prescribed Product, you are required show your redemption code of the same medication. Please enquire with your healthcare providers about the redemption details.": "In order for you to redeem the prescribed Product, you are required show your redemption code of the same medication. Please enquire with your healthcare providers about the redemption details.",
    "Upon the first submission, you shall enter a Program period of one (1) year. Thereafter, any Program renewal requires new submission.": "Upon the first submission, you shall enter a Program period of one (1) year. Thereafter, any Program renewal requires new submission.",
    "You will receive an SMS notification with a redemption code once the redemption is verified and approved by the Company.": "You will receive an SMS notification with a redemption code once the redemption is verified and approved by the Company.",
    "You will need to show the redemption code to our Partners in order to collect your redemption medication.": "You will need to show the redemption code to our Partners in order to collect your redemption medication.",
    "All Redemptions will be checked and verified by the Company.": "All Redemptions will be checked and verified by the Company.",
    "You must redeem or collect the complimentary Product within 90 days of receiving the verification / redemption code, after which you are no longer eligible to receive the complimentary Product and the relevant verification / redemption code shall be void.": "You must redeem or collect the complimentary Product within 90 days of receiving the verification / redemption code, after which you are no longer eligible to receive the complimentary Product and the relevant verification / redemption code shall be void.",
    "There is a cap on the maximum annual quantity allowed for redemption of each Product. Please refer to our Partners for more details.": "There is a cap on the maximum annual quantity allowed for redemption of each Product. Please refer to our Partners for more details.",
    "Terms and Conditions": "Terms and Conditions",
    "You are only eligible to participate in the Program if you are prescribed by our Partners any of the Products offered and marketed by Viatris.": "You are only eligible to participate in the Program if you are prescribed by our Partners any of the Products offered and marketed by Viatris.",
    "You will assist the Company to prevent fraudulent use of the Program and shall extend your assistance to the Company in any investigation relating to the Program, which may include but not limited to, fraudulent activities.": "You will assist the Company to prevent fraudulent use of the Program and shall extend your assistance to the Company in any investigation relating to the Program, which may include but not limited to, fraudulent activities.",
    "The Company reserves the rights, at its sole and absolute discretion, to suspend, cancel or amend the Program at any time, as it deems fit, and the Company will provide 30 days' prior notice for the same.": "The Company reserves the rights, at its sole and absolute discretion, to suspend, cancel or amend the Program at any time, as it deems fit, and the Company will provide 30 days' prior notice for the same.",
    "The Company reserves the right, at its sole and absolute discretion, to disqualify or terminate your participation, if you are deemed by the Company to be in violation of any terms contained herein.": "The Company reserves the right, at its sole and absolute discretion, to disqualify or terminate your participation, if you are deemed by the Company to be in violation of any terms contained herein.",
    "You shall comply and abide by all instructions and recommendations given to you by the Healthcare Professional attending to you in respect of the Product and/or Program.": "You shall comply and abide by all instructions and recommendations given to you by the Healthcare Professional attending to you in respect of the Product and/or Program.",
    "You hereby agree that Company will not be liable in contract, tort (including negligence), statute or otherwise for any direct, indirect, incidental, punitive, or consequential damages arising from your wrongful or negligent consumption of the Product, the Partners’ negligent prescription of the Products, the Partners’ and/or your negligent usage of the App or for any other claim related in any way to the negligent use of or inability to use the App or for any breach of any applicable laws and regulations.": "You hereby agree that Company will not be liable in contract, tort (including negligence), statute or otherwise for any direct, indirect, incidental, punitive, or consequential damages arising from your wrongful or negligent consumption of the Product, the Partners’ negligent prescription of the Products, the Partners’ and/or your negligent usage of the App or for any other claim related in any way to the negligent use of or inability to use the App or for any breach of any applicable laws and regulations.",
    "Pursuant to the Malaysian Personal Data Protection Act 2010, all personal data received as a result of the participation in the Program and App shall be kept strictly confidential and will be used, processed, analyzed, shared etc. only for purposes deemed reasonably necessary to execute the Program.": "Pursuant to the Malaysian Personal Data Protection Act 2010, all personal data received as a result of the participation in the Program and App shall be kept strictly confidential and will be used, processed, analyzed, shared etc. only for purposes deemed reasonably necessary to execute the Program.",
    "The data in relation to Redemption is not meant to assess prescriptions pattern or to be used to form decision for provision of any potential sponsorship, support for attendance at meeting, speaking or consultancy engagement, provision of funding, or any other benefits in cash or kind.": "The data in relation to Redemption is not meant to assess prescriptions pattern or to be used to form decision for provision of any potential sponsorship, support for attendance at meeting, speaking or consultancy engagement, provision of funding, or any other benefits in cash or kind.",
    "To the extent that the Company determines that it is commercially reasonable to implement an electronic data migration and interchange system in relation to the Program, your continued participation in the Program constitutes your acceptance of the aforementioned implementation. Electronic data migration and interchange system refers to the moving of data and information regarding the Program electronically.": "To the extent that the Company determines that it is commercially reasonable to implement an electronic data migration and interchange system in relation to the Program, your continued participation in the Program constitutes your acceptance of the aforementioned implementation. Electronic data migration and interchange system refers to the moving of data and information regarding the Program electronically.",
    "Should any of the provisions under these Terms and Conditions be considered unlawful, void or otherwise unenforceable, then such provisions shall be deemed severable from these Terms and Conditions and not affect the validity and enforceability of any other provisions. To the extent any express or implied restrictions are not permitted by applicable laws, these express or implied restrictions shall remain in force and effect to the maximum extent permitted by such applicable laws.": "Should any of the provisions under these Terms and Conditions be considered unlawful, void or otherwise unenforceable, then such provisions shall be deemed severable from these Terms and Conditions and not affect the validity and enforceability of any other provisions. To the extent any express or implied restrictions are not permitted by applicable laws, these express or implied restrictions shall remain in force and effect to the maximum extent permitted by such applicable laws.",
    "These Terms and Conditions shall be governed by and construed in accordance with the laws of Malaysia, and any dispute in relation thereto shall be subject to the jurisdiction of the Malaysia courts.": "These Terms and Conditions shall be governed by and construed in accordance with the laws of Malaysia, and any dispute in relation thereto shall be subject to the jurisdiction of the Malaysia courts.",
    "SELECT YOUR IDENTITY": "SELECT YOUR IDENTITY",
    "I AM HCP": "I AM HCP",
    "I AM PATIENT": "I AM PATIENT",
    "Welcome": "Welcome",
    "FOR HEALTHCARE PROFESSIONAL ONLY": "FOR HEALTHCARE PROFESSIONAL ONLY",
    "LOGIN": "LOGIN",
    "Terms & Condition": "Terms & Condition",
    "Login": "Login",
    "EMAIL": "EMAIL",
    "PASSWORD": "PASSWORD",
    "Forgot Password": "Forgot Password",
    "Remember me": "Remember me",
    "Forgot Password?": "Forgot Password?",
    "The OTP will sent to your email. Are you sure to continue?": "The OTP will sent to your email. Are you sure to continue?",
    "YES": "YES",
    "NO": "NO",
    "Please fill in the email.": "Please fill in the email.",
    "An email has been sent to": "An email has been sent to",
    ". Please key in the OTP to finish the login process.": ". Please key in the OTP to finish the login process.",
    "Didn't receive the email?": "Didn't receive the email?",
    "RESENT": "RESENT",
    "OTP will be resent in": "OTP will be resent in",
    "I agree with the terms & conditions.": "I agree with the terms & conditions.",
    "Continue": "Continue",
    "Privacy Policy": "Privacy Policy",
    "I agree with the privacy policy.": "I agree with the privacy policy.",
    "Save the webpage before you begin your Journey.": "Save the webpage before you begin your Journey.",
    "Choose your phone": "Choose your phone",
    "Android": "Android",
    "STEP 1": "STEP 1",
    "Tap the menu icon (3 dots in upper right-hand corner).": "Tap the menu icon (3 dots in upper right-hand corner).",
    "STEP 2": "STEP 2",
    'Tap "Add to Home Screen".': 'Tap "Add to Home Screen".',
    "STEP 3": "STEP 3",
    'Rename the website then tap "Add".': 'Rename the website then tap "Add".',
    "STEP 4": "STEP 4",
    "Done.": "Done.",
    "OK": "OK",
    "Tap the Share icon (Safari).": "Tap the Share icon (Safari).",
    "Welcome to the tutorial!": "Welcome to the tutorial!",
    "START": "START",
    "SKIP": "SKIP",
    "Search by patient's MRN, IC or phone number to view their profile and transaction history": "Search by patient's MRN, IC or phone number to view their profile and transaction history",
    "Or register a patient by entering their MRN, IC or phone number": "Or register a patient by entering their MRN, IC or phone number",
    "Enter redemption code that sent to patient's mobile or inbox": "Enter redemption code that sent to patient's mobile or inbox",
    "See records of all patients' submissions and redemptions": "See records of all patients' submissions and redemptions",
    "Check your credit balance of products in your inventory": "Check your credit balance of products in your inventory",
    "Welcome,": "Welcome,",
    "Submission": "Submission",
    "PATIENT MRN/IC/PHONE NO.": "PATIENT MRN/IC/PHONE NO.",
    "SEARCH": "SEARCH",
    "Register": "Register",
    "Search": "Search",
    "Redemption": "Redemption",
    "REDEMPTION CODE": "REDEMPTION CODE",
    "REDEEM": "REDEEM",
    "Enter code": "Enter code",
    "Please enter patient's MRN, IC or phone number": "Please enter patient's MRN, IC or phone number",
    "Please enter the redemption code": "Please enter the redemption code",
    "This number does not exist in our database. Do you wish to register this patient?": "This number does not exist in our database. Do you wish to register this patient?",
    "REGISTER": "REGISTER",
    "Registration successful!": "Registration successful!",
    "TRANSACTION HISTORY": "TRANSACTION HISTORY",
    "CREDIT BALANCE": "CREDIT BALANCE",
    "BRAND INFO": "BRAND INFO",
    "HELP": "HELP",
    "Brand Info": "Brand Info",
    "Help": "Help",
    "Hotline": "Hotline",
    "Contact a member of our Customer Service team!": "Contact a member of our Customer Service team!",
    "CONTACT": "CONTACT",
    "Credit Balance": "Credit Balance",
    "Product": "Product",
    "Total inventory": "Total inventory",
    "Credit balance": "Credit balance",
    "Unit": "Unit",
    "Status": "Status",
    "High": "High",
    "Medium": "Medium",
    "Low": "Low",
    "Lipitor 10 mg": "Lipitor 10 mg",
    "Lipitor 20 mg": "Lipitor 20 mg",
    "Lipitor 40 mg": "Lipitor 40 mg",
    "Lipitor 80 mg": "Lipitor 80 mg",
    "Norvasc 5 mg": "Norvasc 5 mg",
    "Norvasc 10 mg": "Norvasc 10 mg",
    "Caduet 5/10mg": "Caduet 5/10mg",
    "Caduet 5/20mg": "Caduet 5/20mg",
    "Caduet 5/40mg": "Caduet 5/40mg",
    "Caduet 10/10mg": "Caduet 10/10mg",
    "Caduet 10/20mg": "Caduet 10/20mg",
    "Caduet 10/40mg": "Caduet 10/40mg",
    "Viagra 100 mg": "Viagra 100 mg",
    "Legalon 140 mg": "Legalon 140 mg",
    "Viartril-S Sachets 1500 mg": "Viartril-S Sachets 1500 mg",
    "Viartril-S Capsules 500 mg": "Viartril-S Capsules 500 mg",
    "Adaxil Sachets 750mg/600mg": "Adaxil Sachets 750mg/600mg",
    "Transaction history": "Transaction history",
    "MRN/IC/Patient Number": "MRN/IC/Patient Number",
    "Brand": "Brand",
    "Select date": "Select date",
    "Annual cycle end date": "Annual cycle end date",
    "Date": "Date",
    "Place": "Place",
    "Submitted": "Submitted",
    "Redeemed": "Redeemed",
    "Sign up": "Sign up",
    "Mandatory field to be filled": "Mandatory field to be filled",
    "PHONE NUMBER": "PHONE NUMBER",
    "REQUEST OTP": "REQUEST OTP",
    "OTP Verification": "OTP Verification",
    "An SMS has been sent to your phone number": "An SMS has been sent to your phone number",
    "with your OTP. Please key in the OTP to finish the registration process.": "with your OTP. Please key in the OTP to finish the registration process.",
    "VERIFY": "VERIFY",
    "Didn't receive the SMS?": "Didn't receive the SMS?",
    "Medication name cannot be same": "Medication name cannot be same",
    "NRIC / MRN": "NRIC / MRN",
    "SMS PREFERRED LANGUAGE": "SMS PREFERRED LANGUAGE",
    "English": "English",
    "Malay": "Malay",
    "Chinese": "Chinese",
    "MEDICATION": "MEDICATION",
    "Type medication name here": "Type medication name here",
    "Select mg": "Select mg",
    "10 mg": "10 mg",
    "20 mg": "20 mg",
    "40 mg": "40 mg",
    "80 mg": "80 mg",
    "5 mg": "5 mg",
    "5/10mg": "5/10mg",
    "5/20mg": "5/20mg",
    "5/40mg": "5/40mg",
    "10/10mg": "10/10mg",
    "10/20mg": "10/20mg",
    "10/40mg": "10/40mg",
    "100 mg": "100 mg",
    "140 mg": "140 mg",
    "1500 mg": "1500 mg",
    "500 mg": "500 mg",
    "750mg/600mg": "750mg/600mg",
    "200 mg": "200 mg",
    "50 mg": "50 mg",
    "75 mg": "75 mg",
    "Remove": "Remove",
    "ADD MORE": "ADD MORE",
    "SIGN UP": "SIGN UP",
    "Update Medication": "Update Medication",
    "UPDATE": "UPDATE",
    "Please fill in the quantity to complete your submission": "Please fill in the quantity to complete your submission",
    "Pending redemption": "Pending redemption",
    "Reach maximum submission": "Reach maximum submission",
    "Insufficient for redemption": "Insufficient for redemption",
    "Patient Profile": "Patient Profile",
    "Mobile Number": "Mobile Number",
    "NRIC / MRN Number": "NRIC / MRN Number",
    "SUBMISSIONS": "SUBMISSIONS",
    "REDEMPTIONS": "REDEMPTIONS",
    "Submission summary": "Submission summary",
    "Remaining": "Remaining",
    "Registered Medication": "Registered Medication",
    "Credit": "Credit",
    "tablets": "tablets",
    "ADD MORE / EDIT": "ADD MORE / EDIT",
    "Submit": "Submit",
    "Submission History": "Submission History",
    "Submission date": "Submission date",
    "Quantity submitted": "Quantity submitted",
    "HCP code": "HCP code",
    "HCP name": "HCP name",
    "Pending Redemptions": "Pending Redemptions",
    "Medication": "Medication",
    "Quantity": "Quantity",
    "Redemption end date": "Redemption end date",
    "Redemption code": "Redemption code",
    "Redemption History": "Redemption History",
    "Redemption date": "Redemption date",
    "Quantity redeemed": "Quantity redeemed",
    "Redemption Code": "Redemption Code",
    "You are unable to submit more": "You are unable to submit more",
    ". Please complete redemption to continue.": ". Please complete redemption to continue.",
    "You are unable to submit more Lipitor 10 mg. Please complete redemption to continue.": "You are unable to submit more Lipitor 10 mg. Please complete redemption to continue.",
    "You are unable to submit more Lipitor 20 mg. Please complete redemption to continue.": "You are unable to submit more Lipitor 20 mg. Please complete redemption to continue.",
    "You are unable to submit more Lipitor 40 mg. Please complete redemption to continue.": "You are unable to submit more Lipitor 40 mg. Please complete redemption to continue.",
    "You are unable to submit more Lipitor 80 mg. Please complete redemption to continue.": "You are unable to submit more Lipitor 80 mg. Please complete redemption to continue.",
    "You are unable to submit more Norvasc 5 mg. Please complete redemption to continue.": "You are unable to submit more Norvasc 5 mg. Please complete redemption to continue.",
    "You are unable to submit more Norvasc 10 mg. Please complete redemption to continue.": "You are unable to submit more Norvasc 10 mg. Please complete redemption to continue.",
    "You are unable to submit more Caduet 5/10mg. Please complete redemption to continue.": "You are unable to submit more Caduet 5/10mg. Please complete redemption to continue.",
    "You are unable to submit more Caduet 5/20mg. Please complete redemption to continue.": "You are unable to submit more Caduet 5/20mg. Please complete redemption to continue.",
    "You are unable to submit more Caduet 5/40mg. Please complete redemption to continue.": "You are unable to submit more Caduet 5/40mg. Please complete redemption to continue.",
    "You are unable to submit more Caduet 10/10mg. Please complete redemption to continue.": "You are unable to submit more Caduet 10/10mg. Please complete redemption to continue.",
    "You are unable to submit more Caduet 10/20mg. Please complete redemption to continue.": "You are unable to submit more Caduet 10/20mg. Please complete redemption to continue.",
    "You are unable to submit more Caduet 10/40mg. Please complete redemption to continue.": "You are unable to submit more Caduet 10/40mg. Please complete redemption to continue.",
    "You are unable to submit more Viagra 100 mg. Please complete redemption to continue.": "You are unable to submit more Viagra 100 mg. Please complete redemption to continue.",
    "You are unable to submit more Legalon 140 mg. Please complete redemption to continue.": "You are unable to submit more Legalon 140 mg. Please complete redemption to continue.",
    "You are unable to submit more Viartril-S Sachets 1500 mg. Please complete redemption to continue.": "You are unable to submit more Viartril-S Sachets 1500 mg. Please complete redemption to continue.",
    "You are unable to submit more Viartril-S Capsules 500 mg. Please complete redemption to continue.": "You are unable to submit more Viartril-S Capsules 500 mg. Please complete redemption to continue.",
    "You are unable to submit more Adaxil Sachets 750mg/600mg. Please complete redemption to continue.": "You are unable to submit more Adaxil Sachets 750mg/600mg. Please complete redemption to continue.",
    "REDEEM NOW": "REDEEM NOW",
    "VIEW REDEMPTION CODE": "VIEW REDEMPTION CODE",
    "This medication": "This medication",
    "is currently out of stock": "is currently out of stock",
    "This medication (Lipitor 10 mg) is currently out of stock.": "This medication (Lipitor 10 mg) is currently out of stock.",
    "This medication (Lipitor 20 mg) is currently out of stock.": "This medication (Lipitor 20 mg) is currently out of stock.",
    "This medication (Lipitor 40 mg) is currently out of stock.": "This medication (Lipitor 40 mg) is currently out of stock.",
    "This medication (Lipitor 80 mg) is currently out of stock.": "This medication (Lipitor 80 mg) is currently out of stock.",
    "This medication (Norvasc 5 mg) is currently out of stock.": "This medication (Norvasc 5 mg) is currently out of stock.",
    "This medication (Norvasc 10 mg) is currently out of stock.": "This medication (Norvasc 10 mg) is currently out of stock.",
    "This medication (Caduet 5/10mg) is currently out of stock.": "This medication (Caduet 5/10mg) is currently out of stock.",
    "This medication (Caduet 5/20mg) is currently out of stock.": "This medication (Caduet 5/20mg) is currently out of stock.",
    "This medication (Caduet 5/40mg) is currently out of stock.": "This medication (Caduet 5/40mg) is currently out of stock.",
    "This medication (Caduet 10/10mg) is currently out of stock.": "This medication (Caduet 10/10mg) is currently out of stock.",
    "This medication (Caduet 10/20mg) is currently out of stock.": "This medication (Caduet 10/20mg) is currently out of stock.",
    "This medication (Caduet 10/40mg) is currently out of stock.": "This medication (Caduet 10/40mg) is currently out of stock.",
    "This medication (Viagra 100 mg) is currently out of stock.": "This medication (Viagra 100 mg) is currently out of stock.",
    "This medication (Legalon 140 mg) is currently out of stock.": "This medication (Legalon 140 mg) is currently out of stock.",
    "This medication (Viartril-S Sachets 1500 mg) is currently out of stock.": "This medication (Viartril-S Sachets 1500 mg) is currently out of stock.",
    "This medication (Viartril-S Capsules 500 mg) is currently out of stock.": "This medication (Viartril-S Capsules 500 mg) is currently out of stock.",
    "This medication (Adaxil Sachets 750mg/600mg) is currently out of stock.": "This medication (Adaxil Sachets 750mg/600mg) is currently out of stock.",
    "GO BACK": "GO BACK",
    "The patient has changed his/her medication.": "The patient has changed his/her medication.",
    "This medication has been submitted for redemption.": "This medication has been submitted for redemption.",
    "OKAY": "OKAY",
    "This medication is available for instant redemption.": "This medication is available for instant redemption.",
    "This function is only for patient who requires instant collection of complimentary product(s).": "This function is only for patient who requires instant collection of complimentary product(s).",
    "Instant redemption of product(s) is completed before a quality check is performed by the Program Manager.": "Instant redemption of product(s) is completed before a quality check is performed by the Program Manager.",
    "To avoid rejection after redemption is made, please confirm that:": "To avoid rejection after redemption is made, please confirm that:",
    "Your inventory level is adequate for the purpose of this redemption.": "Your inventory level is adequate for the purpose of this redemption.",
    "Patient is eligible for this redemption.": "Patient is eligible for this redemption.",
    "The company shall not be responsible for any potential rejection or loss resulting from any violation/ error by the user of the program.": "The company shall not be responsible for any potential rejection or loss resulting from any violation/ error by the user of the program.",
    "Please click “Agree” below if you wish to proceed.": "Please click “Agree” below if you wish to proceed.",
    "AGREE": "AGREE",
    "NOT NOW": "NOT NOW",
    "Instant Redemption": "Instant Redemption",
    "ENTER REDEMPTION CODE": "ENTER REDEMPTION CODE",
    "Verified": "Verified",
    "Verify": "Verify",
    "Checkout": "Checkout",
    "Submission Quantity": "Submission Quantity",
    "Instant Redemption Quantity": "Instant Redemption Quantity",
    "Redemption successful!": "Redemption successful!",
    "Please enter your redemption code and verify": "Please enter your redemption code and verify",
    "Please verify your redemption code": "Please verify your redemption code",
    "Product name": "Product name",
    "Redeem quantity": "Redeem quantity",
    "Mobile number": "Mobile number",
    "Are you sure you want to redeem these products?": "Are you sure you want to redeem these products?",
    "One normal redemption code for one product": "One normal redemption code for one product",
    "The following redemption is currently expired.": "The following redemption is currently expired.",
    "Do you want to complete the redemption?": "Do you want to complete the redemption?",
    "FOR PATIENT ONLY": "FOR PATIENT ONLY",
    "T&C and Personal Data Protection Act (PDPA)": "T&C and Personal Data Protection Act (PDPA)",
    "Don't have an account?": "Don't have an account?",
    "with your OTP. Please key in the OTP to finish the login process.": "with your OTP. Please key in the OTP to finish the login process.",
    "Enter OTP": "Enter OTP",
    "View your submission history here": "View your submission history here",
    "View your pending redemption and redemption history here": "View your pending redemption and redemption history here",
    "View your submissions and redemptions record here": "View your submissions and redemptions record here",
    "Check your inbox for latest notifications and messages": "Check your inbox for latest notifications and messages",
    "Contact our hotline or read the FAQs here": "Contact our hotline or read the FAQs here",
    "SUBMISSION HISTORY": "SUBMISSION HISTORY",
    "PENDING REDEMPTIONS": "PENDING REDEMPTIONS",
    "REDEMPTION HISTORY": "REDEMPTION HISTORY",
    "Your healthcare provider has changed your medication.": "Your healthcare provider has changed your medication.",
    "INBOX": "INBOX",
    "Inbox": "Inbox",
    "Transaction History": "Transaction History",
    "Redemeed": "Redemeed",
    "TOTAL": "TOTAL",
    "This email does not have HCP account.": "This email does not have HCP account.",
    "Internal Error": "Internal Error",
    "Password is incorrect": "Password is incorrect",
    "Token Expired": "Token Expired",
    "Please enter a valid phone number: Starts with '01', numeric value only and no separator in between": "Please enter a valid phone number: Starts with '01', numeric value only and no separator in between",
    "Sorry, your OTP has been expired.": "Sorry, your OTP has been expired.",
    "OTP not match.": "OTP not match.",
    "Hcp not found": "Hcp not found",
    "Patient registered": "Patient registered",
    "Please fill in all the required input": "Please fill in all the required input",
    "Please verify your phone number before sign up.": "Please verify your phone number before sign up.",
    "Patient not found": "Patient not found",
    "Redemption not found": "Redemption not found",
    "This phone number doesn't have an account.": "This phone number doesn't have an account.",
    "This phone number doesn't have an account. Please sign up.": "This phone number doesn't have an account. Please sign up.",
    "Please scroll down to the end to continue": "Please scroll down to the end to continue",
    "LOG OUT": "LOG OUT",
    "Are you sure want to log out?": "Are you sure want to log out?",
    "CANCEL": "CANCEL",
    "General": "General",
    "What is iCARE Connect+ Program and what does it offer?": "What is iCARE Connect+ Program and what does it offer?",
    "iCARE Connect+ Program is a digital patient assistance program that enables you to take control of your health through health information, medication rebate and support for treatment continuity.": "iCARE Connect+ Program is a digital patient assistance program that enables you to take control of your health through health information, medication rebate and support for treatment continuity.",
    "What happen if my medication has changed?": "What happen if my medication has changed?",
    "You will receive a pop-up notification upon login if HCP updates the medication.": "You will receive a pop-up notification upon login if HCP updates the medication.",
    "Registration": "Registration",
    "How do I register for the program?": "How do I register for the program?",
    "Step 1: Scan QR Code provided by your HCP or visit www.icareconnectplus.com.my to get onboard.": "Step 1: Scan QR Code provided by your HCP or visit www.icareconnectplus.com.my to get onboard.",
    "Step 2: Click Sign Up and key in mobile number for OTP code requisition.": "Step 2: Click Sign Up and key in mobile number for OTP code requisition.",
    "Step 3: Key in OTP code which sent to your mobile through SMS.": "Step 3: Key in OTP code which sent to your mobile through SMS.",
    "Step 4: Fill up your NRIC/ MRN and medication prescribed then click Sign Up.": "Step 4: Fill up your NRIC/ MRN and medication prescribed then click Sign Up.",
    "Step 5: Acknowledge the T&C and privacy policy upon registration.": "Step 5: Acknowledge the T&C and privacy policy upon registration.",
    "Please take some time to read the terms and conditions and privacy policy. We care for your privacy and want you to feel secure on this program. We are committed to respect and maintain your privacy throughout the program.": "Please take some time to read the terms and conditions and privacy policy. We care for your privacy and want you to feel secure on this program. We are committed to respect and maintain your privacy throughout the program.",
    "Is there any other way I can register for this program with other’s help?": "Is there any other way I can register for this program with other’s help?",
    "Yes. Participating hospitals and clinics may help on your behalf for registration. You will need to provide your mobile number and the OTP code you received for verification during the registration process.": "Yes. Participating hospitals and clinics may help on your behalf for registration. You will need to provide your mobile number and the OTP code you received for verification during the registration process.",
    "Can I register in a different hospital/ clinic?": "Can I register in a different hospital/ clinic?",
    "You may register with maximum of 3 different hospitals/ clinics. However, you will need to complete the purchase from the same hospital/ clinic to be eligible for redemption.": "You may register with maximum of 3 different hospitals/ clinics. However, you will need to complete the purchase from the same hospital/ clinic to be eligible for redemption.",
    "Can I register with multiple phone numbers?": "Can I register with multiple phone numbers?",
    "Only one mobile number is allowed to register for each unique NRIC/ MRN. Please inform your healthcare provider if your mobile number has changed.": "Only one mobile number is allowed to register for each unique NRIC/ MRN. Please inform your healthcare provider if your mobile number has changed.",
    "Can I bring forward my submission this year if I have not achieved the redemption mechanics?": "Can I bring forward my submission this year if I have not achieved the redemption mechanics?",
    "Yes. You can bring forward any outstanding submission to the next annual cycle.": "Yes. You can bring forward any outstanding submission to the next annual cycle.",
    "How does the redemption scheme work?": "How does the redemption scheme work?",
    "iCARE Connect+ Program offers medication rebate from participating hospitals and clinics. Talk to your healthcare provider for more information about the scheme.": "iCARE Connect+ Program offers medication rebate from participating hospitals and clinics. Talk to your healthcare provider for more information about the scheme.",
    "How do I go about the medication redemption?": "How do I go about the medication redemption?",
    "Step 1: Make purchase of the prescribed medication from participating hospital/ clinic.": "Step 1: Make purchase of the prescribed medication from participating hospital/ clinic.",
    "Step 2: HCP to submit the medication and key in the quantity purchased.": "Step 2: HCP to submit the medication and key in the quantity purchased.",
    "Step 3: Verification code will be sent to you via SMS when you are eligible for a redemption.": "Step 3: Verification code will be sent to you via SMS when you are eligible for a redemption.",
    "Step 4: Show verification code to HCP to redeem complimentary medication.": "Step 4: Show verification code to HCP to redeem complimentary medication.",
    "When do I need to collect my medication?": "When do I need to collect my medication?",
    "The verification code will be valid for 90 days. You will need to collect your complimentary medication in 90 days, after which the redemption will be forfeited.": "The verification code will be valid for 90 days. You will need to collect your complimentary medication in 90 days, after which the redemption will be forfeited.",
    "What should I do if I received an SMS of completed redemption without doing any transaction?": "What should I do if I received an SMS of completed redemption without doing any transaction?",
    "In the event if you received an SMS of completed redemption without your consent, please reach out to your healthcare professionals for more information.": "In the event if you received an SMS of completed redemption without your consent, please reach out to your healthcare professionals for more information.",
    "Business Rules": "Business Rules",
    "Is there a limit on purchase?": "Is there a limit on purchase?",
    "Yes. The limitation on purchase and redemption differs by the respective medication. Please refer to your healthcare provider for more details.": "Yes. The limitation on purchase and redemption differs by the respective medication. Please refer to your healthcare provider for more details.",
    "MRN/IC registered before.": "MRN/IC registered before.",
    "HCP NAME": "HCP NAME",
    "HCP Name": "HCP Name",
}

const translationBm = {
    "Terms & Conditions": "Terma & Syarat",
    "TERMS AND CONDITIONS (AGREEMENT) FOR PATIENTS iCARE CONNECT+": "TERMA DAN SYARAT (PERJANJIAN) UNTUK PESAKIT iCARE CONNECT+",
    "We wish to support you by providing a treatment assistance program for healthcare products. The Program (as defined below) endeavors to promote accessibility and treatment adherence of healthcare products through the provision of product redemption to you. The Product redemption provided through the Program is only offered by Partners that have signed up for the Program. Redemption will be made only on basis of the prescription, which will be verified from your end.": "Kami ingin menyokong anda dengan menyediakan program bantuan rawatan bagi produk penjagaan kesihatan. Program (seperti yang ditakrifkan di bawah ini) berusaha untuk menggalakkan kebolehcapaian dan pematuhan rawatan terhadap produk penjagaan kesihatan melalui peruntukan penebusan produk kepada anda. Penebusan Produk yang disediakan melalui Program ini hanya ditawarkan oleh Rakan Kongsi yang telah mendaftar untuk Program ini. Penebusan akan hanya dilakukan berdasarkan preskripsi yang akan disahkan daripada pihak anda.",
    'By registering and using the App, you agree to the terms and conditions set forth below by the Company. If you do not agree to the outlined terms and conditions (the "Terms and Conditions"), you are not allowed to participate in the Program or to use the App. The Terms and Conditions may change from time to time, and the Company will notify you of these changes. Your continued use of the App once the Terms and Conditions have been amended constitutes your acceptance of the amended Terms and Conditions.': 'Dengan mendaftar dan menggunakan Aplikasi, anda bersetuju dengan terma dan syarat yang ditetapkan di bawah ini oleh Syarikat. Jika anda tidak bersetuju dengan terma dan syarat yang digariskan ("Terma dan Syarat"), anda tidak dibenarkan mengambil bahagian dalam Program ini atau menggunakan Aplikasi. Terma dan Syarat boleh berubah dari semasa ke semasa dan Syarikat akan memaklumkan anda tentang perubahan ini. Penggunaan Aplikasi yang berterusan oleh anda selepas Terma dan Syarat dipinda membentuk penerimaan anda terhadap Terma dan Syarat yang dipinda itu.',
    "Definition": "Takrif",
    '“App” shall refer to iCARE CONNECT+ digital mobile platform, that serves to provide treatment support services. The App allows the users to do submission to the Company to verify their Redemption eligibility. The App operates on SMS, smartphone, web and/or other technology-based platforms.': '“Aplikasi” hendaklah merujuk kepada platform mudah alih digital iCARE CONNECT+, yang bertindak untuk menyediakan perkhidmatan sokongan rawatan. Aplikasi ini membolehkan pengguna membuat penyerahan kepada Syarikat untuk mengesahkan kelayakan Penebusan mereka. Aplikasi ini beroperasi pada platform SMS, telefon pintar, web dan/atau platform berasaskan teknologi lain.',
    '“Company” shall refer to Click Services Sdn Bhd.': '“Syarikat” hendaklah merujuk kepada Click Services Sdn Bhd.',
    '“Healthcare Professionals” shall refer to healthcare professionals in Malaysia, which includes, but not limited to, medical practitioners, pharmacists, nurses, medical assistants and other allied healthcare professionals and any other person involved in the giving of medical, health, pharmaceutical and any other healthcare services under the jurisdiction of the Ministry of Health of Malaysia that are duly-registered and/or duly-licensed at all times.': '“Profesional Penjagaan Kesihatan” hendaklah merujuk kepada profesional penjagaan kesihatan di Malaysia, yang termasuk, tetapi tidak terhad kepada para pengamal perubatan, ahli farmasi, jururawat, pembantu perubatan dan profesional penjagaan kesihatan bersekutu lain dan mana-mana orang lain yang terlibat dalam hal memberikan perkhidmatan perubatan, kesihatan, farmasi dan apa-apa perkhidmatan penjagaan kesihatan yang lain di bawah bidang kuasa Kementerian Kesihatan Malaysia yang didaftarkan dengan sewajarnya dan/atau berlesen dengan sewajarnya pada sepanjang masa.',
    '“Partners” shall refer to the participating hospitals and/or clinics for this Program.': '“Rakan Kongsi” hendaklah merujuk kepada hospital dan/atau klinik yang mengambil bahagian untuk Program ini.',
    '“Program” shall refer to iCARE CONNECT+ Program for private hospitals and clinics in Malaysia.': '“Program” hendaklah merujuk kepada Program iCARE CONNECT+ untuk hospital dan klinik swasta di Malaysia.',
    '“Product” or “Products”, whichever applicable, shall refer to the healthcare products that are prescribed under the Program.': '“Produk” atau “Produk-produk”, mengikut mana yang berkenaan, hendaklah merujuk kepada produk penjagaan kesihatan yang dipreskripsi di bawah Program.',
    '“Redemption” shall refer to the entitlement of the patient to redeem the Products, provided that requirements set out under these Terms and Conditions are satisfied.': '“Penebusan” hendaklah merujuk kepada kelayakan pesakit untuk menebus Produk, dengan syarat keperluan yang ditetapkan di bawah Terma dan Syarat ini dipenuhi.',
    '“You” shall refer to the patient who is in need of the Products, and shall include customers who are purchasing the Products on behalf of the patient from the Partners under the Program.': '“Anda” hendaklah merujuk kepada pesakit yang memerlukan Produk, dan hendaklah termasuk pelanggan yang membeli Produk bagi pihak pesakit dari Rakan Kongsi di bawah Program ini.',
    "How this Program works": "Bagaimana Program ini berfungsi",
    "The Program is only applicable for Products that are purchased from Partners.": "Program ini hanya boleh digunakan untuk Produk yang dibeli dari Rakan Kongsi.",
    "programWorks": "Program ini bermula pada <b>7 Jun 2022</b> dan akan berterusan sehingga ditamatkan oleh Viatris (“Terma”). Sekiranya Viatris memutuskan untuk menghentikan Program ini, Syarikat akan memberikan notis 30 hari terlebih dahulu kepada pesakit.",
    "In order to help your doctors to manage your health conditions, benefits under the Program are only provided in respect of the Products, and ONLY IF the Products have been adequately prescribed by a Healthcare Professional qualified to do so.": "Untuk tujuan membantu doktor anda menguruskan keadaan kesihatan anda, manfaat-manfaat di bawah Program ini hanya diberikan berhubung dengan Produk ini, dan HANYA JIKA Produk telah dipreskripsi dengan secukupnya oleh Profesional Penjagaan Kesihatan yang berkelayakan berbuat demikian.",
    "For every purchase of Product prescribed by a Healthcare Professional, a submission summary pop-up and notification will be sent to you. You will be required to check and confirm the submission details, which details will be then recorded and updated in the app. Failing of confirmation, which you shall not be entitled to the benefits under this Program.": "Bagi setiap pembelian Produk yang dipreskripsi oleh Profesional Penjagaan Kesihatan, mesej timbul dan pemberitahuan ringkasan penyerahan akan dihantar kepada anda. Anda dikehendaki menyemak dan mengesahkan butiran penyerahan, butiran itu akan direkodkan dan dikemas kini dalam aplikasi. Jika anda gagal mengesahkan ini, anda tidak berhak mendapat manfaat di bawah Program ini.",
    "In order for you to redeem the prescribed Product, you are required show your redemption code of the same medication. Please enquire with your healthcare providers about the redemption details.": "Untuk tujuan membolehkan anda menebus Produk yang dipreskripsi, anda dikehendaki menunjukkan kod penebusan bagi ubat yang sama. Sila bertanya penyedia penjagaan kesihatan anda mengenai butir-butir penebusan.",
    "Upon the first submission, you shall enter a Program period of one (1) year. Thereafter, any Program renewal requires new submission.": "Semasa penyerahan pertama, anda akan memasuki tempoh Program selama satu (1) tahun. Selepas itu, apa-apa pembaharuan Program memerlukan penyerahan baharu.",
    "You will receive an SMS notification with a redemption code once the redemption is verified and approved by the Company.": "Anda akan menerima pemberitahuan SMS dengan kod penebusan sebaik sahaja penebusan disahkan dan diluluskan oleh Syarikat.",
    "You will need to show the redemption code to our Partners in order to collect your redemption medication.": "Anda perlu menunjukkan kod penebusan kepada Rakan Kongsi kami untuk tujuan mengambil ubat penebusan anda.",
    "All Redemptions will be checked and verified by the Company.": "Semua Penebusan akan disemak dan disahkan oleh Syarikat.",
    "You must redeem or collect the complimentary Product within 90 days of receiving the verification / redemption code, after which you are no longer eligible to receive the complimentary Product and the relevant verification / redemption code shall be void.": "Anda mesti menebus atau mengambil Produk percuma dalam masa 90 hari selepas menerima kod pengesahan/penebusan, selepas itu anda tidak lagi layak menerima Produk percuma itu dan kod pengesahan/penebusan yang berkaitan akan terbatal.",
    "There is a cap on the maximum annual quantity allowed for redemption of each Product. Please refer to our Partners for more details.": "Terdapat had pada kuantiti tahunan maksimum yang dibenarkan untuk penebusan bagi setiap Produk. Sila rujuk kepada Rakan Kongsi kami untuk butiran lanjut.",
    "Terms and Conditions": "Terma dan Syarat",
    "You are only eligible to participate in the Program if you are prescribed by our Partners any of the Products offered and marketed by Viatris.": "Anda hanya layak untuk mengambil bahagian dalam Program ini jika anda dipreskripsi oleh Rakan Kongsi kami mana-mana Produk yang ditawarkan dan dipasarkan oleh Viatris.",
    "You will assist the Company to prevent fraudulent use of the Program and shall extend your assistance to the Company in any investigation relating to the Program, which may include but not limited to, fraudulent activities.": "Anda akan membantu Syarikat untuk mencegah penggunaan Program secara penipuan dan hendaklah menghulurkan bantuan anda kepada Syarikat dalam mana-mana penyiasatan yang berkaitan dengan Program, yang mungkin termasuk tetapi tidak terhad kepada, aktiviti penipuan.",
    "The Company reserves the rights, at its sole and absolute discretion, to suspend, cancel or amend the Program at any time, as it deems fit, and the Company will provide 30 days' prior notice for the same.": "Syarikat berhak, atas budi bicaranya sendiri yang mutlak, menggantung, membatalkan atau meminda Program pada bila-bila masa, sebagaimana yang dianggapnya sesuai, dan Syarikat akan memberikan notis 30 hari terlebih dahulu tentang hal yang sama.",
    "The Company reserves the right, at its sole and absolute discretion, to disqualify or terminate your participation, if you are deemed by the Company to be in violation of any terms contained herein.": "Syarikat berhak, atas budi bicaranya sendiri yang mutlak, untuk membatalkan kelayakan atau menamatkan penyertaan anda, jika anda dianggap oleh Syarikat telah melanggar mana-mana terma yang terkandung di dalam perjanjian ini.",
    "You shall comply and abide by all instructions and recommendations given to you by the Healthcare Professional attending to you in respect of the Product and/or Program.": "Anda hendaklah mematuhi dan akur dengan semua arahan dan saranan yang diberikan kepada anda oleh Profesional Penjagaan Kesihatan yang merawat anda berhubung dengan Produk dan/atau Program ini.",
    "You hereby agree that Company will not be liable in contract, tort (including negligence), statute or otherwise for any direct, indirect, incidental, punitive, or consequential damages arising from your wrongful or negligent consumption of the Product, the Partners’ negligent prescription of the Products, the Partners’ and/or your negligent usage of the App or for any other claim related in any way to the negligent use of or inability to use the App or for any breach of any applicable laws and regulations.": "Dengan ini, anda bersetuju bahawa Syarikat tidak akan bertanggungjawab dari segi kontrak, tort (termasuk kecuaian), statut atau dengan cara lain terhadap apa-apa ganti rugi langsung, tidak langsung, sampingan, punitif atau berbangkit yang timbul daripada penggunaan Produk secara salah atau cuai, kecuaian preskripsi Produk oleh Rakan Kongsi, kecuaian penggunaan Aplikasi oleh Rakan Kongsi dan/atau anda atau apa-apa tuntutan lain yang berkaitan dengan apa-apa cara penggunaan yang cuai atau ketidakupayaan untuk menggunakan Aplikasi atau apa-apa perlanggaran dengan mana-mana undang-undang dan peraturan yang diterima pakai.",
    "Pursuant to the Malaysian Personal Data Protection Act 2010, all personal data received as a result of the participation in the Program and App shall be kept strictly confidential and will be used, processed, analyzed, shared etc. only for purposes deemed reasonably necessary to execute the Program.": "Menurut Akta Perlindungan Data Peribadi Malaysia 2010, semua data peribadi yang diterima kerana penyertaan dalam Program dan Aplikasi ini hendaklah disimpan sulit sepenuhnya dan akan digunakan, diproses, dianalisis, dikongsi dan dsb. hanya untuk tujuan yang secara munasabah difikirkan perlu untuk melaksanakan Program.",
    "The data in relation to Redemption is not meant to assess prescriptions pattern or to be used to form decision for provision of any potential sponsorship, support for attendance at meeting, speaking or consultancy engagement, provision of funding, or any other benefits in cash or kind.": "Data yang berkaitan dengan Penebusan tidak bertujuan untuk menilai corak preskripsi atau digunakan untuk membentuk keputusan bagi peruntukan apa-apa kemungkinan penajaan, sokongan untuk kehadiran di perjumpaan, penglibatan bicara atau konsultasi, peruntukan dana atau apa-apa manfaat lain dalam bentuk tunai atau seumpamanya.",
    "To the extent that the Company determines that it is commercially reasonable to implement an electronic data migration and interchange system in relation to the Program, your continued participation in the Program constitutes your acceptance of the aforementioned implementation. Electronic data migration and interchange system refers to the moving of data and information regarding the Program electronically.": "Setakat mana Syarikat menentukan bahawa adalah munasabah secara komersial untuk melaksanakan sistem migrasi dan saling tukar data elektronik berhubung dengan Program ini, penyertaan anda yang berterusan dalam Program ini membentuk penerimaan anda terhadap pelaksanaan yang telah dinyatakan di atas. Sistem migrasi dan saling menukar data elektronik merujuk kepada pengalihan data dan maklumat yang berkaitan Program ini secara elektronik.",
    "Should any of the provisions under these Terms and Conditions be considered unlawful, void or otherwise unenforceable, then such provisions shall be deemed severable from these Terms and Conditions and not affect the validity and enforceability of any other provisions. To the extent any express or implied restrictions are not permitted by applicable laws, these express or implied restrictions shall remain in force and effect to the maximum extent permitted by such applicable laws.": "Sekiranya mana-mana peruntukan di bawah Terma dan Syarat ini dianggap menyalahi undang-undang, terbatal atau dengan cara lain tidak boleh dikuatkuasakan, maka peruntukan sedemikian hendaklah dianggap boleh diasingkan daripada Terma dan Syarat ini dan tidak menjejaskan kesahihan dan kebolehkuatkuasaan mana-mana peruntukan lain. Setakat mana-mana sekatan yang tersurat atau tersirat tidak dibenarkan oleh undang-undang yang diterima pakai, sekatan yang tersurat atau tersirat ini hendaklah terus berkuasa dan dikuatkuasakan sehingga takat maksimum yang dibenarkan oleh undang-undang yang diterima pakai sedemikian.",
    "These Terms and Conditions shall be governed by and construed in accordance with the laws of Malaysia, and any dispute in relation thereto shall be subject to the jurisdiction of the Malaysia courts.": "Terma dan Syarat ini hendaklah ditadbir oleh dan ditafsirkan menurut undang-undang Malaysia, dan apa-apa pertikaian berhubung dengannya hendaklah tertakluk kepada bidang kuasa mahkamah Malaysia.",
    "SELECT YOUR IDENTITY": "PILIH IDENTITI ANDA",
    "I AM HCP": "SAYA HCP",
    "I AM PATIENT": "SAYA PESAKIT",
    "Welcome": "Selamat Datang",
    "FOR HEALTHCARE PROFESSIONAL ONLY": "UNTUK AHLI PROFESIONAL KESIHATAN SAHAJA",
    "LOGIN": "DAFTAR MASUK",
    "Terms & Condition": "Terma & Syarat",
    "Login": "Daftar Masuk",
    "EMAIL": "EMEL",
    "PASSWORD": "KATA LALUAN",
    "Forgot Password": "Lupa Kata Laluan",
    "Remember me": "Ingat Saya",
    "Forgot Password?": "Lupa Kata Laluan?",
    "The OTP will sent to your email. Are you sure to continue?": "OTP akan hantar ke emel anda. Adakah anda pasti meneruskan?",
    "YES": "YA",
    "NO": "TIDAK",
    "Please fill in the email.": "Sila isikan emel anda.",
    "An email has been sent to": "Emel telah dihantar ke",
    ". Please key in the OTP to finish the login process.": ". Sila masukkan OTP untuk lengkapkan proses daftar masuk.",
    "Didn't receive the email?": "Tidak terima emel?",
    "RESENT": "HANTAR SEMULA",
    "OTP will be resent in": "OTP akan dihantar semula dalam",
    "I agree with the terms & conditions.": "Saya setuju dengan terma & syarat",
    "Continue": "SETERUSNYA",
    "Privacy Policy": "Dasar Privasi",
    "I agree with the privacy policy.": "Saya setuju dengan terma & syarat",
    "Save the webpage before you begin your Journey.": "Simpan halaman web sebelum anda memulakan perjalanan anda.",
    "Choose your phone": "Pilih telefon anda",
    "Android": "Android",
    "STEP 1": "LANGKAH 1",
    "Tap the menu icon (3 dots in upper right-hand corner).": "Ketik ikon menu (3 titik di sudut kanan atas).",
    "STEP 2": "LANGKAH 2",
    'Tap "Add to Home Screen".': 'Ketik "Tambah ke Skrin Utama".',
    "STEP 3": "LANGKAH 3",
    'Rename the website then tap "Add".': 'Namakan semula tapak web kemudian ketik "Tambah".',
    "STEP 4": "LANGKAH 4",
    "Done.": "Selesai.",
    "OK": "OK",
    "Tap the Share icon (Safari).": "Ketik ikon Kongsi (Safari).",
    "Welcome to the tutorial!": "Selamat Datang ke Tutorial!",
    "START": "MULA",
    "SKIP": "LANGKAU",
    "Search by patient's MRN, IC or phone number to view their profile and transaction history": "Cari dengan menggunakan MRN, IC atau nombor telefon pesakit untuk lihat profil pesakit dan sejarah urus niaga.",
    "Or register a patient by entering their MRN, IC or phone number": "Atau daftarkan pesakit dengan masukkan MRN, IC atau nombor telefon pesakit.",
    "Enter redemption code that sent to patient's mobile or inbox": "Masukkan kod penebusan yang dihantar ke telefon atau peti mesej pesakit",
    "See records of all patients' submissions and redemptions": "Lihat semua rekod penyertaan dan penebusan pesakit",
    "Check your credit balance of products in your inventory": "Periksa baki kredit produk anda dalam inventori anda",
    "Welcome,": "Selamat Datang,",
    "Submission": "Penyertaan",
    "PATIENT MRN/IC/PHONE NO.": "NO. MRN/IC/PHONE PESAKIT",
    "SEARCH": "CARI",
    "Register": "DAFTAR",
    "Search": "Cari",
    "Redemption": "Penebusan",
    "REDEMPTION CODE": "KOD PENEBUSAN",
    "REDEEM": "TEBUS",
    "Enter code": "Masukkan Kod",
    "Please enter patient's MRN, IC or phone number": "Sila masukkan MRN, IC atau nombor telefon pesakit",
    "Please enter the redemption code": "Sila masukkan kod penebusan",
    "This number does not exist in our database. Do you wish to register this patient?": "Nombor ini tidak wujud dalam pangkalan data kami. Adakah anda ingin mendaftarkan pesakit ini?",
    "REGISTER": "DAFTAR",
    "Registration successful!": "Pendaftaran Berjaya!",
    "TRANSACTION HISTORY": "SEJARAH URUS NIAGA",
    "CREDIT BALANCE": "BAKI KREDIT",
    "BRAND INFO": "INFO JENAMA",
    "HELP": "BANTUAN",
    "Brand Info": "Info Jenama",
    "Help": "Bantuan",
    "Hotline": "Hotline",
    "Contact a member of our Customer Service team!": "Hubungi pasukan Khidmat Pelanggan kami!",
    "CONTACT": "HUBUNGI",
    "Credit Balance": "Baki Kredit",
    "Product": "Produk",
    "Total inventory": "Jumlah Inventori",
    "Credit balance": "Baki Kredit",
    "Unit": "Unit",
    "Status": "Status",
    "High": "Tinggi",
    "Medium": "Sederhana",
    "Low": "Rendah",
    "Lipitor 10 mg": "Lipitor 10 mg",
    "Lipitor 20 mg": "Lipitor 20 mg",
    "Lipitor 40 mg": "Lipitor 40 mg",
    "Lipitor 80 mg": "Lipitor 80 mg",
    "Norvasc 5 mg": "Norvasc 5 mg",
    "Norvasc 10 mg": "Norvasc 10 mg",
    "Caduet 5/10mg": "Caduet 5/10mg",
    "Caduet 5/20mg": "Caduet 5/20mg",
    "Caduet 5/40mg": "Caduet 5/40mg",
    "Caduet 10/10mg": "Caduet 10/10mg",
    "Caduet 10/20mg": "Caduet 10/20mg",
    "Caduet 10/40mg": "Caduet 10/40mg",
    "Viagra 100 mg": "Viagra 100 mg",
    "Legalon 140 mg": "Legalon 140 mg",
    "Viartril-S Sachets 1500 mg": "Viartril-S Sachets 1500 mg",
    "Viartril-S Capsules 500 mg": "Viartril-S Capsules 500 mg",
    "Adaxil Sachets 750mg/600mg": "Adaxil Sachets 750mg/600mg",
    "Transaction history": "Sejarah Urus Niaga",
    "MRN/IC/Patient Number": "MRN/IC/Nombor Pesakit",
    "Brand": "Jenama",
    "Select date": "Pilih tarikh",
    "Annual cycle end date": "Tarikh akhir kitaran tahunan",
    "Date": "Tarikh",
    "Place": "Tempat",
    "Submitted": "Hantar",
    "Redeemed": "Tebus",
    "Sign up": "Daftar",
    "Mandatory field to be filled": "Ruangan wajib diisi",
    "PHONE NUMBER": "NOMBOR TELEFON",
    "REQUEST OTP": "MOHON OTP",
    "OTP Verification": "Pengesahan OTP",
    "An SMS has been sent to your phone number": "SMS telah dihantar ke",
    "with your OTP. Please key in the OTP to finish the registration process.": "bersama OTP anda. Sila masukkan OTP untuk melengkapkan proses daftar.",
    "VERIFY": "SAHKAN",
    "Didn't receive the SMS?": "Tidak terima SMS?",
    "Medication name cannot be same": "Ubatan tidak boleh sama",
    "NRIC / MRN": "NO. KP / MRN",
    "SMS PREFERRED LANGUAGE": "BAHASA PILIHAN SMS",
    "English": "Bahasa Inggeris",
    "Malay": "Bahasa Melayu",
    "Chinese": "Bahasa Cina",
    "MEDICATION": "UBATAN",
    "Type medication name here": "Masukkan nama ubatan",
    "Select mg": "Pilih mg",
    "10 mg": "10 mg",
    "20 mg": "20 mg",
    "40 mg": "40 mg",
    "80 mg": "80 mg",
    "5 mg": "5 mg",
    "5/10mg": "5/10mg",
    "5/20mg": "5/20mg",
    "5/40mg": "5/40mg",
    "10/10mg": "10/10mg",
    "10/20mg": "10/20mg",
    "10/40mg": "10/40mg",
    "100 mg": "100 mg",
    "140 mg": "140 mg",
    "1500 mg": "1500 mg",
    "500 mg": "500 mg",
    "750mg/600mg": "750mg/600mg",
    "200 mg": "200 mg",
    "50 mg": "50 mg",
    "75 mg": "75 mg",
    "Remove": "Keluarkan",
    "ADD MORE": "TAMBAH",
    "SIGN UP": "DAFTAR",
    "Update Medication": "Kemaskini Ubatan",
    "UPDATE": "KEMASKINI",
    "Please fill in the quantity to complete your submission": "Sila isikan kuantiti untuk melengkapkan penyertaan anda",
    "Pending redemption": "Penebusan tangguh",
    "Reach maximum submission": "Capai penyertaan maksimum",
    "Insufficient for redemption": "Tidak mencukupi untuk penebusan",
    "Patient Profile": "Profil Pesakit",
    "Mobile Number": "Nombor Telefon",
    "NRIC / MRN Number": "NRIC / MRN nombor",
    "SUBMISSIONS": "PENYERTAAN",
    "REDEMPTIONS": "PENEBUSAN",
    "Submission summary": "Ringkasan Penyertaan",
    "Remaining": "Baki",
    "Registered Medication": "Ubatan Didaftar",
    "Credit": "Kredit",
    "tablets": "tablet",
    "ADD MORE / EDIT": "TAMBAH / SUNTING",
    "Submit": "HANTAR",
    "Submission History": "Sejarah Penyertaan",
    "Submission date": "Tarikh Penyertaan",
    "Quantity submitted": "Kuantiti Penyertaan",
    "HCP code": "Kod HCP",
    "HCP name": "Nama HCP",
    "Pending Redemptions": "Penangguhan Penebusan",
    "Medication": "Ubatan",
    "Quantity": "Kuantiti",
    "Redemption end date": "Tarikh Akhir Menebus",
    "Redemption code": "Kod Penebusan",
    "Redemption History": "Sejarah Penebusan",
    "Redemption date": "Tarikh Menebus",
    "Quantity redeemed": "Kuantiti Ditebus",
    "Redemption Code": "Kod Penebusan",
    "You are unable to submit more": "Anda tidak boleh masukkan",
    ". Please complete redemption to continue.": " lagi. Sila lengkapkan proses penebusan untuk sambung.",
    "You are unable to submit more Lipitor 10 mg. Please complete redemption to continue.": "Anda tidak boleh masukkan Lipitor 10 mg lagi. Sila lengkapkan proses penebusan untuk sambung.",
    "You are unable to submit more Lipitor 20 mg. Please complete redemption to continue.": "Anda tidak boleh masukkan 20 mg lagi. Sila lengkapkan proses penebusan untuk sambung.",
    "You are unable to submit more Lipitor 40 mg. Please complete redemption to continue.": "Anda tidak boleh masukkan 40 mg lagi. Sila lengkapkan proses penebusan untuk sambung.",
    "You are unable to submit more Lipitor 80 mg. Please complete redemption to continue.": "Anda tidak boleh masukkan 80 mg lagi. Sila lengkapkan proses penebusan untuk sambung.",
    "You are unable to submit more Norvasc 5 mg. Please complete redemption to continue.": "Anda tidak boleh masukkan Norvasc 5 mg lagi. Sila lengkapkan proses penebusan untuk sambung.",
    "You are unable to submit more Norvasc 10 mg. Please complete redemption to continue.": "Anda tidak boleh masukkan Norvasc 10 mg lagi. Sila lengkapkan proses penebusan untuk sambung.",
    "You are unable to submit more Caduet 5/10mg. Please complete redemption to continue.": "Anda tidak boleh masukkan Caduet 5/10mg lagi. Sila lengkapkan proses penebusan untuk sambung.",
    "You are unable to submit more Caduet 5/20mg. Please complete redemption to continue.": "Anda tidak boleh masukkan Caduet 5/20mg lagi. Sila lengkapkan proses penebusan untuk sambung.",
    "You are unable to submit more Caduet 5/40mg. Please complete redemption to continue.": "Anda tidak boleh masukkan Caduet 5/40mg lagi. Sila lengkapkan proses penebusan untuk sambung.",
    "You are unable to submit more Caduet 10/10mg. Please complete redemption to continue.": "Anda tidak boleh masukkan Caduet 10/10mg lagi. Sila lengkapkan proses penebusan untuk sambung.",
    "You are unable to submit more Caduet 10/20mg. Please complete redemption to continue.": "Anda tidak boleh masukkan Caduet 10/20mg lagi. Sila lengkapkan proses penebusan untuk sambung.",
    "You are unable to submit more Caduet 10/40mg. Please complete redemption to continue.": "Anda tidak boleh masukkan Caduet 10/40mg lagi. Sila lengkapkan proses penebusan untuk sambung.",
    "You are unable to submit more Viagra 100 mg. Please complete redemption to continue.": "Anda tidak boleh masukkan Viagra 100 mg lagi. Sila lengkapkan proses penebusan untuk sambung.",
    "You are unable to submit more Legalon 140 mg. Please complete redemption to continue.": "Anda tidak boleh masukkan Legalon 140 mg lagi. Sila lengkapkan proses penebusan untuk sambung.",
    "You are unable to submit more Viartril-S Sachets 1500 mg. Please complete redemption to continue.": "Anda tidak boleh masukkan Viartril-S Sachets 1500 mg lagi. Sila lengkapkan proses penebusan untuk sambung.",
    "You are unable to submit more Viartril-S Capsules 500 mg. Please complete redemption to continue.": "Anda tidak boleh masukkan Viartril-S Capsules 500 mg lagi. Sila lengkapkan proses penebusan untuk sambung.",
    "You are unable to submit more Adaxil Sachets 750mg/600mg. Please complete redemption to continue.": "Anda tidak boleh masukkan Adaxil Sachets 750mg/600mg lagi. Sila lengkapkan proses penebusan untuk sambung.",
    "REDEEM NOW": "TEBUS SEKARANG",
    "VIEW REDEMPTION CODE": "LIHAT KOD PENEBUSAN",
    "This medication": "Ubat ini",
    "is currently out of stock": "telah habis stok",
    "This medication (Lipitor 10 mg) is currently out of stock.": "Ubat ini (Lipitor 10 mg) telah habis stok.",
    "This medication (Lipitor 20 mg) is currently out of stock.": "Ubat ini (Lipitor 20 mg) telah habis stok.",
    "This medication (Lipitor 40 mg) is currently out of stock.": "Ubat ini (Lipitor 40 mg) telah habis stok.",
    "This medication (Lipitor 80 mg) is currently out of stock.": "Ubat ini (Lipitor 80 mg) telah habis stok.",
    "This medication (Norvasc 5 mg) is currently out of stock.": "Ubat ini (Norvasc 5 mg) telah habis stok.",
    "This medication (Norvasc 10 mg) is currently out of stock.": "Ubat ini (Norvasc 10 mg) telah habis stok.",
    "This medication (Caduet 5/10mg) is currently out of stock.": "Ubat ini (Caduet 5/10mg) telah habis stok.",
    "This medication (Caduet 5/20mg) is currently out of stock.": "Ubat ini (Caduet 5/20mg) telah habis stok.",
    "This medication (Caduet 5/40mg) is currently out of stock.": "Ubat ini (Caduet 5/40mg) telah habis stok.",
    "This medication (Caduet 10/10mg) is currently out of stock.": "Ubat ini (Caduet 10/10mg) telah habis stok.",
    "This medication (Caduet 10/20mg) is currently out of stock.": "Ubat ini (Caduet 10/20mg) telah habis stok.",
    "This medication (Caduet 10/40mg) is currently out of stock.": "Ubat ini (Caduet 10/40mg) telah habis stok.",
    "This medication (Viagra 100 mg) is currently out of stock.": "Ubat ini (Viagra 100 mg) telah habis stok.",
    "This medication (Legalon 140 mg) is currently out of stock.": "Ubat ini (Legalon 140 mg) telah habis stok.",
    "This medication (Viartril-S Sachets 1500 mg) is currently out of stock.": "Ubat ini (Viartril-S Sachets 1500 mg) telah habis stok.",
    "This medication (Viartril-S Capsules 500 mg) is currently out of stock.": "Ubat ini (Viartril-S Capsules 500 mg) telah habis stok.",
    "This medication (Adaxil Sachets 750mg/600mg) is currently out of stock.": "Ubat ini (Adaxil Sachets 750mg/600mg) telah habis stok.",
    "GO BACK": "KEMBALI",
    "The patient has changed his/her medication.": "Pesakit telah melakukan perubatan ubatan mereka.",
    "This medication has been submitted for redemption.": "Ubatan ini telah disertakan",
    "OKAY": "OKAY",
    "This medication is available for instant redemption.": "Ubat ini telah sedia untuk penebusan segera.",
    "This function is only for patient who requires instant collection of complimentary product(s).": "Penebusan ini hanya untuk pesakit yang memerlukan koleksi segera produk yang percuma.",
    "Instant redemption of product(s) is completed before a quality check is performed by the Program Manager.": "Penebusan Segera produk perlu dilengkap sebelum pemeriksaan kualiti yang akan dilakukan oleh Pengurus Program.",
    "To avoid rejection after redemption is made, please confirm that:": "Untuk mengelakkan daripada ditolak selepas penebusan dilakukan sila pastikan:",
    "Your inventory level is adequate for the purpose of this redemption.": "Tahap inventori anda cukup untuk tujuan penebusan ini.",
    "Patient is eligible for this redemption.": "Pesakit layak untuk melakukan penebusan ini.",
    "The company shall not be responsible for any potential rejection or loss resulting from any violation/ error by the user of the program.": "Syarikat ini tidak akan bertanggungjawab pada mana-mana   penolakan atau kehilangan yang menyebabkan keganasan atau ralat oleh pengguna program ini.",
    "Please click “Agree” below if you wish to proceed.": "Sila klik “Setuju” di bawah jika anda ingin teruskan.",
    "AGREE": "SETUJU",
    "NOT NOW": "BUKAN SEKARANG",
    "Instant Redemption": "Penebusan Segera",
    "ENTER REDEMPTION CODE": "MASUKKAN KOD PENEBUSAN",
    "Verified": "Disahkan",
    "Verify": "Sahkan",
    "Checkout": "Semak Keluar",
    "Submission Quantity": "Kuantiti Dihantar",
    "Instant Redemption Quantity": "Kuantiti Penebusan Segera",
    "Redemption successful!": "Penebusan Berjaya!",
    "Please enter your redemption code and verify": "Sila masukkan kod penebusan dan sahkan",
    "Please verify your redemption code": "Sila sahkan kod penebusan",
    "Product name": "Produk",
    "Redeem quantity": "Kuantiti Ditebus",
    "Mobile number": "Nombor Telefon",
    "Are you sure you want to redeem these products?": "Adakah anda pasti untuk tebus produk-produk ini?",
    "One normal redemption code for one product": "Satu kod tebusan untuk satu produk",
    "The following redemption is currently expired.": "Penebusan ini sudah tamat tempoh.",
    "Do you want to complete the redemption?": "Anda ingin melengkapkan proses penebusan?",
    "FOR PATIENT ONLY": "UNTUK PESAKIT SAHAJA",
    "T&C and Personal Data Protection Act (PDPA)": "T&S and Akta Perlindungan Data Peribadi (PDPA) ",
    "Don't have an account?": "Tidak mempunyai akaun?",
    "with your OTP. Please key in the OTP to finish the login process.": "bersama OTP anda. Sila masukkan OTP untuk melengkapkan proses daftar.",
    "Enter OTP": "Masukkan OTP",
    "View your submission history here": "Lihat sejarah penyerahan anda di sini",
    "View your pending redemption and redemption history here": "Lihat penebusan dan sejarah penebusan anda yang belum selesai di sini",
    "View your submissions and redemptions record here": "Lihat rekod penyerahan dan penebusan anda di sini",
    "Check your inbox for latest notifications and messages": "Semak peti masuk anda untuk mendapatkan pemberitahuan dan mesej terkini",
    "Contact our hotline or read the FAQs here": "Hubungi talian hotline kami atau baca Soalan Lazim di sini.",
    "SUBMISSION HISTORY": "SEJARAH PENYERTAAN",
    "PENDING REDEMPTIONS": "PENEBUSAN TERTANGGUH",
    "REDEMPTION HISTORY": "SEJARAH PENEBUSAN",
    "Your healthcare provider has changed your medication.": "Penjaga kesihatan anda telah menukar ubat anda.",
    "INBOX": "PETI MASUK",
    "Inbox": "Peti Masuk",
    "Transaction History": "Sejarah Urus Niaga",
    "Redemeed": "Tebus",
    "TOTAL": "JUMLAH",
    "This email does not have HCP account.": "E-mel ini tidak mempunyai akaun HCP.",
    "Internal Error": "Masalah dalaman",
    "Password is incorrect": "Kata laluan tidak betul",
    "Token Expired": "Token Tamat Tempoh",
    "Please enter a valid phone number: Starts with '01', numeric value only and no separator in between": "Sila masukkan nombor telefon yang sah: Bermula dengan '01', nilai angka sahaja dan tiada pemisah di antaranya",
    "Sorry, your OTP has been expired.": "Maaf, OTP anda telah tamat tempoh.",
    "OTP not match.": "OTP tidak sepadan.",
    "Hcp not found": "Hcp tidak ditemui",
    "Patient registered": "Pesakit telah berdaftar",
    "Please fill in all the required input": "Sila isikan semua input yang diperlukan",
    "Please verify your phone number before sign up.": "Sila sahkan nombor telefon anda sebelum mendaftar.",
    "Patient not found": "Pesakit tidak ditemui",
    "Redemption not found": "Penebusan tidak ditemui",
    "This phone number doesn't have an account.": "Nombor telefon ini tiada akaun.",
    "This phone number doesn't have an account. Please sign up.": "Nombor telefon ini tiada akaun. Sila daftar.",
    "Please scroll down to the end to continue": "Sila tatal ke bawah hingga akhir untuk meneruskan",
    "LOG OUT": "LOG KELUAR",
    "Are you sure want to log out?": "Adakah anda pasti mahu log keluar?",
    "CANCEL": "BATAL",
    "General": "Umum",
    "What is iCARE Connect+ Program and what does it offer?": "Apakah itu Program iCARE Connect+ dan apa program ini menawarkan?",
    "iCARE Connect+ Program is a digital patient assistance program that enables you to take control of your health through health information, medication rebate and support for treatment continuity.": "Program iCARE Connect+ adalah program digital bantuan pesakit yang membolehkan anda untuk mengawal keadaan kesihatan anda melalui informasi kesihatan, rebat ubat dan sokongan untuk pemulihan yang berterusan.",
    "What happen if my medication has changed?": "Apa akan berlaku jika ubat-ubatan saya berubah?",
    "You will receive a pop-up notification upon login if HCP updates the medication.": "Anda akan menerima pop-up notifikasi setelah log masuk jika HCP kemaskini ubat-ubatan anda.",
    "Registration": "Pendaftaran",
    "How do I register for the program?": "Bagaimana untuk saya mendaftar program ini?",
    "Step 1: Scan QR Code provided by your HCP or visit www.icareconnectplus.com.my to get onboard.": "Langkah 1: Imbas Kod QR yang disediakan oleh HCP anda atau layari www.icareconnectplus.com.my untuk mula.",
    "Step 2: Click Sign Up and key in mobile number for OTP code requisition.": "Langkah 2: Klik Daftar dan masukkan nombor telefon untuk permohonan kod OTP.",
    "Step 3: Key in OTP code which sent to your mobile through SMS.": "Langkah 3: Masukkan kod OTP yang telah dihantar ke telefon anda melalui SMS.",
    "Step 4: Fill up your NRIC/ MRN and medication prescribed then click Sign Up.": "Langkah 4: Sila isi nombor NRIC/MRN anda dan ubat yang telah ditetapkan kemudian tekan daftar.",
    "Step 5: Acknowledge the T&C and privacy policy upon registration.": "Langkah 5: Mengetahui tentang terma & syarat dan Dasar polisi setelah mendaftar.",
    "Please take some time to read the terms and conditions and privacy policy. We care for your privacy and want you to feel secure on this program. We are committed to respect and maintain your privacy throughout the program.": "Sila ambil masa anda untuk membaca terma dan syarat dan Dasar polisi. Kami sangat berhati-hati dengan privasi anda dan mahu anda berasa selamat di dalam program ini. Kami sangat komited untuk hormat dan mengekalkan privasi anda sepanjang program ini.",
    "Is there any other way I can register for this program with other’s help?": "Adakah terdapat cara lain saya boleh mendaftar untuk program ini dengan bantuan orang lain?",
    "Yes. Participating hospitals and clinics may help on your behalf for registration. You will need to provide your mobile number and the OTP code you received for verification during the registration process.": "Ya. Hospital dan klinik yang mengambil bahagian akan menolong anda untuk mendaftar. Anda perlu menyediakan nombot telefon anda dan anda akan menerima pengesahan ketika proses mendaftar.",
    "Can I register in a different hospital/ clinic?": "Bolehkah saya mendaftar di hospital/klinik lain?",
    "You may register with maximum of 3 different hospitals/ clinics. However, you will need to complete the purchase from the same hospital/ clinic to be eligible for redemption.": "Anda boleh mendaftar dengan maksimum 3 hospital/klinik yang berbeza. Walau bagaimanapun, anda perlu melengkapkan pembelian dari hospital/klinik yang sama untuk mendapat kelayakan untuk penebusan.",
    "Can I register with multiple phone numbers?": "Bolehkah saya mendaftar dengan beberapa nombor telefon?",
    "Only one mobile number is allowed to register for each unique NRIC/ MRN. Please inform your healthcare provider if your mobile number has changed.": "Hanya satu nombor telefon bimbit dibenarkan untuk mendaftar bagi setiap kad pengenalan/MRN yang unik. Sila maklumkan kepada pakar kesihatan anda jika nombor telefon anda berubah.",
    "Can I bring forward my submission this year if I have not achieved the redemption mechanics?": "Bolehkah saya mengemukakan penyerahan saya tahun ini jika saya belum mencapai mekanik penebusan?",
    "Yes. You can bring forward any outstanding submission to the next annual cycle.": "Ya. Anda boleh mengemukankan mana-mana penyerahan tertuggak ke kitaran tahunan seterusnya.",
    "How does the redemption scheme work?": "Bagaimana skim penebusan ini berfungsi?",
    "iCARE Connect+ Program offers medication rebate from participating hospitals and clinics. Talk to your healthcare provider for more information about the scheme.": "Program iCARE Connect+ menawarkan rebat ubat-ubatan daripada hospital dan klinik yang mengambil bahagian. Sila berbincang dengan pakar kesihatan anda untuk informasi yang lebih lanjut tentang skim ini.",
    "How do I go about the medication redemption?": "Bagaimana cara untuk menebus ubat?",
    "Step 1: Make purchase of the prescribed medication from participating hospital/ clinic.": "Langkah 1: Melakukan pembelian ubat yang dicadangkan daripada hospital dan klinik yang mengambil bahagian.",
    "Step 2: HCP to submit the medication and key in the quantity purchased.": "Langkah 2: HCP harus memasukkan ubat-ubatan dan masukkan kuantiti yang dibeli.",
    "Step 3: Verification code will be sent to you via SMS when you are eligible for a redemption.": "Langkah 3: Kod pengesahan akan dihantar kepada anda melalui SMS apabila anda mendapat kelayakan untuk penebusan.",
    "Step 4: Show verification code to HCP to redeem complimentary medication.": "Langkah 4: Tunjukkan kod pengesahan kepada HCP untuk tebus ubat-ubatan percuma.",
    "When do I need to collect my medication?": "Bilakah saya perlu mengambil ubat saya?",
    "The verification code will be valid for 90 days. You will need to collect your complimentary medication in 90 days, after which the redemption will be forfeited.": "Kod pengesahan akan sah selama 90 hari. Anda perlu mengambil ubat percuma anda dalam 90 hari, jika melebihi 90 hari penebusan tersebut akan dibatalkan.",
    "What should I do if I received an SMS of completed redemption without doing any transaction?": "Apakah yang perlu saya lakukan jika saya menerima SMS penebusan yang lengkap tanpa melakukan sebarang transaksi?",
    "In the event if you received an SMS of completed redemption without your consent, please reach out to your healthcare professionals for more information.": "Sekiranya anda menerima SMS penebusan yang lengkap tanpa kebenaran anda, sila hubungi pakar kesihatan untuk mendapatkan maklumat lanjut.",
    "Business Rules": "Peraturan Urusan",
    "Is there a limit on purchase?": "Adakah terdapat had pembelian?",
    "Yes. The limitation on purchase and redemption differs by the respective medication. Please refer to your healthcare provider for more details.": "Ya. Had pembelian dan penebusan adalah berbeza mengikut ubat-ubatan. Sila rujuk pakar kesihatan anda untuk maklumat lebih lanjut.",
    "MRN/IC registered before.": "MRN/No.KP telah berdaftar",
    "HCP NAME": "NAMA HCP",
    "HCP Name": "Nama HCP",
}

const translationCh = {
    "Terms & Conditions": "条款 & 条件",
    "TERMS AND CONDITIONS (AGREEMENT) FOR PATIENTS iCARE CONNECT+": "患者条款和条件（协议）iCARE CONNECT+",
    "We wish to support you by providing a treatment assistance program for healthcare products. The Program (as defined below) endeavors to promote accessibility and treatment adherence of healthcare products through the provision of product redemption to you. The Product redemption provided through the Program is only offered by Partners that have signed up for the Program. Redemption will be made only on basis of the prescription, which will be verified from your end.": "我们希望提供一个以医疗保健药品作为辅助治疗方式的计划来协助您。本计划（定义如下）致力通过提供产品兑换的优惠，以促进医疗保健药品的可及性和治疗的依从性。本计划的药品兑换优惠仅限于已注册此计划的合作伙伴。兑换的药品仅可透过处方领取，且需由您自行核实。",
    'By registering and using the App, you agree to the terms and conditions set forth below by the Company. If you do not agree to the outlined terms and conditions (the "Terms and Conditions"), you are not allowed to participate in the Program or to use the App. The Terms and Conditions may change from time to time, and the Company will notify you of these changes. Your continued use of the App once the Terms and Conditions have been amended constitutes your acceptance of the amended Terms and Conditions.': '透过注册并使用本应用程序，您即同意由本公司下述所制定的条款和条件。若您不同意以下条款和条件（“条款及条件”），您将无法参与本计划或使用本应用程序。条款和条件可能不时修改，若有修改，本公司将另行通知您。若您在本条款及条件被修改后仍持续使用此应用程式，您将被视为已接受更改后的条款及条件。',
    "Definition": "定义",
    '“App” shall refer to iCARE CONNECT+ digital mobile platform, that serves to provide treatment support services. The App allows the users to do submission to the Company to verify their Redemption eligibility. The App operates on SMS, smartphone, web and/or other technology-based platforms.': '“应用程序” 意指iCARE CONNECT+数码移动平台，用于提供辅助治疗服务。本应用程序允许用户向本公司提交相关数据以验证其兑换资格。应用程序可在简讯、智能型手机、网页及／或其他技术基础平台运作。',
    '“Company” shall refer to Click Services Sdn Bhd.': '“公司” 意指Click Services Sdn Bhd。',
    '“Healthcare Professionals” shall refer to healthcare professionals in Malaysia, which includes, but not limited to, medical practitioners, pharmacists, nurses, medical assistants and other allied healthcare professionals and any other person involved in the giving of medical, health, pharmaceutical and any other healthcare services under the jurisdiction of the Ministry of Health of Malaysia that are duly-registered and/or duly-licensed at all times.': '“医疗保健专业人员” 意指马来西亚的医疗保健专业人员，包括但不限于医师、药剂师、护士、医疗助理和其他医疗保健专业人员，以及所有其他参与提供医疗、健康、药物及受马来西亚卫生部规范下的任何经注册或登记之其他医疗保健服务人员。',
    '“Partners” shall refer to the participating hospitals and/or clinics for this Program.': '“合作伙伴” 意指参与本计划的医院和/或诊所。',
    '“Program” shall refer to iCARE CONNECT+ Program for private hospitals and clinics in Malaysia.': '“计划” 意指为马来西亚私人医院和诊所而设的iCARE CONNECT+计划。',
    '“Product” or “Products”, whichever applicable, shall refer to the healthcare products that are prescribed under the Program.': '“药品” 意指本计划指定的医疗保健药品。',
    '“Redemption” shall refer to the entitlement of the patient to redeem the Products, provided that requirements set out under these Terms and Conditions are satisfied.': '“兑换” 意指患者以符合本条款和条件之要求为前提，有权兑换及领取药品。',
    '“You” shall refer to the patient who is in need of the Products, and shall include customers who are purchasing the Products on behalf of the patient from the Partners under the Program.': '“您” 意指需要药品的患者，包括代替患者本人从合作伙伴处购买药品的消费者。',
    "How this Program works": "计划运作方式",
    "The Program is only applicable for Products that are purchased from Partners.": "本计划仅适用于从合作伙伴处购买的药品。",
    'programWorks': '本计划于<b>2022 年 6月 7 日</b>开始，并持续至Viatris宣布终止（“期限”）。若Viatris决定终止本计划，本公司将提前30天通知消费者。',
    "In order to help your doctors to manage your health conditions, benefits under the Program are only provided in respect of the Products, and ONLY IF the Products have been adequately prescribed by a Healthcare Professional qualified to do so.": "为了帮助您的医生管理您的健康状况，本计划下的福利仅针对指定药品提供，且须由合格的医疗保健专业人员所开立之处方。",
    "For every purchase of Product prescribed by a Healthcare Professional, a submission summary pop-up and notification will be sent to you. You will be required to check and confirm the submission details, which details will be then recorded and updated in the app. Failing of confirmation, which you shall not be entitled to the benefits under this Program.": "每次购买由医疗保健专业人员开立之药品时，提交摘要将透过弹出窗口及通知发送给您。您需要检查并确认提交的详细信息，且相关信息将纪录并更新于应用程序中。若您未进行确认，您将无法享受本计划提供之福利。",
    "In order for you to redeem the prescribed Product, you are required show your redemption code of the same medication. Please enquire with your healthcare providers about the redemption details.": "为了兑换指定药品，，您需要出示对应药物的兑换码。请向您的医疗人员咨询兑换详情。",
    "Upon the first submission, you shall enter a Program period of one (1) year. Thereafter, any Program renewal requires new submission.": "首次提交数据后，您将享有参加为期一年计划的资格。此后，每次更新计划皆需重新提交。",
    "You will receive an SMS notification with a redemption code once the redemption is verified and approved by the Company.": "经本公司验证并批准您的兑换信息后，您将收到带有兑换代码的简讯通知。",
    "You will need to show the redemption code to our Partners in order to collect your redemption medication.": "您需要向我们的合作伙伴出示兑换代码，才能领取您的兑换药物。",
    "All Redemptions will be checked and verified by the Company.": "本公司将对所有兑换信息进行审查和验证。",
    "You must redeem or collect the complimentary Product within 90 days of receiving the verification / redemption code, after which you are no longer eligible to receive the complimentary Product and the relevant verification / redemption code shall be void.": "您必须在收到验证／兑换代码后的 90 天内兑换或领取免费药品。否则您将不再具有获得免费药品的资格，且相关验证／兑换代码将被作废。",
    "There is a cap on the maximum annual quantity allowed for redemption of each Product. Please refer to our Partners for more details.": "每个药品的年度最高兑换量均设有上限。请咨询我们的合作伙伴以了解更多详情。",
    "Terms and Conditions": "条款和条件",
    "You are only eligible to participate in the Program if you are prescribed by our Partners any of the Products offered and marketed by Viatris.": "只有在我们的合作伙伴为您开立Viatris提供及发售之任何药品时，您才具有参与本计划的资格。",
    "You will assist the Company to prevent fraudulent use of the Program and shall extend your assistance to the Company in any investigation relating to the Program, which may include but not limited to, fraudulent activities.": "您将协助本公司防止针对此计划的任何欺诈性使用，并协助任何与此计划有关的调查，其中包括但不限于欺诈性活动。",
    "The Company reserves the rights, at its sole and absolute discretion, to suspend, cancel or amend the Program at any time, as it deems fit, and the Company will provide 30 days' prior notice for the same.": "本公司保留在认为合适的任何时间自行决定暂停、取消或修改本计划之权利；本公司将针对前述变动，提前 30 天发出通知。",
    "The Company reserves the right, at its sole and absolute discretion, to disqualify or terminate your participation, if you are deemed by the Company to be in violation of any terms contained herein.": "如果本公司认为您违反了本条款的任何内容，本公司保留自行决定取消或终止您参与资格的权利。",
    "You shall comply and abide by all instructions and recommendations given to you by the Healthcare Professional attending to you in respect of the Product and/or Program.": "您应遵从专业医疗人员就药品和/或计划向您提供的所有指示和建议。",
    "You hereby agree that Company will not be liable in contract, tort (including negligence), statute or otherwise for any direct, indirect, incidental, punitive, or consequential damages arising from your wrongful or negligent consumption of the Product, the Partners’ negligent prescription of the Products, the Partners’ and/or your negligent usage of the App or for any other claim related in any way to the negligent use of or inability to use the App or for any breach of any applicable laws and regulations.": "由于您对指定药品的错误或疏忽使用、合作伙伴疏忽地以指定药品开的药方、合作伙伴和/或您对应用程式的疏忽使用、任何其他与疏忽使用或无法使用此应用程式有关的索赔、或任何违反有关法律及法规的行为，而造成的任何直接、间接、附带、惩罚性、專項或相应而生的损害，您谨此同意本公司将不会在合同、侵权行为（包括疏忽）、法规或其他方面承担以上任何责任。",
    "Pursuant to the Malaysian Personal Data Protection Act 2010, all personal data received as a result of the participation in the Program and App shall be kept strictly confidential and will be used, processed, analyzed, shared etc. only for purposes deemed reasonably necessary to execute the Program.": "根据马来西亚《2010年个人资料保护法》，所有因参与本计划和应用程序而获得的个人资料，均将严格保密，仅限于执行本程序的合理、必要范围内使用。",
    "The data in relation to Redemption is not meant to assess prescriptions pattern or to be used to form decision for provision of any potential sponsorship, support for attendance at meeting, speaking or consultancy engagement, provision of funding, or any other benefits in cash or kind.": "与药品兑换有关的数据不会用于评估处方模式，也不会用来决定是否提供潜在的赞助、资助会议出席、演讲或咨询服务、资金或任何其他现金或实物等好处。",
    "To the extent that the Company determines that it is commercially reasonable to implement an electronic data migration and interchange system in relation to the Program, your continued participation in the Program constitutes your acceptance of the aforementioned implementation. Electronic data migration and interchange system refers to the moving of data and information regarding the Program electronically.": "若公司确定实施与本计划相关的数据移动和交换系统，并认为这在商业角度上是合理的，则您继续参与本计划即表示您接受上述措施。电子数据移动和交换系统是指以电子方式移动有关程序的数据和信息。",
    "Should any of the provisions under these Terms and Conditions be considered unlawful, void or otherwise unenforceable, then such provisions shall be deemed severable from these Terms and Conditions and not affect the validity and enforceability of any other provisions. To the extent any express or implied restrictions are not permitted by applicable laws, these express or implied restrictions shall remain in force and effect to the maximum extent permitted by such applicable laws.": "若本条款中的任何条款被认为非法、无效或无法执行，则该等条款应被视为可与其他条款分离，且不影响其他条款的有效性和可执行性。如果适用法律不允许任何明示或默示限制，则这些明示或默示限制应在该法律允许的最大范围内继续有效。",
    "These Terms and Conditions shall be governed by and construed in accordance with the laws of Malaysia, and any dispute in relation thereto shall be subject to the jurisdiction of the Malaysia courts.": "这些条款和条件应受马来西亚法律管辖并根据其解释，与此相关的任何争议应受马来西亚法院之管辖。",
    "SELECT YOUR IDENTITY": "选择您的身份",
    "I AM HCP": "我是HCP",
    "I AM PATIENT": "我是患者",
    "Welcome": "欢迎",
    "FOR HEALTHCARE PROFESSIONAL ONLY": "医疗保健仅限专业人士",
    "LOGIN": "登入",
    "Terms & Condition": "条款与协议",
    "Login": "登入",
    "EMAIL": "邮箱",
    "PASSWORD": "密码",
    "Forgot Password": "忘记密码",
    "Remember me": "记住密码",
    "Forgot Password?": "忘记密码？",
    "The OTP will sent to your email. Are you sure to continue?": "一次性密码（OTP）将发送到您的邮箱。你确定要继续吗？",
    "YES": "确认",
    "NO": "取消",
    "Please fill in the email.": "请输入您的邮箱。",
    "An email has been sent to": "确认邮件已经发送到您的邮箱",
    ". Please key in the OTP to finish the login process.": "。 请输入一次性密码（OTP） 完成登录过程。",
    "Didn't receive the email?": "没有收到邮件？",
    "RESENT": "重新发送",
    "OTP will be resent in": "可重新发送 OTP",
    "I agree with the terms & conditions.": "我同意条款和条件。",
    "Continue": "确认",
    "Privacy Policy": "隐私政策",
    "I agree with the privacy policy.": "我同意隐私政策。",
    "Save the webpage before you begin your Journey.": "在您开始之前，不妨先保存这精彩网页。",
    "Choose your phone": "选择您的手机系统",
    "Android": "安卓",
    "STEP 1": "步骤 1",
    "Tap the menu icon (3 dots in upper right-hand corner).": "点击菜单图标（右上角的 3 个点）。",
    "STEP 2": "步骤 2",
    'Tap "Add to Home Screen".': '点击“添加到主屏幕”。',
    "STEP 3": "步骤 3",
    'Rename the website then tap "Add".': '重命名网站，然后点击“添加”。',
    "STEP 4": "步骤 4",
    "Done.": "完成。",
    "OK": "确认",
    "Tap the Share icon (Safari).": "点击共享图标 (Safari)。",
    "Welcome to the tutorial!": "欢迎来到教程！",
    "START": "开始",
    "SKIP": "跳过",
    "Search by patient's MRN, IC or phone number to view their profile and transaction history": "按患者的 MRN、IC 或电话号码搜索以查看他们的个人资料和交易历史",
    "Or register a patient by entering their MRN, IC or phone number": "或通过输入患者的 MRN、IC 或电话号码来注册患者",
    "Enter redemption code that sent to patient's mobile or inbox": "输入发送到患者手机或邮件箱的兑换码",
    "See records of all patients' submissions and redemptions": "查看所有患者提交和兑换的记录",
    "Check your credit balance of products in your inventory": "检查库存中产品的积分额度",
    "Welcome,": "欢迎回来,",
    "Submission": "提交药物",
    "PATIENT MRN/IC/PHONE NO.": "患者 MRN/IC/电话号码",
    "SEARCH": "搜索",
    "Register": "注册",
    "Search": "搜索",
    "Redemption": "兑换",
    "REDEMPTION CODE": "兑换码",
    "REDEEM": "兑换",
    "Enter code": "输入兑换码",
    "Please enter patient's MRN, IC or phone number": "请输入患者的 MRN、IC 或电话号码",
    "Please enter the redemption code": "请输入兑换码",
    "This number does not exist in our database. Do you wish to register this patient?": "这个号码在我们的数据库中不存在。您要注册该患者吗？",
    "REGISTER": "立即注册",
    "Registration successful!": "注册成功！",
    "TRANSACTION HISTORY": "交易记录",
    "CREDIT BALANCE": "积分额度",
    "BRAND INFO": "品牌信息",
    "HELP": "帮助",
    "Brand Info": "品牌信息",
    "Help": "帮助",
    "Hotline": "热线",
    "Contact a member of our Customer Service team!": "拨打热线联系我们的客户服务团队成员！",
    "CONTACT": "联络",
    "Credit Balance": "积分额度",
    "Product": "产品",
    "Total inventory": "总库存",
    "Credit balance": "积分额度",
    "Unit": "单元",
    "Status": "状态",
    "High": "高",
    "Medium": "中等",
    "Low": "低",
    "Lipitor 10 mg": "Lipitor 10毫克",
    "Lipitor 20 mg": "Lipitor 20毫克",
    "Lipitor 40 mg": "Lipitor 40毫克",
    "Lipitor 80 mg": "Lipitor 80毫克",
    "Norvasc 5 mg": "Norvasc 5毫克",
    "Norvasc 10 mg": "Norvasc 10毫克",
    "Caduet 5/10mg": "Caduet 5/10毫克",
    "Caduet 5/20mg": "Caduet 5/20毫克",
    "Caduet 5/40mg": "Caduet 5/40毫克",
    "Caduet 10/10mg": "Caduet 10/10毫克",
    "Caduet 10/20mg": "Caduet 10/20毫克",
    "Caduet 10/40mg": "Caduet 10/40毫克",
    "Viagra 100 mg": "Viagra 100毫克",
    "Legalon 140 mg": "Legalon 140毫克",
    "Viartril-S Sachets 1500 mg": "Viartril-S Sachets 1500毫克",
    "Viartril-S Capsules 500 mg": "Viartril-S Capsules 500毫克",
    "Adaxil Sachets 750mg/600mg": "Adaxil Sachets 750mg/600毫克",
    "Transaction history": "交易记录",
    "MRN/IC/Patient Number": "MRN/IC/患者电话号码",
    "Brand": "品牌",
    "Select date": "选择日期",
    "Annual cycle end date": "年度周期结束日期",
    "Date": "日期",
    "Place": "地点",
    "Submitted": "提交",
    "Redeemed": "兑换",
    "Sign up": "注册",
    "Mandatory field to be filled": "必填项",
    "PHONE NUMBER": "电话号码",
    "REQUEST OTP": "申请一次性密码（OTP）",
    "OTP Verification": "一次性密码认证",
    "An SMS has been sent to your phone number": "确认邮件已经发送到您的电话号码",
    "with your OTP. Please key in the OTP to finish the registration process.": "。请输入一次性密码（OTP） 完成登录过程。",
    "VERIFY": "认证",
    "Didn't receive the SMS?": "没有收到邮件？",
    "Medication name cannot be same": "药物不能重复",
    "NRIC / MRN": "NRIC / MRN",
    "SMS PREFERRED LANGUAGE": "短信首选语言",
    "English": "英文",
    "Malay": "马来文",
    "Chinese": "简体中文",
    "MEDICATION": "药物",
    "Type medication name here": "输入药物名",
    "Select mg": "选择毫克",
    "10 mg": "10 毫克",
    "20 mg": "20 毫克",
    "40 mg": "40 毫克",
    "80 mg": "80 毫克",
    "5 mg": "5 毫克",
    "5/10mg": "5/10毫克",
    "5/20mg": "5/20毫克",
    "5/40mg": "5/40毫克",
    "10/10mg": "10/10毫克",
    "10/20mg": "10/20毫克",
    "10/40mg": "10/40毫克",
    "100 mg": "100 毫克",
    "140 mg": "140 毫克",
    "1500 mg": "1500 毫克",
    "500 mg": "500 毫克",
    "750mg/600mg": "750毫克/600毫克",
    "200 mg": "200 毫克",
    "50 mg": "50 毫克",
    "75 mg": "75 毫克",
    "Remove": "移除",
    "ADD MORE": "添加更多",
    "SIGN UP": "注册",
    "Update Medication": "更新药物",
    "UPDATE": "更新",
    "Please fill in the quantity to complete your submission": "请输入数量以完成提交",
    "Pending redemption": "待兑换",
    "Reach maximum submission": "已达提交上限",
    "Insufficient for redemption": "不足",
    "Patient Profile": "患者资料",
    "Mobile Number": "电话号码",
    "NRIC / MRN Number": "NRIC / MRN  号码",
    "SUBMISSIONS": "提交",
    "REDEMPTIONS": "兑换",
    "Submission summary": "药物提交总结",
    "Remaining": "剩余",
    "Registered Medication": "注册药物",
    "Credit": "积分",
    "tablets": "片剂",
    "ADD MORE / EDIT": "添加更多 / 编辑",
    "Submit": "提交",
    "Submission History": "提交历史",
    "Submission date": "提交日期",
    "Quantity submitted": "兑换数量",
    "HCP code": "HCP 码",
    "HCP name": "HCP 名",
    "Pending Redemptions": "待兑换",
    "Medication": "药物",
    "Quantity": "数量",
    "Redemption end date": "兑换结束日期",
    "Redemption code": "兑换码",
    "Redemption History": "兑换历史",
    "Redemption date": "兑换日期",
    "Quantity redeemed": "兑换数量",
    "Redemption Code": "兑换码",
    "You are unable to submit more": "您无法提交更多",
    ". Please complete redemption to continue.": "。 请完成兑换以继续。",
    "You are unable to submit more Lipitor 10 mg. Please complete redemption to continue.": "您无法提交更多 Lipitor 10 mg。 请完成兑换以继续。",
    "You are unable to submit more Lipitor 20 mg. Please complete redemption to continue.": "您无法提交更多 Lipitor 20 mg。 请完成兑换以继续。",
    "You are unable to submit more Lipitor 40 mg. Please complete redemption to continue.": "您无法提交更多 Lipitor 40 mg。 请完成兑换以继续。",
    "You are unable to submit more Lipitor 80 mg. Please complete redemption to continue.": "您无法提交更多 Lipitor 80 mg。 请完成兑换以继续。",
    "You are unable to submit more Norvasc 5 mg. Please complete redemption to continue.": "您无法提交更多 Norvasc 5 mg。 请完成兑换以继续。",
    "You are unable to submit more Norvasc 10 mg. Please complete redemption to continue.": "您无法提交更多 Norvasc 10 mg。 请完成兑换以继续。",
    "You are unable to submit more Caduet 5/10mg. Please complete redemption to continue.": "您无法提交更多 Caduet 5/10mg。 请完成兑换以继续。",
    "You are unable to submit more Caduet 5/20mg. Please complete redemption to continue.": "您无法提交更多 Caduet 5/20mg。 请完成兑换以继续。",
    "You are unable to submit more Caduet 5/40mg. Please complete redemption to continue.": "您无法提交更多 Caduet 5/40mg。 请完成兑换以继续。",
    "You are unable to submit more Caduet 10/10mg. Please complete redemption to continue.": "您无法提交更多 Caduet 10/10mg。 请完成兑换以继续。",
    "You are unable to submit more Caduet 10/20mg. Please complete redemption to continue.": "您无法提交更多 Caduet 10/20mg。 请完成兑换以继续。",
    "You are unable to submit more Caduet 10/40mg. Please complete redemption to continue.": "您无法提交更多 Caduet 10/40mg。 请完成兑换以继续。",
    "You are unable to submit more Viagra 100 mg. Please complete redemption to continue.": "您无法提交更多 Viagra 100 mg。 请完成兑换以继续。",
    "You are unable to submit more Legalon 140 mg. Please complete redemption to continue.": "您无法提交更多 Legalon 140 mg。 请完成兑换以继续。",
    "You are unable to submit more Viartril-S Sachets 1500 mg. Please complete redemption to continue.": "您无法提交更多 Viartril-S Sachets 1500 mg。 请完成兑换以继续。",
    "You are unable to submit more Viartril-S Capsules 500 mg. Please complete redemption to continue.": "您无法提交更多 Viartril-S Capsules 500 mg。 请完成兑换以继续。",
    "You are unable to submit more Adaxil Sachets 750mg/600mg. Please complete redemption to continue.": "您无法提交更多 Adaxil Sachets 750mg/600mg。 请完成兑换以继续。",
    "REDEEM NOW": "马上兑换",
    "VIEW REDEMPTION CODE": "查看兑换码",
    "This medication": "药物",
    "is currently out of stock": "目前缺货。",
    "This medication (Lipitor 10 mg) is currently out of stock.": "药物 (Lipitor 10 mg) 目前缺货。",
    "This medication (Lipitor 20 mg) is currently out of stock.": "药物 (Lipitor 20 mg) 目前缺货。",
    "This medication (Lipitor 40 mg) is currently out of stock.": "药物 (Lipitor 40 mg) 目前缺货。",
    "This medication (Lipitor 80 mg) is currently out of stock.": "药物 (Lipitor 80 mg) 目前缺货。",
    "This medication (Norvasc 5 mg) is currently out of stock.": "药物 (Norvasc 5 mg) 目前缺货。",
    "This medication (Norvasc 10 mg) is currently out of stock.": "药物 (Norvasc 10 mg) 目前缺货。",
    "This medication (Caduet 5/10mg) is currently out of stock.": "药物 (Caduet 5/10mg) 目前缺货。",
    "This medication (Caduet 5/20mg) is currently out of stock.": "药物 (Caduet 5/20mg) 目前缺货。",
    "This medication (Caduet 5/40mg) is currently out of stock.": "药物 (Caduet 5/40mg) 目前缺货。",
    "This medication (Caduet 10/10mg) is currently out of stock.": "药物 (Caduet 10/10mg) 目前缺货。",
    "This medication (Caduet 10/20mg) is currently out of stock.": "药物 (Caduet 10/20mg) 目前缺货。",
    "This medication (Caduet 10/40mg) is currently out of stock.": "药物 (Caduet 10/40mg) 目前缺货。",
    "This medication (Viagra 100 mg) is currently out of stock.": "药物 (Viagra 100 mg) 目前缺货。",
    "This medication (Legalon 140 mg) is currently out of stock.": "药物 (Legalon 140 mg) 目前缺货。",
    "This medication (Viartril-S Sachets 1500 mg) is currently out of stock.": "药物 (Viartril-S Sachets 1500 mg) 目前缺货。",
    "This medication (Viartril-S Capsules 500 mg) is currently out of stock.": "药物 (Viartril-S Capsules 500 mg) 目前缺货。",
    "This medication (Adaxil Sachets 750mg/600mg) is currently out of stock.": "药物 (Adaxil Sachets 750mg/600mg) 目前缺货。",
    "GO BACK": "确认",
    "The patient has changed his/her medication.": "患者已改变了他/她的药物。",
    "This medication has been submitted for redemption.": "此药物已提交",
    "OKAY": "确认",
    "This medication is available for instant redemption.": "这种药物可用于即时兑换。",
    "This function is only for patient who requires instant collection of complimentary product(s).":  "此功能仅适用于需要即时领取免费产品的患者。",
    "Instant redemption of product(s) is completed before a quality check is performed by the Program Manager.": "在项目经理执行质量检查之前完成产品的即时兑换。",
    "To avoid rejection after redemption is made, please confirm that:": "为避免兑换后被拒，请确认：",
    "Your inventory level is adequate for the purpose of this redemption.": "您的库存水平足以进行此次兑换",
    "Patient is eligible for this redemption.": "患者有资格获得此兑换。",
    "The company shall not be responsible for any potential rejection or loss resulting from any violation/ error by the user of the program.": "对于因程序用户的任何违规/错误而导致的任何潜在拒绝或损失，本公司概不负责。",
    "Please click “Agree” below if you wish to proceed.": "如果您想继续，请单击下面的“同意”。",
    "AGREE": "同意",
    "NOT NOW": "取消",
    "Instant Redemption": "即使兑换",
    "ENTER REDEMPTION CODE": "输入兑换码",
    "Verified": "已认证",
    "Verify": "认证",
    "Checkout": "确认",
    "Submission Quantity": "提交数量",
    "Instant Redemption Quantity": "即时兑换数量",
    "Redemption successful!": "兑换成功！",
    "Please enter your redemption code and verify": "请输入兑换码后认证",
    "Please verify your redemption code": "请认证兑换码",
    "Product name": "产品",
    "Redeem quantity": "兑换数量",
    "Mobile number": "电话号码",
    "Are you sure you want to redeem these products?": "您确定要兑换这些产品吗？",
    "One normal redemption code for one product": "一个产品对应一个普通兑换码",
    "The following redemption is currently expired.": "以下兑换目前已过期无效。",
    "Do you want to complete the redemption?": "您要完成兑换吗？",
    "FOR PATIENT ONLY": "仅限患者使用",
    "T&C and Personal Data Protection Act (PDPA)": "条款和条件和个人数据保护法 (PDPA)",
    "Don't have an account?": "没有账号？",
    "with your OTP. Please key in the OTP to finish the login process.": "。请输入一次性密码（OTP） 完成登录过程。",
    "Enter OTP": "输入一次性密码（OTP）",
    "View your submission history here": "点击此处查看您的药物提交历史",
    "View your pending redemption and redemption history here": "点击此处查看您的待兑换和兑换历史记录",
    "View your submissions and redemptions record here": "点击此处查看您的提交和兑换记录",
    "Check your inbox for latest notifications and messages": "检查您的信箱以获取最新的通知和讯息",
    "Contact our hotline or read the FAQs here": "联系我们的热线或在此处阅读常见问题解答",
    "SUBMISSION HISTORY": "提交历史",
    "PENDING REDEMPTIONS": "待兑换",
    "REDEMPTION HISTORY": "兑换历史",
    "Your healthcare provider has changed your medication.": "您的医疗保健提供者已更改您的药物。",
    "INBOX": "信息",
    "Inbox": "信息",
    "Transaction History": "交易记录历史",
    "Redemeed": "兑换",
    "TOTAL": "总共",
    "This email does not have HCP account.": "此电子邮件没有 HCP 账号。",
    "Internal Error": "内部错误",
    "Password is incorrect": "密码不正确",
    "Token Expired": "请重新登入",
    "Please enter a valid phone number: Starts with '01', numeric value only and no separator in between": "请输入有效的电话号码：以 '01' 开头，只有数字，中间没有分隔符",
    "Sorry, your OTP has been expired.": "抱歉，您的 OTP 已过期。",
    "OTP not match.": "OTP 不匹配。",
    "Hcp not found": "未找到 Hcp",
    "Patient registered": "患者已注册",
    "Please fill in all the required input": "请填写所有必填项",
    "Please verify your phone number before sign up.": "请在注册前验证您的电话号码。",
    "Patient not found": "找不到患者",
    "Redemption not found": "未找到兑换",
    "This phone number doesn't have an account.": "这个电话号码没有账号。",
    "This phone number doesn't have an account. Please sign up.": "这个电话号码没有账号，请注册。",  
    "Please scroll down to the end to continue": "请向下滚动到末尾以继续",
    "LOG OUT": "登出",
    "Are you sure want to log out?": "您确定要登出吗？",
    "CANCEL": "取消",
    "General": "通用",
    "What is iCARE Connect+ Program and what does it offer?": "什么是 iCARE Connect+ 计划，它提供什么？",
    "iCARE Connect+ Program is a digital patient assistance program that enables you to take control of your health through health information, medication rebate and support for treatment continuity.": "iCARE Connect+ 计划是一项电子患者援助计划，使您能够通过健康信息、药物回扣和对治疗连续性的支持来控制您的健康。",
    "What happen if my medication has changed?": "如果我的处方药改变了怎么办？",
    "You will receive a pop-up notification upon login if HCP updates the medication.": "如果 HCP 更新药物，您将在登录时收到弹出通知。",
    "Registration": "注册",
    "How do I register for the program?": "我该如何注册iCARE Connect+计划？",
    "Step 1: Scan QR Code provided by your HCP or visit www.icareconnectplus.com.my to get onboard.": "第 1 步：扫描您的 HCP 提供的二维码或访问 www.icareconnectplus.com.my 以加入。",
    "Step 2: Click Sign Up and key in mobile number for OTP code requisition.": "第 2 步：单击注册并输入手机号码以获取 OTP 代码。",
    "Step 3: Key in OTP code which sent to your mobile through SMS.": "第 3 步：输入通过 SMS 发送到您的手机的 OTP 代码。",
    "Step 4: Fill up your NRIC/ MRN and medication prescribed then click Sign Up.": "第 4 步：填写您的 NRIC/MRN 和处方药，然后点击注册。",
    "Step 5: Acknowledge the T&C and privacy policy upon registration.": "第 5 步：在注册时确认 T&C 和隐私政策。",
    "Please take some time to read the terms and conditions and privacy policy. We care for your privacy and want you to feel secure on this program. We are committed to respect and maintain your privacy throughout the program.": "请仔细阅读条款和条件以及隐私政策。 我们关心您的隐私，并希望您在此计划中得到保障。 我们致力于在整个计划中尊重和维护您的隐私。",
    "Is there any other way I can register for this program with other’s help?": "我可以通过其他方式注册iCARE Connect+计划吗？",
    "Yes. Participating hospitals and clinics may help on your behalf for registration. You will need to provide your mobile number and the OTP code you received for verification during the registration process.": "是的。 参与的医院和诊所可能会代表您帮助您进行注册。 在注册过程中，您只需要提供您的手机号码和收到的 OTP 代码以进行验证。",
    "Can I register in a different hospital/ clinic?": "我可以在不同的医院/诊所注册吗？",
    "You may register with maximum of 3 different hospitals/ clinics. However, you will need to complete the purchase from the same hospital/ clinic to be eligible for redemption.": "您最多可以在 3 家不同的医院/诊所注册。 但是，您需要在同一家医院/诊所完成购买才有资格兑换。",
    "Can I register with multiple phone numbers?": "我可以注册多个电话号码吗？",
    "Only one mobile number is allowed to register for each unique NRIC/ MRN. Please inform your healthcare provider if your mobile number has changed.": "每个 NRIC/MRN 只允许一个手机号码注册。 如果您的手机号码已更改，请通知您的医疗保健提供者。",
    "Can I bring forward my submission this year if I have not achieved the redemption mechanics?": "如果我没有达到兑换所需的积分，我可以累积到下一个年度周期吗？",
    "Yes. You can bring forward any outstanding submission to the next annual cycle.": "是的。 您可以将任何兑换的积分累计到下一个年度周期。",
    "How does the redemption scheme work?": "兑换计划如何运作？",
    "iCARE Connect+ Program offers medication rebate from participating hospitals and clinics. Talk to your healthcare provider for more information about the scheme.": "iCARE Connect+ 计划提供参与医院和诊所的药物回扣。 请咨询您的医疗保健提供者，了解有关该计划的更多信息。",
    "How do I go about the medication redemption?": "我该如何兑换药物？",
    "Step 1: Make purchase of the prescribed medication from participating hospital/ clinic.": "第 1 步：从参与的医院/诊所购买处方药。",
    "Step 2: HCP to submit the medication and key in the quantity purchased.": "第 2 步：HCP 提交药物并输入购买的数量。",
    "Step 3: Verification code will be sent to you via SMS when you are eligible for a redemption.": "第 3 步：当您符合兑换条件时，验证码将通过短信发送给您。",
    "Step 4: Show verification code to HCP to redeem complimentary medication.": "第 4 步：向 HCP 出示验证码以兑换免费药物。",
    "When do I need to collect my medication?": "我什么时候可以领取已兑换成功免费药呢？",
    "The verification code will be valid for 90 days. You will need to collect your complimentary medication in 90 days, after which the redemption will be forfeited.": "验证码有效期为 90 天。 您需要在 90 天内领取您的免费药物，否则将会被作废。",
    "What should I do if I received an SMS of completed redemption without doing any transaction?": "如果我收到了已成功兑换的短信提醒，但我没有进行任何交易，我该怎么办？",
    "In the event if you received an SMS of completed redemption without your consent, please reach out to your healthcare professionals for more information.": "如果在未经您同意的情况下收到已成功兑换的短信提醒，请联系您的医疗保健专业人员以获取更多信息。",
    "Business Rules": "商业规则",
    "Is there a limit on purchase?": "购买药物有限制吗？",
    "Yes. The limitation on purchase and redemption differs by the respective medication. Please refer to your healthcare provider for more details.": "有的。 购买和兑换的限制因各自的药物而异。 有关详细信息，请咨询您的医疗保健提供者。",
    "MRN/IC registered before.": "MRN/IC 已注册",
    "HCP NAME": "HCP 名",
    "HCP Name": "HCP 名",
}

const resources = {
    en: {
        translation: translationEn
    },
    bm: {
        translation: translationBm
    },
    ch: {
        translation: translationCh
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: localStorage.getItem("patient-language") || "en",
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;