import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { MDBDataTableV5 } from 'mdbreact';
import { CircularProgress, Checkbox, TextField, Autocomplete, Drawer } from "@mui/material";
import axios from 'axios';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import EditIcon from '@mui/icons-material/Edit';
import { Modal } from 'react-bootstrap';
import { styled } from '@mui/material/styles';
import { medicationSkuList } from "../dynamicController";

// components
import Sidebar from "./components/sidebar";
import Header from "./components/header";

const mySwal = withReactContent(Swal);

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-20%`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      maxWidth: "80%"
    }),
  }),
);

const PendingRedemptionReport = ({ role, token }) => {

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [productList, setProductList] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [redemptionId, setRedemptionId] = useState("");
  const [accountName, setAccountName] = useState("");
  const [accountCode, setAccountCode] = useState("");
  const [patientId, setPatientId] = useState("");
  const [sku, setSku] = useState("");
  const [status, setStatus] = useState("");
  const [remarks, setRemarks] = useState("");
  const [reassignment, setReassignment] = useState("");
  const [reassignmentList, setReassignmentList] = useState([]);
  const [validationStatus, setValidationStatus] = useState("");
  const [oriStatus, setOriStatus] = useState("");
  const [openSidebar, setOpenSidebar] = useState(true);
  const [reassignmentHistory, setReassignmentHistory] = useState("");
  const [showIcMrn, setShowIcMrn] = useState(false);

  let tableData = [];

  useEffect(() => {
    if (role && token) {
      axios.post("/api/admin/pendingRedemption", { role, token, productList: [] })
        .then(res => {
          if (res.data.showIcMrn) {
            setShowIcMrn(res.data.showIcMrn)
          }
          setData(res.data.data);
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
          if (err.response.data.error === "Token Expired") {
            mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
              .then(click => {
                if (click.isConfirmed) {
                  navigate('/admin/login');
                }
              })
          } else {
            mySwal.fire("Error", err.response.data.error, "error")
          }
        });
    } else {
      navigate('/admin/login');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDate = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }

  const handleFilter = () => {
    setLoading(true);

    let sendThis = {
      role,
      token,
      productList
    }

    if (startDate && endDate) {
      sendThis.startDate = new Date(startDate).getFullYear() + "-" + (new Date(startDate).getMonth() + 1) + "-" + new Date(startDate).getDate()
      sendThis.endDate = new Date(endDate).getFullYear() + "-" + (new Date(endDate).getMonth() + 1) + "-" + new Date(endDate).getDate()
    }

    axios.post('/api/admin/pendingRedemption', sendThis)
      .then(res => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        if (err.response.data.error === "Token Expired") {
          mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
            .then(click => {
              if (click.isConfirmed) {
                navigate('/admin/login');
              }
            })
        } else {
          mySwal.fire("Error", err.response.data.error, "error")
        }
      });
  }

  const handleReset = () => {
    setStartDate("");
    setEndDate("");
    setProductList([]);
    setLoading(true);

    axios.post('/api/admin/pendingRedemption', { role, token, productList: [] })
      .then(res => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        if (err.response.data.error === "Token Expired") {
          mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
            .then(click => {
              if (click.isConfirmed) {
                navigate('/admin/login');
              }
            })
        } else {
          mySwal.fire("Error", err.response.data.error, "error")
        }
      });
  }

  const showEditModal = (redemptionId, accountName, accountCode, patientId, sku, status, remarks, reassignment, reassignmentList, validationStatus) => {
    setRedemptionId(redemptionId);
    setAccountName(accountName);
    setAccountCode(accountCode);
    setPatientId(patientId);
    setSku(sku);
    setStatus(status);
    setOriStatus(status);
    if (remarks !== "-") {
      setRemarks(remarks);
    } else {
      setRemarks("Collection Overdue");
    }
    if (reassignment !== "-") {
      setReassignmentHistory(reassignment);
    }
    setReassignmentList(reassignmentList);
    setValidationStatus(validationStatus);
    setEditModal(true);
  }

  const hideEditModal = () => {
    setRedemptionId("");
    setAccountName("");
    setAccountCode("");
    setPatientId("");
    setSku("");
    setStatus("");
    setOriStatus("");
    setRemarks("");
    setReassignment("");
    setReassignmentHistory("");
    setReassignmentList([]);
    setValidationStatus("");
    setEditModal(false);
  }

  const reassignRedemption = () => {
    if (reassignmentHistory) {
      mySwal.fire("Error", "This redemption have reassigned", "error")
    } else {
      if (validationStatus === "Approve" || validationStatus === "Reassign") {
        if (oriStatus === "Expired") {
          if (status === "Re-Allocate") {
            if (remarks) {
              if (reassignment) {
                setLoading(true);
                axios.post("/api/admin/reassignRedemption", { role, token, redemptionId, remarks, reassignment })
                  .then(res => {
                    axios.post('/api/admin/pendingRedemption', { role, token, startDate, endDate, productList })
                      .then(res => {
                        setData(res.data.data);
                        setRedemptionId("");
                        setAccountName("");
                        setAccountCode("");
                        setPatientId("");
                        setSku("");
                        setStatus("");
                        setOriStatus("");
                        setRemarks("");
                        setReassignment("");
                        setReassignmentHistory("");
                        setReassignmentList([]);
                        setValidationStatus("");
                        setEditModal(false);
                        setLoading(false);
                      })
                      .catch(err => {
                        setLoading(false);
                        if (err.response.data.error === "Token Expired") {
                          mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
                            .then(click => {
                              if (click.isConfirmed) {
                                navigate('/admin/login');
                              }
                            })
                        } else {
                          mySwal.fire("Error", err.response.data.error, "error")
                        }
                      });
                  })
                  .catch(err => {
                    setLoading(false);
                    if (err.response.data.error === "Token Expired") {
                      mySwal.fire({ title: "Error", text: err.response.data.error, icon: "error", allowOutsideClick: false })
                        .then(click => {
                          if (click.isConfirmed) {
                            navigate('/admin/login');
                          }
                        })
                    } else {
                      mySwal.fire("Error", err.response.data.error, "error")
                    }
                  });
              } else {
                mySwal.fire("Error", "Please choose the reassignment", "error")
              }
            } else {
              mySwal.fire("Error", "Please choose the remarks", "error")
            }
          } else {
            mySwal.fire("Error", "Please choose the status to Re-Allocate", "error")
          }
        } else {
          mySwal.fire("Error", "Only expired redemption can re-allocate", "error")
        }
      } else {
        mySwal.fire("Error", "Only redemption validation status is approve can reassignment", "error")
      }
    }
  }

  let devTableData = {
    columns: [
      {
        field: "hcpType",
        label: "HCP Type",
        sort: "asc"
      },
      {
        field: "accountName",
        label: "Account Name",
        sort: "asc"
      },
      {
        field: "accountCode",
        label: "Account Code",
        sort: "asc"
      },
      {
        field: "patientId",
        label: "Patient ID",
        sort: "asc"
      },
      {
        field: "sku",
        label: "Redeemed SKU",
        sort: "asc"
      },
      {
        field: "releaseDate",
        label: "Redemption Code Release Date",
        sort: "asc"
      },
      {
        field: "dueForCollection",
        label: "Due for Collection",
        sort: "asc"
      },
      {
        field: "quantityToRedeem",
        label: "Quantity To Redeem",
        sort: "asc"
      },
      {
        field: "redemptionStatus",
        label: "Redemption Status",
        sort: "asc"
      },
      {
        field: "patientMobile",
        label: "Patient Mobile",
        sort: "asc"
      },
      {
        field: "se",
        label: "SE Name",
        sort: "asc"
      },
      {
        field: "remarks",
        label: "Remarks",
        sort: "asc"
      },
      {
        field: "patientReassign",
        label: "Patient Re-assign",
        sort: "asc"
      },
      {
        field: "action",
        label: "Action",
        sort: "asc"
      },
    ],
    rows: tableData ? tableData : []
  };

  let hcpTableData = {
    columns: [
      {
        field: "hcpType",
        label: "HCP Type",
        sort: "asc"
      },
      {
        field: "accountName",
        label: "Account Name",
        sort: "asc"
      },
      {
        field: "accountCode",
        label: "Account Code",
        sort: "asc"
      },
      {
        field: "patientId",
        label: "Patient ID",
        sort: "asc"
      },
      {
        field: "sku",
        label: "Redeemed SKU",
        sort: "asc"
      },
      {
        field: "releaseDate",
        label: "Redemption Code Release Date",
        sort: "asc"
      },
      {
        field: "dueForCollection",
        label: "Due for Collection",
        sort: "asc"
      },
      {
        field: "quantityToRedeem",
        label: "Quantity To Redeem",
        sort: "asc"
      },
      {
        field: "redemptionStatus",
        label: "Redemption Status",
        sort: "asc"
      },
      {
        field: "patientMobile",
        label: "Patient Mobile",
        sort: "asc"
      },
      {
        field: "se",
        label: "SE Name",
        sort: "asc"
      },
      {
        field: "remarks",
        label: "Remarks",
        sort: "asc"
      },
      {
        field: "patientReassign",
        label: "Patient Re-assign",
        sort: "asc"
      }
    ],
    rows: tableData ? tableData : []
  };

  let hcpIcMrnTableData = {
    columns: [
      {
        field: "hcpType",
        label: "HCP Type",
        sort: "asc"
      },
      {
        field: "accountName",
        label: "Account Name",
        sort: "asc"
      },
      {
        field: "accountCode",
        label: "Account Code",
        sort: "asc"
      },
      {
        field: "patientId",
        label: "Patient ID",
        sort: "asc"
      },
      {
        field: "sku",
        label: "Redeemed SKU",
        sort: "asc"
      },
      {
        field: "releaseDate",
        label: "Redemption Code Release Date",
        sort: "asc"
      },
      {
        field: "dueForCollection",
        label: "Due for Collection",
        sort: "asc"
      },
      {
        field: "quantityToRedeem",
        label: "Quantity To Redeem",
        sort: "asc"
      },
      {
        field: "redemptionStatus",
        label: "Redemption Status",
        sort: "asc"
      },
      {
        field: "patientMobile",
        label: "Patient Mobile",
        sort: "asc"
      },
      {
        field: "patientIc",
        label: "Patient IC",
        sort: "asc"
      },
      {
        field: "patientMrn",
        label: "Patient MRN",
        sort: "asc"
      },
      {
        field: "se",
        label: "SE Name",
        sort: "asc"
      },
      {
        field: "remarks",
        label: "Remarks",
        sort: "asc"
      },
      {
        field: "patientReassign",
        label: "Patient Re-assign",
        sort: "asc"
      }
    ],
    rows: tableData ? tableData : []
  };

  let adminTableData = {
    columns: [
      {
        field: "hcpType",
        label: "HCP Type",
        sort: "asc"
      },
      {
        field: "accountName",
        label: "Account Name",
        sort: "asc"
      },
      {
        field: "accountCode",
        label: "Account Code",
        sort: "asc"
      },
      {
        field: "patientId",
        label: "Patient ID",
        sort: "asc"
      },
      {
        field: "sku",
        label: "Redeemed SKU",
        sort: "asc"
      },
      {
        field: "releaseDate",
        label: "Redemption Code Release Date",
        sort: "asc"
      },
      {
        field: "dueForCollection",
        label: "Due for Collection",
        sort: "asc"
      },
      {
        field: "quantityToRedeem",
        label: "Quantity To Redeem",
        sort: "asc"
      },
      {
        field: "redemptionStatus",
        label: "Redemption Status",
        sort: "asc"
      },
      {
        field: "se",
        label: "SE Name",
        sort: "asc"
      },
      {
        field: "remarks",
        label: "Remarks",
        sort: "asc"
      },
      {
        field: "patientReassign",
        label: "Patient Re-assign",
        sort: "asc"
      },
      {
        field: "action",
        label: "Action",
        sort: "asc"
      },
    ],
    rows: tableData ? tableData : []
  };

  let salesTableData = {
    columns: [
      {
        field: "hcpType",
        label: "HCP Type",
        sort: "asc"
      },
      {
        field: "accountName",
        label: "Account Name",
        sort: "asc"
      },
      {
        field: "accountCode",
        label: "Account Code",
        sort: "asc"
      },
      {
        field: "patientId",
        label: "Patient ID",
        sort: "asc"
      },
      {
        field: "sku",
        label: "Redeemed SKU",
        sort: "asc"
      },
      {
        field: "releaseDate",
        label: "Redemption Code Release Date",
        sort: "asc"
      },
      {
        field: "dueForCollection",
        label: "Due for Collection",
        sort: "asc"
      },
      {
        field: "quantityToRedeem",
        label: "Quantity To Redeem",
        sort: "asc"
      },
      {
        field: "redemptionStatus",
        label: "Redemption Status",
        sort: "asc"
      },
      {
        field: "se",
        label: "SE Name",
        sort: "asc"
      },
      {
        field: "remarks",
        label: "Remarks",
        sort: "asc"
      },
      {
        field: "patientReassign",
        label: "Patient Re-assign",
        sort: "asc"
      }
    ],
    rows: tableData ? tableData : []
  };

  let csv = [];

  if (data) {
    for (let i = 0; i < data.length; i++) {
      const dataObject = {
        hcpType: data[i].hcpType,
        accountName: data[i].accountName,
        accountCode: data[i].accountCode,
        patientId: data[i].patientId,
        sku: data[i].sku,
        releaseDate: data[i].releaseDate,
        dueForCollection: data[i].dueForCollection,
        quantityToRedeem: data[i].quantityToRedeem,
        redemptionStatus: data[i].redemptionStatus,
        se: data[i].se,
        remarks: data[i].remarks,
        patientReassign: data[i].patientReassign,
        patientMobile: data[i].patientMobile,
        patientIc: data[i].patientIc,
        patientMrn: data[i].patientMrn,
        action: (
          <div className="text-center" onClick={() => showEditModal(data[i].redemptionId, data[i].accountName, data[i].accountCode, data[i].patientId, data[i].sku, data[i].redemptionStatus, data[i].remarks, data[i].patientReassign, data[i].reassignmentList, data[i].validationStatus)}>
            <EditIcon />
          </div>
        )
      }
      tableData.push(dataObject);

      const csvObject = {
        hcpType: data[i].hcpType,
        accountName: data[i].accountName,
        accountCode: data[i].accountCode,
        patientId: data[i].patientId,
        sku: data[i].sku,
        releaseDate: data[i].releaseDate,
        dueForCollection: data[i].dueForCollection,
        quantityToRedeem: data[i].quantityToRedeem,
        redemptionStatus: data[i].redemptionStatus,
        se: data[i].se,
        remarks: data[i].remarks,
        patientReassign: data[i].patientReassign,
        patientIc: data[i].patientIc,
        patientMrn: data[i].patientMrn
      }
      if (role === "dev" || role === "hcp") {
        csvObject.patientMobile = `=""${data[i].patientMobile}""`;
      }
      csv.push(csvObject);
    }
  }

  const hcpCsvData = {
    headers: [
      { label: "HCP Type", key: "hcpType" },
      { label: "Account Name", key: "accountName" },
      { label: "Account Code", key: "accountCode" },
      { label: "Patient ID", key: "patientId" },
      { label: "Redeemed SKU", key: "sku" },
      { label: "Redemption Code Release Date", key: "releaseDate" },
      { label: "Due for Collection", key: "dueForCollection" },
      { label: "Quantity To Redeem", key: "quantityToRedeem" },
      { label: "Redemption Status", key: "redemptionStatus" },
      { label: "Patient Mobile", key: "patientMobile" },
      { label: "SE Name", key: "se" },
      { label: "Remarks", key: "remarks" },
      { label: "Patient Re-assign", key: "patientReassign" }
    ],
    data: csv
  }

  const hcpIcMrnCsvData = {
    headers: [
      { label: "HCP Type", key: "hcpType" },
      { label: "Account Name", key: "accountName" },
      { label: "Account Code", key: "accountCode" },
      { label: "Patient ID", key: "patientId" },
      { label: "Redeemed SKU", key: "sku" },
      { label: "Redemption Code Release Date", key: "releaseDate" },
      { label: "Due for Collection", key: "dueForCollection" },
      { label: "Quantity To Redeem", key: "quantityToRedeem" },
      { label: "Redemption Status", key: "redemptionStatus" },
      { label: "Patient Mobile", key: "patientMobile" },
      { label: "Patient IC", key: "patientIc" },
      { label: "Patient MRN", key: "patientMrn" },
      { label: "SE Name", key: "se" },
      { label: "Remarks", key: "remarks" },
      { label: "Patient Re-assign", key: "patientReassign" }
    ],
    data: csv
  }

  const adminCsvData = {
    headers: [
      { label: "HCP Type", key: "hcpType" },
      { label: "Account Name", key: "accountName" },
      { label: "Account Code", key: "accountCode" },
      { label: "Patient ID", key: "patientId" },
      { label: "Redeemed SKU", key: "sku" },
      { label: "Redemption Code Release Date", key: "releaseDate" },
      { label: "Due for Collection", key: "dueForCollection" },
      { label: "Quantity To Redeem", key: "quantityToRedeem" },
      { label: "Redemption Status", key: "redemptionStatus" },
      { label: "SE Name", key: "se" },
      { label: "Remarks", key: "remarks" },
      { label: "Patient Re-assign", key: "patientReassign" }
    ],
    data: csv
  }

  return (
    <div className="d-flex">

      <Drawer
        sx={{
          minWidth: "20%",
          '& .MuiDrawer-paper': {
            minWidth: "20%",
            boxSizing: 'border-box',
            minHeight: "100vh",
            backgroundColor: "#FE7072",
          },
        }}
        anchor="left"
        open={openSidebar}
        variant="persistent">
        <Sidebar />
      </Drawer>

      <Main open={openSidebar}>
        <div className="mt-3 px-3">
          <Header title="PENDING REDEMPTION REPORT" openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />

          <div className="report-header mt-3 d-flex py-3 px-3 justify-content-between">
            <div className="col-4">
              <p>This report shows patient's redemptions status that is either pending to be close by healthcare providers in the system or has expired.</p>
            </div>
            <div>
              <div className="d-flex">
                <div>
                  <DatePicker placeholderText="Date" value={startDate ? `${startDate ? new Date(startDate).toLocaleDateString('en-GB') : ""} - ${endDate ? new Date(endDate).toLocaleDateString('en-GB') : ""}` : ""} onChange={handleDate} startDate={startDate} endDate={endDate} selectsRange shouldCloseOnSelect={false} className="form-control py-3" />
                </div>
                <div className="ps-2">
                  <Autocomplete
                    multiple
                    id="sku"
                    options={medicationSkuList}
                    disableCloseOnSelect
                    value={productList}
                    onChange={(e, value) => {
                      setProductList(value);
                    }}
                    renderOption={(props, medicationSkuList, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          checked={selected}
                        />
                        {medicationSkuList}
                      </li>
                    )}
                    style={{ width: 250, backgroundColor: "white" }}
                    renderInput={(params) => (
                      <TextField {...params} label="SKU" />
                    )}
                  />
                </div>
                <div className="ps-2">
                  <button type="button" className="btn btn-primary shadow-none admin-button" onClick={() => handleFilter()}>Filter</button>
                </div>
                <div className="ps-2">
                  <button type="button" className="btn btn-primary shadow-none admin-button" onClick={() => handleReset()}>Reset</button>
                </div>
                <div className="ps-2">
                  <CSVLink className="btn btn-primary shadow-none admin-button" data={csv} headers={role === "hcp" && showIcMrn ? hcpIcMrnCsvData.headers : role === "dev" || role === "hcp" ? hcpCsvData.headers : adminCsvData.headers} filename={`pending_redemption_report_${new Date().getFullYear()}_${new Date().getMonth() + 1}_${new Date().getDate()}.csv`} target="_blank">
                    Export
                  </CSVLink>
                </div>
              </div>
            </div>
          </div>
          <div className="report-header d-flex px-3 justify-content-between">
            <div>
              <p>*<span style={{ color: "green" }}>Pending</span>: Kindly contact your patients and be reminded to conduct closure in the system upon dispensing the complimentary boxes. Each redemption code only valid for 90 days.</p>
            </div>
            <div>
              <p>**<span style={{ color: "red" }}>Expired</span>: Redemptions that are failed to redeem within 90 days. Viatris will collect back the uncollected redemption stocks from hospital. Kindly contact your Viatris representative if patient had collected the stocks without completing redemption process in the system.</p>
            </div>
          </div>
          {
            loading ? (
              <div className="text-center">
                <CircularProgress />
              </div>
            ) : (
              <div>
                <MDBDataTableV5 data={role === "hcp" && showIcMrn ? hcpIcMrnTableData : role === "dev" ? devTableData : role === "admin" ? adminTableData : role === "sales" ? salesTableData : hcpTableData} hover responsive bordered />
              </div>
            )
          }
        </div>
      </Main>

      <Modal show={editModal} onHide={hideEditModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Patient Re-Assignment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div>
              <label htmlFor="accountName">Account Name</label>
              <input
                name="accountName"
                type="text"
                value={accountName}
                className="form-control"
                readOnly
              />
            </div>
            <div className="pt-3">
              <label htmlFor="accountCode">Account Code</label>
              <input
                name="accountCode"
                type="text"
                value={accountCode}
                className="form-control"
                readOnly
              />
            </div>
            <div className="pt-3">
              <label htmlFor="patientId">Patient ID</label>
              <input
                name="patientId"
                type="text"
                value={patientId}
                className="form-control"
                readOnly
              />
            </div>
            <div className="pt-3">
              <label htmlFor="sku">SKU</label>
              <input
                name="sku"
                type="text"
                value={sku}
                className="form-control"
                readOnly
              />
            </div>
            <div className="pt-3">
              <label htmlFor="status">Status</label>
              <select className="form-select" name="status" value={status} onChange={(e) => setStatus(e.target.value)}>
                <option value="Pending" disabled>Pending</option>
                <option value="Expired" disabled>Expired</option>
                <option value="Re-Allocate">Re-Allocate</option>
              </select>
            </div>
            <div className="pt-3">
              <label htmlFor="remarks">Remarks</label>
              <select className="form-select" name="remarks" value={remarks} onChange={(e) => setRemarks(e.target.value)}>
                <option value="Collection Overdue">Collection Overdue</option>
                {/* <option value="Forgotten to close by HCP" disabled>Forgotten to close by HCP</option> */}
              </select>
            </div>
            <div className="pt-3">
              <label htmlFor="reassignment">Reassignment</label>
              {
                reassignmentHistory ? (
                  <input
                    name="reassignmentHistory"
                    type="text"
                    value={reassignmentHistory}
                    className="form-control"
                    readOnly
                  />
                ) : (
                  <select className="form-select" name="reassignment" value={reassignment} onChange={(e) => setReassignment(e.target.value)}>
                    <option value="" disabled>Last Patient Number</option>
                    {reassignmentList.map((redemption, index) => <option key={index} value={redemption.id}>{redemption.patient.number.substr(-4)}</option>)}
                  </select>
                )
              }
            </div>
          </div>
          {
            loading ? (
              <div className="text-center">
                <CircularProgress />
              </div>
            ) : (
              <div className="text-center mt-4">
                <button type="button" className="btn btn-primary shadow-none admin-button" onClick={reassignRedemption}>CONFIRM</button>
              </div>
            )
          }
        </Modal.Body>
      </Modal>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    token: state.admin.token,
    role: state.admin.role
  };
};

export default connect(mapStateToProps, null)(PendingRedemptionReport);
