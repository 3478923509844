import React, { useState, useEffect } from "react";
import { CircularProgress } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { connect } from 'react-redux';
import { hcpLogin } from "../redux/actions/hcpActions";
import axios from 'axios';

const HcpForgotPw = ({ hcpLogin }) => {

  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [otp, setOtp] = useState("");
  // const [second, setSecond] = useState(60);

  // let timer;

  useEffect(() => {
    if (location.state && location.state.hcpEmail) {
      setEmail(location.state.hcpEmail);
    } else {
      navigate('/hcp/welcome');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   startTimer();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [second])

  // const startTimer = () => {
  //   timer = setTimeout(() => {
  //     if (second > 0) {
  //       setSecond(second - 1)
  //     } else if (second === 0) {
  //       resend();
  //     }
  //   }, 1000)
  // }

  // const stopTimer = () => {
  //   clearTimeout(timer);
  // }

  const handleSubmit = e => {
    e.preventDefault();

    setError("")
    setLoading(true);

    axios.post('/api/hcp/verifyOtp', { email, otp })
      .then(res => {
        // stopTimer();
        hcpLogin(res.data);
        setLoading(false);
        navigate('/hcp/home');
      })
      .catch(err => {
        setError(err.response.data.error);
        setLoading(false);
      });
  }

  const resend = () => {
    setError("")
    setLoading(true);
    // stopTimer();

    axios.post('/api/hcp/resendOtp', { email })
      .then(res => {
        // setSecond(60);
        setLoading(false);
      })
      .catch(err => {
        setError(err.response.data.error);
        setLoading(false);
      });
  }

  return (
    <div className="hcp-login-page">
      <div style={{ height: "5vh" }}></div>
      <div>
        <div className="hcp-login-container">

          <div>
            <h2 className="hcp-login-title">Forgot Password</h2>
          </div>

          <div className="form-container">
            <div>
              <p className="hcp-pw-notifier">
                An email has been sent to {email}. Please key in the OTP to finish the login process.
              </p>
            </div>

            <form onSubmit={handleSubmit}>
              <div>
                <label className="hcp-email-label"><b>OTP</b></label>
                <input className="form-control input-field-white" type="number" id="otp" name="otp" value={otp} onChange={e => setOtp(e.target.value)} onWheel={(e) => e.target.blur()} required />
              </div>

              {
                error ? (
                  <div className="pt-3">
                    <p style={{ color: "red", textAlign: "center" }}>{error}</p>
                  </div>
                ) : null
              }

              {
                loading ? (
                  <div className="text-center">
                    <CircularProgress />
                  </div>
                ) : (
                  <div className="button-container">
                    <button type="submit" className="btn form-control hcp-login-button py-3">
                      <b>LOGIN</b>
                    </button>
                  </div>
                )
              }

            </form>

            <div className="hcp-didnt-received">
              <div className="hcp-line"></div>
              <p className="hcp-didnt-receive-text">
                Didn't receive the email?
              </p>
              <div className="hcp-line"></div>
            </div>

            {
              loading ? (
                <div className="text-center mt-5">
                  <CircularProgress />
                </div>
              ) : (
                <div className="button-container">
                  <button className="btn form-control hcp-resend-button py-3" onClick={() => resend()}>
                    <b>RESENT</b>
                  </button>
                  {/* <p style={{ color: "white", textAlign: "center", fontSize: "18px" }}>{language === "ch" ? (<b>{second === 60 ? "01:00" : second < 10 ? `00:0${second}` : `00:${second}`} {t("OTP will be resent in")}</b>) : (<b>{t("OTP will be resent in")} {second === 60 ? "01:00" : second < 10 ? `00:0${second}` : `00:${second}`}</b>)}</p> */}
                  {/* <p style={{ color: "white", textAlign: "center", fontSize: "18px" }}>OTP will be resent in <b>{second === 60 ? "01:00" : second < 10 ? `00:0${second}` : `00:${second}`}</b></p> */}
                </div>
              )
            }
          </div>

        </div>
      </div>
    </div>
  );
};


const mapDispatchToProps = dispatch => {
  return {
    hcpLogin: data => dispatch(hcpLogin(data))
  };
};


export default connect(null, mapDispatchToProps)(HcpForgotPw);
