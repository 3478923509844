import React, { useState, useEffect } from "react";
import { FormControl, InputBase, CircularProgress } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router";
import { connect } from 'react-redux';
import { updatePatientDetails, updateHcpDetails } from "../redux/actions/hcpActions";
import axios from 'axios';

// images
import leftArrow from "../assets/image/left-arrow.png";

// component
import HcpNavigationBar from "./components/navigationBar";
import HcpHeader from "./components/header";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: "10px",
    position: "relative",
    border: "2px solid #000000",
    backgroundColor: "#00000000",
    fontSize: 16,
    width: "100%",
    padding: "10px 12px",
    marginBottom: "1rem",
    color: "#000000",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha("#000", 0.25)} 0 0 0 0.1rem`,
      borderColor: "#ffffff",
    },
  },
}));

const HcpInstantRedemption = ({ patient, updatePatientDetails, token, hcp, updateHcpDetails }) => {

  let number = localStorage.getItem("patient-number");

  const [redemptionCodeList, setRedemptionCodeList] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [verifyList, setVerifyList] = useState([]);
  const [instantRedemption, setInstantRedemption] = useState([]);
  const [normalSubmission, setNormalSubmission] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.normalSubmission) {
      setNormalSubmission(location.state.normalSubmission);
    }

    if (location.state && location.state.instantRedemption) {
      setInstantRedemption(location.state.instantRedemption);
    }

    if (number) {
      axios.post('/api/hcp/patientDetails', { number })
        .then(res => {
          updatePatientDetails(res.data.data)

        })
        .catch(err => {
          navigate('/hcp/home');
        });
    } else {
      navigate('/hcp/home');
    }

    if (!hcp) {
      if (token) {
        axios.post('/api/hcp/checkToken', { token })
          .then(res => {
            updateHcpDetails(res.data.data);
          })
          .catch(err => {
            setError(err.response.data.error);
          });
      } else {
        navigate("/hcp/welcome")
      }
    }

    let tempCodeList = [];
    let tempVerifyList = [];

    for (let i = 0; i < location.state.instantRedemption.length; i++) {
      tempVerifyList[i] = false;
      tempCodeList[i] = "";
    }
    setRedemptionCodeList(tempCodeList);
    setVerifyList(tempVerifyList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInputChange = (e, i) => {
    const list = [...redemptionCodeList];
    list[i] = e.target.value;
    setRedemptionCodeList(list);
  };

  const verify = (i) => {
    setError("");
    setLoading(true);
    axios.post('/api/hcp/verifyRedemptionCode', { redemptionCode: redemptionCodeList[i], hcpId: hcp.hcp.id })
      .then(res => {
        if (redemptionCodeList.filter(code => code === redemptionCodeList[i]).length > 1) {
          setError("Duplicated redemption code.");
          setLoading(false);
        } else if (res.data.redemption.type === "Instant" && res.data.redemption.number === patient.patient.number && res.data.redemption.productId === instantRedemption[i].productId && res.data.redemption.status === "Pending" && res.data.redemption.hcpId === hcp.hcp.id) {
          const list = [...verifyList];
          list[i] = true;
          setVerifyList(list);
          setLoading(false);
          if (!list.includes(false)) {
            if (normalSubmission) {
              navigate('/hcp/hcpCheckoutInstantRedemption', { state: { redemptionCode: redemptionCodeList, instantRedemption, normalSubmission } });
            } else {
              navigate('/hcp/hcpCheckoutInstantRedemption', { state: { redemptionCode: redemptionCodeList, instantRedemption } });
            }
          }
        } else {
          setError("Invalid redemption code. Please try again.");
          setLoading(false);
        }
      })
      .catch(err => {
        setError(err.response.data.error);
        setLoading(false);
      });
  }

  return (
    <div>
      <HcpHeader />
      <div className="hcp-whole-body">
        <div className="page-title mt-3">
          <img src={leftArrow} className="hcp-left-arrow" alt="back-icon" onClick={() => navigate(-1)} />
          <h2>Instant Redemption</h2>
        </div>

        <div className="page-body">
          <div className="px-3">

            {
              patient ? instantRedemption.map((redemption, i) => {
                return (
                  <div className="hcp-checkout-details" key={i}>
                    <div>
                      <p>Product: {patient.product.filter(p => p.productId === redemption.productId)[0].medication} {patient.product.filter(p => p.productId === redemption.productId)[0].sku}</p>
                    </div>
                    <table className="hcp-table-width-submission">
                      <thead>
                        <tr className="hcp-small-font ">
                          <th>
                            <p className="hcp-table-header-text">Submitted</p>
                          </th>
                          <th>
                            <p className="hcp-table-header-text">Remaining</p>
                          </th>
                          <th>
                            <p className="hcp-table-header-text">Instant Redemption</p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="hcp-small-font">
                          <td>{redemption.threshold}</td>
                          <td>0</td>
                          <td>{patient.product.filter(p => p.productId === redemption.productId)[0].redeemQuantity}</td>
                        </tr>
                      </tbody>
                    </table>

                    <div>
                      <h4>ENTER REDEMPTION CODE</h4>

                      <FormControl variant="standard" className="col-12">
                        {
                          verifyList[i] === true ? (
                            <BootstrapInput id="redemptionCode" name="redemptionCode" type="text" value={redemptionCodeList[i]} onChange={e => handleInputChange(e, i)} readOnly />
                          ) : (
                            <BootstrapInput id="redemptionCode" name="redemptionCode" type="text" value={redemptionCodeList[i]} onChange={e => handleInputChange(e, i)} />
                          )
                        }
                        <div className="hcp-code-div">

                          {
                            loading ? (
                              <div className="text-center">
                                <CircularProgress />
                              </div>
                            ) : (
                              <button className="btn form-control hcp-verify-button-instant py-2" style={{ minWidth: "100%" }} onClick={() => verify(i)} disabled={verifyList[i] === true}>
                                <b>{verifyList[i] === true ? "Verified" : "Verify"}</b>
                              </button>
                            )
                          }

                        </div>
                      </FormControl>
                    </div>

                  </div>
                )
              }) : (
                <div className="text-center">
                  <CircularProgress />
                </div>
              )
            }

            {
              error ? (
                <div className="pt-3">
                  <p style={{ color: "red", textAlign: "center" }}>{error}</p>
                </div>
              ) : null
            }

          </div>
        </div>
      </div>
      <HcpNavigationBar />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    patient: state.hcp.patient,
    hcp: state.hcp.hcp,
    token: state.hcp.token
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updatePatientDetails: data => dispatch(updatePatientDetails(data)),
    updateHcpDetails: data => dispatch(updateHcpDetails(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HcpInstantRedemption);
