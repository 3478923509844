import React, { useState, useEffect } from "react";
import { FormControlLabel, CircularProgress, Modal, Box } from "@mui/material";
import { MDBBtn } from "mdb-react-ui-kit";
import Checkbox from "@material-ui/core/Checkbox";
import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import { updateHcpRememberMe, hcpLogin } from "../redux/actions/hcpActions";
import axios from 'axios';

import question from "../assets/image/question_mark.png";

const HcpLogin = ({ hcpLogin, rememberme, updateHcpRememberMe, token }) => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (rememberme) {
            if (token) {
                axios.post('/api/hcp/checkToken', { token })
                    .then(res => {
                        hcpLogin(res.data);
                        setLoading(false);
                        navigate('/hcp/home');
                    })
                    .catch(err => {
                        setError(err.response.data.error);
                        setLoading(false);
                    });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = e => {
        e.preventDefault();

        setError("")
        setLoading(true);

        axios.post('/api/hcp/login', { email, password })
            .then(res => {
                hcpLogin(res.data);
                setLoading(false);
                navigate('/hcp/home');
            })
            .catch(err => {
                setError(err.response.data.error);
                setLoading(false);
            });
    }

    const forgetPassword = () => {
        if (!email) {
            setError("Please fill in the email.")
        } else {
            setError("")
            setLoading(true);

            axios.post('/api/hcp/forgetPassword', { email })
                .then(res => {
                    setLoading(false);
                    navigate('/hcp/forgotPw', { state: { hcpEmail: email } });
                })
                .catch(err => {
                    setError(err.response.data.error);
                    setLoading(false);
                });
        }
    }

    return (
        <div className="hcp-login-page">
            <div style={{ height: "5vh" }}></div>
            <div className="hcp-login-container">

                <div>
                    <h2 className="hcp-login-title">Login</h2>
                </div>

                <div className="form-container">
                    <form onSubmit={handleSubmit}>
                        <div>
                            <div className="pt-5">
                                <label className="hcp-email-label"><b>EMAIL</b></label>
                                <input className="form-control input-field-white" type="email" id="email" name="email" value={email} onChange={e => setEmail(e.target.value)} required />
                            </div>

                            <div className="pt-5">
                                <label className="hcp-email-label"><b>PASSWORD</b></label>
                                <input className="form-control input-field-white" type="password" id="password" name="password" value={password} onChange={e => setPassword(e.target.value)} required />
                                <p className="hcp-forgot-pw" onClick={() => setOpen(true)}>Forgot Password</p>
                            </div>

                            <FormControlLabel
                                label="Remember me"
                                style={{ color: "white" }}
                                control={
                                    <Checkbox
                                        style={{ color: "white" }}
                                        checked={rememberme}
                                        onChange={e => updateHcpRememberMe(e.target.checked)}
                                    />
                                }
                            />
                        </div>

                        {
                            error ? (
                                <div className="pt-3">
                                    <p style={{ color: "red", textAlign: "center" }}>{error}</p>
                                </div>
                            ) : null
                        }

                        <div className="mt-3">
                            {
                                loading ? (
                                    <div className="text-center">
                                        <CircularProgress />
                                    </div>
                                ) : (
                                    <div className="px-5">
                                        <button type="submit" className="btn form-control hcp-login-button py-3">
                                            <b>LOGIN</b>
                                        </button>
                                    </div>
                                )
                            }
                        </div>
                    </form>
                </div>
            </div>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="red-modal">
                    <div className="align-right">
                        <MDBBtn
                            className="btn-close btn-close-white"
                            color="none"
                            aria-label="Close"
                            onClick={() => setOpen(false)}
                        />
                    </div>
                    <div className="text-center">
                        <img src={question} alt="question" />
                    </div>

                    <p className="hcp-alert">Forgot Password?</p>
                    <p style={{ color: "white", textAlign: "center" }}>The OTP will sent to your email. Are you sure to continue?</p>
                    <div className="button-container">
                        {
                            error ? (
                                <div className="pt-3">
                                    <p style={{ color: "red", textAlign: "center" }}>{error}</p>
                                </div>
                            ) : null
                        }

                        {
                            loading ? (
                                <div className="text-center">
                                    <CircularProgress />
                                </div>
                            ) : (
                                <button className="btn form-control hcp-register-button py-3" onClick={() => forgetPassword()}>
                                    <b>YES</b>
                                </button>
                            )
                        }

                    </div>
                    <div className="button-container">
                        <button className="btn form-control hcp-no-button py-3" onClick={() => setOpen(false)}>
                            <b>NO</b>
                        </button>
                    </div>
                </Box>
            </Modal>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        rememberme: state.hcp.rememberme,
        token: state.hcp.token
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateHcpRememberMe: data => dispatch(updateHcpRememberMe(data)),
        hcpLogin: data => dispatch(hcpLogin(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HcpLogin);
