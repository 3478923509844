import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Box } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';

// images
import patientMobile2En from "../assets/image/tutorial/patient/mobile/patientTutorial2-en.png";
import patientDesktop2En from "../assets/image/tutorial/patient/desktop/patientTutorial2-en.png";
import patientDesktop2Bm from "../assets/image/tutorial/patient/desktop/patientTutorial2-bm.png";
import tutorialArrow from "../assets/image/tutorialArrow.png";

const PatientTutorial2 = ({ language }) => {

    const navigate = useNavigate();
    const { t } = useTranslation();

    const [openTutorial, setOpenTutorial] = useState(true);

    return (
        <div>
            {
                isMobile ? (
                    <img src={patientMobile2En} alt="patientTutorial" style={{ height: "100vh", width: "100vw" }} />
                ) : (
                    <img src={language === "bm" ? patientDesktop2Bm : patientDesktop2En} alt="patientTutorial" style={{ height: "100vh", width: "100vw" }} />
                )
            }
            <Modal
                open={openTutorial}
                onClose={() => setOpenTutorial(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                hideBackdrop={true}
            >
                <Box className="tutorial-modal-2">
                    <p style={{ fontSize: "18px" }}>{t("View your pending redemption and redemption history here")}</p>
                    <img src={tutorialArrow} alt="arrow" style={{ float: "right" }} onClick={() => navigate('/patient/patientTutorial3')} />
                </Box>
            </Modal>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        language: state.patient.language
    };
};

export default connect(mapStateToProps, null)(PatientTutorial2);
