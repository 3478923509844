import React, { useState, useEffect } from "react";
import { Modal, Box, CircularProgress, Badge } from "@mui/material";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import { MDBBtn } from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import { updateRegisterPopup, patientCheckToken, homepageNavigation, patientLogout, updatePatientLanguage } from "../redux/actions/patientActions";
import axios from 'axios';
// import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-bootstrap';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// image
// import viatrislogo from "../assets/image/viatris-logo.png";
import uploadDoc from "../assets/image/upload-doc.png";
import write2 from "../assets/image/write2.png";
import redemptionHistory from "../assets/image/redemption-history-icon.png";
import pendingRedemption from "../assets/image/pending-redemption-icon.png";
// import desktopBanner1 from "../assets/image/desktopBanner1.png";
// import desktopBanner2 from "../assets/image/desktopBanner2.png";
// import desktopBanner3 from "../assets/image/desktopBanner3.png";
import logoutIcon from "../assets/image/logout-icon.png";
import subtract from "../assets/image/patient-subtract.png";
import norvascBmBanner from "../assets/image/banner/norvasc-bm-banner.jpg";
import norvascChBanner from "../assets/image/banner/norvasc-ch-banner.jpg";
import norvascEnBanner from "../assets/image/banner/norvasc-en-banner.jpg";
import viagraBmBanner from "../assets/image/banner/viagra-bm-banner.jpg";
import viagraChBanner from "../assets/image/banner/viagra-ch-banner.jpg";
import viagraEnBanner from "../assets/image/banner/viagra-en-banner.jpg";
import legalonEnBanner1 from "../assets/image/banner/legalon-en-banner1.jpg";
import legalonEnBanner2 from "../assets/image/banner/legalon-en-banner2.jpg";
import legalonEnBanner3 from "../assets/image/banner/legalon-en-banner3.jpg";
import legalonBmBanner1 from "../assets/image/banner/legalon-bm-banner1.jpg";
import legalonBmBanner2 from "../assets/image/banner/legalon-bm-banner2.jpg";
import legalonBmBanner3 from "../assets/image/banner/legalon-bm-banner3.jpg";
import legalonChBanner1 from "../assets/image/banner/legalon-ch-banner1.jpg";
import legalonChBanner2 from "../assets/image/banner/legalon-ch-banner2.jpg";
import legalonChBanner3 from "../assets/image/banner/legalon-ch-banner3.jpg";
import Banner1 from "../assets/NewBanner/Banner1.jpg";
import Banner2 from "../assets/NewBanner/Banner2.jpg";
import Banner3 from "../assets/NewBanner/Banner3.jpg";
import Banner4 from "../assets/NewBanner/Banner4.jpg";
import Banner5 from "../assets/NewBanner/Banner5.jpg";

// component
import PatientNavigationBar from "./components/navigationBar";
import PatientHeader from "./components/header";

const PatientHome = ({ registerPopup, updateRegisterPopup, token, patientCheckToken, patient, homepageNavigation, patientLogout, language, updatePatientLanguage }) => {

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [open, setOpen] = useState(true);
  const [open2, setOpen2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [badgeInvisible, setBadgeInvisible] = useState(true);
  const [badgeNumber, setBadgeNumber] = useState(0);
  const [logoutModal, setLogoutModal] = useState(false);

  useEffect(() => {
    setError("")
    setLoading(true);

    if (token) {
      axios.post('/api/patient/checkToken', { token })
        .then(res => {
          patientCheckToken(res.data.data);
          let pendingRedemption = res.data.data.redemption.filter(r => r.status === "Pending");
          if (pendingRedemption.length > 0) {
            setBadgeNumber(pendingRedemption.length);
            setBadgeInvisible(false);
          }

          if (res.data.data.patient.updatedBy === "doctor" && res.data.data.patient.updatedPopup === true) {
            setOpen2(true);
          }

          if (res.data.data.patient.firstTimeLogin) {
            navigate('/patient/patientAddToHomeScreenTutorial');
          }

          setLoading(false);
        })
        .catch(err => {
          setError(err.response.data.error);
          setLoading(false);
        });
    } else {
      navigate("/patient/patientWelcome")
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModal = () => {

    setError("");
    setLoading(true);

    axios.post('/api/hcp/updatePatientPopup', { number: patient.patient.number })
      .then(res => {
        setLoading(false);
        setOpen2(false);
      })
      .catch(err => {
        setLoading(false);
        setError(err.response.data.error);
      });

  }

  const submittedAmount = (medication) => {
    let filterHcpPoint = patient.point[0];
    if (filterHcpPoint) {
      let filterMedicationPoint = filterHcpPoint.medication.filter(m => m.medication === medication)[0];
      if (filterMedicationPoint) {
        return filterMedicationPoint.point
      } else {
        return 0
      }
    } else {
      return 0
    }
  }

  const remainingAmount = (medication, sku) => {
    let threshold = patient.product.filter(product => product.medication === medication && product.sku === sku)[0].threshold;
    let remaining = threshold - submittedAmount(medication);
    if (remaining < 0) {
      return 0;
    } else {
      return remaining;
    }
  }

  const submissionStatus = (medication, sku) => {
    if (remainingAmount(medication, sku) === 0) {
      let pendingRedemption = patient.redemption.filter(r => r.status === "Pending" && r.medication === medication && r.sku === sku)
      if (pendingRedemption.length > 0) {
        return "Pending redemption";
      } else {
        return "Reach maximum redemption";
      }
    } else {
      return "Insufficient for redemption";
    }
  }

  const logout = () => {
    patientLogout();
    navigate('/patient/patientWelcome')
  }

  return (
    <div>
      <PatientHeader />
      <div className="hcp-whole-body">
        <div className="hcp-home-welcome d-flex justify-content-between">
          <p style={{ color: "#1F1C6B" }}><b>{t("Welcome,")}</b></p>
          <div className="hcp-signup-lang">
            <p className={language === "en" ? "hcp-signup-lang-border px-2" : "px-2"} onClick={() => updatePatientLanguage("en")}>ENG</p>
            <p className={language === "bm" ? "hcp-signup-lang-border px-2" : "px-2"} onClick={() => updatePatientLanguage("bm")}>BM</p>
            <p className={language === "ch" ? "hcp-signup-lang-border px-2" : "px-2"} onClick={() => updatePatientLanguage("ch")}>MAN</p>
          </div>
        </div>

        <div>
          <Carousel nextIcon={<ArrowForwardIosIcon />} prevIcon={<ArrowBackIosIcon />}>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={language === "en" ? norvascEnBanner : language === "bm" ? norvascBmBanner : norvascChBanner}
                alt="First slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={language === "en" ? viagraEnBanner : language === "bm" ? viagraBmBanner : viagraChBanner}
                alt="Second slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={language === "en" ? legalonEnBanner1 : language === "bm" ? legalonBmBanner1 : legalonChBanner1}
                alt="Third slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={language === "en" ? legalonEnBanner2 : language === "bm" ? legalonBmBanner2 : legalonChBanner2}
                alt="Forth slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={language === "en" ? legalonEnBanner3 : language === "bm" ? legalonBmBanner3 : legalonChBanner3}
                alt="Fifth slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={Banner1}
                alt="Sixth slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={Banner2}
                alt="Seventh slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={Banner3}
                alt="Eight slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={Banner4}
                alt="Nineth slide"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={Banner5}
                alt="Tenth slide"
              />
            </Carousel.Item>
          </Carousel>
        </div>
        {/* {
          isMobile ? (
            <div>
              <img src={viatrislogo} className="hcp-home-logo" alt="viatris-logo" />
            </div>
          ) : (
            <div className="d-flex" style={{ overflowX: "auto" }}>
              <img src={desktopBanner1} className="hcp-home-logo" alt="viatris-logo" />
              <img src={desktopBanner2} className="hcp-home-logo" alt="viatris-logo" />
              <img src={desktopBanner3} className="hcp-home-logo" alt="viatris-logo" />
            </div>
          )
        } */}

        {
          error ? (
            <div className="pt-3">
              <p style={{ color: "red", textAlign: "center" }}>{t(error)}</p>
            </div>
          ) : null
        }

        {
          loading ? (
            <div className="text-center">
              <CircularProgress />
            </div>
          ) : (
            <div className="hcp-home-padding-outside">
              <div className="hcp-home-submission">
                <div className="hcp-home-submission-text pt-3">
                  <p>{t("Submission")}</p>
                </div>

                <div className="text-center">
                  <button className="btn patient-home-submission-table py-3" onClick={() => { navigate("/patient/patientSubmission"); homepageNavigation("submission") }}>
                    <img src={uploadDoc} alt="submission-history" className="pe-1" />
                    <b>{t("SUBMISSION HISTORY")}</b>
                  </button>
                </div>

              </div>

              <div className="patient-home-redemption">
                <div className="hcp-home-submission-text pt-3">
                  <p>{t("Redemption")}</p>
                </div>

                <div className="text-center">
                  <button className="btn patient-home-redemption-table py-3 mt-3" onClick={() => { navigate("/patient/patientSubmission"); homepageNavigation("pending") }}>
                    <img src={pendingRedemption} alt="pending-redemption" className="pe-1" />
                    {
                      !badgeInvisible ? (
                        <Badge badgeContent={badgeNumber} color="error" invisible={badgeInvisible}>
                          <b>{t("PENDING REDEMPTIONS")}</b>
                        </Badge>
                      ) : (
                        <b>{t("PENDING REDEMPTIONS")}</b>
                      )
                    }
                  </button>
                </div>

                <div className="text-center">
                  <button className="btn patient-home-redemption-table py-3 mt-3" onClick={() => { navigate("/patient/patientSubmission"); homepageNavigation("redemption") }}>
                    <img src={redemptionHistory} alt="redemption-history" className="pe-1" />
                    <b>{t("REDEMPTION HISTORY")}</b>
                  </button>
                </div>

              </div>
            </div>
          )
        }
        <div className="text-center">
          <button className="btn hcp-addmore-button" onClick={() => setLogoutModal(true)}>
            <img src={logoutIcon} alt="logout-icon" className="px-2" />
            <b className="pe-2">{t("LOG OUT")}</b>
          </button>
        </div>
      </div>

      <PatientNavigationBar />

      <div className="hcp-modal-box">
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="blue-modal">
            <div className="align-right">
              <MDBBtn
                className="btn-close"
                color="none"
                aria-label="Close"
                onClick={() => setOpen(false)}
              />
            </div>
            <h3 className="hcp-alert2">{t("Submission summary")}</h3>
            <table className="hcp-table-width">
              <thead>
                <tr className="hcp-header hcp-small-font">
                  <td>
                    <p className="hcp-label">{t("Product")}</p>
                  </td>
                  <td>
                    <p className="hcp-label">{t("Submitted")}</p>
                  </td>
                  <td>
                    <p className="hcp-label">{t("Remaining")}</p>
                  </td>
                  <td>
                    <p className="hcp-label">{t("Status")}</p>
                  </td>
                </tr>
              </thead>
              <tbody>
                {
                  patient ? patient.medication.map((m, i) => {
                    return (
                      <tr className="hcp-small-font hcp-table" key={i}>
                        <td>{m.medication}</td>
                        <td>{submittedAmount(m.medication)}</td>
                        <td>{remainingAmount(m.medication, m.sku)}</td>
                        <td>{t(submissionStatus(m.medication, m.sku))}</td>
                      </tr>
                    )
                  }) : null
                }
              </tbody>
            </table>
          </Box>
        </Modal>
        <Modal
          open={open2}
          onClose={closeModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="blue-modal">
            <div className="align-right">
              <MDBBtn
                className="btn-close"
                color="none"
                aria-label="Close"
                onClick={closeModal}
              />
            </div>
            <div className="text-center">
              <img src={write2} alt="success-icon" />
            </div>

            <p className="hcp-alert" style={{ color: "black", fontSize: "20px" }}>{t("Your healthcare provider has changed your medication.")}</p>
            <div className="button-container">
              {
                loading ? (
                  <div className="text-center">
                    <CircularProgress />
                  </div>
                ) : (
                  <button className="btn form-control patient-login-button py-3" onClick={closeModal}>
                    <b>{t("OK")}</b>
                  </button>
                )
              }
            </div>
          </Box>
        </Modal>
        <Modal
          open={registerPopup}
          onClose={() => updateRegisterPopup(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="blue-modal">
            <div className="align-right">
              <MDBBtn
                className="btn-close"
                color="none"
                aria-label="Close"
                onClick={() => updateRegisterPopup(false)}
              />
            </div>
            <div className="text-center">
              <img src={write2} alt="success-icon" />
            </div>

            <p className="hcp-alert" style={{ color: "black", fontSize: "20px" }}>{t("Registration successful!")}</p>
            <div className="button-container">
              <button className="btn form-control patient-login-button py-3" onClick={() => updateRegisterPopup(false)}>
                <b>{t("OKAY")}</b>
              </button>
            </div>
          </Box>
        </Modal>
        <Modal
          open={logoutModal}
          onClose={() => setLogoutModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="blue-modal">
            <div className="align-right">
              <MDBBtn
                className="btn-close"
                color="none"
                aria-label="Close"
                onClick={() => setLogoutModal(false)}
              />
            </div>
            <div className="text-center">
              <img src={subtract} alt="warning-icon" />
            </div>

            <p className="hcp-alert" style={{ color: "black" }}>
              {t("Are you sure want to log out?")}
            </p>
            <div className="button-container">
              <button className="btn form-control patient-signup-button py-3" onClick={() => logout()}>
                <b>{t("LOG OUT")}</b>
              </button>
            </div>
            <div className="button-container">
              <button className="btn form-control patient-login-button py-3" onClick={() => setLogoutModal(false)}>
                <b>{t("CANCEL")}</b>
              </button>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    registerPopup: state.patient.registerPopup,
    token: state.patient.token,
    patient: state.patient.patient,
    language: state.patient.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateRegisterPopup: data => dispatch(updateRegisterPopup(data)),
    patientCheckToken: data => dispatch(patientCheckToken(data)),
    homepageNavigation: data => dispatch(homepageNavigation(data)),
    patientLogout: () => dispatch(patientLogout()),
    updatePatientLanguage: data => dispatch(updatePatientLanguage(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientHome);
