import React, { useState, useRef } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import { updateRegisterPopup } from "../redux/actions/patientActions";
import { Trans, useTranslation } from 'react-i18next';

const PatientPrivacyPolicy = ({ updateRegisterPopup }) => {

  const navigate = useNavigate();
  const scrollRef = useRef();
  const { t } = useTranslation();

  const [disableButton, setDisableButton] = useState(true);

  const onScroll = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 1) {
        setDisableButton(false);
      }
    }
  }

  const handleSubmit = e => {
    e.preventDefault();

    updateRegisterPopup(true);
    navigate("/patient/patientHome");
  }

  return (
    <div className="patient-terms-and-condition-frame">
      <div className="text-center">
        <h3>{t("Privacy Policy")}</h3>
      </div>
      <div className="hcp-terms-text" onScroll={() => onScroll()} ref={scrollRef}>

        <div className="text-center">
          <h6>CLICK SERVICES SDN BHD</h6>
          <h6>{t("TERMS AND CONDITIONS (AGREEMENT) FOR PATIENTS iCARE CONNECT+")}</h6>
        </div>
        <div>
          <p>{t("We wish to support you by providing a treatment assistance program for healthcare products. The Program (as defined below) endeavors to promote accessibility and treatment adherence of healthcare products through the provision of product redemption to you. The Product redemption provided through the Program is only offered by Partners that have signed up for the Program. Redemption will be made only on basis of the prescription, which will be verified from your end.")}</p>
          <p>{t('By registering and using the App, you agree to the terms and conditions set forth below by the Company. If you do not agree to the outlined terms and conditions (the "Terms and Conditions"), you are not allowed to participate in the Program or to use the App. The Terms and Conditions may change from time to time, and the Company will notify you of these changes. Your continued use of the App once the Terms and Conditions have been amended constitutes your acceptance of the amended Terms and Conditions.')}</p>
          <p><b>{t("Definition")}:</b></p>
          <ol>
            <li>{t('“App” shall refer to iCARE CONNECT+ digital mobile platform, that serves to provide treatment support services. The App allows the users to do submission to the Company to verify their Redemption eligibility. The App operates on SMS, smartphone, web and/or other technology-based platforms.')}</li>
            <li>{t('“Company” shall refer to Click Services Sdn Bhd.')}</li>
            <li>{t('“Healthcare Professionals” shall refer to healthcare professionals in Malaysia, which includes, but not limited to, medical practitioners, pharmacists, nurses, medical assistants and other allied healthcare professionals and any other person involved in the giving of medical, health, pharmaceutical and any other healthcare services under the jurisdiction of the Ministry of Health of Malaysia that are duly-registered and/or duly-licensed at all times.')}</li>
            <li>{t('“Partners” shall refer to the participating hospitals and/or clinics for this Program.')}</li>
            <li>{t('“Program” shall refer to iCARE CONNECT+ Program for private hospitals and clinics in Malaysia.')}</li>
            <li>{t('“Product” or “Products”, whichever applicable, shall refer to the healthcare products that are prescribed under the Program.')}</li>
            <li>{t('“Redemption” shall refer to the entitlement of the patient to redeem the Products, provided that requirements set out under these Terms and Conditions are satisfied.')}</li>
            <li>{t('“You” shall refer to the patient who is in need of the Products, and shall include customers who are purchasing the Products on behalf of the patient from the Partners under the Program.')}</li>
          </ol>
          <p><b>{t("How this Program works")}:</b></p>
          <ol>
            <li>{t("The Program is only applicable for Products that are purchased from Partners.")}</li>
            <li><Trans i18nKey="programWorks" components={{ b: <b />, sup: <sup /> }}>The Program begins on <b>7<sup>th</sup> Jun 2022</b> and will continue until terminated by Viatris (“Term”). Where Viatris decides to terminate the Program, 30 days prior notice will be provided by the Company to the patient.</Trans></li>
            <li>{t("In order to help your doctors to manage your health conditions, benefits under the Program are only provided in respect of the Products, and ONLY IF the Products have been adequately prescribed by a Healthcare Professional qualified to do so.")}</li>
            <li>{t("For every purchase of Product prescribed by a Healthcare Professional, a submission summary pop-up and notification will be sent to you. You will be required to check and confirm the submission details, which details will be then recorded and updated in the app. Failing of confirmation, which you shall not be entitled to the benefits under this Program.")}</li>
            <li>{t("In order for you to redeem the prescribed Product, you are required show your redemption code of the same medication. Please enquire with your healthcare providers about the redemption details.")}</li>
            <li>{t("Upon the first submission, you shall enter a Program period of one (1) year. Thereafter, any Program renewal requires new submission.")}</li>
            <li>{t("You will receive an SMS notification with a redemption code once the redemption is verified and approved by the Company.")}</li>
            <li>{t("You will need to show the redemption code to our Partners in order to collect your redemption medication.")}</li>
            <li>{t("All Redemptions will be checked and verified by the Company.")}</li>
            <li>{t("You must redeem or collect the complimentary Product within 90 days of receiving the verification / redemption code, after which you are no longer eligible to receive the complimentary Product and the relevant verification / redemption code shall be void.")}</li>
            <li>{t("There is a cap on the maximum annual quantity allowed for redemption of each Product. Please refer to our Partners for more details.")}</li>
          </ol>
          <p><b>{t("Terms and Conditions")}</b></p>
          <ol>
            <li>{t("You are only eligible to participate in the Program if you are prescribed by our Partners any of the Products offered and marketed by Viatris.")}</li>
            <li>{t("You will assist the Company to prevent fraudulent use of the Program and shall extend your assistance to the Company in any investigation relating to the Program, which may include but not limited to, fraudulent activities.")}</li>
            <li>{t("The Company reserves the rights, at its sole and absolute discretion, to suspend, cancel or amend the Program at any time, as it deems fit, and the Company will provide 30 days' prior notice for the same.")}</li>
            <li>{t("The Company reserves the right, at its sole and absolute discretion, to disqualify or terminate your participation, if you are deemed by the Company to be in violation of any terms contained herein.")}</li>
            <li>{t("You shall comply and abide by all instructions and recommendations given to you by the Healthcare Professional attending to you in respect of the Product and/or Program.")}</li>
            <li>{t("You hereby agree that Company will not be liable in contract, tort (including negligence), statute or otherwise for any direct, indirect, incidental, punitive, or consequential damages arising from your wrongful or negligent consumption of the Product, the Partners’ negligent prescription of the Products, the Partners’ and/or your negligent usage of the App or for any other claim related in any way to the negligent use of or inability to use the App or for any breach of any applicable laws and regulations.")}</li>
            <li>{t("Pursuant to the Malaysian Personal Data Protection Act 2010, all personal data received as a result of the participation in the Program and App shall be kept strictly confidential and will be used, processed, analyzed, shared etc. only for purposes deemed reasonably necessary to execute the Program.")}</li>
            <li>{t("The data in relation to Redemption is not meant to assess prescriptions pattern or to be used to form decision for provision of any potential sponsorship, support for attendance at meeting, speaking or consultancy engagement, provision of funding, or any other benefits in cash or kind.")}</li>
            <li>{t("To the extent that the Company determines that it is commercially reasonable to implement an electronic data migration and interchange system in relation to the Program, your continued participation in the Program constitutes your acceptance of the aforementioned implementation. Electronic data migration and interchange system refers to the moving of data and information regarding the Program electronically.")}</li>
            <li>{t("Should any of the provisions under these Terms and Conditions be considered unlawful, void or otherwise unenforceable, then such provisions shall be deemed severable from these Terms and Conditions and not affect the validity and enforceability of any other provisions. To the extent any express or implied restrictions are not permitted by applicable laws, these express or implied restrictions shall remain in force and effect to the maximum extent permitted by such applicable laws.")}</li>
            <li>{t("These Terms and Conditions shall be governed by and construed in accordance with the laws of Malaysia, and any dispute in relation thereto shall be subject to the jurisdiction of the Malaysia courts.")}</li>
          </ol>
        </div>

      </div>
      <form onSubmit={handleSubmit}>
        <div className="hcp-agree">
          <Checkbox color="default" required disabled={disableButton} />
          <p className="hcp-agree-text">{t("I agree with the privacy policy.")}</p>
        </div>
        <div className="hcp-button-agree">
          <div className="button-container">
            <button type="submit" className="btn form-control hcp-signup-button py-3" style={{ marginBottom: "1rem" }} disabled={disableButton}>
              <b>{t("Continue")}</b>
            </button>
            <p className="text-center">{t("Please scroll down to the end to continue")}</p>
          </div>
        </div>
      </form>
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    updateRegisterPopup: data => dispatch(updateRegisterPopup(data))
  };
};

export default connect(null, mapDispatchToProps)(PatientPrivacyPolicy);
